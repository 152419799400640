import { Components, Theme } from "@mui/material";
export const MuiTableCell: Components<
  Omit<Theme, "components">
>["MuiTableCell"] = {
  styleOverrides: {
    head: ({ theme }) => ({
      boxShadow: "inset -1px 0 0 0 rgba(3, 4, 5, 0.1)",
      borderBottom: "none",
      padding: "0 16px",
      fontSize: theme.typography.inter14.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.colors.gray.o70,
      textTransform: "uppercase",

      "&:last-child": {
        boxShadow: "none",
      },
    }),
    body: ({ theme }) => ({
      height: 72,
      padding: theme.spacing(2),
      backgroundColor: theme.palette.common.white,
      borderBottom: "none",
      boxShadow: "0 1px 0 0 rgba(0, 0, 0, 0.1)",
      fontSize: theme.typography.inter18.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      "&:last-child": {
        boxShadow: "1px 1px 0 0 rgba(0, 0, 0, 0.1)",
      },
    }),
    sizeSmall: ({ theme }) => ({
      padding: theme.spacing(1),
      height: "3rem",
      fontSize: theme.typography.inter14.fontSize,
      fontWeight: theme.typography.fontWeightMedium,
    }),
  },
};
