import { Box, BoxProps, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";

export const LoadingPage = styled(
  (props: BoxProps) => (
    <Box {...props}>
      <CircularProgress />
    </Box>
  ),
  {
    shouldForwardProp: (props) => props !== "page",
  }
)<{ page?: boolean }>(({ page }) => ({
  display: "flex",
  flexGrow: 1,
  alignItems: "center",
  justifyContent: "center",
  height: page ? "100%" : "100vh",
}));

export default LoadingPage;
