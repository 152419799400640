import { AxiosInstance } from "axios";
import { RangeRoomService } from "services/RevenueManagement/Rate";

export interface RateRangeRoomServiceModel {
  create: ReturnType<typeof RangeRoomService.create>;
  getAll: ReturnType<typeof RangeRoomService.getAll>;
  getOneById: ReturnType<typeof RangeRoomService.getOneById>;
  remove: ReturnType<typeof RangeRoomService.remove>;
}

export const initRateRangeRoomService = (
  axios: AxiosInstance
): RateRangeRoomServiceModel => ({
  create: RangeRoomService.create(axios),
  getAll: RangeRoomService.getAll(axios),
  getOneById: RangeRoomService.getOneById(axios),
  remove: RangeRoomService.remove(axios),
});
