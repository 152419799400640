import { AxiosInstance } from "axios";
import { RateDependenciesService } from "services";

export interface SettingsDependenciesServiceModel {
  getAll: ReturnType<typeof RateDependenciesService.getAll>;
}

export const initSettingsDependenciesService = (
  axios: AxiosInstance
): SettingsDependenciesServiceModel => ({
  getAll: RateDependenciesService.getAll(axios),
});
