import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const ChevronDownIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.293 8.293a1 1 0 0 1 1.414 0L12 13.585l5.293-5.292a1 1 0 0 1 1.32-.083l.094.083a1 1 0 0 1 0 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414z"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};
