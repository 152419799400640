import { AxiosInstance } from "axios";
import { ReservationRoomsAndRatesAddonsService } from "services/Reservations/RoomsAndRates";

export interface RoomsAndRatesAddonsServiceModel {
  create: ReturnType<typeof ReservationRoomsAndRatesAddonsService.create>;
  getByRoomRate: ReturnType<
    typeof ReservationRoomsAndRatesAddonsService.getByRoomRate
  >;
  remove: ReturnType<typeof ReservationRoomsAndRatesAddonsService.remove>;
}

export const initRoomsAndRatesAddonsService = (
  axios: AxiosInstance
): RoomsAndRatesAddonsServiceModel => ({
  create: ReservationRoomsAndRatesAddonsService.create(axios),
  getByRoomRate: ReservationRoomsAndRatesAddonsService.getByRoomRate(axios),
  remove: ReservationRoomsAndRatesAddonsService.remove(axios),
});
