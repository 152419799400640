import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { DepositRequestModel } from "models/Settings/RatesAndPromo/Rules/DepositRequestModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";

export const remove =
  (axios: AxiosInstance) =>
  async (id: number): Promise<DepositRequestModel> => {
    return await axios.delete(
      `${SettingsEndpoint.SETTINGS_RATES_RULE_DEPOSIT_REQUEST}/${id}`
    );
  };

export const getAll =
  (axios: AxiosInstance) =>
  async (
    params?: FetchingRequestParamsModels | undefined
  ): Promise<DepositRequestModel[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_RATES_RULE_DEPOSIT_REQUEST, { params })
      .then(({ data }) => data.items);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: DepositRequestModel): Promise<DepositRequestModel> => {
    return await axios
      .post(SettingsEndpoint.SETTINGS_RATES_RULE_DEPOSIT_REQUEST, data)
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (data: DepositRequestModel): Promise<DepositRequestModel> => {
    return await axios
      .put(
        `${SettingsEndpoint.SETTINGS_RATES_RULE_DEPOSIT_REQUEST}/${data.id}`,
        data
      )
      .then(({ data }) => data);
  };

export const toggleStatus =
  (axios: AxiosInstance) =>
  async (id: number): Promise<DepositRequestModel> => {
    return await axios
      .patch(`${SettingsEndpoint.SETTINGS_RATES_RULE_DEPOSIT_REQUEST}/${id}`)
      .then(({ data }) => data);
  };
