import { RatesEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance, AxiosResponse } from "axios";
import { RateRangeLevelModel } from "models/RevenueManagement/Rate/RateRangeLevelModel";
import { sprintf } from "sprintf-js";

export const getAll =
  (axios: AxiosInstance) =>
  async (
    rateId: number,
    rangeId: number
  ): Promise<Array<RateRangeLevelModel>> => {
    return await axios
      .get(sprintf(RatesEndpoint.RATE_RANGE_LEVEL_GET_ALL, rateId, rangeId))
      .then(({ data }) => data);
  };

export const getOneById =
  (axios: AxiosInstance) =>
  async (
    rateId: number,
    rangeId: number,
    id: number
  ): Promise<RateRangeLevelModel> => {
    return await axios
      .get(sprintf(RatesEndpoint.RATE_RANGE_LEVEL_GET_ONE, rateId, rangeId, id))
      .then(({ data }) => data);
  };

export const create =
  (axios: AxiosInstance) =>
  async (
    rateId: number,
    rangeId: number,
    payload: Omit<RateRangeLevelModel, "id" | "level" | "rooms">
  ): Promise<RateRangeLevelModel> => {
    return await axios
      .post(
        sprintf(RatesEndpoint.RATE_RANGE_LEVEL_CREATE, rateId, rangeId),
        payload
      )
      .then(({ data }) => data);
  };

export const remove =
  (axios: AxiosInstance) =>
  async (
    rateId: number,
    rangeId: number,
    id: number
  ): Promise<AxiosResponse<any>> => {
    return await axios.delete(
      sprintf(RatesEndpoint.RATE_RANGE_LEVEL_DELETE, rateId, rangeId, id)
    );
  };
