import { AxiosInstance } from "axios";
import { UserService } from "services";

export interface UserServiceModel {
  whoami: ReturnType<typeof UserService.whoami>;
}

export const initUserService = (axios: AxiosInstance): UserServiceModel => ({
  whoami: UserService.whoami(axios),
});
