import { Components, Theme } from "@mui/material";

export const MuiStep: Components<Omit<Theme, "components">>["MuiStep"] = {
  styleOverrides: {
    root: {
      padding: 0,
      "&:not(:last-child)": {
        marginRight: 3,
      },
    },
    alternativeLabel: {
      // border: "solid 1px green",
    },
  },
};
