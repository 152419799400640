import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { CurrencyModel } from "models/RevenueManagement/Rate/CurrencyModel";

export const getAll =
  (axios: AxiosInstance) => async (): Promise<CurrencyModel[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_FINANCIAL_CURRENCY)
      .then(({ data }) => data.items);
  };
