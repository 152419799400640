import { AxiosInstance } from "axios";
import { initSettingCardService, SettingCardServiceModel } from "./Cards";
import { CountryServiceModel, initCountryService } from "./CountryService";
import {
  initSettingFinancialService,
  SettingFinancialServiceModel,
} from "./Financial";
import { HelperServicesModel, initHelperServices } from "./HelperServices";
import {
  initSettingReservationService,
  SettingReservationServiceModel,
} from "./Reservation";
import { AclServiceModel, initAclService } from "./Acl";
import {
  DepartmentAndStaffServiceModel,
  initDepartmentAndStaffService,
} from "../HotelSettingServices/DepartmentsAndStaffServices";
import {
  initNationalityService,
  NationalityServiceModel,
} from "./NationalityService";

export interface ChainSettingServiceModel {
  financial: SettingFinancialServiceModel;
  reservation: SettingReservationServiceModel;
  cards: SettingCardServiceModel;
  country: CountryServiceModel;
  helper: HelperServicesModel;
  acl: AclServiceModel;
  departmentAndStaff: DepartmentAndStaffServiceModel;
  nationality: NationalityServiceModel;
}

export const initSettingService = (
  axios: AxiosInstance
): ChainSettingServiceModel => ({
  financial: initSettingFinancialService(axios),
  cards: initSettingCardService(axios),
  country: initCountryService(axios),
  reservation: initSettingReservationService(axios),
  helper: initHelperServices(axios),
  acl: initAclService(axios),
  departmentAndStaff: initDepartmentAndStaffService(axios),
  nationality: initNationalityService(axios),
});
