import { Components, Theme } from "@mui/material";
import { ChevronLeftIcon } from "../../components/SvgIcons/ChevronLeftIcon";
import { ChevronLeftTwoIcon } from "../../components/SvgIcons/ChevronLeftTwoIcon";
import { ChevronRightIcon } from "../../components/SvgIcons/ChevronRightIcon";
import { ChevronRightTwoIcon } from "../../components/SvgIcons/ChevronRightTwoIcon";

export const MuiPaginationItem: Components<
  Omit<Theme, "components">
>["MuiPaginationItem"] = {
  defaultProps: {
    components: {
      next: ChevronRightIcon,
      previous: ChevronLeftIcon,
      first: ChevronLeftTwoIcon,
      last: ChevronRightTwoIcon,
    },
  },
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: 0,
      backgroundColor: theme.palette.common.white,
      width: 48,
      height: 48,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "0 4px",
      boxShadow: "0 1px 0 0 rgba(0, 0, 0, 0.1)",
      "&:hover": {
        border: `solid 2px ${theme.palette.colors.purple.o70}`,
        backgroundColor: theme.palette.common.white,
        "& .MuiPaginationItem-text": {
          color: theme.palette.colors.purple.o70,
        },
        "& .MuiPaginationItem-icon": {
          color: theme.palette.colors.purple.o70,
        },
        "& .Mui-selected": {
          color: theme.palette.common.white,
        },
      },
      "&:disabled": {
        backgroundColor: theme.palette.common.white,
        opacity: 1,
        color: theme.palette.colors.gray.o30,
      },
    }),
    text: {
      color: "#030405",
      fontSize: 16,
      fontWeight: 500,
      textAlign: "center",
    },
  },
};
