import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import * as React from "react";

export const PlusIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M11 4v7H4a1 1 0 0 0 0 2h7v7a1 1 0 0 0 2 0v-7h7a1 1 0 0 0 0-2h-7V4a1 1 0 0 0-2 0z"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};
