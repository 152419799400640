import { AxiosInstance } from "axios";
import { ReservationRoomPlanService } from "services/Reservations";

export interface RoomPlanService {
  getAll: ReturnType<typeof ReservationRoomPlanService.getAll>;
  update: ReturnType<typeof ReservationRoomPlanService.update>;
  getToAssign: ReturnType<typeof ReservationRoomPlanService.getToAssign>;
}

export const initRoomPlanService = (axios: AxiosInstance): RoomPlanService => ({
  getAll: ReservationRoomPlanService.getAll(axios),
  update: ReservationRoomPlanService.update(axios),
  getToAssign: ReservationRoomPlanService.getToAssign(axios),
});
