import { EndOfDayEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import {
  EndOfDayDataModel,
  EndOfDayUpdateModel,
} from "models/EndOfDay/EndOfDayModel";
import { CheckEndOfDayModel } from "pages/RevenuManagementModule/pages/EndOfDateCheckPage/models/CheckEndOfDayModel";

export const getAll =
  (axios: AxiosInstance) => async (): Promise<EndOfDayDataModel> => {
    return await axios
      .get(EndOfDayEndpoint.END_OF_DAY_TASKS_GET)
      .then(({ data }) => data);
  };

export const checkEndOfDay =
  (axios: AxiosInstance) => async (): Promise<CheckEndOfDayModel> => {
    return await axios
      .get(EndOfDayEndpoint.END_OF_DAY_CHECK)
      .then(({ data }) => data);
  };

export const runEndOfDay =
  (axios: AxiosInstance) => async (): Promise<EndOfDayDataModel> => {
    return await axios
      .get(EndOfDayEndpoint.END_OF_DAY_RUN)
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (data: EndOfDayUpdateModel): Promise<EndOfDayDataModel> => {
    return await axios
      .put(EndOfDayEndpoint.END_OF_DAY_TASKS_UPDATE, data)
      .then(({ data }) => data?.items);
  };
