import { AxiosInstance } from "axios";
import { NosShowPenalityService } from "services";

export interface NosShowPenalityServiceModel {
  getAll: ReturnType<typeof NosShowPenalityService.getAll>;
  create: ReturnType<typeof NosShowPenalityService.create>;
  update: ReturnType<typeof NosShowPenalityService.update>;
  remove: ReturnType<typeof NosShowPenalityService.remove>;
  toggleStatus: ReturnType<typeof NosShowPenalityService.toggleStatus>;
}

export const initNosShowPenalityService = (
  axios: AxiosInstance
): NosShowPenalityServiceModel => ({
  getAll: NosShowPenalityService.getAll(axios),
  create: NosShowPenalityService.create(axios),
  update: NosShowPenalityService.update(axios),
  remove: NosShowPenalityService.remove(axios),
  toggleStatus: NosShowPenalityService.toggleStatus(axios),
});
