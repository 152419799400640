import { ReservationsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { StayCardGeneralInfosModel } from "models/Reservations/StayCard/ReservationStayCardModel";
import { sprintf } from "sprintf-js";

type GetParamsType = {
  voucher: string | number;
  confirmation_number: string | number;
  guest: string | number;
};

type PostParamsType = {
  voucher: string | number;
  confirmation_number: string | number;
  guest: string | number;
  room_reservation: string | number;
  data: StayCardGeneralInfosModel;
};

export const getStayCardGeneralInfos =
  (axios: AxiosInstance) =>
  async ({
    guest,
    ...params
  }: GetParamsType): Promise<StayCardGeneralInfosModel> => {
    return await axios
      .get(
        sprintf(
          ReservationsEndpoint.RESERVATION_STAY_CARD_GENERAL_INFOS_GET,
          guest
        ),
        { params }
      )
      .then(({ data }) => data);
  };

export const saveStayCardGeneralInfos =
  (axios: AxiosInstance) =>
  async ({
    guest,
    room_reservation,
    data,
    ...params
  }: PostParamsType): Promise<StayCardGeneralInfosModel> => {
    return await axios
      .post(
        sprintf(
          ReservationsEndpoint.RESERVATION_STAY_CARD_GENERAL_INFOS_POST,
          guest,
          String(room_reservation)
        ),
        { ...data }
      )
      .then(({ data }) => data);
  };
