export enum ChargeCodeTypesModel {
  ROOM = "room",
  ADD_ON = "add_on",
  OTHER_REVENUE = "other_revenue",
  A_AND_R_MANUAL = "a_and_r_manual",
  F_AND_B = "f_and_b",
  PAYMENT = "payment",
  TAX = "tax",
  TOURISTIC_TAX = "touristic_tax",
  FEES = "fees",
}
