import common from "./common.json";
import list from "./list.json";
import create from "./create.json";

const RateLangConfig = {
  common,
  list,
  create,
};

export default RateLangConfig;
