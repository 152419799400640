import { AxiosInstance } from "axios";
import { ReservationPaymentType } from "services/Reservations";

export interface ReservationPaymentTypeService {
  getAll: ReturnType<typeof ReservationPaymentType.getAll>;
  calculateAmount: ReturnType<typeof ReservationPaymentType.calculateAmount>;
}

export const initReservationPaymentTypeService = (
  axios: AxiosInstance
): ReservationPaymentTypeService => ({
  getAll: ReservationPaymentType.getAll(axios),
  calculateAmount: ReservationPaymentType.calculateAmount(axios),
});
