import { ReservationsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import ForecastAvailabilityModel from "models/Reservations/Forecast/ForecastAvailabilityModel";
import ForecastPaxModel from "models/Reservations/Forecast/ForecastPaxModel";
import ForecastRoomModel from "models/Reservations/Forecast/ForecastRoomModel";
import { ForcecastMenuRoomEnum } from "pages/ReservationModule/pages/ForecastPage/hooks/useForecastData";

type ForecastParams = {
  from: string;
  to: string;
  scope?: ForcecastMenuRoomEnum;
};

export const getAvailability =
  (axios: AxiosInstance) =>
  async (params: ForecastParams): Promise<ForecastAvailabilityModel[]> => {
    return await axios
      .get(ReservationsEndpoint.RESERVATION_FORCAST_AVAILABILITY_GET_ALL, {
        params,
      })
      .then(({ data }) => data);
  };

export const getRoom =
  (axios: AxiosInstance) =>
  async (params: ForecastParams): Promise<ForecastRoomModel[]> => {
    return await axios
      .get(ReservationsEndpoint.RESERVATION_FORCAST_ROOM_GET_ALL, {
        params,
      })
      .then(({ data }) => data);
  };

export const getPax =
  (axios: AxiosInstance) =>
  async (params: ForecastParams): Promise<ForecastPaxModel[]> => {
    return await axios
      .get(ReservationsEndpoint.RESERVATION_FORCAST_PAX_GET_ALL, {
        params,
      })
      .then(({ data }) => data);
  };
