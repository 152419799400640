import { AxiosInstance } from "axios";
import { CustomReservation } from "services/Reservations";

export interface CustomReservationServiceModel {
  getCustomReservation: ReturnType<typeof CustomReservation.getCustomReservation>;
  updateCustomReservation: ReturnType<typeof CustomReservation.updateCustomReservation>;
  getTypeDataPerRoomRateCustomId: ReturnType<typeof CustomReservation.getTypeDataPerRoomRateCustomId>;
  recalculatePricing: ReturnType<typeof CustomReservation.recalculatePricing>;
  createBoardByRoomRate: ReturnType<typeof CustomReservation.createBoardByRoomRate>;
}

export const initCustomReservationService = (
  axios: AxiosInstance
): CustomReservationServiceModel => ({
  getCustomReservation: CustomReservation.getCustomReservation(axios),
  updateCustomReservation: CustomReservation.updateCustomReservation(axios),
  getTypeDataPerRoomRateCustomId: CustomReservation.getTypeDataPerRoomRateCustomId(axios),
  recalculatePricing: CustomReservation.recalculatePricing(axios),
  createBoardByRoomRate: CustomReservation.createBoardByRoomRate(axios),
});
