import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { GuestCardFieldsModel } from "models/Settings/Cards/GuestCardFieldsModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";

export const remove =
  (axios: AxiosInstance) =>
  async (id: number): Promise<GuestCardFieldsModel> => {
    return await axios.delete(
      `${SettingsEndpoint.SETTINGS_CARD_GUEST_GROUP_FIELD}/${id}`
    );
  };

export const getAll =
  (axios: AxiosInstance) =>
  async (
    params?: FetchingRequestParamsModels
  ): Promise<GuestCardFieldsModel[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_CARD_GUEST_GROUP_FIELD, {
        params,
      })
      .then(({ data }) => data.items);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: GuestCardFieldsModel): Promise<GuestCardFieldsModel> => {
    return await axios
      .post(SettingsEndpoint.SETTINGS_CARD_GUEST_GROUP_FIELD, data)
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (data: GuestCardFieldsModel): Promise<GuestCardFieldsModel> => {
    return await axios
      .put(
        `/${SettingsEndpoint.SETTINGS_CARD_GUEST_GROUP_FIELD}/${data.id}`,
        data
      )
      .then(({ data }) => data);
  };

export const toggleStatus =
  (axios: AxiosInstance) =>
  async (data: GuestCardFieldsModel): Promise<GuestCardFieldsModel> => {
    return await axios
      .put(
        `/${SettingsEndpoint.SETTINGS_CARD_GUEST_GROUP_FIELD}/${data?.id}`,
        data
      )
      .then(({ data }) => data);
  };
