import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import * as React from "react";

export const ArrowLeftIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M12.707 4.293a1 1 0 0 1 0 1.414L7.415 11H19a1 1 0 0 1 0 2H7.415l5.292 5.293a1 1 0 0 1 .083 1.32l-.083.094a1 1 0 0 1-1.414 0l-7-7-.073-.082a1.005 1.005 0 0 1-.007-.008l.08.09A1.008 1.008 0 0 1 4 12.02v-.037c0-.024.002-.048.004-.071L4 12a1.008 1.008 0 0 1 .213-.617c.025-.031.051-.062.08-.09l7-7a1 1 0 0 1 1.414 0z"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};
