import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AccessibiltyModel } from "@constants/system";
import { AxiosInstance } from "axios";

export const getAll =
  (axios: AxiosInstance) => async (): Promise<AccessibiltyModel> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_APPLICATION_INTERFACE_ACCESSIBILITY)
      .then(({ data }) => data);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: AccessibiltyModel): Promise<AccessibiltyModel[]> => {
    return await axios
      .post(SettingsEndpoint.SETTINGS_APPLICATION_INTERFACE_ACCESSIBILITY, data)
      .then(({ data }) => data);
  };
