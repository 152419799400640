import * as React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const ChevronRightIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M16 12 8 6v12z" fillRule="evenodd" />
    </SvgIcon>
  );
};
