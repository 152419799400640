import { AxiosInstance } from "axios";
import { QuickReservationService } from "services/QuickReservation";

export interface QuickReservationSearchService {
  search: ReturnType<typeof QuickReservationService.search>;
}

export const initQuickReservationSearchService = (
  axios: AxiosInstance
): QuickReservationSearchService => ({
  search: QuickReservationService.search(axios),
});
