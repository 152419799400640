import { CardsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance, AxiosProgressEvent, AxiosResponse } from "axios";
import { FileModel } from "models/RevenueManagement/Card/NoteModel";
import { ApiResponseType } from "services/ApiResultType";
import { sprintf } from "sprintf-js";

export const getAll =
  (axios: AxiosInstance) =>
  async (cardId: number): Promise<ApiResponseType<FileModel>> => {
    return await axios
      .get(sprintf(CardsEndpoint.CARD_FILE_GET_ALL, cardId))
      .then(({ data }) => data);
  };

export const download =
  (axios: AxiosInstance) =>
  async (cardId: number, fileId: number): Promise<any> => {
    return await axios.get(
      sprintf(CardsEndpoint.CARD_FILE_DOWNLOAD, cardId, fileId),
      {
        responseType: "blob",
      }
    );
  };

export const upload =
  (axios: AxiosInstance) =>
  async ({
    data,
    onUploadProgress,
  }: {
    data: any;
    onUploadProgress: (progress: number) => void;
  }): Promise<FileModel> => {
    const formdata = new FormData();
    formdata.append("file", data.file);
    formdata.append("card_id", data.card_id);
    return await axios
      .post(sprintf(CardsEndpoint.CARD_FILE_CREATE, data.card_id), formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          const progress = progressEvent.total
            ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
            : 0;
          onUploadProgress(progress);
        },
      })
      .then(({ data }) => data);
  };

export const remove =
  (axios: AxiosInstance) =>
  async (cardId: number, fileId: number): Promise<AxiosResponse<FileModel>> => {
    return await axios.delete(
      sprintf(CardsEndpoint.CARD_FILE_DELETE, cardId, fileId)
    );
  };
