import { Components, Theme } from "@mui/material";
import { ChevronDownIcon } from "components/SvgIcons/Chevrons/ChevronDownIcon";

export const MuiSelect: Components<Omit<Theme, "components">>["MuiSelect"] = {
  defaultProps: {
    IconComponent: ChevronDownIcon,
    MenuProps: {
      PaperProps: {
        style: {
          marginTop: 3,
          maxHeight: 260,
        },
      },
    },
  },

  styleOverrides: {
    select: ({ theme }) => ({
      overflowY: "auto",
      "&.MuiInputBase-input": {
        "&.MuiFilledInput-input": {
          paddingTop: theme.spacing(3),
        },
        "&.MuiInput-input": {
          "&.MuiSelect-icon": {},
          paddingRight: 36,
        },
        "&.MuiOutlinedInput-input": {
          padding: "11.5px 16px",
        },
      },
      "& svg": {
        display: "none",
      },
    }),
    icon: () => ({
      fontSize: "1rem",
    }),
  },
};
