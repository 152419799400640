import i18next from "i18next";
import React, { FC } from "react";
import { Outlet, RouteObject, useRouteError } from "react-router-dom";
import { Breadcrumb } from "routing/components/Breadcrumb";

const GroupsPage = React.lazy(() => import("../pages/GroupsPage"));
const GuestCardFieldsPage = React.lazy(
  () => import("../pages/GuestCardFieldsPage")
);
const LandingPage = React.lazy(() => import("../pages/LandingPage"));

const SettingsCardsBoundary: FC = (): JSX.Element => {
  let error = useRouteError() as Error;
  return (
    <>
      <h2>Error 💥</h2>
      <p>{error.message}</p>
    </>
  );
};

export const routePathsCards: RouteObject[] = [
  {
    index: true,
    element: <LandingPage />,
    errorElement: <SettingsCardsBoundary />,
  },
  {
    path: "company-ta-cards-group",
    element: <Outlet />,
    loader: (data) => {
      return data;
    },
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t(
            "settings:CardConfig.landingPage.company-ta-cards-group"
          )}
          path={"settings/cards/company-ta-cards-group"}
        />
      ),
    },
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <GroupsPage />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    path: "guest_card_fileds",
    element: <Outlet />,
    loader: (data) => {
      return data;
    },
    handle: {
      crumb: () => (
        <Breadcrumb
          label="Guest card fileds"
          path={"settings/cards/guest_card_fileds"}
        />
      ),
    },
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <GuestCardFieldsPage />
          </React.Suspense>
        ),
      },
    ],
  },
];
