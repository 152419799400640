import { AxiosInstance } from "axios";
import {
  ReservationReservationService,
  initReservationReservationService,
} from "./ReservationService";
import {
  ReservationQuickReservationService,
  initReservationQuickReservationService,
} from "./QuickReservation";
import {
  ReservationDependenciesServiceModel,
  initReservationDependenciesService,
} from "./ReservationDependenciesService";
import { FrontDeskService, initFrontDeskService } from "./FrontDesk";
import { ForecastService, initForecastService } from "./Forecast";

export interface ReservationsService {
  reservation: ReservationReservationService;
  quickReservation: ReservationQuickReservationService;
  dependencies: ReservationDependenciesServiceModel;
  frontDesk: FrontDeskService;
  forecast: ForecastService;
}

export const initReservationsService = (
  axios: AxiosInstance
): ReservationsService => ({
  reservation: initReservationReservationService(axios),
  quickReservation: initReservationQuickReservationService(axios),
  dependencies: initReservationDependenciesService(axios),
  frontDesk: initFrontDeskService(axios),
  forecast: initForecastService(axios),
});
