import { CircularProgress, Stack } from "@mui/material";
import { FC } from "react";

const PageSuspenseFallback: FC = () => (
  <Stack
    justifyContent="center"
    alignItems="center"
    sx={{ width: "100%", height: "100%" }}
  >
    <CircularProgress />
  </Stack>
);

export default PageSuspenseFallback;
