import { AxiosInstance } from "axios";
import {
  initSettingApplicationSettingsService,
  SettingApplicationSettingsServiceModel,
} from "./ApplicationSettings";
import {
  initSettingsDependenciesService,
  SettingsDependenciesServiceModel,
} from "./Dependencies/Rate";
import {
  initSettingFinancialService,
  SettingFinancialServiceModel,
} from "./Financial";
import {
  initSettingHouseKeepingService,
  SettingHouseKeepingServiceModel,
} from "./HouseKeeping";
import {
  initSettingRateAndPromoService,
  SettingRateAndPromoServiceModel,
} from "./RateAndPromo";
import {
  initSettingReservationService,
  SettingReservationServiceModel,
} from "./Reservation";
import {
  initSettingRevenuManagementService,
  SettingRevenuManagementServiceModel,
} from "./RevenuManagement";
import { initSettingRoomsService, SettingRoomsServiceModel } from "./Room";
import {
  initSettingCardService,
  SettingCardServiceModel,
} from "../ChainSettingServices/Cards";

export interface HotelSettingServiceModel {
  rateAndPromo: SettingRateAndPromoServiceModel;
  financial: SettingFinancialServiceModel;
  revenuManagement: SettingRevenuManagementServiceModel;
  room: SettingRoomsServiceModel;
  applicationSettings: SettingApplicationSettingsServiceModel;
  reservation: SettingReservationServiceModel;
  houseKeeping: SettingHouseKeepingServiceModel;
  dependencies: SettingsDependenciesServiceModel;
  cards: SettingCardServiceModel;
}

export const initHotelSettingService = (
  axios: AxiosInstance
): HotelSettingServiceModel => ({
  rateAndPromo: initSettingRateAndPromoService(axios),
  financial: initSettingFinancialService(axios),
  room: initSettingRoomsService(axios),
  applicationSettings: initSettingApplicationSettingsService(axios),
  revenuManagement: initSettingRevenuManagementService(axios),
  reservation: initSettingReservationService(axios),
  dependencies: initSettingsDependenciesService(axios),
  houseKeeping: initSettingHouseKeepingService(axios),
  cards: initSettingCardService(axios),
});
