import { SettingsPaths } from "@constants/Navigation/AppPaths";
import { Box } from "@mui/material";
import i18next from "i18next";
import React, { FC } from "react";
import { RouteObject, useRouteError } from "react-router-dom";
import { Breadcrumb } from "routing/components/Breadcrumb";
import FinancialAccountNumberPage from "../pages/FinancialAccountNumberPage";

const CurrencyPage = React.lazy(() => import("../pages/CurrencyPage"));
const LandingPage = React.lazy(() => import("../pages/LandingPage"));
const PaymentMethodPage = React.lazy(() => import("../pages/PaymentPage"));
const PaymentTypePage = React.lazy(() => import("../pages/PaymentTypesPage"));
const CashRegisterOperationPage = React.lazy(
  () => import("../pages/CashOprationRegisterPage")
);
const CashRegisterPage = React.lazy(() => import("../pages/CashRegisterPage"));
const CashCategoryPage = React.lazy(() => import("../pages/CashCategoryPage"));
const CashierPage = React.lazy(() => import("../pages/CashierPage"));
const FinancialAccountGroupPage = React.lazy(
  () => import("../pages/FinancialAccountGroupPage")
);

const SettingsCardsBoundary: FC = (): JSX.Element => {
  let error = useRouteError() as Error;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h2>Error 💥</h2>
      <p>{error.message}</p>
    </Box>
  );
};

export const FinancialRoutePaths: RouteObject[] = [
  {
    index: true,
    element: <LandingPage />,
    errorElement: <SettingsCardsBoundary />,
  },
  {
    path: "payment-method",
    element: (
      <React.Suspense fallback={<p>LoadingPage ...</p>}>
        <PaymentMethodPage />
      </React.Suspense>
    ),
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t(
            "settings:FinancialConfig.landingPage.payment-methods"
          )}
          path={SettingsPaths.SETTINGS_FINANCIAL_PAYMENT_METHOD_PATH}
        />
      ),
    },
  },
  {
    path: "payment-type",
    element: (
      <React.Suspense fallback={<p>LoadingPage ...</p>}>
        <PaymentTypePage />
      </React.Suspense>
    ),
    handle: {
      crumb: () => (
        <Breadcrumb
          label={"Payment Type"}
          path={SettingsPaths.SETTINGS_FINANCIAL_PAYMENT_TYPE_PATH}
        />
      ),
    },
  },
  {
    path: "cash-operation-register",
    element: (
      <React.Suspense fallback={<p>LoadingPage ...</p>}>
        <CashRegisterOperationPage />
      </React.Suspense>
    ),
    handle: {
      crumb: () => (
        <Breadcrumb
          label={"Cash Register Operation"}
          path={SettingsPaths.SETTINGS_FINANCIAL_CASH_OPERATION_REGISTER_PATH}
        />
      ),
    },
  },
  {
    path: "cash-register",
    element: (
      <React.Suspense fallback={<p>LoadingPage ...</p>}>
        <CashRegisterPage />
      </React.Suspense>
    ),
    handle: {
      crumb: () => (
        <Breadcrumb
          label={"Cash Register"}
          path={SettingsPaths.SETTINGS_FINANCIAL_CASH_REGISTER_PATH}
        />
      ),
    },
  },
  {
    path: "cash-category",
    element: (
      <React.Suspense fallback={<p>LoadingPage ...</p>}>
        <CashCategoryPage />
      </React.Suspense>
    ),
    handle: {
      crumb: () => (
        <Breadcrumb
          label={"Cash Category"}
          path={SettingsPaths.SETTINGS_FINANCIAL_CASH_CATGEORY_PATH}
        />
      ),
    },
  },
  {
    path: "cashier",
    element: (
      <React.Suspense fallback={<p>LoadingPage ...</p>}>
        <CashierPage />
      </React.Suspense>
    ),
    handle: {
      crumb: () => (
        <Breadcrumb
          label={"Cashier"}
          path={SettingsPaths.SETTINGS_FINANCIAL_CASHIER}
        />
      ),
    },
  },
  {
    path: "currency",
    element: (
      <React.Suspense fallback={<p>LoadingPage ...</p>}>
        <CurrencyPage />
      </React.Suspense>
    ),
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t("settings:FinancialConfig.landingPage.currency")}
          path={SettingsPaths.SETTINGS_FINANCIAL_CURRENCY_PATH}
        />
      ),
    },
  },
  {
    path: "account-group",
    element: (
      <React.Suspense fallback={<p>Loading Page ...</p>}>
        <FinancialAccountGroupPage />
      </React.Suspense>
    ),
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t("settings:FinancialConfig.landingPage.accountGroup")}
          path={null}
        />
      ),
    },
  },
  {
    path: "account-number",
    element: (
      <React.Suspense fallback={<p>Loading Page ...</p>}>
        <FinancialAccountNumberPage />
      </React.Suspense>
    ),
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t(
            "settings:FinancialConfig.landingPage.accountNumber"
          )}
          path={null}
        />
      ),
    },
  },
];
