import { AxiosInstance } from "axios";
import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { GroupModel, GroupRateModel } from "models/Settings/Cards/GroupModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";
import { sprintf } from "sprintf-js";

export const remove =
  (axios: AxiosInstance) =>
  async (id: number): Promise<GroupModel> => {
    return await axios.delete(`${SettingsEndpoint.SETTINGS_CARD_GROUP}/${id}`);
  };

export const getAll =
  (axios: AxiosInstance) =>
  async (params?: FetchingRequestParamsModels): Promise<GroupModel[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_CARD_GROUP, {
        params,
      })
      .then(({ data }) => data.items);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: GroupModel): Promise<GroupModel> => {
    return await axios
      .post(SettingsEndpoint.SETTINGS_CARD_GROUP, data)
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (data: GroupModel): Promise<GroupModel> => {
    return await axios
      .put(`/${SettingsEndpoint.SETTINGS_CARD_GROUP}/${data.id}`, data)
      .then(({ data }) => data);
  };

export const toggleStatus =
  (axios: AxiosInstance) =>
  async (id: number): Promise<GroupModel> => {
    return await axios
      .patch(`/${SettingsEndpoint.SETTINGS_CARD_GROUP}/${id}`)
      .then(({ data }) => data);
  };

export const getAllAssignedRates =
  (axios: AxiosInstance) =>
  async (
    params?: (FetchingRequestParamsModels & { groupId: number }) | undefined
  ): Promise<GroupRateModel[]> => {
    return await axios
      .get(
        sprintf(SettingsEndpoint.SETTINGS_CARD_GROUP_RATE, params?.groupId),
        {
          params,
        }
      )
      .then(({ data }) => data.items);
  };

export const defaultRate =
  (axios: AxiosInstance) =>
  async (
    params?:
      | (FetchingRequestParamsModels & { groupId: number; rateId: number })
      | undefined
  ): Promise<GroupRateModel[]> => {
    return await axios
      .patch(
        sprintf(
          SettingsEndpoint.SETTINGS_CARD_GROUP_RATE_PATCH,
          params?.groupId,
          params?.rateId
        )
      )
      .then(({ data }) => data);
  };
