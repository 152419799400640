import { Components, Theme } from "@mui/material";
export const MuiInputAdornment: Components<
  Omit<Theme, "components">
>["MuiInputAdornment"] = {
  styleOverrides: {
    root: {
      margin: 0,
    },
    positionEnd: {
      marginLeft: 0,
      marginRight: 16,
    },
    positionStart: {
      marginLeft: 16,
      marginRight: 8,
      ".MuiOutlinedInput-root > &": {
        marginRight: -8,
      },
    },
    // outlined: {
    //   marginRight: "8px",
    // },
    // standard: {
    //   marginRight: "8px",
    // },
    // filled: {
    //   marginRight: "8px",
    // },
  },
};
