import { Components, Theme } from "@mui/material";
export const MuiSwitch: Components<Omit<Theme, "components">>["MuiSwitch"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      margin: 0,
      padding: 0,
      width: 56,
      height: 32,

      "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 26,
        height: 26,
      },
      "& .MuiSwitch-track": {
        borderRadius: "16px",
        backgroundColor: theme.palette.colors.gray.o40,
        opacity: 1,
      },
    }),

    sizeSmall: ({ theme }) => ({
      width: 50,
      "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: "3px",
        borderRadius: "4px",
        "&.Mui-checked": {
          transform: "translateX(19px)",
        },
      },
    }),

    switchBase: ({ theme }) => ({
      padding: 0,
      margin: "3px",
      transitionDuration: "300ms",

      "&.Mui-checked": {
        transform: "translateX(24px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.colors.purple.o70,
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled": {
          color: theme.palette.colors.gray.o10,
          "& + .MuiSwitch-track": {
            backgroundColor: theme.palette.colors.gray.o30,
            opacity: 1,
          },
        },
      },
      "&.Mui-disabled": {
        color: theme.palette.colors.gray.o10,
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.colors.gray.o30,
          opacity: 1,
        },
      },
    }),
  },
};
