import React from "react";
import { Components, Theme, Slide, alpha } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const MuiDialog: Components<Omit<Theme, "components">>["MuiDialog"] = {
  defaultProps: {
    TransitionComponent: Transition,
  },
  styleOverrides: {
    paper: {
      borderRadius: 8,
      boxShadow: "0 16px 24px -8px rgba(0, 0, 0, 0.1)",
      padding: "24px 24px 32px 24px",
    },
    root: ({ theme }) => ({
      "& .MuiBackdrop-root": {
        backgroundColor: alpha(theme.palette.common.white, 0.8),
      },
    }),
  },
};
