import { AxiosInstance } from "axios";
import {
  CurrencyChainServiceModel,
  initCurrencyChainService,
} from "./Currency/CurrenyChainService";
import {
  CurrencyRevenuManagementServiceModel,
  initCurrencyRevenuManagementService,
} from "./Currency/CurrenyRevenuManagementService";
import {
  initPaymentMethodService,
  PaymentMethodServiceModel,
} from "./PaymentMethod/PaymentMethod";
import {
  initPaymentTypeService,
  PaymentTypeServiceModel,
} from "./PaymentType/PaymentType";

export interface SettingFinancialServiceModel {
  currencyChain: CurrencyChainServiceModel;
  currencyRenvuManagement: CurrencyRevenuManagementServiceModel;
  paymentMethod: PaymentMethodServiceModel;
  paymentType: PaymentTypeServiceModel;
}

export const initSettingFinancialService = (
  axios: AxiosInstance
): SettingFinancialServiceModel => ({
  currencyChain: initCurrencyChainService(axios),
  paymentMethod: initPaymentMethodService(axios),
  currencyRenvuManagement: initCurrencyRevenuManagementService(axios),
  paymentType: initPaymentTypeService(axios),
});
