import { Crumb } from "components/TranslatableCrub";
import { lazy, Suspense } from "react";
import { RoutePathDefinition } from "routing/routes";

const StayCardPage = lazy(() => import("../pages/StayCardPage"));

const CreateReservationPageNew = lazy(
  () => import("../pages/CreateReservationPageNew")
);

const ReservationListPage = lazy(() => import("../pages/ReservationListPage"));

const ForecastPage = lazy(() => import("../pages/ForecastPage"));

const ReservationRoutes: Array<RoutePathDefinition> = [
  {
    index: true,
    element: (
      <Suspense fallback={<>...</>}>
        <ReservationListPage />
      </Suspense>
    ),
    handle: {
      crumb: () => (
        <Crumb label="reservation.breadcrubms.find-reservation" path={null} />
      ),
    },
  },
  {
    path: "create-reservation",
    element: (
      <Suspense fallback={<>...</>}>
        <CreateReservationPageNew key="create-reservation" />
      </Suspense>
    ),
    handle: {
      crumb: () => (
        <Crumb label="reservation.breadcrubms.find-reservation" path={null} />
      ),
    },
  },
  {
    path: ":id/edit-reservation",
    element: (
      <Suspense fallback={<>...</>}>
        <CreateReservationPageNew key="edit-reservation" />
      </Suspense>
    ),
    handle: {
      crumb: () => (
        <Crumb label="reservation.breadcrubms.find-reservation" path={null} />
      ),
    },
  },

  {
    path: "stay-card/:guestId/:voucher/:confirmationNumber",
    element: (
      <Suspense fallback={<>...</>}>
        <StayCardPage />
      </Suspense>
    ),
    handle: {
      crumb: () => (
        <Crumb label="reservation.breadcrubms.stay-card" path={null} />
      ),
    },
  },

  {
    path: "forecast",
    element: (
      <Suspense fallback={<>...</>}>
        <ForecastPage />
      </Suspense>
    ),
    handle: {
      crumb: () => (
        <Crumb
          label="reservation.breadcrubms.forcast-available-room"
          path={null}
        />
      ),
    },
  },
];

export default ReservationRoutes;
