import { Typography, TypographyProps } from "@mui/material";
import { FC } from "react";

export const Error: FC<TypographyProps> = ({ children, sx, ...props }) => (
  <Typography
    gutterBottom
    align="left"
    variant="caption"
    color="error"
    sx={{ mt: 1, ...sx }}
    {...props}
  >
    {children}
  </Typography>
);
