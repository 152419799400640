import { AxiosInstance } from "axios";
import { MarketCodeService } from "services";

export interface MarketCodeServiceModel {
  getAll: ReturnType<typeof MarketCodeService.getAll>;
  getByParent: ReturnType<typeof MarketCodeService.getByParent>;
  create: ReturnType<typeof MarketCodeService.create>;
  update: ReturnType<typeof MarketCodeService.update>;
  remove: ReturnType<typeof MarketCodeService.remove>;
  toggleStatus: ReturnType<typeof MarketCodeService.toggleStatus>;
  getAllAssignedRates: ReturnType<typeof MarketCodeService.getAllAssignedRates>;
  defaultRate: ReturnType<typeof MarketCodeService.defaultRate>;
}

export const initMarketCodeService = (
  axios: AxiosInstance
): MarketCodeServiceModel => ({
  getAll: MarketCodeService.getAll(axios),
  getByParent: MarketCodeService.getByParent(axios),
  create: MarketCodeService.create(axios),
  update: MarketCodeService.update(axios),
  remove: MarketCodeService.remove(axios),
  toggleStatus: MarketCodeService.toggleStatus(axios),
  getAllAssignedRates: MarketCodeService.getAllAssignedRates(axios),
  defaultRate: MarketCodeService.defaultRate(axios),
});
