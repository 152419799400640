import { AxiosInstance } from "axios";
import { ArrivalService, initArrivalService } from "./ArrivalService";
import { DepartureService, initDepartureService } from "./DepartureService";
import { InHouseService, initInHouseService } from "./InHouseService";
import { RoomPlanService, initRoomPlanService } from "./RoomPlanService";

export interface FrontDeskService {
  arrival: ArrivalService;
  departure: DepartureService;
  inHouse: InHouseService;
  roomPlan: RoomPlanService;
}

export const initFrontDeskService = (
  axios: AxiosInstance
): FrontDeskService => ({
  arrival: initArrivalService(axios),
  departure: initDepartureService(axios),
  inHouse: initInHouseService(axios),
  roomPlan: initRoomPlanService(axios),
});
