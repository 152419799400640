import { Components, Theme } from "@mui/material";

export const MuiOutlinedInput: Components<
  Omit<Theme, "components">
>["MuiOutlinedInput"] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
        margin: 0,
      },
      "input[type=number]": {
        MozAppearance: "textfield",
      },
      padding: 0,
      borderRadius: 8,
      fontSize: theme.typography.inter16.fontSize,
      fontWeight: theme.typography.fontWeightMedium,
      "& > fieldset": {
        border: "none",
      },

      ...(!ownerState.error && {
        boxShadow: `inset 0px 0px 0px 1.5px ${theme.palette.colors.gray.o40}`,
      }),
      ...(!ownerState.disabled && {
        "&:hover": {
          backgroundColor: theme.palette.common.white,
        },
      }),
      ".background > &": {
        backgroundColor: theme.palette.common.white,
      },
      "&.MuiInputBase-colorPrimary": {
        ...(!ownerState.error &&
          !ownerState.disabled && {
            "&.Mui-focused": {
              boxShadow: `inset 0px 0px 0px 2px ${theme.palette.colors.purple.o70}`,
              backgroundColor: theme.palette.common.white,
            },
            "&:hover": {
              boxShadow: `inset 0px 0px 0px 2px ${theme.palette.colors.purple.o70}`,
            },
            ".background > &": {
              backgroundColor: theme.palette.common.white,
            },
          }),
      },
      ...(ownerState.error === true && {
        "&.Mui-error": {
          boxShadow: `inset 0px 0px 0px 1.5px ${theme.palette.colors.red.o40}`,
        },
      }),
      ...(ownerState.disabled && {
        backgroundColor: theme.palette.colors.gray.o20,
        boxShadow: "none",
      }),
    }),
  },
};
