import common from "./common.json";
import GroupConfig from "./group";
import landingPage from "./landing_page.json"

const CardConfig = {
    common,
    GroupConfig,
    landingPage
};

export default CardConfig;