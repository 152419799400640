import { AxiosInstance } from "axios";
import { RangeTemplateService } from "services";

export interface RangeTemplateServiceModel {
  getAll: ReturnType<typeof RangeTemplateService.getAll>;
  getDependencies: ReturnType<typeof RangeTemplateService.getDependencies>;
  getById: ReturnType<typeof RangeTemplateService.getById>;
  create: ReturnType<typeof RangeTemplateService.create>;
  update: ReturnType<typeof RangeTemplateService.update>;
  updateIsActive: ReturnType<typeof RangeTemplateService.updateIsActive>;
  remove: ReturnType<typeof RangeTemplateService.remove>;
}

export const initRangeTemplateService = (
  axios: AxiosInstance
): RangeTemplateServiceModel => ({
  getAll: RangeTemplateService.getAll(axios),
  getDependencies: RangeTemplateService.getDependencies(axios),
  getById: RangeTemplateService.getById(axios),
  create: RangeTemplateService.create(axios),
  update: RangeTemplateService.update(axios),
  updateIsActive: RangeTemplateService.updateIsActive(axios),
  remove: RangeTemplateService.remove(axios),
});
