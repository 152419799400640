import { AxiosInstance } from "axios";
import { RoomsListServices } from "services/Settings";

export interface RoomsListServiceModel {
  getAll: ReturnType<typeof RoomsListServices.getAll>;
  getOne: ReturnType<typeof RoomsListServices.getOne>;
  create: ReturnType<typeof RoomsListServices.create>;
  update: ReturnType<typeof RoomsListServices.update>;
  toggleStatus: ReturnType<typeof RoomsListServices.toggleStatus>;
  remove: ReturnType<typeof RoomsListServices.remove>;
}

export const initRoomsListServiceModel = (
  axios: AxiosInstance
): RoomsListServiceModel => ({
  getAll: RoomsListServices.getAll(axios),
  getOne: RoomsListServices.getOne(axios),
  create: RoomsListServices.create(axios),
  update: RoomsListServices.update(axios),
  toggleStatus: RoomsListServices.toggleStatus(axios),
  remove: RoomsListServices.remove(axios),
});
