import { FC } from "react";
import { Popper, PopperProps } from "@mui/material";
import { ChevronLeftIcon } from "components/SvgIcons/Chevrons/ChevronLeftIcon";
import { ChevronRightIcon } from "components/SvgIcons/Chevrons/ChevronRightIcon";

export const CalenderPopper: FC<PopperProps> = ({ children, ...props }) => (
  <Popper
    placement="bottom"
    sx={{
      zIndex: 2000,
    }}
    {...props}
  >
    {children}
  </Popper>
);

export const LeftButtonIcon: FC = () => (
  <ChevronLeftIcon sx={{ color: "colors.gray.o80" }} />
);

export const RightButtonIcon: FC = () => (
  <ChevronRightIcon sx={{ color: "colors.gray.o80" }} />
);
