import { Components, Theme } from "@mui/material";

export const MuiStepper: Components<Omit<Theme, "components">>["MuiStepper"] = {
  styleOverrides: {
    root: {
      "& .MuiStep": {
        border: "solid 1px red",
      },
    },
  },
};
