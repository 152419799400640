import { AxiosInstance } from "axios";
import { ReservationSummaryService } from "services/Reservations";

export interface ReservationSummaryServiceModel {
  getSummary: ReturnType<typeof ReservationSummaryService.getSummary>;
  saveSummary: ReturnType<typeof ReservationSummaryService.saveSummary>;
}

export const initReservationSummaryService = (
  axios: AxiosInstance
): ReservationSummaryServiceModel => ({
  getSummary: ReservationSummaryService.getSummary(axios),
  saveSummary: ReservationSummaryService.saveSummary(axios),
});
