import { AxiosInstance } from "axios";
import { RoleServices } from "services/Settings/ACL";

export interface RoleServiceModel {
  getAll: ReturnType<typeof RoleServices.getAll>;
  getById: ReturnType<typeof RoleServices.getById>;
  create: ReturnType<typeof RoleServices.create>;
  update: ReturnType<typeof RoleServices.update>;
  remove: ReturnType<typeof RoleServices.remove>;
}

export const initRoleService = (
  axios: AxiosInstance
): RoleServiceModel => ({
  getAll: RoleServices.getAll(axios),
  create: RoleServices.create(axios),
  update: RoleServices.update(axios),
  remove: RoleServices.remove(axios),
  getById: RoleServices.getById(axios),
});
