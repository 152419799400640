import { AxiosInstance } from "axios";
import { HouseKeepingWorkTaskServices } from "services/Settings";

export interface HouseKeepingWorkTaskServiceModel {
  getAll: ReturnType<typeof HouseKeepingWorkTaskServices.getAll>;
  getOne: ReturnType<typeof HouseKeepingWorkTaskServices.getOne>;
  create: ReturnType<typeof HouseKeepingWorkTaskServices.create>;
  update: ReturnType<typeof HouseKeepingWorkTaskServices.update>;
  patch: ReturnType<typeof HouseKeepingWorkTaskServices.patch>;
  remove: ReturnType<typeof HouseKeepingWorkTaskServices.remove>;
}

export const initHouseKeepingWorkTaskServiceModel = (
  axios: AxiosInstance
): HouseKeepingWorkTaskServiceModel => ({
  getAll: HouseKeepingWorkTaskServices.getAll(axios),
  getOne: HouseKeepingWorkTaskServices.getOne(axios),
  create: HouseKeepingWorkTaskServices.create(axios),
  update: HouseKeepingWorkTaskServices.update(axios),
  patch: HouseKeepingWorkTaskServices.patch(axios),
  remove: HouseKeepingWorkTaskServices.remove(axios),
});
