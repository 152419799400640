import { AxiosInstance } from "axios";
import { ComplimentaryCategoryService } from "services";

export interface ComplimentaryCategoryServiceModel {
  getAll: ReturnType<typeof ComplimentaryCategoryService.getAll>;
  create: ReturnType<typeof ComplimentaryCategoryService.create>;
  update: ReturnType<typeof ComplimentaryCategoryService.update>;
  remove: ReturnType<typeof ComplimentaryCategoryService.remove>;
  toggleStatus: ReturnType<typeof ComplimentaryCategoryService.toggleStatus>;
}

export const initComplimentaryCategoryService = (
  axios: AxiosInstance
): ComplimentaryCategoryServiceModel => ({
  getAll: ComplimentaryCategoryService.getAll(axios),
  create: ComplimentaryCategoryService.create(axios),
  update: ComplimentaryCategoryService.update(axios),
  remove: ComplimentaryCategoryService.remove(axios),
  toggleStatus: ComplimentaryCategoryService.toggleStatus(axios),
});
