import { FC } from "react";
import { Link } from "react-router-dom";

export interface BreadcrumbPropsModel {
  label: string;
  path: string | null;
}

export const Breadcrumb: FC<BreadcrumbPropsModel> = ({
  label,
  path,
}): JSX.Element => {
  return path ? (
    <Link style={{ textDecoration: "none", color: "inherit" }} to={path}>
      {label}
    </Link>
  ) : (
    <span>{label}</span>
  );
};
