import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const ChevronLeftIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.707 5.293a1 1 0 0 0-1.414 0l-6 6a1 1 0 0 0 0 1.414l6 6a1 1 0 1 0 1.414-1.414L10.415 12l5.292-5.293a1 1 0 0 0 .083-1.32l-.083-.094z"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};
