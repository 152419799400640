import { AxiosInstance } from "axios";
import { AeroportService } from "services";

export interface AeroportServiceModel {
  getAll: ReturnType<typeof AeroportService.getAll>;
  create: ReturnType<typeof AeroportService.create>;
  update: ReturnType<typeof AeroportService.update>;
  remove: ReturnType<typeof AeroportService.remove>;
  toggleStatus: ReturnType<typeof AeroportService.toggleStatus>;
}

export const initAeroportService = (
  axios: AxiosInstance
): AeroportServiceModel => ({
  getAll: AeroportService.getAll(axios),
  create: AeroportService.create(axios),
  update: AeroportService.update(axios),
  remove: AeroportService.remove(axios),
  toggleStatus: AeroportService.toggleStatus(axios),
});
