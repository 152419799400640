import { AxiosInstance } from "axios";
import {  HouseKeepingStateServices } from "services";

export interface HouseKeepingStateServicesModel {
  getAll: ReturnType<typeof HouseKeepingStateServices.getAll>;
  update: ReturnType<typeof HouseKeepingStateServices.update>;
  remove: ReturnType<typeof HouseKeepingStateServices.remove>;
  create: ReturnType<typeof HouseKeepingStateServices.create>;
  patch: ReturnType<typeof HouseKeepingStateServices.patch>;
}

export const initCheckConfigServices = (
  axios: AxiosInstance
): HouseKeepingStateServicesModel => ({
  getAll: HouseKeepingStateServices.getAll(axios),
  update: HouseKeepingStateServices.update(axios),
  remove: HouseKeepingStateServices.remove(axios),
  create: HouseKeepingStateServices.create(axios),
  patch: HouseKeepingStateServices.patch(axios),
});
