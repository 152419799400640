import { Box, CircularProgress } from "@mui/material";
import { createApiInstance } from "api/ApiInstance";
import { AxiosInstance } from "axios";
import { SessionExpired } from "components/RootContainer/SessionExpired";
import { AppRunningModeModel } from "models/Shared/AppRunningModeModel";
import { FC, ReactNode, useMemo } from "react";
import { useQuery } from "react-query";
import { WhoamiModel } from "services/UserService";
import PageContainer from "./components/PageContainer";

const PoliciesGuard: FC<{ children: ReactNode }> = ({ children }) => {
  const { hostname } = window.location;
  // const hostname = "onomo.staging.preprod.pms.lightresa.com";

  const hostnameSegments = useMemo(
    () =>
      hostname
        .replace(
          `.${String(process.env.REACT_APP_API_DOMAIN_SUFFIX).replace(
            "/api",
            ""
          )}`,
          ""
        )
        .split("."),
    [hostname]
  );

  if (
    hostnameSegments.length === 1 &&
    process.env.REACT_APP_MODE === AppRunningModeModel.STAGING
  ) {
    const baseURL = `https://${hostnameSegments[0]}.${process.env.REACT_APP_API_DOMAIN_SUFFIX}/chain`;
    return <ChainPage baseURL={baseURL} />;
  }

  return <>{children}</>;
};

export default PoliciesGuard;

const ChainPage: FC<{ baseURL: string }> = ({ baseURL }) => {
  const httpClient = useMemo(() => createApiInstance(baseURL), [baseURL]);

  const whoami =
    (axios: AxiosInstance) => async (): Promise<WhoamiModel | null> => {
      return await axios.get("/whoami");
    };

  const { data, isLoading } = useQuery({
    queryKey: ["whoami", baseURL],
    queryFn: () => whoami(httpClient)(),
    enabled: Boolean(baseURL),
  });

  if (isLoading) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!isLoading && !data) {
    return <SessionExpired />;
  }

  return <PageContainer user={data!} />;
};
