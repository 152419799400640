import { ReservationsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import {
  CustomReservationGlobalModel,
  CustomReservationModel,
  RoomRateCustomModel,
  RoomRateCustomPerRoomDataModel,
} from "models/Reservations/ReservationCustomModel";
import { BoardServiceModel } from "models/Settings/RatesAndPromo/BoardServiceModel";

import { sprintf } from "sprintf-js";

export interface BoardsPerRoomModel {
  id: number;
  name: string;
  boards: Pick<BoardServiceModel, "id" | "name">[];
}

export const getCustomReservation =
  (axios: AxiosInstance) =>
  async (
    reservationId: number,
    roomRateId: number
  ): Promise<RoomRateCustomModel<CustomReservationModel.ROOM>[]> => {
    return await axios
      .get(
        sprintf(
          ReservationsEndpoint.CUSTOM_RESERVARION,
          reservationId,
          roomRateId
        )
      )
      .then(({ data }) => data);
  };

export const updateCustomReservation =
  (axios: AxiosInstance) =>
  async (
    item: CustomReservationGlobalModel<CustomReservationModel>
  ): Promise<RoomRateCustomPerRoomDataModel> => {
    return await axios
      .put(
        sprintf(
          ReservationsEndpoint.CUSTOM_RESERVARION_TYPE
          // item.reservationId,
          // item.roomRateId,
          // item.id,
          // item.type
        ),
        { data: item }
      )
      .then(({ data }) => data);
  };

export const getTypeDataPerRoomRateCustomId =
  (axios: AxiosInstance) =>
  async (item: {
    reservationId: number;
    roomRateId: number;
    customId: number;
    type: string;
  }): Promise<any> => {
    return await axios
      .get(
        sprintf(
          ReservationsEndpoint.CUSTOM_RESERVARION_TYPE,
          item.reservationId,
          item.roomRateId,
          item.customId,
          item.type
        )
      )
      .then(({ data }) => data);
  };

export const createBoardByRoomRate =
  (axios: AxiosInstance) =>
  async (roomRateId: number): Promise<BoardsPerRoomModel> => {
    return await axios
      .post(
        sprintf(ReservationsEndpoint.RESERVATION_BOARD_DETAILS, roomRateId),
        {
          start_date: "2021-07-30",
          end_date: "2024-01-18",
        }
      )
      .then(({ data }) => data.items);
  };

export const recalculatePricing =
  (axios: AxiosInstance) =>
  async ({
    reservationId,
    roomRateId,
    customRoomId,
    type,
    data,
    pax_number,
    pax_type,
  }: {
    reservationId: number;
    roomRateId: number;
    customRoomId: number;
    type: string;
    data: any;
    pax_number?: number;
    pax_type?: string;
  }): Promise<any> => {
    return await axios
      .post(
        sprintf(
          ReservationsEndpoint.CUSTOM_RESERVARION_RECALCULATE_PRICE,
          reservationId,
          roomRateId,
          customRoomId
        ),
        {
          type,
          data,
          ...(type === CustomReservationModel.PAX
            ? {
                pax_number,
                pax_type,
              }
            : {}),
        }
      )
      .then(({ data }) => data);
  };

// get room data per room

// save data per room
