import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const DeleteFilledIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 .667a7.333 7.333 0 1 1 0 14.666A7.333 7.333 0 0 1 8 .667zm-1.896 4.73a.5.5 0 1 0-.708.707L7.293 8 5.396 9.896a.5.5 0 1 0 .708.708L8 8.707l1.896 1.897a.5.5 0 1 0 .708-.708L8.707 8l1.897-1.896a.5.5 0 1 0-.708-.708L8 7.293z"
      fillRule="evenodd"
    />
  </SvgIcon>
);
