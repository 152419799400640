import { Grid, IconButton, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ArrowIcon } from "components/SvgIcons/ArrowIcon";
import { SettingsIcon } from "components/SvgIcons/Shell/SettingsIcon";
import { FC } from "react";
import { WhoamiModel } from "services/UserService";
import HotelCard from "./HotelCard";

export const PageContent: FC<{
  user: WhoamiModel;
}> = ({ user }) => {
  return (
    <Stack sx={{ width: "53rem" }}>
      <Stack
        flexDirection="row"
        alignItems="center"
        columnGap={2}
        py={2}
        px={3}
        width={"100%"}
        borderRadius={16}
        bgcolor={"colors.purple.o20"}
      >
        <SettingsIcon
          fontSize="large"
          sx={{
            color: "colors.purple.o70",
          }}
        />
        <Box>
          <Typography
            variant="inter18"
            fontWeight={(theme) => theme.typography.fontWeightSemibold}
          >
            Super administrator area
          </Typography>
          <br />
          <Typography variant="inter14" fontWeight={"normal"}>
            Everything you need to manage your hotels and chains
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton
          // LinkComponent={LinkBehavior}
          // href="/"
          sx={{
            p: 0,
          }}
        >
          <ArrowIcon
            viewBox="0 0 15 15"
            sx={{
              color: "colors.purple.o70",
              width: "1.5rem",
              height: "1.5rem",
            }}
          />
        </IconButton>
      </Stack>

      <Box
        sx={{
          pt: 3,
        }}
      >
        <Stack flexDirection={"row"} alignItems={"center"}>
          <Typography
            variant="inter24"
            fontWeight={(theme) => theme.typography.fontWeightBold}
          >
            {user.chain ?? ""}
          </Typography>

          <Box sx={{ flexGrow: 1 }} />

          {/* <Button
            endIcon={<ChevronsRightIcon sx={{ stroke: "#6e47ed" }} />}
            // LinkComponent={LinkBehavior}
            // href="/"
            sx={{
              fontSize: (theme) => theme.typography.inter16.fontSize,
              fontWeight: (theme) => theme.typography.fontWeightSemibold,
              ":hover": {
                backgroundColor: "transparent",
              },
              ":focus": {
                backgroundColor: "transparent",
              },
            }}
          >
            Go to Chain
          </Button> */}
        </Stack>

        <Grid
          display={"grid"}
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          columnGap={2}
          rowGap={1}
          pt={3}
        >
          {user.hotels.map((item, index) => (
            <Stack key={index}>
              <HotelCard hotel={item} />
              <Box
                sx={{
                  width: "10.3rem",
                  alignContent: "center",
                  mx: "auto",
                  mt: 2,
                }}
              >
                <Typography
                  gutterBottom
                  variant="inter18"
                  fontWeight={(theme) => theme.typography.fontWeightSemibold}
                  component="div"
                  sx={{ textAlign: "center" }}
                >
                  {item.name}
                </Typography>
              </Box>
            </Stack>
          ))}
        </Grid>
      </Box>
    </Stack>
  );
};
