import { useTranslation } from "react-i18next";
import * as yup from "yup";

export default function useSchema() {
  const { t } = useTranslation("validation");

  const schema = yup.object().shape({
    account_number: yup.string().nullable().required(t("required")),
    description: yup.string().nullable().required(t("required")),
    account_group_id: yup.string().nullable().required(t("required")),
    is_active: yup.boolean(),
  });

  return schema;
}
