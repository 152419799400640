import { AxiosInstance } from "axios";
import { ReservationRoomsAndRatesService } from "services/Reservations/RoomsAndRates";

export interface RoomsAndRatesServiceModel {
  createOne: ReturnType<typeof ReservationRoomsAndRatesService.createOne>;
  updateOne: ReturnType<typeof ReservationRoomsAndRatesService.updateOne>;
  update: ReturnType<typeof ReservationRoomsAndRatesService.update>;
  getAll: ReturnType<typeof ReservationRoomsAndRatesService.getAll>;
  duplicate: ReturnType<typeof ReservationRoomsAndRatesService.duplicate>;
}

export const initRoomsAndRatesService = (
  axios: AxiosInstance
): RoomsAndRatesServiceModel => ({
  createOne: ReservationRoomsAndRatesService.createOne(axios),
  updateOne: ReservationRoomsAndRatesService.updateOne(axios),
  update: ReservationRoomsAndRatesService.update(axios),
  getAll: ReservationRoomsAndRatesService.getAll(axios),
  duplicate: ReservationRoomsAndRatesService.duplicate(axios),
});
