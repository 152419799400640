import { AxiosInstance } from "axios";
import { CountryService } from "services";

export interface CountryServiceModel {
  getAll: ReturnType<typeof CountryService.getAll>;
}

export const initCountryService = (
  axios: AxiosInstance
): CountryServiceModel => ({
  getAll: CountryService.getAll(axios),
});
