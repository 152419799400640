import { AxiosInstance } from "axios";
import { AeroportServiceModel, initAeroportService } from "./Aeroport/aeroport";
import {
  AgeRangeServiceModel,
  initAgeRangeService,
} from "./AgeRange/AgeRangeService";
import {
  OriginOfBookingServiceModel,
  initOriginOfBookingService,
} from "./OriginOfBooking/OriginOfBooking";
import {
  ReservationStateServiceModel,
  initReservationStateService,
} from "./ReservationState/ReservationState";
import { SourceServiceModel, initSourceService } from "./Source/source";
import {
  initMarketCodeService,
  MarketCodeServiceModel,
} from "api/Services/ChainSettingServices/Reservation/MarketCode/MarketCode";

export interface SettingReservationServiceModel {
  source: SourceServiceModel;
  reservationState: ReservationStateServiceModel;
  aeroport: AeroportServiceModel;
  originOfBooking: OriginOfBookingServiceModel;
  ageRange: AgeRangeServiceModel;
  marketCode: MarketCodeServiceModel;
}

export const initSettingReservationService = (
  axios: AxiosInstance
): SettingReservationServiceModel => ({
  source: initSourceService(axios),
  reservationState: initReservationStateService(axios),
  aeroport: initAeroportService(axios),
  originOfBooking: initOriginOfBookingService(axios),
  ageRange: initAgeRangeService(axios),
  marketCode: initMarketCodeService(axios),
});
