import { Box, Stack } from "@mui/material";
import EmptyContent from "components/EmptyContent";
import { RootContextProps } from "components/RootContainer";
import { SearchIcon } from "components/SvgIcons/SearchIcon";
import i18next from "i18next";
import React, { FC, useEffect } from "react";
import {
  Link,
  RouteObject,
  useNavigate,
  useRouteError,
} from "react-router-dom";
import { Breadcrumb } from "routing/components/Breadcrumb";
import AclPage from "../pages/ACL";
import { routePathsAcl } from "../pages/ACL/routing/routes";
import { routePathsApplicationSettings } from "../pages/ApplicationSettingsPage/routing/routes";
import { routePathsCards } from "../pages/CardsPage/routing/routes";
import { FinancialRoutePaths } from "../pages/FinacialPage/routing/routes";
import { routePaths as routePathsRatesPromo } from "../pages/RatesPromoPage/routing/routes";
import { routePathsReservations } from "../pages/ReservationPage/routing/routes";
import { routePathsRevenuManagement } from "../pages/RevenuManagment/routing/routes";
import { routePathsRooms } from "../pages/RoomsPage/routing/routes";
import HouseKeepingRoutes from "../pages/HouseKeeping/routing";
import { SettingsPaths } from "@constants/Navigation/AppPaths";

const SettingHotelPage = React.lazy(() => import("../pages/HotelPage"));
const RevenuManagementPage = React.lazy(
  () => import("../pages/RevenuManagment/pages/index")
);
const RoomsPage = React.lazy(() => import("../pages/RoomsPage/index"));
const ReservationPage = React.lazy(
  () => import("../pages/ReservationPage/index")
);
const RatesPromoPage = React.lazy(
  () => import("../pages/RatesPromoPage/index")
);
const FinancialPage = React.lazy(
  () => import("../pages/FinacialPage/pages/index")
);
const CardsPage = React.lazy(() => import("../pages/CardsPage/index"));
const ApplicationSettingsPage = React.lazy(
  () => import("../pages/ApplicationSettingsPage/index")
);
const HouseKeepingPage = React.lazy(() => import("../pages/HouseKeeping"));

const LandingPage: FC = (): JSX.Element => {
  let navigate = useNavigate();
  useEffect(() => {
    navigate("/settings/hotel", { replace: true });
  }, []);

  return <></>;
};

const SettingsReservationBoundary: FC = (): JSX.Element => {
  let error = useRouteError() as Error;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        height: "100%",
      }}
    >
      <h2>Error : </h2>
      <p>{error.message}</p>
    </Box>
  );
};

export const settingsRoutePaths = (api: RootContextProps): RouteObject[] => [
  {
    index: true,
    element: <LandingPage />,
  },
  {
    path: SettingsPaths.SETTINGS_HOTEL_PATH,
    element: (
      <React.Suspense fallback={<p>...LoadingPage</p>}>
        <SettingHotelPage />
      </React.Suspense>
    ),
    errorElement: <SettingsReservationBoundary />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t("settings:menu.hotel")}
          path={SettingsPaths.SETTINGS_HOTEL_PATH}
        />
      ),
    },
  },

  {
    path: "cards",
    element: <CardsPage />,
    errorElement: <SettingsReservationBoundary />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t("settings:menu.cards")}
          path={SettingsPaths.SETTINGS_CARDS_PATH}
        />
      ),
    },
    children: [...routePathsCards],
  },

  {
    path: SettingsPaths.SETTINGS_RESERVATION_PATH,
    element: <ReservationPage />,
    errorElement: <SettingsReservationBoundary />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t("settings:menu.reservation")}
          path={SettingsPaths.SETTINGS_RESERVATION_PATH}
        />
      ),
    },
    children: [...routePathsReservations],
  },

  {
    path: SettingsPaths.SETTINGS_FINANCIAL_PATH,
    element: <FinancialPage />,
    errorElement: <SettingsReservationBoundary />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t("settings:menu.financial")}
          path={SettingsPaths.SETTINGS_FINANCIAL_PATH}
        />
      ),
    },
    children: FinancialRoutePaths,
  },

  {
    path: "*",
    element: (
      <div>
        <h2>It looks like you're lost...</h2>
        <p>
          <Link to="/">Go to the home page</Link>
        </p>
      </div>
    ),
  },
  {
    path: SettingsPaths.SETTINGS_PROPERTY_STAF_PATH,
    errorElement: <SettingsReservationBoundary />,
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <Stack position={"relative"} height="70vh">
              <EmptyContent
                title="Property & Staf page"
                subTitle="Search for Property & Staf"
                icon={() => (
                  <SearchIcon sx={{ width: "3rem", height: "3rem" }} />
                )}
              />
            </Stack>
          </React.Suspense>
        ),
      },
    ],
  },
  {
    path: SettingsPaths.SETTINGS_REVENU_MANAGEMENT_PATH,
    element: <RevenuManagementPage />,
    errorElement: <SettingsReservationBoundary />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t("settings:menu.revenu-management")}
          path={SettingsPaths.SETTINGS_REVENU_MANAGEMENT_PATH}
        />
      ),
    },
    children: [...routePathsRevenuManagement(api)],
  },
  {
    path: SettingsPaths.SETTINGS_HOUSE_KEEPING_PATH,
    errorElement: <SettingsReservationBoundary />,
    element: (
      <React.Suspense fallback={<></>}>
        <HouseKeepingPage />
      </React.Suspense>
    ),
    children: HouseKeepingRoutes,
    handle: {
      crumb: () => (
        <Breadcrumb
          label="House Keeping"
          path={SettingsPaths.SETTINGS_HOUSE_KEEPING_PATH}
        />
      ),
    },
  },
  {
    path: SettingsPaths.SETTINGS_MAINTENANCE_PATH,
    errorElement: <SettingsReservationBoundary />,
    children: [
      {
        index: true,
        element: (
          <Stack position={"relative"} height="70vh">
            <EmptyContent
              title="Settings Maintenance page"
              subTitle="Search for Settings Maintenance"
              icon={() => <SearchIcon sx={{ width: "3rem", height: "3rem" }} />}
            />
          </Stack>
        ),
      },
    ],
  },
  {
    path: SettingsPaths.SETTINGS_TRANSFER_EXCURSION_PATH,
    errorElement: <SettingsReservationBoundary />,
    children: [
      {
        index: true,
        element: (
          <Stack position={"relative"} height="70vh">
            <EmptyContent
              title="Settings Transfer & excursion page"
              subTitle="Search for Settings Transfer & excursion"
              icon={() => <SearchIcon sx={{ width: "3rem", height: "3rem" }} />}
            />
          </Stack>
        ),
      },
    ],
  },
  {
    path: SettingsPaths.SETTINGS_RATE_PROMO_PATH,
    element: <RatesPromoPage />,
    errorElement: <SettingsReservationBoundary />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t("settings:menu.rates-promo")}
          path={SettingsPaths.SETTINGS_RATE_PROMO_PATH}
        />
      ),
    },
    children: [...routePathsRatesPromo(api)],
  },
  {
    path: SettingsPaths.SETTINGS_ROOMS_PATH,
    element: <RoomsPage />,
    errorElement: <SettingsReservationBoundary />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t("settings:menu.rooms")}
          path={SettingsPaths.SETTINGS_ROOMS_PATH}
        />
      ),
    },
    children: [...routePathsRooms(api)],
  },
  {
    path: SettingsPaths.SETTINGS_APPLICATION_SETTINGS_PATH,
    element: <ApplicationSettingsPage />,
    errorElement: <SettingsReservationBoundary />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t("settings:menu.application-settings")}
          path={SettingsPaths.SETTINGS_APPLICATION_SETTINGS_PATH}
        />
      ),
    },
    children: [...routePathsApplicationSettings(api)],
  },
  {
    path: SettingsPaths.SETTINGS_ACL_PATH,
    element: <AclPage />,
    errorElement: <SettingsReservationBoundary />,
    handle: {
      crumb: () => (
        <Breadcrumb label="ACL" path={SettingsPaths.SETTINGS_ACL_PATH} />
      ),
    },
    children: [...routePathsAcl(api)],
  },
];
