import { AxiosInstance } from "axios";
import { RoomsCategoryServices } from "services/Settings";

export interface RoomsCategoryServiceModel {
  getAll: ReturnType<typeof RoomsCategoryServices.getAll>;
  getOne: ReturnType<typeof RoomsCategoryServices.getOne>;
  create: ReturnType<typeof RoomsCategoryServices.create>;
  update: ReturnType<typeof RoomsCategoryServices.update>;
  patch: ReturnType<typeof RoomsCategoryServices.patch>;
  remove: ReturnType<typeof RoomsCategoryServices.remove>;
}

export const initRoomsCategoryServiceModel = (
  axios: AxiosInstance
): RoomsCategoryServiceModel => ({
  getAll: RoomsCategoryServices.getAll(axios),
  getOne: RoomsCategoryServices.getOne(axios),
  create: RoomsCategoryServices.create(axios),
  update: RoomsCategoryServices.update(axios),
  patch: RoomsCategoryServices.patch(axios),
  remove: RoomsCategoryServices.remove(axios),
});
