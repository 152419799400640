import * as React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const ChevronRightTwoIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g fillRule="evenodd">
        <path d="M12 12 6 6v12zM18 12l-6-6v12z" />
      </g>
    </SvgIcon>
  );
};
