import { ContactModel } from "models/RevenueManagement/Card/ContactModel";
import { AxiosInstance, AxiosResponse } from "axios";
import { ApiResponseType } from "services/ApiResultType";
import { sprintf } from "sprintf-js";
import { CardsEndpoint } from "@constants/ApiEndpoints";

export const create =
  (axios: AxiosInstance) =>
  async (formdata: ContactModel): Promise<ContactModel> => {
    return await axios
      .post(
        sprintf(CardsEndpoint.CARD_CONTACT_CREATE, formdata.card_id),
        formdata
      )
      .then(({ data }) => data);
  };

export const getAll =
  (axios: AxiosInstance) =>
  async (
    cardId: number,
    params?: any
  ): Promise<ApiResponseType<ContactModel>> => {
    return await axios
      .get(sprintf(CardsEndpoint.CARD_CONTACT_GET_ALL, cardId), {
        params,
      })
      .then(({ data }) => data);
  };

export const getByCard =
  (axios: AxiosInstance) =>
  async (
    cardId: number,
    params?: any
  ): Promise<ApiResponseType<ContactModel>> => {
    return await axios
      .get(sprintf(CardsEndpoint.CARD_CONTACT_GET_ALL, cardId), {
        params,
      })
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (contact: ContactModel): Promise<ContactModel> => {
    return await axios
      .put(
        sprintf(CardsEndpoint.CARD_CONTACT_UPDATE, contact.card_id, contact.id),
        contact
      )
      .then(({ data }) => data);
  };

export const remove =
  (axios: AxiosInstance) =>
  async ({
    cardId,
    contactId,
  }: {
    cardId: number;
    contactId: number;
  }): Promise<AxiosResponse<any>> => {
    return await axios.delete(
      sprintf(CardsEndpoint.CARD_CONTACT_DELETE, cardId, contactId)
    );
  };
