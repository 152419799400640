import { AxiosInstance } from "axios";
import { MarketCodeServiceModel, initMarketCodeService } from "./MarketCode/MarketCode";
import { RoomLikeAndFeatureServiceModel, initRoomLikeAndFeatureService } from "./RoomLikeAndFeature/RoomLikeAndFeature";
import { SegmentMarketCodeServiceModel, initSegmentMarketCodeService } from "./SegmentMarket/SegmentMarket";
import { VipCategoryServiceModel, initVipCategoryService } from "./VipCategory/vipCategory";

export interface SettingReservationServiceModel {
  segmentMarketCode: SegmentMarketCodeServiceModel;
  marketCode: MarketCodeServiceModel;
  roomLikeAndFeature: RoomLikeAndFeatureServiceModel;
  vipCategory: VipCategoryServiceModel;
}

export const initSettingReservationService = (
  axios: AxiosInstance
): SettingReservationServiceModel => ({
  segmentMarketCode: initSegmentMarketCodeService(axios),
  marketCode: initMarketCodeService(axios),
  roomLikeAndFeature: initRoomLikeAndFeatureService(axios),
  vipCategory: initVipCategoryService(axios),
});
