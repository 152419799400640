import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { VipCategoryModel } from "models/Settings/Reservation/vipCategory/VipCategoryModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";

export const remove =
  (axios: AxiosInstance) =>
  async (id: number): Promise<VipCategoryModel> => {
    return await axios.delete(
      `${SettingsEndpoint.SETTINGS_RESERVATION_VIP_CATEGORY}/${id}`
    );
  };

export const getAll =
  (axios: AxiosInstance) =>
  async (params?: FetchingRequestParamsModels): Promise<VipCategoryModel[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_RESERVATION_VIP_CATEGORY, {
        params,
      })
      .then(({ data }) => data.items);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: VipCategoryModel): Promise<VipCategoryModel> => {
    return await axios
      .post(SettingsEndpoint.SETTINGS_RESERVATION_VIP_CATEGORY, data)
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (data: VipCategoryModel): Promise<VipCategoryModel> => {
    return await axios
      .put(
        `${SettingsEndpoint.SETTINGS_RESERVATION_VIP_CATEGORY}/${data.id}`,
        data
      )
      .then(({ data }) => data);
  };

export const toggleStatus =
  (axios: AxiosInstance) =>
  async (id: number): Promise<VipCategoryModel> => {
    return await axios
      .patch(`${SettingsEndpoint.SETTINGS_RESERVATION_VIP_CATEGORY}/${id}`)
      .then(({ data }) => data);
  };
