import { Components, Theme } from "@mui/material";
import { ChevronDownIcon } from "components/SvgIcons/Chevrons/ChevronDownIcon";

export const MuiAutocomplete: Components<
  Omit<Theme, "components">
>["MuiAutocomplete"] = {
  defaultProps: {
    popupIcon: <ChevronDownIcon sx={{ fontSize: "1rem" }} />,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      padding: 0,
      border: "none",

      "& .MuiOutlinedInput-root": {
        padding: 0,
        "& .MuiAutocomplete-input": {
          padding: 0,
          paddingLeft: 16,
        },
      },
      "& .MuiInput-root": {
        padding: 0,
        "& .MuiInput-input": {
          padding: 0,
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(1),
        },
      },
      "& .MuiFilledInput-root": {
        padding: 0,
        paddingTop: 20,

        "&.MuiInputBase-sizeSmall": {
          padding: 0,
          paddingTop: 18,
          "& .MuiFilledInput-input": {
            fontSize: "0.875rem",
            fontWeight: 500,
            padding: 0,
            paddingLeft: "14px",
          },
        },

        "& .MuiFilledInput-input": {
          fontSize: "0.875rem",
          fontWeight: 600,
          padding: 0,
          paddingLeft: "14px",
        },
      },
    }),
    inputRoot: {
      padding: 0,
      paddingRight: 0,
    },
    hasPopupIcon: {
      "&.MuiAutocomplete-root": {
        "& .MuiAutocomplete-inputRoot": {
          paddingRight: "16px",
          // alignContent: "flex-end",
        },
      },
    },
    endAdornment: ({ theme }) => ({
      right: theme.spacing(1.5),
      top: "calc(50% - 14px)",
      // marginRight: theme.spacing(2),
      // marginLeft: theme.spacing(1),
      fontSize: "1rem",
    }),
    input: {},
    listbox: ({ theme }) => ({
      padding: 0,
      "& .MuiAutocomplete-option.Mui-focused": {
        backgroundColor: theme.palette.colors.purple.o10,
      },
    }),
    option: ({ theme }) => ({
      height: "3rem",
      padding: "0.5rem 0.938rem",
      fontSize: theme.typography.inter14.fontSize,
      color: "#030405",
      boxShadow: `inset 0 -1px 0 0 ${theme.palette.colors.purple.o10}`,
      display: "flex",
      flexFlow: "row",
      width: "100%",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      "&.Mui-selected": {
        backgroundColor: theme.palette.colors.purple.o20,
        color: theme.palette.colors.purple.o70,
      },

      "&[aria-selected='true']": {
        backgroundColor: theme.palette.colors.purple.o20,
        color: theme.palette.colors.purple.o70,
      },
    }),
    paper: ({ theme }) => ({
      boxShadow: `inset 0 -1px 0 0 ${theme.palette.colors.purple.o10}`,
    }),
  },
};
