import { isBefore, isEqual, isWithinInterval } from "date-fns";
import { useCallback } from "react";

type CalendarActionsProps = {
  start: Date | null;
  end: Date | null;
  preSelectedDate: Date | null;
  focus: "start" | "end" | null;
};

export default function useCalendarActions({
  start,
  end,
  preSelectedDate,
  focus,
}: CalendarActionsProps) {
  const isPreSelected = useCallback(
    (day: Date) => {
      if (!preSelectedDate) return false;
      if (start && end) {
        if (
          (focus === "start" && isBefore(end, preSelectedDate)) ||
          (focus === "end" && isBefore(preSelectedDate, start))
        ) {
          return false;
        }
        return isWithinInterval(day, {
          start: preSelectedDate < start ? preSelectedDate : start,
          end: preSelectedDate > end ? preSelectedDate : end,
        });
      }
      if (start) {
        const interval =
          preSelectedDate > start
            ? {
                start,
                end: preSelectedDate,
              }
            : {
                start: preSelectedDate,
                end: start,
              };
        return isWithinInterval(day, interval);
      }
      if (end) {
        const interval =
          preSelectedDate > end
            ? {
                start: end,
                end: preSelectedDate,
              }
            : {
                start: preSelectedDate,
                end,
              };
        return isWithinInterval(day, interval);
      }
      return false;
    },
    [preSelectedDate, start, end, focus]
  );

  const isInInterval = useCallback(
    (date: Date) => {
      if (!start || !end) return false;
      if (start > end) return false;
      const _date = new Date(date.getTime());
      const left = new Date(start.getTime());
      const right = new Date(end.getTime());
      date.setHours(0, 0, 0, 0);
      left.setHours(0, 0, 0, 0);
      right.setHours(0, 0, 0, 0);
      return isWithinInterval(_date, {
        start: left,
        end: right,
      });
    },
    [start, end]
  );

  const isFirstDay = useCallback(
    (date: Date) => {
      if (!start) return false;
      const left = new Date(start.getTime());
      const right = new Date(date.getTime());
      left.setHours(0, 0, 0, 0);
      right.setHours(0, 0, 0, 0);
      return isEqual(left, right);
    },
    [start]
  );

  const isLastDay = useCallback(
    (date: Date) => {
      if (!end) return false;
      const left = new Date(end.getTime());
      const right = new Date(date.getTime());
      left.setHours(0, 0, 0, 0);
      right.setHours(0, 0, 0, 0);
      return isEqual(left, right);
    },
    [end]
  );

  return {
    isPreSelected,
    isInInterval,
    isFirstDay,
    isLastDay,
  };
}
