import { Box } from "@mui/material";
import { FC, useState } from "react";
import CashierRateExchangeList from "./components/List";
import CashierRateExchangeFakeData from "./fake-data/data";
import CashierExchangeCreate from "./components/Create";
import CashierRateExchangeModel from "models/Cashier/CashierRateExchange";
import { v4 as uuid } from "uuid";
import Header from "./components/Header";

const defaultValues: CashierRateExchangeModel = {
  is_active: true,
  currency_name: null,
  currency_unit: null,
  from_date: null,
  rate: null,
  currency_id: null,
};

type OpenCreateType = {
  key: string;
  open: boolean;
  defaultValues: CashierRateExchangeModel;
  mode: "create" | "update";
};

const openCreateValues: OpenCreateType = {
  key: uuid(),
  open: false,
  mode: "create",
  defaultValues,
};

const CashierExchangePage: FC = () => {
  const [search, setSearch] = useState<string>("");

  const handleChangeSearch = (value: string) => setSearch(value);

  const handleClearSearch = () => setSearch("");

  const [openCreate, setOpenCreate] =
    useState<OpenCreateType>(openCreateValues);

  const handleOpenCreate = () => {
    setOpenCreate({ key: uuid(), open: true, mode: "create", defaultValues });
  };

  const handleCloseCreate = () => {
    setOpenCreate({
      key: uuid(),
      open: false,
      mode: "create",
      defaultValues,
    });
  };

  const handleOpenEdit = (defaultValues: CashierRateExchangeModel) => () => {
    setOpenCreate({ key: uuid(), open: true, mode: "update", defaultValues });
  };

  const render = () => {
    return (
      <CashierRateExchangeList
        data={CashierRateExchangeFakeData}
        onClickEdit={handleOpenEdit}
      />
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexFlow: "column",
          height: "100%",
        }}
      >
        <Header
          onClickAddNew={handleOpenCreate}
          search={search}
          onChangeSearch={handleChangeSearch}
          onClickClearSearch={handleClearSearch}
        />
        <Box sx={{ display: "flex", flex: "1 1 auto", p: "1rem" }}>
          {render()}
        </Box>
      </Box>
      <CashierExchangeCreate {...openCreate} handleClose={handleCloseCreate} />
    </>
  );
};

export default CashierExchangePage;
