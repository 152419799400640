import { AxiosInstance } from "axios";
import { CardCardSetService } from "services/RevenueManagement/Card";

export interface CardCardSetServiceModel {
  getAll: ReturnType<typeof CardCardSetService.getAll>;
  getAllSearch: ReturnType<typeof CardCardSetService.getAllSearch>;
  update: ReturnType<typeof CardCardSetService.update>;
  remove: ReturnType<typeof CardCardSetService.remove>;
}

export const initCardCardSetService = (
  axios: AxiosInstance
): CardCardSetServiceModel => ({
  getAll: CardCardSetService.getAll(axios),
  getAllSearch: CardCardSetService.getAllSearch(axios),
  update: CardCardSetService.update(axios),
  remove: CardCardSetService.remove(axios),
});
