import { AxiosInstance } from "axios";
import { ReservationAddonService } from "services/Reservations";

export interface ReservationAddonServiceModel {
  update: ReturnType<typeof ReservationAddonService.update>;
  getAll: ReturnType<typeof ReservationAddonService.getAll>;
}

export const initReservationAddonService = (
  axios: AxiosInstance
): ReservationAddonServiceModel => ({
  update: ReservationAddonService.update(axios),
  getAll: ReservationAddonService.getAll(axios),
});
