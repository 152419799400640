import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { RoleModel } from "models/Settings/Acl/RoleModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";

export const remove =
  (axios: AxiosInstance) =>
    async (id: number): Promise<RoleModel> => {
      return await axios.delete(
        `${SettingsEndpoint.SETTINGS_ROLE}/${id}`
      );
    };

export const getAll =
  (axios: AxiosInstance) =>
    async (params?: FetchingRequestParamsModels): Promise<RoleModel[]> => {
      return await axios
        .get(SettingsEndpoint.SETTINGS_ROLE, {
          params,
        })
        .then(({ data }) => data.items);
    };

export const getById =
  (axios: AxiosInstance) =>
    async (id?: number): Promise<RoleModel> => {
      return await axios
        .get(`/${SettingsEndpoint.SETTINGS_ROLE}/${id}`)
        .then(({ data }) => data);
    };

export const create =
  (axios: AxiosInstance) =>
    async (data: any): Promise<RoleModel> => {
      return await axios
        .post(SettingsEndpoint.SETTINGS_ROLE, data)
        .then(({ data }) => data);
    };

export const update =
  (axios: AxiosInstance) =>
    async (data: RoleModel): Promise<RoleModel> => {
      return await axios
        .put(`/${SettingsEndpoint.SETTINGS_ROLE}/${data.id}`, data)
        .then(({ data }) => data);
    };
