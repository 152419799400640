import { AxiosInstance } from "axios";
import { CashierRootServices } from "services";
import {
  CashierOtherServiceModel,
  initCashierOtherService,
} from "./OtherServices";
import {
  CashierExchangeServiceModel,
  initCashierExchangeService,
} from "./TransactionServices";

export interface CashierRootServiceModel {
  getAll: ReturnType<typeof CashierRootServices.getAll>;
  other: CashierOtherServiceModel;
  exchange: CashierExchangeServiceModel;
  cashRegisterForCurrentUser: ReturnType<
    typeof CashierRootServices.getCashRegisterForCurrentUser
  >;
  getAllCashRegisters: ReturnType<
    typeof CashierRootServices.getAllCashRegisters
  >;
  verifyCodePin: ReturnType<typeof CashierRootServices.verifyCodePin>;
}

export const initCashierRootService = (
  axios: AxiosInstance
): CashierRootServiceModel => ({
  getAll: CashierRootServices.getAll(axios),
  other: initCashierOtherService(axios),
  exchange: initCashierExchangeService(axios),
  cashRegisterForCurrentUser:
    CashierRootServices.getCashRegisterForCurrentUser(axios),
  getAllCashRegisters: CashierRootServices.getAllCashRegisters(axios),
  verifyCodePin: CashierRootServices.verifyCodePin(axios),
});
