import { AxiosInstance } from "axios";
import { HouseKeepingWorkTypeServices } from "services/Settings";

export interface HouseKeepingWorkTypeServiceModel {
  getAll: ReturnType<typeof HouseKeepingWorkTypeServices.getAll>;
  getOne: ReturnType<typeof HouseKeepingWorkTypeServices.getOne>;
  create: ReturnType<typeof HouseKeepingWorkTypeServices.create>;
  update: ReturnType<typeof HouseKeepingWorkTypeServices.update>;
  patch: ReturnType<typeof HouseKeepingWorkTypeServices.patch>;
  remove: ReturnType<typeof HouseKeepingWorkTypeServices.remove>;
}

export const initHouseKeepingWorkTypeServiceModel = (
  axios: AxiosInstance
): HouseKeepingWorkTypeServiceModel => ({
  getAll: HouseKeepingWorkTypeServices.getAll(axios),
  getOne: HouseKeepingWorkTypeServices.getOne(axios),
  create: HouseKeepingWorkTypeServices.create(axios),
  update: HouseKeepingWorkTypeServices.update(axios),
  patch: HouseKeepingWorkTypeServices.patch(axios),
  remove: HouseKeepingWorkTypeServices.remove(axios),
});
