import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { ComplimentartCategoryModel } from "models/Settings/RatesAndPromo/ComplimentaryCategoryModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";

export const remove =
  (axios: AxiosInstance) =>
  async (id: number): Promise<ComplimentartCategoryModel> => {
    return await axios.delete(
      `${SettingsEndpoint.SETTINGS_RATES_COMPLIMENTARY_CATEGORY}/${id}`
    );
  };

export const getAll =
  (axios: AxiosInstance) =>
  async (
    params?: FetchingRequestParamsModels
  ): Promise<ComplimentartCategoryModel[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_RATES_COMPLIMENTARY_CATEGORY, {
        params,
      })
      .then(({ data }) => data.items);
  };

export const create =
  (axios: AxiosInstance) =>
  async (
    data: ComplimentartCategoryModel
  ): Promise<ComplimentartCategoryModel> => {
    return await axios
      .post(SettingsEndpoint.SETTINGS_RATES_COMPLIMENTARY_CATEGORY, data)
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (
    data: ComplimentartCategoryModel
  ): Promise<ComplimentartCategoryModel> => {
    return await axios
      .put(
        `/${SettingsEndpoint.SETTINGS_RATES_COMPLIMENTARY_CATEGORY}/${data.id}`,
        data
      )
      .then(({ data }) => data);
  };

export const toggleStatus =
  (axios: AxiosInstance) =>
  async (id: number): Promise<ComplimentartCategoryModel> => {
    return await axios
      .patch(`/${SettingsEndpoint.SETTINGS_RATES_COMPLIMENTARY_CATEGORY}/${id}`)
      .then(({ data }) => data);
  };
