import { AxiosInstance } from "axios";
import { CardContractRateService } from "services/RevenueManagement/Card";

export interface CardContractRateServiceModel {
  getAll: ReturnType<typeof CardContractRateService.getAll>;
  getByCard: ReturnType<typeof CardContractRateService.getByCard>;
  patch: ReturnType<typeof CardContractRateService.patch>;
}

export const initCardContractRateService = (
  axios: AxiosInstance
): CardContractRateServiceModel => ({
  getAll: CardContractRateService.getAll(axios),
  getByCard: CardContractRateService.getByCard(axios),
  patch: CardContractRateService.patch(axios),
});
