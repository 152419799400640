import accessibilty from "./accessibilty.json";
import addOnFrequencyModel from "./add-on-frequency-model.json";
import addOnScopeModel from "./add-on-scope-model.json";
import addOnTypeModel from "./add-on-type-model.json";
import adultOnApplyOnModel from "./adult-on-apply-on-model.json";
import applicationPricingTypeModel from "./application-pricing-type-model.json";
import applyOnModel from "./apply-on-model.json";
import Calculated from "./calculated.json";
import cardTypes from "./card-type.json";
import chargeTypes from "./charge-types.json";
import CheckConfigOption from "./check-config-options.json";
import CheckConfig from "./check-config.json";
import CustumReservationTypes from "./custum-reservation-type.json";
import dayInAdvanceType from "./day-in-advance-type.json";
import defaultFirstBoard from "./default-first-board.json";
import guestCardFields from "./guest-card-fields.json";
import guestType from "./guest-model.json";
import houseKeepingTypeEnum from "./house-keeping-type-enum.json";
import onlyRevenuModel from "./only-revenu-model.json";
import perNightOrStayType from "./per-night-or-stay-type.json";
import PostType from "./post-type.json";
import PricingTypeModel from "./pricing-type-model.json";
import PricingType from "./pricing-type.json";
import PromotionChargeRulesTypes from "./promotion-charge-rules-types.json";
import RateRangeRoom from "./rate-range-room.json";
import rateTypeModel from "./rate-type-model.json";
import resctrictions from "./resctrictions.json";
import roomRevenu from "./room-revenu.json";
import rulesTypeModel from "./rules-type-model.json";
import Salutation from "./salutation.json";
import StateTypes from "./state-types.json";
import timing from "./timing.json";
import typeOfValue from "./type-of-value.json";
import typeEnum from "./type.json";
import vipCategoryType from "./vip-category-type.json";
import CashOperationTypeRegister from "./cash-operation-register-type.json";
import CashOperationSensRegister from "./cash-operation-register-sens.json";
import CashRegisterCategory from "./cash-register-category.json";

const SystemConfig = {
  accessibilty,
  applyOnModel,
  addOnFrequencyModel,
  addOnScopeModel,
  addOnTypeModel,
  adultOnApplyOnModel,
  applicationPricingTypeModel,
  chargeTypes,
  dayInAdvanceType,
  defaultFirstBoard,
  onlyRevenuModel,
  perNightOrStayType,
  rateTypeModel,
  resctrictions,
  rulesTypeModel,
  typeEnum,
  timing,
  typeOfValue,
  roomRevenu,
  vipCategoryType,
  guestCardFields,
  PostType,
  PricingTypeModel,
  RateRangeRoom,
  PromotionChargeRulesTypes,
  Calculated,
  cardTypes,
  Salutation,
  CustumReservationTypes,
  PricingType,
  guestType,
  StateTypes,
  CheckConfig,
  CheckConfigOption,
  houseKeepingTypeEnum,
  CashOperationTypeRegister,
  CashOperationSensRegister,
  CashRegisterCategory,
};

export default SystemConfig;
