import { Paths } from "@constants/Navigation/AppPaths";
import EmptyContent from "components/EmptyContent";
import { LoadingPage } from "components/LoadingPage";
import { RootContextProps } from "components/RootContainer";
import { RootErrorBoundary } from "components/RootErrorBoundary";
import { Crumb } from "components/TranslatableCrub";
import i18next from "i18next";
import FrontDeskRoutes from "pages/FrontDeskModule/routes";
import HouseKeepingRoutes from "pages/HouseKeepingModule/routing";
import GuestRoutes from "pages/ReservationModule/pages/GuestPage/routing/routes";
import QuickReservationRoutes from "pages/ReservationModule/pages/QuickReservation/routes";
import ReservationRoutes from "pages/ReservationModule/routes";
import RevenueManagementRoutes from "pages/RevenuManagementModule/routing/routes";
import RoomPlanRoutes from "pages/RoomPlanModule/routes";
import React from "react";
import { RouteObject } from "react-router-dom";
import { settingsRoutePaths as routePathsSettings } from "../pages/SettingsModule/routing/routes";
import { Breadcrumb } from "./components/Breadcrumb";
import CashierExchangePage from "pages/CashierExchangePage";

const DashboardPage = React.lazy(() => import("pages/DashboardPage"));
const OverridesPage = React.lazy(() => import("pages/Overrides"));
const UserPage = React.lazy(() => import("pages/UserPage"));
const PrivateLayout = React.lazy(() => import("layouts/private-layout"));
const PublicLayout = React.lazy(() => import("layouts/public-layout"));
const LoginPage = React.lazy(() => import("pages/LoginPage"));
const SettingsPage = React.lazy(() => import("pages/SettingsModule"));
const GroupsPage = React.lazy(() => import("pages/GroupsPage"));
const ChangeOperationsPage = React.lazy(
  () => import("pages/ChangeOperationsPage")
);
const CheckinPage = React.lazy(() => import("pages/CheckinPage"));
const CashierPage = React.lazy(() => import("pages/CashierPage"));
const PostChargesPage = React.lazy(() => import("pages/PostChargesPage"));
const ProfilePage = React.lazy(() => import("pages/ProfilePage"));

export type RoutePathDefinition = RouteObject & {
  children?: RoutePathDefinition[];
  handle?: {
    crumb?: <Type extends keyof unknown>(data: Type) => JSX.Element;
  };
};

export const routePaths = (
  api: RootContextProps
): Array<RoutePathDefinition> => [
  {
    path: Paths.BASE_PATH,
    element: (
      <React.Suspense fallback={<LoadingPage />}>
        <PrivateLayout />
      </React.Suspense>
    ),
    errorElement: <RootErrorBoundary />,
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<>...</>}>
            <DashboardPage />
          </React.Suspense>
        ),
        handle: {
          crumb: () => <Breadcrumb label="Dashboard" path={null} />,
        },
      },
      {
        path: `${Paths.SETTINGS_PATH}`,
        element: (
          <React.Suspense fallback={<>...</>}>
            <SettingsPage />
          </React.Suspense>
        ),
        handle: {
          crumb: () => <Crumb label="settings" path={null} />,
        },
        children: routePathsSettings(api),
      },

      {
        path: Paths.GROUPS_PATH,
        children: [
          {
            index: true,
            element: (
              <React.Suspense fallback={<>...</>}>
                <GroupsPage />
              </React.Suspense>
            ),
            handle: {
              crumb: () => <Breadcrumb label={"Groups"} path={null} />,
            },
          },
        ],
      },
      {
        path: Paths.REVENU_MANAGEMENT_PATH,
        handle: {
          crumb: () => <Breadcrumb label="Revenue Management" path={null} />,
        },
        children: RevenueManagementRoutes(api),
      },
      {
        path: Paths.GUEST_PATH,
        handle: {
          crumb: () => <Breadcrumb label="Reservation" path={null} />,
        },
        children: GuestRoutes(api),
      },
      {
        path: "/x",
        handle: {
          crumb: () => <Breadcrumb label="X Page" path={"/x"} />,
        },
        element: (
          <React.Suspense fallback={<>...</>}>
            <OverridesPage />
          </React.Suspense>
        ),
      },
      {
        path: "quick-reservation",
        children: QuickReservationRoutes,
      },
      {
        path: "change-operations",
        children: [
          {
            index: true,
            element: (
              <React.Suspense fallback={<>...</>}>
                <ChangeOperationsPage />
              </React.Suspense>
            ),
            handle: {
              crumb: () => (
                <Breadcrumb label={"Change Operations"} path={null} />
              ),
            },
          },
        ],
      },
      {
        path: "front-desk",
        handle: {
          crumb: () => (
            <Breadcrumb label={i18next.t("frontDesk:frontDesk")} path={null} />
          ),
        },
        children: FrontDeskRoutes,
      },
      {
        path: "post-charges",
        children: [
          {
            index: true,
            element: (
              <React.Suspense fallback={<>...</>}>
                <PostChargesPage />
              </React.Suspense>
            ),
            handle: {
              crumb: () => <Breadcrumb label={"Post Charges"} path={null} />,
            },
          },
        ],
      },
      {
        path: "checkin",
        children: [
          {
            index: true,
            element: (
              <React.Suspense fallback={<>...</>}>
                <CheckinPage />
              </React.Suspense>
            ),
            handle: {
              crumb: () => <Breadcrumb label={"Checkin"} path={null} />,
            },
          },
        ],
      },
      {
        path: "/rooms",
        element: (
          <React.Suspense fallback={<>...</>}>
            <EmptyContent title="Rooms" subTitle="Room Page" />
          </React.Suspense>
        ),
      },
      {
        path: "/reservation",
        element: (
          <React.Suspense fallback={<>...</>}>
            <EmptyContent title="Reservation" subTitle="Reservation Page" />
          </React.Suspense>
        ),
      },
      {
        path: "/front-desk",
        element: (
          <React.Suspense fallback={<>...</>}>
            <EmptyContent title="Front Desk" subTitle="Front Desk Page" />
          </React.Suspense>
        ),
      },
      {
        path: "/concierge",
        element: (
          <React.Suspense fallback={<>...</>}>
            <EmptyContent title="Concierge" subTitle="Concierge Page" />
          </React.Suspense>
        ),
      },
      {
        path: Paths.CASH_REGISTER_PATH,
        element: (
          <React.Suspense fallback={<>...</>}>
            <CashierPage />
          </React.Suspense>
        ),
        handle: {
          crumb: () => <Crumb label="Cashier" path={"cashier"} />,
        },
      },
      {
        path: Paths.RATE_EXCHANGE_PATH,
        element: (
          <React.Suspense fallback={<>...</>}>
            <CashierExchangePage />
          </React.Suspense>
        ),
        handle: {
          crumb: () => <Crumb label="Cashier - Rate Exchange" path={null} />,
        },
      },
      {
        handle: {
          crumb: () => <Breadcrumb label="House Keeping" path={null} />,
        },
        children: HouseKeepingRoutes,
      },
      {
        path: "/maintenance",
        element: (
          <React.Suspense fallback={<>...</>}>
            <EmptyContent title="Maintenance" subTitle="Maintenance Page" />
          </React.Suspense>
        ),
      },
      {
        path: "/financial",
        element: (
          <React.Suspense fallback={<>...</>}>
            <EmptyContent title="Finance" subTitle="Finance Page" />
          </React.Suspense>
        ),
      },
      {
        path: "user",
        element: (
          <React.Suspense fallback={<>...</>}>
            <UserPage />
          </React.Suspense>
        ),
      },
      {
        path: "/reservations",
        handle: {
          crumb: () => <Breadcrumb label="Reservations" path="/reservations" />,
        },
        children: ReservationRoutes,
      },
      {
        path: "/room-plan",
        handle: {
          crumb: () => <Crumb label="rootMenu.room_plan" />,
        },
        children: RoomPlanRoutes,
      },
    ],
  },
  {
    path: "/login",
    element: <PublicLayout />,
    children: [
      {
        index: true,
        element: <LoginPage />,
      },
    ],
  },
  {
    path: "/profile",
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<>...</>}>
            <ProfilePage />
          </React.Suspense>
        ),
      },
    ],
  },
];
