import { CardsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { ContractModel } from "models/RevenueManagement/Card/ContractModel";
import { ApiResponseType } from "services/ApiResultType";
import { sprintf } from "sprintf-js";

export const getAll =
  (axios: AxiosInstance) =>
  async (
    cardId: number,
    params?: any
  ): Promise<ApiResponseType<ContractModel>> => {
    return await axios
      .get(sprintf(CardsEndpoint.CARD_CONTRACT_RATE_GET_ALL, cardId), {
        params,
      })
      .then(({ data }) => data);
  };

export const getByCard =
  (axios: AxiosInstance) =>
  async (
    cardId: number,
    params?: any
  ): Promise<ApiResponseType<ContractModel>> => {
    return await axios
      .get(sprintf(CardsEndpoint.CARD_CONTRACT_RATE_GET_ALL, cardId), {
        params,
      })
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (contractRate: ContractModel): Promise<ContractModel> => {
    return await axios
      .put(
        sprintf(
          CardsEndpoint.CARD_CONTRACT_RATE_UPDATE,
          contractRate.card_id,
          contractRate.id
        ),
        contractRate
      )
      .then(({ data }) => data);
  };

export const patch =
  (axios: AxiosInstance) =>
  async (payload: { card: number; rate: number }): Promise<ContractModel> => {
    return await axios
      .patch(
        sprintf(
          CardsEndpoint.CARD_CONTRACT_RATE_UPDATE,
          payload?.card,
          payload?.rate
        )
      )
      .then(({ data }) => data);
  };
