import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { ItemModel } from "models/Settings/RevenuManagement/ItemModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";

export const remove =
  (axios: AxiosInstance) =>
  async (id: number): Promise<ItemModel> => {
    return await axios.delete(
      `${SettingsEndpoint.SETTINGS_REVENU_MANAGEMENT_ITEMS}/${id}`
    );
  };

export const getAll =
  (axios: AxiosInstance) =>
  async (params?: FetchingRequestParamsModels): Promise<ItemModel[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_REVENU_MANAGEMENT_ITEMS, {
        params,
      })
      .then(({ data }) => data.items);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: ItemModel): Promise<ItemModel> => {
    return await axios
      .post(SettingsEndpoint.SETTINGS_REVENU_MANAGEMENT_ITEMS, data)
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (data: ItemModel): Promise<ItemModel> => {
    return await axios
      .put(
        `/${SettingsEndpoint.SETTINGS_REVENU_MANAGEMENT_ITEMS}/${data.id}`,
        data
      )
      .then(({ data }) => data);
  };

export const toggleStatus =
  (axios: AxiosInstance) =>
  async (id: number): Promise<ItemModel> => {
    return await axios
      .patch(`/${SettingsEndpoint.SETTINGS_REVENU_MANAGEMENT_ITEMS}/${id}`)
      .then(({ data }) => data);
  };
