import { ReservationsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import ReservationAddonModel, {
  ReservationAddonFormModel,
} from "models/Reservations/ReservationAddonModel";
import { sprintf } from "sprintf-js";

export const getAll =
  (axios: AxiosInstance) =>
  async (id: number): Promise<ReservationAddonModel[]> => {
    return await axios
      .get(sprintf(ReservationsEndpoint.RESERVATION_GET_ADD_ONS, id))
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (item: {
    id: number;
    data: ReservationAddonFormModel;
  }): Promise<ReservationAddonModel[]> => {
    return await axios
      .put(
        sprintf(ReservationsEndpoint.RESERVATION_UPDATE_ADD_ONS, item.id),
        item.data
      )
      .then(({ data }) => data);
  };
