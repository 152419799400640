import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { PermissionModel } from "models/Settings/Acl/permissionModel";
import { sprintf } from "sprintf-js";

export const getAll =
  (axios: AxiosInstance) => async (): Promise<PermissionModel[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_USER_MY_PERMISSIONS)
      .then(({ data }) => data?.permissions || []);
  };

export const getAllUserPermissions =
  (axios: AxiosInstance) => async (id: string | undefined): Promise<{ permissions: string[], roles: string[] }> => {
    return await axios
      .get(sprintf(SettingsEndpoint.SETTINGS_USER_PERMISSIONS, id))
      .then(({ data }) => data || []);
  };

export const getAllRolePermissions =
  (axios: AxiosInstance) => async (id: string | undefined): Promise<string[]> => {
    return await axios
      .get(sprintf(SettingsEndpoint.SETTINGS_ROLE_PERMISSIONS, id))
      .then(({ data }) => data?.permissions || []);
  };
