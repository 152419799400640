import { SettingsHouseKeepingEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import SectionModel, {
  SectionCreateModel,
} from "models/Settings/HouseKeeping/Section";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";
import { sprintf } from "sprintf-js";

export const getAll =
  (axios: AxiosInstance) =>
  async (params?: FetchingRequestParamsModels): Promise<SectionModel[]> => {
    return await axios
      .get(SettingsHouseKeepingEndpoint.HOUSE_KEEPING_SECTION, { params })
      .then(({ data }) => data.items);
  };

export const getOne =
  (axios: AxiosInstance) =>
  async (id: number | string): Promise<SectionModel> => {
    return await axios
      .get(sprintf(SettingsHouseKeepingEndpoint.HOUSE_KEEPING_SECTION_ID, id))
      .then(({ data }) => data);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: SectionCreateModel): Promise<SectionModel> => {
    return await axios
      .post(SettingsHouseKeepingEndpoint.HOUSE_KEEPING_SECTION, data)
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (data: SectionCreateModel): Promise<SectionModel> => {
    return await axios
      .put(
        sprintf(SettingsHouseKeepingEndpoint.HOUSE_KEEPING_SECTION_ID, data.id),
        data
      )
      .then(({ data }) => data);
  };

export const patch =
  (axios: AxiosInstance) =>
  async (data: Partial<SectionCreateModel>): Promise<SectionModel> => {
    return await axios
      .patch(
        sprintf(
          SettingsHouseKeepingEndpoint.HOUSE_KEEPING_SECTION_ID,
          data?.id!
        ),
        data
      )
      .then(({ data }) => data);
  };

export const remove =
  (axios: AxiosInstance) =>
  async (id: number | string): Promise<SectionModel> => {
    return await axios
      .delete(
        sprintf(SettingsHouseKeepingEndpoint.HOUSE_KEEPING_SECTION_ID, id)
      )
      .then(({ data }) => data);
  };
