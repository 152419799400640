import { ReservationsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import {
  BillingInformationsChargesModel,
  ReservationBillingInformations,
  ReservationBillingInformationsList,
} from "models/Reservations/ReservationBillingInformations";
import { sprintf } from "sprintf-js";

export const create =
  (axios: AxiosInstance) =>
  async (
    data: ReservationBillingInformations
  ): Promise<ReservationBillingInformations> => {
    return await axios
      .post(
        sprintf(
          ReservationsEndpoint.BILLING_INFORMATIONS_CREATE,
          data.reservation_id
        ),
        data
      )
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (
    data: ReservationBillingInformations
  ): Promise<ReservationBillingInformations> => {
    return await axios
      .put(
        sprintf(
          ReservationsEndpoint.BILLING_INFORMATIONS_UPDATE,
          data.reservation_id,
          data.id!
        ),
        data
      )
      .then(({ data }) => data);
  };

export const remove =
  (axios: AxiosInstance) =>
  async (
    reservationId: number | string,
    id: number
  ): Promise<ReservationBillingInformations> => {
    return await axios
      .delete(
        sprintf(
          ReservationsEndpoint.BILLING_INFORMATIONS_REMOVE,
          reservationId,
          id
        )
      )
      .then(({ data }) => data);
  };

export const getOne =
  (axios: AxiosInstance) =>
  async (
    reservationId: number | string,
    id: number
  ): Promise<ReservationBillingInformations> => {
    return await axios
      .get(
        sprintf(
          ReservationsEndpoint.BILLING_INFORMATIONS_GET_ONE,
          reservationId,
          id
        )
      )
      .then(({ data }) => data);
  };

export const getAllByReservation =
  (axios: AxiosInstance) =>
  async (
    id: number,
    params?: any
  ): Promise<ReservationBillingInformationsList> => {
    return await axios
      .get(
        sprintf(
          ReservationsEndpoint.BILLING_INFORMATIONS_GET_ALL_BY_RESERVATION,
          id
        ),
        { params }
      )
      .then(({ data }) => data.items);
  };

type SearchChargeParams = {
  reservation: number | string;
  from: string;
  to: string;
  billing_route_id?: number | string;
};

export const getCharges =
  (axios: AxiosInstance) =>
  async (
    reservationId: number | string,
    params: SearchChargeParams
  ): Promise<BillingInformationsChargesModel> => {
    return await axios
      .get(
        sprintf(
          ReservationsEndpoint.BILLING_INFORMATIONS_GET_CHARGES,
          reservationId
        ),
        {
          params,
        }
      )
      .then(({ data }) => data);
  };
