export enum SystemRestrictionsModel {
  CLOSED = "closed",
  CLOSED_TO_ARRIVAL = "closed_to_arrival",
  CLOSED_TO_DEPARTURE = "closed_to_departure",
  MIN_ADVANCE_BOOKING = "min_advance_booking",
  MAX_ADVANCE_BOOKING = "max_advance_booking",
  MIN_LENGTH_STAY = "min_length_stay",
  MAX_LENGTH_STAY = "max_length_stay",
}

export const SystemRestrictionsBooleanFields = [
  "closed",
  "closed_to_arrival",
  "closed_to_departure",
];

export const SystemRestrictionsNumberFields = [
  "max_advance_booking",
  "max_length_stay",
  "min_advance_booking",
  "min_length_stay",
];
