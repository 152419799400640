import { AxiosInstance } from "axios";
import { DepartmentAndStaffService } from "services/Settings";

export interface DepartmentAndStaffServiceModel {
  getAll: ReturnType<typeof DepartmentAndStaffService.getAll>;
  getDepartments: ReturnType<typeof DepartmentAndStaffService.getDepartments>;
  getStaffs: ReturnType<typeof DepartmentAndStaffService.getStaffs>;
}

export const initDepartmentAndStaffService = (
  axios: AxiosInstance
): DepartmentAndStaffServiceModel => ({
  getAll: DepartmentAndStaffService.getAll(axios),
  getDepartments: DepartmentAndStaffService.getDepartments(axios),
  getStaffs: DepartmentAndStaffService.getStaffs(axios),
});
