import { createTheme } from "@mui/material/styles";
import { createStyled } from "@mui/system";
import { breakpoints } from "./breakpoints";
import { components } from "./components";
import { palette } from "./palette";
import { shadows } from "./shadows";
import { typography } from "./typography";

declare module "@mui/material/styles" {
  /**** PALETTE ****/

  interface Theme {}
  interface PaletteColorsOption {
    o100: string;
    o90: string;
    o80: string;
    o70: string;
    o60: string;
    o50: string;
    o40: string;
    o30: string;
    o20: string;
    o10: string;
  }

  interface PaletteColor {
    lighter?: string;
    light: string;
    main: string;
    dark: string;
    darker?: string;
    contrastText: string;
  }

  // interface PaletteColorOptions {
  //   lighter?: string;
  //   light: string;
  //   main: string;
  //   dark: string;
  //   darker?: string;
  //   o100: string;
  //   o90: string;
  //   o80: string;
  //   o70: string;
  //   o60: string;
  //   o50: string;
  //   o40: string;
  //   o30: string;
  //   o20: string;
  //   o10: string;
  // }

  interface Palette {
    joy: PaletteColor;
    neutral: PaletteColor;
    childhood: PaletteColor;
    colors: {
      gray: PaletteColorsOption;
      blue: PaletteColorsOption;
      blueR: PaletteColorsOption;
      green: PaletteColorsOption;
      orange: PaletteColorsOption;
      purple: PaletteColorsOption;
      red: PaletteColorsOption;
      yellow: PaletteColorsOption;
      pink: PaletteColorsOption;
    };
  }
  interface PaletteOptions {
    joy: PaletteColor;
    neutral: PaletteColor;
    childhood: PaletteColor;
    colors: {
      gray: PaletteColorsOption;
      blue: PaletteColorsOption;
      blueR: PaletteColorsOption;
      green: PaletteColorsOption;
      orange: PaletteColorsOption;
      purple: PaletteColorsOption;
      red: PaletteColorsOption;
      yellow: PaletteColorsOption;
      pink: PaletteColorsOption;
    };
  }

  /**** TYPOGRAPHY VARIANTS ****/

  interface TypographyVariants {
    fontWeightSemibold: React.CSSProperties["fontWeight"];
    fontWeightExtrabold: React.CSSProperties["fontWeight"];
    inter10: React.CSSProperties;
    inter12: React.CSSProperties;
    inter14: React.CSSProperties;
    inter16: React.CSSProperties;
    inter18: React.CSSProperties;
    inter20: React.CSSProperties;
    inter24: React.CSSProperties;
    inter28: React.CSSProperties;
    inter32: React.CSSProperties;
    inter36: React.CSSProperties;
    inter42: React.CSSProperties;
    inter48: React.CSSProperties;
    inter54: React.CSSProperties;
    inter60: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    fontWeightSemibold: React.CSSProperties["fontWeight"];
    fontWeightExtrabold: React.CSSProperties["fontWeight"];
    inter10: React.CSSProperties;
    inter12: React.CSSProperties;
    inter14: React.CSSProperties;
    inter16: React.CSSProperties;
    inter18: React.CSSProperties;
    inter20: React.CSSProperties;
    inter24: React.CSSProperties;
    inter28: React.CSSProperties;
    inter32: React.CSSProperties;
    inter36: React.CSSProperties;
    inter42: React.CSSProperties;
    inter48: React.CSSProperties;
    inter54: React.CSSProperties;
    inter60: React.CSSProperties;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    neutral: true;
    joy: true;
    childhood: true;
  }
}

declare module "@mui/material/ToggleButtonGroup" {
  interface ToggleButtonGroupPropsColorOverrides {
    neutral: true;
    joy: true;
    childhood: true;
  }
}

declare module "@mui/material/SvgIcon" {
  interface SvgIconPropsColorOverrides {
    danger: true;
    neutral: true;
    joy: true;
    childhood: true;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    fontWeightSemibold: true;
    fontWeightExtrabold: true;
    inter10: true;
    inter12: true;
    inter14: true;
    inter16: true;
    inter18: true;
    inter20: true;
    inter24: true;
    inter28: true;
    inter32: true;
    inter36: true;
    inter42: true;
    inter48: true;
    inter54: true;
    inter60: true;
  }
}

/**** CREATE THEME ****/
const theme = createTheme({
  palette,
  shape: {
    borderRadius: 1,
  },
  typography,
  components,
  breakpoints,
  shadows,
});

export const styled = createStyled({ defaultTheme: theme });

export default theme;
