import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { AgeRangeModel } from "models/Settings/RatesAndPromo/AgeRangeModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";

export const remove =
  (axios: AxiosInstance) =>
  async (id: number): Promise<AgeRangeModel> => {
    return await axios.delete(
      `${SettingsEndpoint.SETTINGS_RATES_AGE_RANGE}/${id}`
    );
  };

export const getAll =
  (axios: AxiosInstance) =>
  async (params?: FetchingRequestParamsModels): Promise<AgeRangeModel[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_RATES_AGE_RANGE, {
        params,
      })
      .then(({ data }) => data.items);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: AgeRangeModel): Promise<AgeRangeModel> => {
    return await axios
      .post(SettingsEndpoint.SETTINGS_RATES_AGE_RANGE, data)
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (data: AgeRangeModel): Promise<AgeRangeModel> => {
    return await axios
      .put(`/${SettingsEndpoint.SETTINGS_RATES_AGE_RANGE}/${data.id}`, data)
      .then(({ data }) => data);
  };

export const toggleStatus =
  (axios: AxiosInstance) =>
  async (id: number): Promise<AgeRangeModel> => {
    return await axios
      .patch(`/${SettingsEndpoint.SETTINGS_RATES_AGE_RANGE}/${id}`)
      .then(({ data }) => data);
  };
