import { Avatar, Box, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { WhoamiModel } from "services/UserService";

const SectionUserInfo: FC<{
  user: WhoamiModel;
}> = ({ user }) => {
  return (
    <Stack direction="row" alignItems="center" m="4.5rem 11rem 2.5rem">
      <Box
        sx={{
          width: "9.5rem",
          height: "9.5rem",
          p: "1rem",
          borderRadius: "50%",
          backgroundColor: "colors.purple.o10",
        }}
      >
        <Avatar
          sizes="small"
          sx={{ width: "7.5rem", height: "7.5rem" }}
          alt="Remy Sharp"
          src="/assets/images/avatar/221.png"
        />
      </Box>
      <Box pl="2rem">
        <Typography
          variant="inter48"
          fontWeight="noraml"
          sx={{ color: "colors.purple.o70", display: "block" }}
        >
          <b>Hello,</b>{" "}
          {`${user?.user?.first_name ?? ""} ${
            user?.user?.last_name ?? ""
          }`.trim()}
        </Typography>

        <Typography
          variant="inter24"
          fontWeight="normal"
          sx={{ color: "colors.gray.o60" }}
        >
          {user?.user?.email ?? ""}
        </Typography>
      </Box>
    </Stack>
  );
};

export default SectionUserInfo;
