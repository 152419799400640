import { AxiosInstance } from "axios";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";

export interface NationalityModel {
  id: string;
  name: string;
}

export const getAll =
  (axios: AxiosInstance) =>
  async (
    params?: FetchingRequestParamsModels | undefined
  ): Promise<NationalityModel[]> => {
    return await axios
      .get("settings/nationalities", {
        params,
      })
      .then(({ data }) => data.items);
  };
