import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";

export interface UploadedFileModel {
  path: string;
  file_name: string;
}

export const uploadFile =
  (axios: AxiosInstance) =>
    async (formdata: FormData): Promise<UploadedFileModel | null> => {
      try {
        return await axios
          .post(SettingsEndpoint.UPLOAD_FILE, formdata, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(({ data }) => data);
      } catch (e) {
        return Promise.resolve(null);
      }
    };
