import { useEffect, useState } from "react";

export default function useDefaultMonthCalendar(start?: Date | null) {
  const [firstDefaultCalendarMonth, setFirstDefaultCalendarMonth] =
    useState<Date>();
  const [secondDefaultCalendarMonth, setSecondDefaultCalendarMonth] =
    useState<Date>();

  useEffect(() => {
    const date = start ? new Date(start.getTime()) : new Date();
    date.setHours(0, 0, 0, 0);
    setFirstDefaultCalendarMonth(date);
    const _date = new Date(date.getTime());
    _date.setMonth(_date.getMonth() + 1);
    setSecondDefaultCalendarMonth(_date);
  }, [start]);

  return { firstDefaultCalendarMonth, secondDefaultCalendarMonth };
}
