import { SETTINGS } from "./CommonEndpoint";

export const HOUSE_KEEPING = `${SETTINGS}/house_keeping`;

export const HOUSE_KEEPING_SECTION = `${HOUSE_KEEPING}/section`;
export const HOUSE_KEEPING_SECTION_ID = `${HOUSE_KEEPING_SECTION}/%s`;

export const HOUSE_KEEPING_HOUSE_KEEPER = `${HOUSE_KEEPING}/house-keepers`;
export const HOUSE_KEEPING_HOUSE_KEEPER_GET_ALL = HOUSE_KEEPING_HOUSE_KEEPER;
export const HOUSE_KEEPING_HOUSE_KEEPER_GET_ONE = `${HOUSE_KEEPING_HOUSE_KEEPER}/%s`;
export const HOUSE_KEEPING_HOUSE_KEEPER_CREATE = HOUSE_KEEPING_HOUSE_KEEPER;
export const HOUSE_KEEPING_HOUSE_KEEPER_UPDATE = `${HOUSE_KEEPING_HOUSE_KEEPER}/%s`;
export const HOUSE_KEEPING_HOUSE_KEEPER_DELETE = `${HOUSE_KEEPING_HOUSE_KEEPER}/%s`;
export const HOUSE_KEEPING_HOUSE_KEEPER_PATCH = `${HOUSE_KEEPING_HOUSE_KEEPER}/%s`;

export const HOUSE_KEEPING_WORK_TYPE = `${HOUSE_KEEPING}/work-types`;
export const HOUSE_KEEPING_WORK_TYPE_GET_ALL = HOUSE_KEEPING_WORK_TYPE;
export const HOUSE_KEEPING_WORK_TYPE_GET_ONE = `${HOUSE_KEEPING_WORK_TYPE}/%s`;
export const HOUSE_KEEPING_WORK_TYPE_CREATE = HOUSE_KEEPING_WORK_TYPE;
export const HOUSE_KEEPING_WORK_TYPE_UPDATE = `${HOUSE_KEEPING_WORK_TYPE}/%s`;
export const HOUSE_KEEPING_WORK_TYPE_DELETE = `${HOUSE_KEEPING_WORK_TYPE}/%s`;
export const HOUSE_KEEPING_WORK_TYPE_PATCH = `${HOUSE_KEEPING_WORK_TYPE}/%s`;

export const HOUSE_KEEPING_WORK_TASK = `${HOUSE_KEEPING}/work-tasks`;
export const HOUSE_KEEPING_WORK_TASK_GET_ALL = HOUSE_KEEPING_WORK_TASK;
export const HOUSE_KEEPING_WORK_TASK_GET_ONE = `${HOUSE_KEEPING_WORK_TASK}/%s`;
export const HOUSE_KEEPING_WORK_TASK_CREATE = HOUSE_KEEPING_WORK_TASK;
export const HOUSE_KEEPING_WORK_TASK_UPDATE = `${HOUSE_KEEPING_WORK_TASK}/%s`;
export const HOUSE_KEEPING_WORK_TASK_DELETE = `${HOUSE_KEEPING_WORK_TASK}/%s`;
export const HOUSE_KEEPING_WORK_TASK_PATCH = `${HOUSE_KEEPING_WORK_TASK}/%s`;

export const HOUSE_KEEPING_MAINTENANCE_TYPE = `${HOUSE_KEEPING}/maintenance-types`;
export const HOUSE_KEEPING_MAINTENANCE_TYPE_GET_ALL =
  HOUSE_KEEPING_MAINTENANCE_TYPE;
export const HOUSE_KEEPING_MAINTENANCE_TYPE_GET_ONE = `${HOUSE_KEEPING_MAINTENANCE_TYPE}/%s`;
export const HOUSE_KEEPING_MAINTENANCE_TYPE_CREATE =
  HOUSE_KEEPING_MAINTENANCE_TYPE;
export const HOUSE_KEEPING_MAINTENANCE_TYPE_UPDATE = `${HOUSE_KEEPING_MAINTENANCE_TYPE}/%s`;
export const HOUSE_KEEPING_MAINTENANCE_TYPE_DELETE = `${HOUSE_KEEPING_MAINTENANCE_TYPE}/%s`;
export const HOUSE_KEEPING_MAINTENANCE_TYPE_PATCH = `${HOUSE_KEEPING_MAINTENANCE_TYPE}/%s`;

export const HOUSE_KEEPING_BLOC = `${SETTINGS}/rooms/bloc`;
export const HOUSE_KEEPING_BLOC_ID = `${SETTINGS}/rooms/bloc/%s`;
