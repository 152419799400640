import { SettingsRoomsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import RoomFloorModel, {
  RoomFloorCreateModel,
} from "models/Settings/Rooms/RoomFloorModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";
import { sprintf } from "sprintf-js";

export const getAll =
  (axios: AxiosInstance) =>
  async (params?: FetchingRequestParamsModels): Promise<RoomFloorModel[]> => {
    return await axios
      .get(SettingsRoomsEndpoint.ROOMS_FLOOR, { params })
      .then(({ data }) => data.items);
  };

export const getOne =
  (axios: AxiosInstance) =>
  async (id: number | string): Promise<RoomFloorModel> => {
    return await axios
      .get(sprintf(SettingsRoomsEndpoint.ROOMS_FLOOR_ID, id))
      .then(({ data }) => data);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: RoomFloorCreateModel): Promise<RoomFloorModel> => {
    return await axios
      .post(SettingsRoomsEndpoint.ROOMS_FLOOR, data)
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (data: RoomFloorModel): Promise<RoomFloorModel> => {
    return await axios
      .put(sprintf(SettingsRoomsEndpoint.ROOMS_FLOOR_ID, data.id), data)
      .then(({ data }) => data);
  };

export const patch =
  (axios: AxiosInstance) =>
  async (data: Partial<RoomFloorModel>): Promise<RoomFloorModel> => {
    return await axios
      .patch(sprintf(SettingsRoomsEndpoint.ROOMS_FLOOR_ID, data?.id!), data)
      .then(({ data }) => data);
  };

export const remove =
  (axios: AxiosInstance) =>
  async (id: number | string): Promise<RoomFloorModel> => {
    return await axios
      .delete(sprintf(SettingsRoomsEndpoint.ROOMS_FLOOR_ID, id))
      .then(({ data }) => data);
  };
