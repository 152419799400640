import { Components, Theme } from "@mui/material";

export const MuiStepConnector: Components<
  Omit<Theme, "components">
>["MuiStepConnector"] = {
  styleOverrides: {
    root: {
      top: 0,
      left: 0,
      right: 0,
    },
    alternativeLabel: {},
    line: ({ theme }) => ({
      borderWidth: 2,
      ".Mui-completed > &": {
        borderColor: theme.palette.colors.purple.o70,
      },
      ".Mui-active > &": {
        borderColor: theme.palette.colors.purple.o70,
      },
    }),
  },
};
