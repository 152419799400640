export enum AccessibiltyModel {
  CRS_CONNEXTION = "crc_connection",
  CLOSED_CHANNEL_MANAGER_CONNECTION = "closed_channel_manager_connection",
  USE_PRECHECKIN_PLATFORM = "use_pre_check_in_platform",
  CHECKIN_CHECKOUT_STATION = "check_in_check_out_station",
  REQUIRE_SIGNATURE_AT_CHECKIN = "require_signature_at_check_in",
  REQUIRE_SIGNATURE_AT_CHECKOUT = "require_signature_at_check_out",
  USE_HOUSE_KEEPING_MOBILE_PLATFORM = "use_house_keeping_mobile_platform",
  USE_MAINTENANCE_MOBILE_PLATFORM = "use_maintenance_mobile_platform",
  USE_ID_SCAN = "use_id_scan",
  GUEST_PICTURE = "guest_picture",
  USE_CREDIT_CARD_INFORMATIONS = "use_credit_card_informations",
  SEND_INVOICE_BY_EMAIL = "send_invoice_by_email",
  ROOM_MUST_BE_CLEAN = "room_must_be_clean",
}
