import { REVENUE_MANAGEMENT_ROOT_ENDPOINT } from "./RootEndpoint";
import { SETTINGS_CARD } from "./SettingsEndpoint";

export const REVENUE_MANAGEMENT_CARD = `${REVENUE_MANAGEMENT_ROOT_ENDPOINT}/agency-card`;

export const CARD_GET_ALL = `${REVENUE_MANAGEMENT_CARD}/card`;
export const CARD_GET_ONE = `${REVENUE_MANAGEMENT_CARD}/card/%s`;
export const CARD_CREATE = `${REVENUE_MANAGEMENT_CARD}/draft`;
export const CARD_UPDATE = `${REVENUE_MANAGEMENT_CARD}/card/%s`;
export const CARD_DISCARD = `${REVENUE_MANAGEMENT_CARD}/%s/discard`;
export const CARD_UPDATE_DRAFT = `${REVENUE_MANAGEMENT_CARD}/%s/draft`;

// Card File Endpoint
export const CARD_FILE_DOWNLOAD = `${REVENUE_MANAGEMENT_CARD}/%1$s/file/%2$s/download`;
export const CARD_FILE_DELETE = `${REVENUE_MANAGEMENT_CARD}/%1$s/file/%2$s`;
export const CARD_FILE_GET_ALL = `${REVENUE_MANAGEMENT_CARD}/%1$s/file`;
export const CARD_FILE_CREATE = `${REVENUE_MANAGEMENT_CARD}/%1$s/file`;

// Card Note Endpoint
export const CARD_NOTE_GET_ONE = `${REVENUE_MANAGEMENT_CARD}/%1$s/note/%2$s`;
export const CARD_NOTE_DELETE = `${REVENUE_MANAGEMENT_CARD}/%1$s/note/%2$s`;
export const CARD_NOTE_GET_ALL = `${REVENUE_MANAGEMENT_CARD}/%1$s/note`;
export const CARD_NOTE_CREATE = `${REVENUE_MANAGEMENT_CARD}/%1$s/note`;

export const CARD_BILLING_INFO_CREATE = `${REVENUE_MANAGEMENT_CARD}/%1$s/billing-route`;
export const CARD_BILLING_INFO_GET_ONE = `${REVENUE_MANAGEMENT_CARD}/%1$s/billing-route/%2$s`;
export const CARD_BILLING_INFO_BY_CARDID = `${REVENUE_MANAGEMENT_CARD}/%1$s/billing-route/%2$s`;

// Card Contact Endpoint
export const CARD_CONTACT_GET_ONE = `${REVENUE_MANAGEMENT_CARD}/%1$s/contact/%2$s`;
export const CARD_CONTACT_UPDATE = `${REVENUE_MANAGEMENT_CARD}/%1$s/contact/%2$s`;
export const CARD_CONTACT_DELETE = `${REVENUE_MANAGEMENT_CARD}/%1$s/contact/%2$s`;
export const CARD_CONTACT_GET_ALL = `${REVENUE_MANAGEMENT_CARD}/%1$s/contact`;
export const CARD_CONTACT_CREATE = `${REVENUE_MANAGEMENT_CARD}/%1$s/contact`;
export const CARD_CONTRACT_RATE_GET_ALL = `${REVENUE_MANAGEMENT_CARD}/%1$s/rate`;
export const CARD_CONTRACT_RATE_UPDATE = `${REVENUE_MANAGEMENT_CARD}/%1$s/rate/%2$s`;

// Card Note Endpoint
export const CARD_PAYMENT_METHOD_DELETE = `${REVENUE_MANAGEMENT_CARD}/%1$s/payment-method/%2$s`;
export const CARD_PAYMENT_METHOD_GET_ALL = `${REVENUE_MANAGEMENT_CARD}/%1$s/payment-method`;
export const CARD_PAYMENT_METHOD_CREATE = `${REVENUE_MANAGEMENT_CARD}/%1$s/payment-method`;

// Card - Card Set Endpoint
export const CARD_CARD_SET_GET_ALL = `${REVENUE_MANAGEMENT_CARD}/%1$s/card-set`;
export const CARD_CARD_SET_GET_ALL_SEARCH = `${REVENUE_MANAGEMENT_CARD}/%1$s/card-set/search`;
export const CARD_CARD_SET_UPDATE = `${REVENUE_MANAGEMENT_CARD}/%1$s/card-set`;
export const CARD_CARD_SET_DELETE = `${REVENUE_MANAGEMENT_CARD}/%1$s/card-set`;

// Card Group Endpoint
export const CARD_GROUP_GET_ALL = `${SETTINGS_CARD}/group`;
