import { AxiosInstance } from "axios";
import { GuestNoteService } from "services/Reservations/Guest";

export interface GuestNoteServiceModel {
  create: ReturnType<typeof GuestNoteService.create>;
  getByGuest: ReturnType<typeof GuestNoteService.getByGuest>;
  remove: ReturnType<typeof GuestNoteService.remove>;
}

export const initGuestNoteService = (
  axios: AxiosInstance
): GuestNoteServiceModel => ({
  create: GuestNoteService.create(axios),
  getByGuest: GuestNoteService.getByGuest(axios),
  remove: GuestNoteService.remove(axios),
});