import { AxiosInstance } from "axios";
import { PaymentMethodService } from "services";

export interface PaymentMethodServiceModel {
  getAllPaymentMethodForReservation: ReturnType<
    typeof PaymentMethodService.getAllPaymentMethodForReservation
  >;
}

export const initPaymentMethodService = (
  axios: AxiosInstance
): PaymentMethodServiceModel => ({
  getAllPaymentMethodForReservation:
    PaymentMethodService.getAllPaymentMethodForReservation(axios),
});
