import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { PromotionAndChargeRulesModel } from "models/Settings/RatesAndPromo/RulesAndPromotionModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";

export const remove =
  (axios: AxiosInstance) =>
  async (id: number): Promise<PromotionAndChargeRulesModel> => {
    return await axios.delete(
      `${SettingsEndpoint.SETTINGS_RATES_RULES_AND_PROMOTION}/${id}`
    );
  };

export const getAll =
  (axios: AxiosInstance) =>
  async (
    params?: FetchingRequestParamsModels
  ): Promise<PromotionAndChargeRulesModel[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_RATES_RULES_AND_PROMOTION, {
        params,
      })
      .then(({ data }) => data.items);
  };

export const getById =
  (axios: AxiosInstance) =>
  async (id?: number): Promise<any> => {
    return await axios
      .get(`${SettingsEndpoint.SETTINGS_RATES_RULES_AND_PROMOTION}/${id}`)
      .then((data) => data.data);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: PromotionAndChargeRulesModel): Promise<PromotionAndChargeRulesModel> => {
    return await axios
      .post(SettingsEndpoint.SETTINGS_RATES_RULES_AND_PROMOTION, data)
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (data: PromotionAndChargeRulesModel): Promise<PromotionAndChargeRulesModel> => {
    return await axios
      .put(
        `/${SettingsEndpoint.SETTINGS_RATES_RULES_AND_PROMOTION}/${data.id}`,
        data
      )
      .then(({ data }) => data);
  };

export const toggleStatus =
  (axios: AxiosInstance) =>
  async (id: number): Promise<PromotionAndChargeRulesModel> => {
    return await axios
      .patch(`/${SettingsEndpoint.SETTINGS_RATES_RULES_AND_PROMOTION}/${id}`)
      .then(({ data }) => data);
  };
