import { WidgetsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";
import { LogbookModel } from "../../../models/Widgets/LogbookModel";

export const getAll =
  (axios: AxiosInstance) =>
  async (params?: FetchingRequestParamsModels): Promise<LogbookModel[]> => {
    return await axios
      .get(WidgetsEndpoint.WIDGETS_LOGBOOKS, {
        params,
      })
      .then(({ data }) => data.items);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: LogbookModel): Promise<LogbookModel> => {
    return await axios
      .post(WidgetsEndpoint.WIDGETS_LOGBOOKS, data)
      .then(({ data }) => data);
  };
