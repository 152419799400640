import AddonConfig from "./addon";
import AgeRangeConfig from "./age_range";
import BoardServiceConfig from "./board";
import common from "./common.json";
import ComplimentaryCategoryConfig from "./complimentary_category";
import FrequencyConfig from "./frequency";
import landingPage from "./landing_page.json";
import PackageConfig from "./package";
import PromotionChargeRulesConfig from "./promotion_and_charge_rules";
import RateRangeTemplateConfig from "./range_template";
import RateTypeConfig from "./rate_type";
import RulesAndRestrictionConfig from "./rules_and_restrictions";
import SeasonConfig from "./season";

const RateAndPromoConfig = {
    common,
    landingPage,
    RateTypeConfig,
    FrequencyConfig,
    PackageConfig,
    AddonConfig,
    RateRangeTemplateConfig,
    BoardServiceConfig,
    ComplimentaryCategoryConfig,
    AgeRangeConfig,
    PromotionChargeRulesConfig,
    RulesAndRestrictionConfig,
    SeasonConfig
};

export default RateAndPromoConfig;