import { RoutePaths } from "@constants";
import { Stack } from "@mui/material";
import ErrorLoadData from "components/Alerts/ErrorLoadData";
import PageSuspenseFallback from "components/PageSuspenseFallback";
import { RootContextProps } from "components/RootContainer";
import { RateModel } from "models/RevenueManagement/Rate/RateModel";
import React from "react";
import { Breadcrumb } from "routing/components/Breadcrumb";
import { RoutePathDefinition } from "routing/routes";

const RatesListPage = React.lazy(
  () => import("pages/RevenuManagementModule/pages/RateModule/pages/ListPage")
);
const RatesEditPage = React.lazy(
  () => import("pages/RevenuManagementModule/pages/RateModule/pages/EditPage")
);

const ErrorElement = () => (
  <Stack sx={{ p: "2rem" }}>
    <ErrorLoadData />
  </Stack>
);

const RatesRoutes = (Api: RootContextProps): Array<RoutePathDefinition> => [
  {
    index: true,
    element: (
      <React.Suspense fallback={<PageSuspenseFallback />}>
        <RatesListPage />
      </React.Suspense>
    ),
    handle: {
      crumb: () => <Breadcrumb label="Rate configuration" path={RoutePaths.RATE_LIST} />,
    },
  },
  {
    path: ":id/edit",
    element: (
      <React.Suspense fallback={<PageSuspenseFallback />}>
        <RatesEditPage />
      </React.Suspense>
    ),
    errorElement: <ErrorElement />,
    loader: async ({ params: { id } }) => {
      return await Api.HotelApi.revenueManagement.rate.rate.getOneById(+id!);
    },
    handle: {
      crumb: (rate: RateModel) => (
        <>
          <Breadcrumb
            label="Rate configuration . "
            path={RoutePaths.RATE_LIST}
          />
          <Breadcrumb label={rate?.details?.name ?? "--"} path={null} />
        </>
      ),
    },
  },
];

export default RatesRoutes;
