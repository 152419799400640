import { AxiosInstance } from "axios";
import { ReservationRoomsAndRatesActionsService } from "services/Reservations/RoomsAndRates";

export interface RoomsAndRatesActionsServiceModel {
  create: ReturnType<typeof ReservationRoomsAndRatesActionsService.create>;
  getByRoomRate: ReturnType<
    typeof ReservationRoomsAndRatesActionsService.getByRoomRate
  >;
  remove: ReturnType<typeof ReservationRoomsAndRatesActionsService.remove>;
}

export const initRoomsAndRatesActionsService = (
  axios: AxiosInstance
): RoomsAndRatesActionsServiceModel => ({
  create: ReservationRoomsAndRatesActionsService.create(axios),
  getByRoomRate: ReservationRoomsAndRatesActionsService.getByRoomRate(axios),
  remove: ReservationRoomsAndRatesActionsService.remove(axios),
});
