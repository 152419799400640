import { AxiosInstance } from "axios";
import { ReservationStayCardHeaderService } from "services/Reservations";

export interface StayCardHeaderService {
  getAll: ReturnType<typeof ReservationStayCardHeaderService.getAll>;
}

export const initStayCardHeaderService = (
  axios: AxiosInstance
): StayCardHeaderService => ({
  getAll: ReservationStayCardHeaderService.getAll(axios),
});
