import { Components, Theme } from "@mui/material";
export const MuiAlert: Components<Omit<Theme, "components">>["MuiAlert"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      minHeight: 48,
      padding: theme.spacing(2),
      lineHeight: "normal",
      color: theme.palette.common.black,
    }),
    icon: {
      height: 24,
      minWidth: 24,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      "& .MuiSvgIcon-root": {
        width: "13.3px",
        height: "13.3px",
      },
    },
    action: ({ theme }) => ({
      height: 24,
      width: 24,
      marginLeft: theme.spacing(2),
      padding: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& .MuiSvgIcon-root": {
        width: "13.3px",
        height: "13.3px",
      },
    }),
    message: ({ theme }) => ({
      overflow:"hidden",
      minHeight: "20px",
      padding: "3px 0",
      "& .MuiAlertTitle-root": {
        marginTop: 0,
        fontSize: theme.typography.inter14.fontSize,
        fontWeight: theme.typography.fontWeightSemibold,
        lineHeight: "normal",
      },
    }),
    standardInfo: ({ theme }) => ({
      fontSize: theme.typography.inter14.fontSize,
      backgroundColor: theme.palette.colors.blue.o10,
      "& .MuiAlert-icon": {
        color: theme.palette.info.contrastText,
        backgroundColor: theme.palette.info.main,
        "& .MuiSvgIcon-root": {
          width: "13.3px",
          height: "13.3px",
        },
      },
    }),
    standardSuccess: ({ theme }) => ({
      fontSize: "14px",
      backgroundColor: theme.palette.colors.green.o10,
      "& .MuiAlert-icon": {
        color: theme.palette.success.contrastText,
        backgroundColor: theme.palette.success.main,
        "& .MuiSvgIcon-root": {
          width: "13.3px",
          height: "13.3px",
        },
      },
    }),
    standardError: ({ theme }) => ({
      fontSize: theme.typography.inter14.fontSize,
      backgroundColor: theme.palette.colors.red.o10,
      "& .MuiAlert-icon": {
        color: theme.palette.error.contrastText,
        backgroundColor: theme.palette.error.main,
        "& .MuiSvgIcon-root": {
          width: "13.3px",
          height: "13.3px",
        },
      },
    }),
  },
};
