import { SettingsHouseKeepingEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import {
  HousekeeperCreatedModel,
  HousekeeperFormModel,
  HousekeeperModel,
} from "models/Settings/HouseKeeping/HousekeeperModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";
import { sprintf } from "sprintf-js";

export const getAll =
  (axios: AxiosInstance) =>
  async (params?: FetchingRequestParamsModels): Promise<HousekeeperModel[]> => {
    return await axios
      .get(SettingsHouseKeepingEndpoint.HOUSE_KEEPING_HOUSE_KEEPER_GET_ALL, {
        params,
      })
      .then(({ data }) => data.items);
  };

export const getOne =
  (axios: AxiosInstance) =>
  async (id: number | string): Promise<HousekeeperModel> => {
    return await axios
      .get(
        sprintf(
          SettingsHouseKeepingEndpoint.HOUSE_KEEPING_HOUSE_KEEPER_GET_ONE,
          id
        )
      )
      .then(({ data }) => data);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: HousekeeperFormModel): Promise<HousekeeperCreatedModel> => {
    return await axios
      .post(
        SettingsHouseKeepingEndpoint.HOUSE_KEEPING_HOUSE_KEEPER_CREATE,
        data
      )
      .then(({ data }) => data);
  };

type UpdateModel = { id: number | string; data: Partial<HousekeeperFormModel> };

export const update =
  (axios: AxiosInstance) =>
  async ({ id, data }: UpdateModel): Promise<HousekeeperCreatedModel> => {
    return await axios
      .put(
        sprintf(
          SettingsHouseKeepingEndpoint.HOUSE_KEEPING_HOUSE_KEEPER_UPDATE,
          id
        ),
        data
      )
      .then(({ data }) => data);
  };

export const patch =
  (axios: AxiosInstance) =>
  async (id: number): Promise<HousekeeperCreatedModel> => {
    return await axios
      .patch(
        sprintf(
          SettingsHouseKeepingEndpoint.HOUSE_KEEPING_HOUSE_KEEPER_PATCH,
          id
        )
      )
      .then(({ data }) => data);
  };

export const remove =
  (axios: AxiosInstance) =>
  async (id: number | string): Promise<HousekeeperCreatedModel> => {
    return await axios
      .delete(
        sprintf(
          SettingsHouseKeepingEndpoint.HOUSE_KEEPING_HOUSE_KEEPER_DELETE,
          id
        )
      )
      .then(({ data }) => data);
  };
