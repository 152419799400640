import { RootContextProps } from "components/RootContainer";
import React from "react";
import { RoutePathDefinition } from "routing/routes";

const RakManagerFilterPage = React.lazy(
  () => import("../pages/RakManagerFilterPage")
);
const GlobalRakPage = React.lazy(() => import("../pages/GlobalRakPage"));
const RateRakPage = React.lazy(() => import("../pages/RateRakPage"));

const RakManagerRoutes = (
  Api: RootContextProps
): Array<RoutePathDefinition> => [
  {
    index: true,
    element: (
      <React.Suspense fallback={<>...</>}>
        <RakManagerFilterPage />
      </React.Suspense>
    ),
  },
  {
    path: "results",
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<>...</>}>
            <GlobalRakPage />
          </React.Suspense>
        ),
      },
      {
        path: ":id",
        element: (
          <React.Suspense fallback={<>...</>}>
            <RateRakPage />
          </React.Suspense>
        ),
      },
    ],
  },
];

export default RakManagerRoutes;
