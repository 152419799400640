import { Components, Theme } from "@mui/material";
// import { TableSortIcon } from "components/SvgIcons/TableSortIcon";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// const IconComponent = (props: any) => {
//   return (
//     <TableSortIcon
//       {...props}
//       // sx={{ color: "colors.gray.o80", width: 16, height: 16 }}
//     />
//   );
// };

const IconSortComponent = (props: any) => {
  return <KeyboardArrowUpIcon {...props} />;
};

export const MuiTableSortLabel: Components<
  Omit<Theme, "components">
>["MuiTableSortLabel"] = {
  defaultProps: {
    IconComponent: IconSortComponent,
  },
  styleOverrides: {
    root: {
      display: "flex",
      justifyContent: "space-between",
      textTransform: "uppercase",
      "&.Mui-active": {
        "& .MuiTableSortLabel-iconDirectionAsc": {
          color: "#000",
        },
        "& .MuiTableSortLabel-iconDirectionDesc": {
          color: "#000",
        },
      },
    },

    icon: ({ ownerState, theme }) => ({
      color: "colors.gray.o80",

      // transition: "none",
      fontSize: "1.5rem",
      // width: 16,
      // height: 16,
      position: "relative",
      // opacity: 1,
    }),
    // iconDirectionAsc: {
    //   color: "inherite",
    // },
    // iconDirectionDesc: {
    //   color: "inherite",
    // },
  },
};
