import { RootContextProps } from "components/RootContainer";
import i18next from "i18next";
import RoomListModel from "models/Settings/Rooms/RoomListModel";
import { RoomsTypesModel } from "models/Settings/Rooms/RoomTypeModel";
import React, { FC } from "react";
import { Outlet, RouteObject, useRouteError } from "react-router-dom";
import { Breadcrumb } from "routing/components/Breadcrumb";

const CreateRoomListPage = React.lazy(
  () => import("../Pages/RoomListsPage/EditPage/CreateRoomList")
);
const EditRoomListPage = React.lazy(
  () => import("../Pages/RoomListsPage/EditPage/EditRoomList")
);
const LandingPage = React.lazy(() => import("../Pages/LandingPage"));
const CreateRoomTypePage = React.lazy(
  () => import("../Pages/RoomsTypesPage/EditPage/CreateRoomTypePage")
);
const EditRoomsTypesPage = React.lazy(
  () => import("../Pages/RoomsTypesPage/EditPage/EditRoomsTypes")
);
const ListingRoomsTypes = React.lazy(
  () => import("../Pages/RoomsTypesPage/ListingPage")
);
const RoomCategoriesPage = React.lazy(
  () => import("../Pages/RoomCategoriesPage")
);
const RoomListsPage = React.lazy(
  () => import("../Pages/RoomListsPage/ListPage")
);
const RoomFloorsPage = React.lazy(() => import("../Pages/RoomFloorsPage"));

const BlocksPage = React.lazy(() => import("../../RoomsPage/Pages/BlocPage"));

const SettingsCardsBoundary: FC = (): JSX.Element => {
  let error = useRouteError() as Error;
  return (
    <>
      <h2>Error 💥</h2>
      <p>{error.message}</p>
    </>
  );
};

export const routePathsRooms = (api: RootContextProps): RouteObject[] => [
  {
    index: true,
    element: <LandingPage />,
    errorElement: <SettingsCardsBoundary />,
  },
  {
    path: "rooms-types",
    element: <Outlet />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t("settings:RoomConfig.landingPage.room-types")}
          path={"/settings/rooms/rooms-types"}
        />
      ),
    },
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <ListingRoomsTypes />
          </React.Suspense>
        ),
      },
      {
        path: "create-room-type",
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <CreateRoomTypePage />
          </React.Suspense>
        ),
      },
      {
        path: ":id/edit",
        element: (
          <React.Suspense fallback={<>...</>}>
            <EditRoomsTypesPage />
          </React.Suspense>
        ),
        loader: async ({ params }) => {
          const {
            HotelApi: {
              settings: {
                room: {
                  roomType: { getById },
                },
              },
            },
          } = api;
          if (params?.id) {
            return getById(Number(params.id));
          }
        }, //roomTypeLoader,
        handle: {
          crumb: (data: RoomsTypesModel) => (
            <Breadcrumb label={data?.code} path={null} />
          ),
        },
      },
    ],
  },
  {
    path: "room-categories",
    element: (
      <React.Suspense fallback={<p>...</p>}>
        <RoomCategoriesPage />
      </React.Suspense>
    ),
    handle: {
      crumb: () => <Breadcrumb label="Room Categories" path={null} />,
    },
  },
  {
    path: "room-lists",
    handle: {
      crumb: () => (
        <Breadcrumb label="Room Lists" path={"/settings/rooms/rooms-types"} />
      ),
    },
    element: <Outlet />,
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<p>...</p>}>
            <RoomListsPage />
          </React.Suspense>
        ),
      },
      {
        path: "create-room",
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <CreateRoomListPage />
          </React.Suspense>
        ),
      },
      {
        path: ":id/edit",
        element: (
          <React.Suspense fallback={<>...</>}>
            <EditRoomListPage />
          </React.Suspense>
        ),
        loader: async ({ params }) => {
          const {
            HotelApi: {
              settings: {
                room: {
                  roomList: { getOne },
                },
              },
            },
          } = api;
          if (params?.id) {
            return getOne(Number(params.id));
          }
        }, //roomTypeLoader,
        handle: {
          crumb: (data: RoomListModel) => (
            <Breadcrumb label={String(data?.room_number)} path={null} />
          ),
        },
      },
    ],
  },
  {
    path: "room-floors",
    handle: {
      crumb: () => <Breadcrumb label="Room Floors" path={null} />,
    },
    element: (
      <React.Suspense fallback={<p>...</p>}>
        <RoomFloorsPage />
      </React.Suspense>
    ),
  },
  {
    path: "blocs",
    element: (
      <React.Suspense fallback={<>...</>}>
        <BlocksPage />
      </React.Suspense>
    ),
    handle: {
      crumb: () => <Breadcrumb label="Blocs" path={null} />,
    },
  },
];
