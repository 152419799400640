import { AxiosInstance } from "axios";
import { GuestService } from "services/Reservations/Guest";

export interface GuestServiceModel {
  getAll: ReturnType<typeof GuestService.getAll>;
  getOneById: ReturnType<typeof GuestService.getOneById>;
  update: ReturnType<typeof GuestService.update>;
  create: ReturnType<typeof GuestService.create>;
  getAllPaginated: ReturnType<typeof GuestService.getAllPaginated>;
}

export const initGuestService = (axios: AxiosInstance): GuestServiceModel => ({
  getAll: GuestService.getAll(axios),
  getOneById: GuestService.getOneById(axios),
  update: GuestService.update(axios),
  create: GuestService.create(axios),
  getAllPaginated: GuestService.getAllPaginated(axios),
});
