import { QuickReservationEnpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import QuickReservationModel from "models/Reservations/QuickReservation/QuikReservation";

export const search =
  (axios: AxiosInstance) =>
  async (data: QuickReservationModel): Promise<QuickReservationModel> => {
    return await axios
      .post(QuickReservationEnpoint.QUICK_RESERVATION_SEARCH_PER_ROOM, data)
      .then(({ data }) => data);
  };
