import common from "./common.json";
import daysEn from "./days.json";
import frontDesk from "./front-desk/common.json";
import menu from "./menu.json";
import monthsEn from "./months.json";
import PromoLangConfig from "./promo";
import QuickReservationConfig from "./quick-reservation";
import RateLangConfig from "./rate";
import rates from "./rates.json";
import ReservationLangConfig from "./reservation";
import RoomPlanConfig from "./room-plan/";
import SettingsConfig from "./settings";
import system from "./system";
import tupleEn from "./tuple.json";
import validation from "./validation.json";

const EnConfig = {
  common,
  validation,
  rates,
  menu,
  days: daysEn,
  rate: RateLangConfig,
  reservation: ReservationLangConfig,
  quickReservation: QuickReservationConfig,
  settings: SettingsConfig,
  system: system,
  roomPlan: RoomPlanConfig,
  promo: PromoLangConfig,
  frontDesk,
  tuple: tupleEn,
  months: monthsEn,
};

export default EnConfig;
