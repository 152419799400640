import { AxiosInstance } from "axios";
import { GuestFileService } from "services/Reservations/Guest";

export interface GuestFileServiceModel {
  getAll: ReturnType<typeof GuestFileService.getAll>;
  upload: ReturnType<typeof GuestFileService.upload>;
  download: ReturnType<typeof GuestFileService.download>;
  remove: ReturnType<typeof GuestFileService.remove>;
}

export const initGuestFileService = (
  axios: AxiosInstance
): GuestFileServiceModel => ({
  getAll: GuestFileService.getAll(axios),
  upload: GuestFileService.upload(axios),
  download: GuestFileService.download(axios),
  remove: GuestFileService.remove(axios),
});
