import { AxiosInstance } from "axios";
import {
  PaymentMethodCreateModel,
  PaymentMethodListModel,
  PaymentMethodModel,
} from "models/Settings/Financial/PaymentMethodModel";
import { sprintf } from "sprintf-js";
import { CardsEndpoint } from "@constants/ApiEndpoints";

export const create =
  (axios: AxiosInstance) =>
  async ({
    cardId,
    data,
  }: {
    cardId: number;
    data: PaymentMethodCreateModel;
  }): Promise<PaymentMethodModel> => {
    return await axios
      .post(sprintf(CardsEndpoint.CARD_PAYMENT_METHOD_CREATE, cardId), data)
      .then(({ data }) => data);
  };

export const getByCard =
  (axios: AxiosInstance) =>
  async (cardId: number): Promise<PaymentMethodListModel> => {
    return await axios
      .get(sprintf(CardsEndpoint.CARD_PAYMENT_METHOD_GET_ALL, cardId))
      .then(({ data }) => data.items);
  };

export const remove =
  (axios: AxiosInstance) =>
  async ({
    cardId,
    pmId,
  }: {
    cardId: number;
    pmId: number;
  }): Promise<PaymentMethodModel> => {
    return await axios
      .delete(sprintf(CardsEndpoint.CARD_PAYMENT_METHOD_DELETE, cardId, pmId))
      .then(({ data }) => data);
  };
