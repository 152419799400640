import { CircularProgress, Stack } from "@mui/material";
import ErrorLoadData from "components/Alerts/ErrorLoadData";
import { FC } from "react";

const RootContainerLoading: FC<{ error?: boolean }> = ({ error }) => {
  return (
    <Stack
      sx={{ flexGrow: 1, height: "100vh", width: "100%", p: "2rem" }}
      justifyContent="center"
      alignItems="center"
    >
      {error ? (
        <ErrorLoadData sx={{ width: "100%" }} />
      ) : (
        <>
          <CircularProgress size={40} />
        </>
      )}
    </Stack>
  );
};

export default RootContainerLoading;
