import { AxiosInstance } from "axios";
import { ReservationStayCardGeneralInfosService } from "services/Reservations";

export interface StayCardGeneralInfosService {
  getStayCardGeneralInfos: ReturnType<
    typeof ReservationStayCardGeneralInfosService.getStayCardGeneralInfos
  >;
  saveStayCardGeneralInfos: ReturnType<
    typeof ReservationStayCardGeneralInfosService.saveStayCardGeneralInfos
  >;
}

export const initStayCardGeneralInfosService = (
  axios: AxiosInstance
): StayCardGeneralInfosService => ({
  getStayCardGeneralInfos:
    ReservationStayCardGeneralInfosService.getStayCardGeneralInfos(axios),

  saveStayCardGeneralInfos:
    ReservationStayCardGeneralInfosService.saveStayCardGeneralInfos(axios),
});
