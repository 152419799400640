import { ReservationsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { StayCardHeaderDataModel } from "models/Reservations/StayCard/ReservationStayCardModel";
import { sprintf } from "sprintf-js";

export const getAll =
  (axios: AxiosInstance) =>
  async (params: {
    guestId: number;
    voucher: string | undefined;
    confirmationNumber: string | undefined;
  }): Promise<StayCardHeaderDataModel> => {
    return await axios
      .get(
        sprintf(
          ReservationsEndpoint.RESERVATION_STAY_CARD_HEADER_GET,
          params.guestId
        ),
        {
          params: {
            voucher: params.voucher,
            confirmation_number: params.confirmationNumber,
          },
        }
      )
      .then(({ data }) => data);
  };
