import { AxiosInstance } from "axios";
import { ReservationForecastService } from "services/Reservations";

export interface ForecastService {
  getAvailability: ReturnType<
    typeof ReservationForecastService.getAvailability
  >;
  getRoom: ReturnType<typeof ReservationForecastService.getRoom>;
  getPax: ReturnType<typeof ReservationForecastService.getPax>;
}

export const initForecastService = (axios: AxiosInstance): ForecastService => ({
  getAvailability: ReservationForecastService.getAvailability(axios),
  getRoom: ReservationForecastService.getRoom(axios),
  getPax: ReservationForecastService.getPax(axios),
});
