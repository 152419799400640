import {
  MenuItem as MuiMenuItem,
  MenuItemProps,
  styled,
  Typography,
} from "@mui/material";
import { CheckIcon } from "components/SvgIcons/CheckIcon";
import { FC } from "react";

export const SelectMenuItem = styled(MuiMenuItem)(
  ({
    theme: {
      palette: { colors },
      typography,
    },
  }) => ({
    height: "3rem",
    padding: "0.5rem 0.938rem",
    fontSize: typography.inter14.fontSize,
    color: "#030405",
    boxShadow: `inset 0 -1px 0 0 ${colors.purple.o10}`,
    display: "flex",
    flexFlow: "row",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    "&:hover": {
      backgroundColor: colors.purple.o10,
    },
    "&.Mui-selected": {
      backgroundColor: colors.purple.o20,
      color: colors.purple.o70,
    },
  })
);

export const MenuItem: FC<
  MenuItemProps & {
    firstItem?: boolean;
    error?: boolean;
  }
> = ({ children, firstItem, selected, error, ...props }) => (
  <SelectMenuItem selected={selected && !firstItem} {...props}>
    <Typography
      variant="inter14"
      flexGrow={1}
      noWrap
      textOverflow="ellipsis"
      {...(error && { color: "colors.red.o50" })}
    >
      {children}
    </Typography>
    {selected && !firstItem && <CheckIcon />}
  </SelectMenuItem>
);
