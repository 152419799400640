export const palette = {
  colors: {
    gray: {
      o100: "#121418",
      o90: "#232830",
      o80: "#475160",
      o70: "#6a798f",
      o60: "#7f8da1",
      o50: "#939eaf",
      o40: "#b7beca",
      o30: "#d2d7de",
      o20: "#e3e7eb",
      o10: "#f6f7f8",
    },
    blue: {
      o100: "#00257d",
      o90: "#00257d",
      o80: "#0032a5",
      o70: "#023dd0",
      o60: "#0f57fe",
      o50: "#3370ff",
      o40: "#719cff",
      o30: "#bfd3ff",
      o20: "#dfe9ff",
      o10: "#eff4ff",
    },
    blueR: {
      o100: "#152243",
      o90: "#223668",
      o80: "#283f7c",
      o70: "#3b5fb8",
      o60: "#4a6dc4",
      o50: "#728dd1",
      o40: "#9aaede",
      o30: "#c3ceea",
      o20: "#d7dff2",
      o10: "#ebeff8",
    },
    green: {
      o100: "#092904",
      o90: "#135209",
      o80: "#136009",
      o70: "#176d09",
      o60: "#1d890e",
      o50: "#2bbf12",
      o40: "#2ed315",
      o30: "#62ef4d",
      o20: "#baf8b0",
      o10: "#eefdeb",
    },
    orange: {
      o100: "#491800",
      o90: "#6d2400",
      o80: "#a43700",
      o70: "#c84300",
      o60: "#ec4f01",
      o50: "#ff5400",
      o40: "#fe6d23",
      o30: "#fe925b",
      o20: "#ffd9c7",
      o10: "#fff3ed",
    },
    purple: {
      o100: "#140641",
      o90: "#2e0e97",
      o80: "#5c32eb",
      o70: "#6e47ed",
      o60: "#8868f0",
      o50: "#a38bf4",
      o40: "#c8b9f8",
      o30: "#dad1fa",
      o20: "#e7e1fc",
      o10: "#f5f3fe",
    },
    red: {
      o100: "#370003",
      o90: "#6c0105",
      o80: "#920008",
      o70: "#b60008",
      o60: "#db000b",
      o50: "#ff020f",
      o40: "#ff4951",
      o30: "#ff9297",
      o20: "#fed8da",
      o10: "#ffedee",
    },
    yellow: {
      o100: "#4d3e01",
      o90: "#735c02",
      o80: "#9a7a02",
      o70: "#d2a900",
      o60: "#e7b903",
      o50: "#fecc0e",
      o40: "#fee376",
      o30: "#ffeeaa",
      o20: "#fff5cc",
      o10: "#fffcee",
    },
    pink: {
      o100: "#440027",
      o90: "#67003a",
      o80: "#89004e",
      o70: "#cd0174",
      o60: "#df007e",
      o50: "#ff0392",
      o40: "#ff78c4",
      o30: "#ffc5e6",
      o20: "#ffd8ee",
      o10: "#ffecf7",
    },
  },
  common: {
    black: "#000000",
    white: "#ffffff",
  },
  primary: {
    lighter: "#f5f3fe",
    light: "#dad1fa",
    main: "#6e47ed",
    dark: "#5c32eb",
    contrastText: "#fff",
  },
  secondary: {
    light: "#c3ceea",
    main: "#223668",
    dark: "#283f7c",
    contrastText: "#fff",
  },

  childhood: {
    light: "#ffc5e6",
    main: "#ff0392",
    dark: "#df007e",
    contrastText: "#fff",
  },
  joy: {
    light: "#ffeeaa",
    main: "#fecc0e",
    dark: "#e7b903",
    contrastText: "#fff",
  },
  neutral: {
    light: "#939eaf",
    main: "#939eaf",
    dark: "#7f8da1",
    contrastText: "#fff",
  },

  error: {
    light: "#ff9297",
    main: "#ff020f",
    dark: "#db000b",
    contrastText: "#fff",
  },
  warning: {
    light: "#fe925b",
    main: "#fe6d23",
    dark: "#ec4f01",
    darker: "#491800",
    contrastText: "#fff",
  },
  info: {
    light: "#bfd3ff",
    main: "#3370ff",
    dark: "#0f57fe",
    darker: "#00257d",
    contrastText: "#fff",
  },
  success: {
    light: "#62ef4d",
    main: "#2bbf12",
    dark: "#1d890e",
    darker: "#092904",
    contrastText: "#fff",
  },

  grey: {
    o50: "#f6f7f8",
    o100: "#e3e7eb",
    200: "#c9cfd7",
    300: "#b7beca",
    400: "#939eaf",
    500: "#7f8da1",
    600: "#6a798f",
    700: "#475160",
    800: "#232830",
    900: "#121418",
    Ao100: "#f6f7f8",
    A200: "#e3e7eb",
    A400: "#b7beca",
    A700: "#6a798f",
  },
  text: {
    primary: "#000",
    secondary: "#7f8da1",
    disabled: "#b7beca",
  },

  background: {
    default: "#e3e7eb",
  },
};
