import {
  CardBillingModel,
  initCardBilling,
} from "api/Services/HotelSettingServices/RevenueManagement/card/billingInfoService";
import { AxiosInstance } from "axios";
import { CardCardServiceModel, initCardCardService } from "./CardCardService";
import {
  CardCardSetServiceModel,
  initCardCardSetService,
} from "./CardCardSetService";
import {
  CardContactServiceModel,
  initCardContactService,
} from "./CardContactService";
import { CardFileServiceModel, initCardFileService } from "./CardFileService";
import { CardNoteServiceModel, initCardNoteService } from "./CardNoteService";
import {
  CardPaymentMethodServiceModel,
  initCardPaymentMethodService,
} from "./CardPaymentMethodService";
import {
  CardContractRateServiceModel,
  initCardContractRateService,
} from "./CardContractRateService";

export interface CardServiceModel {
  card: CardCardServiceModel;
  contact: CardContactServiceModel;
  file: CardFileServiceModel;
  note: CardNoteServiceModel;
  paymentMethod: CardPaymentMethodServiceModel;
  cardSet: CardCardSetServiceModel;
  billingInfo: CardBillingModel;
  contractRate: CardContractRateServiceModel;
}

export const initCardService = (axios: AxiosInstance): CardServiceModel => ({
  card: initCardCardService(axios),
  contact: initCardContactService(axios),
  file: initCardFileService(axios),
  note: initCardNoteService(axios),
  paymentMethod: initCardPaymentMethodService(axios),
  cardSet: initCardCardSetService(axios),
  billingInfo: initCardBilling(axios),
  contractRate: initCardContractRateService(axios),
});
