import { AxiosInstance } from "axios";
import {
  CashCategoryServiceModel,
  initCashCategoryService,
} from "./CashCategory/cashCategory";
import { CashierServiceModel, initCashierService } from "./Cashier/cashier";
import {
  CashRegisterServiceModel,
  initCashRegisterService,
} from "./CashOperation/cashRegisterOperation";
import {
  CashRegisterOperationServiceModel,
  initCashRegisterOperationService,
} from "./CashRegisterOperation/cashRegisterOperation";
import {
  SettingsCurrencyServiceModel,
  initCurrencyService,
} from "./Currency/CurrencyService";
import {
  PaymentMethodServiceModel,
  initPaymentMethodService,
} from "./PaymentMethod/PaymentMethod";
import {
  PaymentTypeServiceModel,
  initPaymentTypeService,
} from "./PaymentType/PaymentType";

export interface SettingFinancialServiceModel {
  currency: SettingsCurrencyServiceModel;
  paymentType: PaymentTypeServiceModel;
  paymentMethod: PaymentMethodServiceModel;
  cashRegisterOperation: CashRegisterOperationServiceModel;
  cashRegister: CashRegisterServiceModel;
  cashCategory: CashCategoryServiceModel;
  cashier: CashierServiceModel;
}

export const initSettingFinancialService = (
  axios: AxiosInstance
): SettingFinancialServiceModel => ({
  currency: initCurrencyService(axios),
  paymentType: initPaymentTypeService(axios),
  paymentMethod: initPaymentMethodService(axios),
  cashRegisterOperation: initCashRegisterOperationService(axios),
  cashRegister: initCashRegisterService(axios),
  cashCategory: initCashCategoryService(axios),
  cashier: initCashierService(axios),
});
