import { AxiosInstance } from "axios";
import { CardNoteService } from "services/RevenueManagement/Card";

export interface CardNoteServiceModel {
  create: ReturnType<typeof CardNoteService.create>;
  getByCard: ReturnType<typeof CardNoteService.getByCard>;
  remove: ReturnType<typeof CardNoteService.remove>;
}

export const initCardNoteService = (
  axios: AxiosInstance
): CardNoteServiceModel => ({
  create: CardNoteService.create(axios),
  getByCard: CardNoteService.getByCard(axios),
  remove: CardNoteService.remove(axios),
});
