import { AxiosInstance } from "axios";
import { AgeRangeService } from "services";

export interface AgeRangeServiceModel {
  getAll: ReturnType<typeof AgeRangeService.getAll>;
  create: ReturnType<typeof AgeRangeService.create>;
  update: ReturnType<typeof AgeRangeService.update>;
  remove: ReturnType<typeof AgeRangeService.remove>;
}

export const initAgeRangeService = (
  axios: AxiosInstance
): AgeRangeServiceModel => ({
  getAll: AgeRangeService.getAll(axios),
  create: AgeRangeService.create(axios),
  update: AgeRangeService.update(axios),
  remove: AgeRangeService.remove(axios),
});
