import list from "./list.json";
import create from "./create.json";
import common from "./common.json";
import edit from "./edit.json";
import details from "./details.json";
import range from "./range";

const RateLangConfig = {
  common,
  list,
  create,
  edit,
  details,
  range,
};

export default RateLangConfig;
