import { ReservationsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import ReservationRoomRateNoteModel, {
  ReservationRoomRateNoteFormModel,
} from "models/Reservations/ReservationRoomRateNote";
import { sprintf } from "sprintf-js";

export const getByRoomRate =
  (axios: AxiosInstance) =>
  async (
    reservation_id: number,
    roomRateId: number
  ): Promise<ReservationRoomRateNoteModel[]> => {
    return await axios
      .get(
        sprintf(
          ReservationsEndpoint.ROOM_RATE_NOTE_GET,
          reservation_id,
          roomRateId
        )
      )
      .then(({ data }) => data.items);
  };

export const create =
  (axios: AxiosInstance) =>
  async (item: {
    reservation_id: number;
    id: number;
    data: ReservationRoomRateNoteFormModel;
  }): Promise<ReservationRoomRateNoteModel> => {
    return await axios
      .post(
        sprintf(
          ReservationsEndpoint.ROOM_RATE_NOTE_CREATE,
          item.reservation_id,
          item.id
        ),
        item.data
      )
      .then(({ data }) => data);
  };

export const remove =
  (axios: AxiosInstance) =>
  async (params: { reservation_id: number; id: number }): Promise<any> => {
    return await axios
      .delete(
        sprintf(
          ReservationsEndpoint.ROOM_RATE_NOTE_REMOVE,
          params.reservation_id,
          params.id
        )
      )
      .then(({ data }) => data);
  };
