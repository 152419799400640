import { Components, Theme } from "@mui/material";
export const MuiInputLabel: Components<
  Omit<Theme, "components">
>["MuiInputLabel"] = {
  defaultProps: {
    shrink: true,
  },
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      fontSize: 12,
      fontWeight: 600,
      textTransform: "uppercase",
      marginTop: 0,

      "&.Mui-focused": {
        color: theme.palette.common.black,
      },
      "&.Mui-error": {
        color: theme.palette.common.black,
      },
    }),

    sizeSmall: {
      "&.MuiInputLabel-filled": {
        fontSize: "11px",
        fontWeight: 600,
      },
    },
    standard: ({ theme }) => ({
      color: theme.palette.common.black,
      transform: "translate(0px, -2px) scale(1)",
    }),
    outlined: ({ theme }) => ({
      color: theme.palette.common.black,
      transform: "translate(0px, -19px) scale(1)",
    }),
    filled: ({ ownerState, theme }) => ({
      fontSize: 11,
      fontWeight: 600,
      lineHeight: "normal",
      letterSpacing: "0.47px",
      transform: "translate(12px, 7px) scale(1)",
    }),
  },
};
