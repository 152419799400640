import {
  Box,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { TrashIcon } from "components/SvgIcons/TrashIcon";
import { format } from "date-fns";
import CashierRateExchangeModel from "models/Cashier/CashierRateExchange";
import { FC } from "react";

type Props = {
  data?: CashierRateExchangeModel[] | null;
  onClickEdit?: (item: CashierRateExchangeModel) => () => void;
};

const CashierRateExchangeList: FC<Props> = ({ data, onClickEdit }) => {
  const renderDateFormat = (date: string | Date | null) => {
    if (!date) return "";
    return format(new Date(date), "dd/MM/yyyy");
  };

  return (
    <TableContainer component={Box}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Currency</TableCell>
            <TableCell>From Date</TableCell>
            <TableCell>Unit</TableCell>
            <TableCell>Rate</TableCell>
            <TableCell align="right" />
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {(data || []).map((item, key) => (
            <TableRow
              key={key}
              role="button"
              sx={{ cursor: "pointer" }}
              onClick={onClickEdit?.(item)}
            >
              <TableCell>{item.currency_name}</TableCell>
              <TableCell>{renderDateFormat(item.from_date)}</TableCell>
              <TableCell>{item.currency_unit}</TableCell>
              <TableCell>{item.rate}</TableCell>
              <TableCell
                sx={{ width: "7.5rem" }}
                align="right"
                onClick={(e) => e.stopPropagation()}
              >
                <Switch checked={item.is_active} />
              </TableCell>
              <TableCell sx={{ width: "5.5rem" }} align="right">
                <IconButton color="error">
                  <TrashIcon sx={{ color: "colors.red.o50" }} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CashierRateExchangeList;
