import { AxiosInstance } from "axios";
import { CurrencyRevenuManagement } from "services";

export interface CurrencyRevenuManagementServiceModel {
  getAll: ReturnType<typeof CurrencyRevenuManagement.getAll>;
}

export const initCurrencyRevenuManagementService = (
  axios: AxiosInstance
): CurrencyRevenuManagementServiceModel => ({
  getAll: CurrencyRevenuManagement.getAll(axios),
});
