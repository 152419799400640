import { AxiosInstance } from "axios";
import { RoomsTypes } from "services";

export interface RoomsTypesModel {
  getAll: ReturnType<typeof RoomsTypes.getAll>;
  getById: ReturnType<typeof RoomsTypes.getById>;
  create: ReturnType<typeof RoomsTypes.create>;
  update: ReturnType<typeof RoomsTypes.update>;
  remove: ReturnType<typeof RoomsTypes.remove>;
  toggleStatus: ReturnType<typeof RoomsTypes.toggleStatus>;
}

export const initRoomsTypes = (axios: AxiosInstance): RoomsTypesModel => ({
  getAll: RoomsTypes.getAll(axios),
  getById: RoomsTypes.getById(axios),
  create: RoomsTypes.create(axios),
  update: RoomsTypes.update(axios),
  remove: RoomsTypes.remove(axios),
  toggleStatus: RoomsTypes.toggleStatus(axios),
});
