import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AddOnScopeModel } from "@constants/system";
import { AxiosInstance } from "axios";
import {
  AddOnModel,
  ItemPackageModel,
} from "models/Settings/RevenuManagement/AddOnModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";

type FetchingRequestParamsAddonModel = FetchingRequestParamsModels & {
  extra_field?: AddOnScopeModel;
};

export const remove =
  (axios: AxiosInstance) =>
  async (id: number): Promise<AddOnModel> => {
    return await axios.delete(
      `${SettingsEndpoint.SETTINGS_REVENU_MANAGEMENT_ADDON}/${id}`
    );
  };

export const getAll =
  (axios: AxiosInstance) =>
  async (params?: FetchingRequestParamsAddonModel): Promise<AddOnModel[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_REVENU_MANAGEMENT_ADDON, {
        params,
      })
      .then(({ data }) => data.items);
  };

export const getAllItemsAndPackages =
  (axios: AxiosInstance) =>
  async (
    params?: FetchingRequestParamsAddonModel
  ): Promise<ItemPackageModel[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_REVENU_MANAGEMENT_ADDON_ITEM_PACKAGE, {
        params,
      })
      .then(({ data }) => data);
  };

export const getById =
  (axios: AxiosInstance) =>
  async (id?: number): Promise<AddOnModel> => {
    return await axios
      .get(`/${SettingsEndpoint.SETTINGS_REVENU_MANAGEMENT_ADDON}/${id}`)
      .then(({ data }) => data);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: AddOnModel): Promise<AddOnModel> => {
    return await axios
      .post(SettingsEndpoint.SETTINGS_REVENU_MANAGEMENT_ADDON, {...data, rate: "rate"})
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (data: AddOnModel): Promise<AddOnModel> => {
    return await axios
      .put(
        `/${SettingsEndpoint.SETTINGS_REVENU_MANAGEMENT_ADDON}/${data.id}`,
        data
      )
      .then(({ data }) => data);
  };

export const toggleStatus =
  (axios: AxiosInstance) =>
  async (id: number): Promise<AddOnModel> => {
    return await axios
      .patch(`/${SettingsEndpoint.SETTINGS_REVENU_MANAGEMENT_ADDON}/${id}`)
      .then(({ data }) => data);
  };
