import { AxiosInstance } from "axios";
import { CardFileService } from "services/RevenueManagement/Card";

export interface CardFileServiceModel {
  getAll: ReturnType<typeof CardFileService.getAll>;
  upload: ReturnType<typeof CardFileService.upload>;
  download: ReturnType<typeof CardFileService.download>;
  remove: ReturnType<typeof CardFileService.remove>;
}

export const initCardFileService = (
  axios: AxiosInstance
): CardFileServiceModel => ({
  getAll: CardFileService.getAll(axios),
  upload: CardFileService.upload(axios),
  download: CardFileService.download(axios),
  remove: CardFileService.remove(axios),
});
