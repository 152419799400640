import { AppBar, AppBarProps } from "@mui/material";
import { FC } from "react";

export const HeaderContainer: FC<AppBarProps> = ({ sx, ...props }) => (
  <AppBar
    position="sticky"
    elevation={0}
    sx={{
      bgcolor: "colors.gray.o30",
      display: "flex",
      flex: "0 1 auto",
      p: "1rem",
      zIndex: 1,
      height: "5rem",
      ...sx,
    }}
    {...props}
  />
);
