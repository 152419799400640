import { AxiosInstance } from "axios";
import { ReservationNotesService } from "services/Reservations";

export interface ReservationNotesServiceModel {
  create: ReturnType<typeof ReservationNotesService.create>;
  getAll: ReturnType<typeof ReservationNotesService.getAll>;
  update: ReturnType<typeof ReservationNotesService.update>;
  remove: ReturnType<typeof ReservationNotesService.remove>;
}

export const initReservationNotesService = (
  axios: AxiosInstance
): ReservationNotesServiceModel => ({
  create: ReservationNotesService.create(axios),
  getAll: ReservationNotesService.getAll(axios),
  update: ReservationNotesService.update(axios),
  remove: ReservationNotesService.remove(axios),
});
