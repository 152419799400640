import { RatesEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance, AxiosResponse } from "axios";
import RateRangeCopyFromModel from "models/RevenueManagement/Rate/RateRangeCopyFromModel";
import {
  RateRangeCreateModel,
  RateRangeDuplicateModel,
  RateRangeModel,
  RateRangesModel,
} from "models/RevenueManagement/Rate/RateRangeModel";
import { sprintf } from "sprintf-js";

export const getAll =
  (axios: AxiosInstance) =>
  async (rateId: number): Promise<RateRangesModel> => {
    return await axios
      .get(sprintf(RatesEndpoint.RATE_RANGE_GET_ALL, rateId))
      .then(({ data }) => data);
  };

export const getOneById =
  (axios: AxiosInstance) =>
  async ({
    id,
    rateId,
  }: {
    id: number;
    rateId: number;
  }): Promise<RateRangeModel> => {
    return await axios
      .get(sprintf(RatesEndpoint.RATE_RANGE_GET_ONE, rateId, id))
      .then(({ data }) => data);
  };

export const create =
  (axios: AxiosInstance) =>
  async ({
    rateId,
    rateRange,
  }: {
    rateId: number;
    rateRange: RateRangeCreateModel;
  }): Promise<RateRangeModel> => {
    return await axios
      .post(sprintf(RatesEndpoint.RATE_RANGE_CREATE, rateId), rateRange)
      .then(({ data }) => data);
  };

export const copyFrom =
  (axios: AxiosInstance) =>
  async (data: {
    rateId: number;
    rate: RateRangeCopyFromModel;
  }): Promise<RateRangeModel[]> => {
    return await axios
      .post(sprintf(RatesEndpoint.RATE_RANGE_COPY_FROM, data.rateId), data.rate)
      .then(({ data }) => data);
  };

export const baseOn =
  (axios: AxiosInstance) =>
  async (data: {
    rateId: number;
    rate: RateRangeCopyFromModel;
  }): Promise<RateRangeModel[]> => {
    return await axios
      .post(sprintf(RatesEndpoint.RATE_RANGE_BASE_ON, data.rateId), data.rate)
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async ({
    rateId,
    rateRangeId,
    rateRange,
  }: {
    rateId: number;
    rateRangeId: number;
    rateRange: RateRangeCreateModel;
  }): Promise<RateRangeModel> => {
    return await axios
      .put(
        sprintf(RatesEndpoint.RATE_RANGE_CREATE, rateId, rateRangeId),
        rateRange
      )
      .then(({ data }) => data);
  };

export const remove =
  (axios: AxiosInstance) =>
  async ({
    rateId,
    rateRangeId,
  }: {
    rateId: number;
    rateRangeId: number;
  }): Promise<AxiosResponse<RateRangeModel>> => {
    return await axios.delete(
      sprintf(RatesEndpoint.RATE_RANGE_DELETE, rateId, rateRangeId)
    );
  };

export const broke =
  (axios: AxiosInstance) =>
  async (rateId: number): Promise<AxiosResponse<RateRangeModel>> => {
    return await axios.delete(sprintf(RatesEndpoint.RATE_RANGE_BROKE, rateId));
  };

export const duplicate =
  (axios: AxiosInstance) =>
  async ({
    rateId,
    rateRangeId,
    rateRange,
  }: {
    rateId: number;
    rateRangeId: number;
    rateRange: RateRangeDuplicateModel;
  }): Promise<RateRangeModel> => {
    return await axios
      .post(
        sprintf(RatesEndpoint.RATE_RANGE_DUPLICATE, rateId, rateRangeId),
        rateRange
      )
      .then(({ data }) => data);
  };
