import { SettingsRoomsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import RoomListModel from "models/Settings/Rooms/RoomListModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";
import { sprintf } from "sprintf-js";

export const getAll =
  (axios: AxiosInstance) =>
  async (
    params?: FetchingRequestParamsModels
  ): Promise<{ total: number; items: RoomListModel[] }> => {
    return await axios
      .get(SettingsRoomsEndpoint.ROOMS_LIST, { params })
      .then(({ data }) => data);
  };

export const getOne =
  (axios: AxiosInstance) =>
  async (id: number | string): Promise<RoomListModel> => {
    return await axios
      .get(sprintf(SettingsRoomsEndpoint.ROOMS_LIST_ID, id))
      .then(({ data }) => data);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: any): Promise<RoomListModel> => {
    return await axios
      .post(SettingsRoomsEndpoint.ROOMS_LIST, data)
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (data: any): Promise<RoomListModel> => {
    return await axios
      .put(sprintf(SettingsRoomsEndpoint.ROOMS_LIST_ID, data.id), data)
      .then(({ data }) => data);
  };

export const toggleStatus =
  (axios: AxiosInstance) =>
  async (id: number): Promise<RoomListModel> => {
    return await axios
      .patch(sprintf(SettingsRoomsEndpoint.ROOMS_LIST_ID, id!), null)
      .then(({ data }) => data);
  };

export const remove =
  (axios: AxiosInstance) =>
  async (id: number | string): Promise<RoomListModel> => {
    return await axios
      .delete(sprintf(SettingsRoomsEndpoint.ROOMS_LIST_ID, id))
      .then(({ data }) => data);
  };
