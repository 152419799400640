import { AxiosInstance } from "axios";
import { LanguageService } from "services";

export interface LanguageServiceModel {
  getCurrentLanguage: ReturnType<typeof LanguageService.getCurrentLanguage>;
  create: ReturnType<typeof LanguageService.create>;
}

export const initLanguageService = (
  axios: AxiosInstance
): LanguageServiceModel => ({
  getCurrentLanguage: LanguageService.getCurrentLanguage(axios),
  create: LanguageService.create(axios),
});
