import { SettingsHouseKeepingEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import {
  WorkTypeFormModel,
  WorkTypeModel,
} from "models/Settings/HouseKeeping/WorkTypeModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";
import { sprintf } from "sprintf-js";

export const getAll =
  (axios: AxiosInstance) =>
  async (params?: FetchingRequestParamsModels): Promise<WorkTypeModel[]> => {
    return await axios
      .get(SettingsHouseKeepingEndpoint.HOUSE_KEEPING_WORK_TYPE_GET_ALL, {
        params,
      })
      .then(({ data }) => data.items);
  };

export const getOne =
  (axios: AxiosInstance) =>
  async (id: number | string): Promise<WorkTypeModel> => {
    return await axios
      .get(
        sprintf(
          SettingsHouseKeepingEndpoint.HOUSE_KEEPING_WORK_TYPE_GET_ONE,
          id
        )
      )
      .then(({ data }) => data);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: WorkTypeFormModel): Promise<WorkTypeModel> => {
    return await axios
      .post(SettingsHouseKeepingEndpoint.HOUSE_KEEPING_WORK_TYPE_CREATE, data)
      .then(({ data }) => data);
  };

type UpdateModel = { id: number | string; data: Partial<WorkTypeFormModel> };

export const update =
  (axios: AxiosInstance) =>
  async ({ id, data }: UpdateModel): Promise<WorkTypeModel> => {
    return await axios
      .put(
        sprintf(
          SettingsHouseKeepingEndpoint.HOUSE_KEEPING_WORK_TYPE_UPDATE,
          id
        ),
        data
      )
      .then(({ data }) => data);
  };

export const patch =
  (axios: AxiosInstance) =>
  async (id: number): Promise<WorkTypeModel> => {
    return await axios
      .patch(
        sprintf(SettingsHouseKeepingEndpoint.HOUSE_KEEPING_WORK_TYPE_PATCH, id)
      )
      .then(({ data }) => data);
  };

export const remove =
  (axios: AxiosInstance) =>
  async (id: number | string): Promise<WorkTypeModel> => {
    return await axios
      .delete(
        sprintf(SettingsHouseKeepingEndpoint.HOUSE_KEEPING_WORK_TYPE_DELETE, id)
      )
      .then(({ data }) => data);
  };
