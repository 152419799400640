import { AxiosInstance } from "axios";
import { NationalityService } from "services";

export interface NationalityServiceModel {
  getAll: ReturnType<typeof NationalityService.getAll>;
}

export const initNationalityService = (
  axios: AxiosInstance
): NationalityServiceModel => ({
  getAll: NationalityService.getAll(axios),
});
