import { RESERVATION_PAYEMENT_TYPE } from "@constants/ApiEndpoints/ReservationEndpoint";
import { AxiosInstance } from "axios";
import { ReservationPaymentTypesListModel } from "pages/ReservationModule/SharedModels/ReservationPaymentTypesListModel";

export const getAll =
  (axios: AxiosInstance) =>
  async (): Promise<ReservationPaymentTypesListModel[]> => {
    return await axios.get(RESERVATION_PAYEMENT_TYPE).then(({ data }) => data);
  };

export const calculateAmount =
  (axios: AxiosInstance) =>
  async (item: {
    payment_type_id: number;
    amount: number;
  }): Promise<{
    amount: number;
    fees_amount: number;
    total_amount: number;
  }> => {
    return await axios
      .post(RESERVATION_PAYEMENT_TYPE, item)
      .then(({ data }) => data);
  };
