import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { AeroportModel } from "models/Settings/Reservation/Aeroport/AireportModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";

export const remove =
  (axios: AxiosInstance) =>
  async (id: number): Promise<AeroportModel> => {
    return await axios.delete(
      `${SettingsEndpoint.SETTINGS_RESERVATION_AEROPORT}/${id}`
    );
  };

export const getAll =
  (axios: AxiosInstance) =>
  async (params?: FetchingRequestParamsModels): Promise<AeroportModel[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_RESERVATION_AEROPORT, {
        params,
      })
      .then(({ data }) => data.items);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: AeroportModel): Promise<AeroportModel> => {
    return await axios
      .post(SettingsEndpoint.SETTINGS_RESERVATION_AEROPORT, data)
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (data: AeroportModel): Promise<AeroportModel> => {
    return await axios
      .put(`${SettingsEndpoint.SETTINGS_RESERVATION_AEROPORT}/${data.id}`, data)
      .then(({ data }) => data);
  };

export const toggleStatus =
  (axios: AxiosInstance) =>
  async (id: number): Promise<AeroportModel> => {
    return await axios
      .patch(`${SettingsEndpoint.SETTINGS_RESERVATION_AEROPORT}/${id}`)
      .then(({ data }) => data);
  };
