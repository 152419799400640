import { SettingsPaths } from "@constants/Navigation/AppPaths";
import i18next from "i18next";
import React, { FC } from "react";
import { Outlet, RouteObject, useRouteError } from "react-router-dom";
import { Breadcrumb } from "routing/components/Breadcrumb";

const AeroportPage = React.lazy(() => import("../pages/AeroportPage"));
const AgeRangesPage = React.lazy(() => import("../pages/AgeRangesPage"));
const LandingPage = React.lazy(() => import("../pages/LandingPage"));
const OriginOfBookingPage = React.lazy(
  () => import("../pages/OriginOfBookingPage")
);
const ReservationStatePage = React.lazy(
  () => import("../pages/ReservationStatePage")
);
const RoomLikeAndFeaturePage = React.lazy(
  () => import("../pages/RoomLikeAndFeaturePage")
);
const SegmentMarketCodePage = React.lazy(
  () => import("../pages/SegmentAndMarketCodePage")
);
const SourcePage = React.lazy(() => import("../pages/SourcesPage"));
const VipCategoryPage = React.lazy(() => import("../pages/VipCategoryPage"));

const SettingsCardsBoundary: FC = (): JSX.Element => {
  let error = useRouteError() as Error;
  return (
    <>
      <h2>Error 💥</h2>
      <p>{error.message}</p>
    </>
  );
};

export const routePathsReservations: RouteObject[] = [
  {
    index: true,
    element: <LandingPage />,
    errorElement: <SettingsCardsBoundary />,
  },
  {
    path: SettingsPaths.SETTINGS_RESERVATION_SEGMENT_AND_MARKET_CODE_PATH,
    element: <Outlet />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t(
            "settings:ReservationConfig.landingPage.segment-market-code"
          )}
          path={SettingsPaths.SETTINGS_RESERVATION_SEGMENT_AND_MARKET_CODE_PATH}
        />
      ),
    },
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <SegmentMarketCodePage />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    path: SettingsPaths.SETTINGS_RESERVATION_SOURCE,
    element: <Outlet />,
    handle: {
      crumb: () => (
        // <Breadcrumb label={i18next.t("settings:ReservationConfig.landingPage.segment-market-code")} path={"settings/reservation/source"} />
        <Breadcrumb
          label={"Sources"}
          path={SettingsPaths.SETTINGS_RESERVATION_SOURCE}
        />
      ),
    },
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <SourcePage />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    path: SettingsPaths.SETTINGS_RESERVATION_RESERVATION_STATES,
    element: <Outlet />,
    handle: {
      crumb: () => (
        // <Breadcrumb label={i18next.t("settings:ReservationConfig.landingPage.segment-market-code")} path={"settings/reservation/source"} />
        <Breadcrumb
          label={"Reservation states"}
          path={SettingsPaths.SETTINGS_RESERVATION_RESERVATION_STATES}
        />
      ),
    },
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <ReservationStatePage />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    path: SettingsPaths.SETTINGS_RESERVATION_AEROPORTS,
    element: <Outlet />,
    handle: {
      crumb: () => (
        // <Breadcrumb label={i18next.t("settings:ReservationConfig.landingPage.segment-market-code")} path={"settings/reservation/source"} />
        <Breadcrumb
          label={"Aeroports"}
          path={SettingsPaths.SETTINGS_RESERVATION_AEROPORTS}
        />
      ),
    },
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <AeroportPage />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    path: SettingsPaths.SETTINGS_RESERVATION_VIP_CATEGORY,
    element: <Outlet />,
    handle: {
      crumb: () => (
        // <Breadcrumb label={i18next.t("settings:ReservationConfig.landingPage.segment-market-code")} path={"settings/reservation/source"} />
        <Breadcrumb
          label={"Vip Category"}
          path={SettingsPaths.SETTINGS_RESERVATION_VIP_CATEGORY}
        />
      ),
    },
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <VipCategoryPage />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    path: SettingsPaths.SETTINGS_RESERVATION_ROOM_LIKE_AND_FEATURE,
    element: <Outlet />,
    handle: {
      crumb: () => (
        // <Breadcrumb label={i18next.t("settings:ReservationConfig.landingPage.segment-market-code")} path={"settings/reservation/source"} />
        <Breadcrumb
          label={"Room likes & Feature"}
          path={SettingsPaths.SETTINGS_RESERVATION_ROOM_LIKE_AND_FEATURE}
        />
      ),
    },
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <RoomLikeAndFeaturePage />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    path: SettingsPaths.SETTINGS_RESERVATION_ORIGIN_OF_BOOKING,
    element: <Outlet />,
    handle: {
      crumb: () => (
        // <Breadcrumb label={i18next.t("settings:ReservationConfig.landingPage.segment-market-code")} path={"settings/reservation/source"} />
        <Breadcrumb
          label={"Origin of booking"}
          path={SettingsPaths.SETTINGS_RESERVATION_ORIGIN_OF_BOOKING}
        />
      ),
    },
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <OriginOfBookingPage />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    path: SettingsPaths.SETTINGS_RESERVATION_AGE_RANGES,
    element: <Outlet />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label="Age Ranges"
          path={SettingsPaths.SETTINGS_RESERVATION_AGE_RANGES}
        />
      ),
    },
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <AgeRangesPage />
          </React.Suspense>
        ),
      },
    ],
  },
];
