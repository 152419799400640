export const logout = () => {
  const { hostname, protocol, host } = window.location;
  document.cookie =
    "lightresa_2=; Path=/;  Domain=" +
    host +
    "; Expires=Thu, 01 Jan 1970 00:00:01 GMT; SameSite=None; Secure";
  document.cookie =
    "lightresa=; Path=/;  Domain=" +
    host +
    "; Expires=Thu, 01 Jan 1970 00:00:01 GMT; SameSite=None; Secure";
  window.location.href = `${protocol}//${hostname}/logout`;
};
