import { AxiosInstance } from "axios";
import { CurrencyService } from "services";

export interface SettingsCurrencyServiceModel {
    getAll: ReturnType<typeof CurrencyService.getAll>;
    create: ReturnType<typeof CurrencyService.create>;
    update: ReturnType<typeof CurrencyService.update>;
    remove: ReturnType<typeof CurrencyService.remove>;
}

export const initCurrencyService = (
    axios: AxiosInstance
): SettingsCurrencyServiceModel => ({
    getAll: CurrencyService.getAll(axios),
    create: CurrencyService.create(axios),
    update: CurrencyService.update(axios),
    remove: CurrencyService.remove(axios),
});
