import { FC } from "react";
import { Alert, AlertProps, AlertTitle, Typography } from "@mui/material";

interface ErrorLoadDataProps extends AlertProps {
  title?: string;
  message?: string;
}

const ErrorLoadData: FC<ErrorLoadDataProps> = ({
  title,
  message,
  ...props
}): JSX.Element => {
  return (
    <Alert severity="error" {...props}>
      {title && <AlertTitle>{title}</AlertTitle>}
      <Typography variant="inter12">{message}</Typography>
    </Alert>
  );
};

ErrorLoadData.defaultProps = {
  title: "Error",
  message: "Unable to load data",
};

export default ErrorLoadData;
