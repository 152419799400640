import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const CalendarIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.2 4.8v1h-4V13.144c0 3.541-.413 6.516-2.707 8.81l-.293.293V23.8h3v4h22v-22h-4v-1h-2v1h-10v-1h-2zm-2 3h2v1h2v-1h10v1h2v-1h2v2h-18v-2zm0 4h18v1.344c0 3.402-.436 6.4-2.49 8.656H6.087c1.71-2.566 2.113-5.593 2.113-8.656V11.8zm2 2v2h2v-2h-2zm4 0v2h2v-2h-2zm4 0v2h2v-2h-2zm4 0v2h2v-2h-2zm-12.31 4-.69 2h2l.69-2h-2zm4 0-.69 2h2l.69-2h-2zm4 0-.69 2h2l.69-2h-2zm8.31 3.973V25.8h-18v-2h16.414l.293-.293c.536-.536.914-1.136 1.293-1.734z"
        fill="#939EAF"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};
