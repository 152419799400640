import { Box } from "@mui/material";
import FinancialAccountNumberModel from "models/Settings/Financial/FinancialAccountNumberModel";
import SettingListHeader from "pages/SettingsModule/components/ListHeader";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import FinancialAccountNumberCreate from "./components/Create";
import FinancialAccountNumberList from "./components/List";
import AccountNumberFakeData from "./fake-data/data";

const defaultValues: FinancialAccountNumberModel = {
  description: null,
  is_active: true,
  account_group_id: null,
  account_number: null,
};

type OpenCreateType = {
  key: string;
  open: boolean;
  defaultValues: FinancialAccountNumberModel;
  mode: "create" | "update";
};

const openCreateValues: OpenCreateType = {
  key: uuid(),
  open: false,
  mode: "create",
  defaultValues,
};

const FinancialAccountNumberPage: FC = () => {
  const navigate = useNavigate();

  const handleClickBack = () => navigate(-1);

  const [openCreate, setOpenCreate] =
    useState<OpenCreateType>(openCreateValues);

  const handleOpenCreate = () => {
    setOpenCreate({ key: uuid(), open: true, mode: "create", defaultValues });
  };

  const handleCloseCreate = () => {
    setOpenCreate({
      key: uuid(),
      open: false,
      mode: "create",
      defaultValues,
    });
  };

  const handleOpenEdit = (defaultValues: FinancialAccountNumberModel) => () => {
    setOpenCreate({ key: uuid(), open: true, mode: "update", defaultValues });
  };

  const [search, setSearch] = useState<string>("");
  const handleSearchChange = (value: string) => setSearch(value);

  const render = () => {
    return (
      <FinancialAccountNumberList
        data={AccountNumberFakeData}
        onClickEdit={handleOpenEdit}
      />
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexFlow: "column",
          flexGrow: 1,
          height: "100%",
        }}
      >
        <SettingListHeader
          search={search}
          searchPlaceholder="Search By Name…"
          handleSearchChange={handleSearchChange}
          handleClickBack={handleClickBack}
          handleClickAddNew={handleOpenCreate}
        />
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            overflowY: "auto",
            scrollSnapType: "y mandatory",
            p: "1rem 1.5rem 2rem",
            position: "relative",
          }}
        >
          {render()}
        </Box>
      </Box>
      <FinancialAccountNumberCreate
        {...openCreate}
        handleClose={handleCloseCreate}
      />
    </>
  );
};

export default FinancialAccountNumberPage;
