import { AxiosInstance } from "axios";
import { HotelService } from "services/Settings";
import {
  DashboardServiceModel,
  initDashboardService,
} from "./Services/DashboardService";
import { DraftServiceModel, initDraftService } from "./Services/DraftService";
import {
  EndOfDayServiceModel,
  initEndOfDayService,
} from "./Services/EndOfDayServices";
import {
  GlobalSettingsServiceModel,
  initGlobalSettingsService,
} from "./Services/GlobalSettingsServices";
import {
  HotelSettingServiceModel,
  initHotelSettingService,
} from "./Services/HotelSettingServices";
import {
  HotelRevenueManagementServiceModel,
  initHotelRevenueManagementService,
} from "./Services/HotelSettingServices/RevenueManagement";
import {
  HouseKeepingRootServiceModel,
  initHouseKeepingRootService,
} from "./Services/HouseKeepingService/HouseKeepingRoot";
import {
  ReservationsService,
  initReservationsService,
} from "./Services/ReservationsServices";
import {
  WidgetsServiceModel,
  initWidgetsService,
} from "./Services/WidgetsServices";
import {
  CashierRootServiceModel,
  initCashierRootService,
} from "./Services/CashierService/RootCashier";

export interface SettingInfosServiceModel {
  getInfos: ReturnType<typeof HotelService.getInfos>;
  update: ReturnType<typeof HotelService.update>;
}

export interface HotelServiceModel {
  hotel: SettingInfosServiceModel;
  settings: HotelSettingServiceModel;
  revenueManagement: HotelRevenueManagementServiceModel;
  reservations: ReservationsService;
  widgets: WidgetsServiceModel;
  globalSettings: GlobalSettingsServiceModel;
  dashboard: DashboardServiceModel;
  draft: DraftServiceModel;
  endOfDay: EndOfDayServiceModel;
  houseKeeping: HouseKeepingRootServiceModel;
  cashier: CashierRootServiceModel;
}

export const getHotelService = (axios: AxiosInstance): HotelServiceModel => ({
  hotel: {
    getInfos: HotelService.getInfos(axios),
    update: HotelService.update(axios),
  },
  revenueManagement: initHotelRevenueManagementService(axios),
  settings: initHotelSettingService(axios),
  reservations: initReservationsService(axios),
  widgets: initWidgetsService(axios),
  globalSettings: initGlobalSettingsService(axios),
  dashboard: initDashboardService(axios),
  draft: initDraftService(axios),
  endOfDay: initEndOfDayService(axios),
  houseKeeping: initHouseKeepingRootService(axios),
  cashier: initCashierRootService(axios),
});
