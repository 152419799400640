import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { CancellationPenlalityModel } from "models/Settings/RatesAndPromo/Rules/CancellationPenlalityModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";

export const remove =
  (axios: AxiosInstance) =>
  async (id: number): Promise<CancellationPenlalityModel> => {
    return await axios.delete(
      `${SettingsEndpoint.SETTINGS_RATES_RULE_CANCELATION_PENALITY}/${id}`
    );
  };

export const getAll =
  (axios: AxiosInstance) =>
  async (
    params?: FetchingRequestParamsModels | undefined
  ): Promise<CancellationPenlalityModel[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_RATES_RULE_CANCELATION_PENALITY, {
        params,
      })
      .then(({ data }) => data.items);
  };

export const create =
  (axios: AxiosInstance) =>
  async (
    data: CancellationPenlalityModel
  ): Promise<CancellationPenlalityModel> => {
    return await axios
      .post(SettingsEndpoint.SETTINGS_RATES_RULE_CANCELATION_PENALITY, data)
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (
    data: CancellationPenlalityModel
  ): Promise<CancellationPenlalityModel> => {
    return await axios
      .put(
        `/${SettingsEndpoint.SETTINGS_RATES_RULE_CANCELATION_PENALITY}/${data.id}`,
        data
      )
      .then(({ data }) => data);
  };

export const toggleStatus =
  (axios: AxiosInstance) =>
  async (id: number): Promise<CancellationPenlalityModel> => {
    return await axios
      .patch(
        `/${SettingsEndpoint.SETTINGS_RATES_RULE_CANCELATION_PENALITY}/${id}`
      )
      .then(({ data }) => data);
  };
