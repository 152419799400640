import { AxiosInstance } from "axios";
import { ReservationStayCardStayCardRoomRateService } from "services/Reservations";

export interface StayCardRoomRatesService {
  getRoomRate: ReturnType<
    typeof ReservationStayCardStayCardRoomRateService.getRoomRateRoom
  >;
  getRoomRateGuests: ReturnType<
    typeof ReservationStayCardStayCardRoomRateService.getRoomRateGuests
  >;
  saveStayCardRoomRates: ReturnType<
    typeof ReservationStayCardStayCardRoomRateService.saveStayCardRoomRate
  >;
}

export const initStayCardRoomRatesService = (
  axios: AxiosInstance
): StayCardRoomRatesService => ({
  getRoomRate:
    ReservationStayCardStayCardRoomRateService.getRoomRateRoom(axios),
  getRoomRateGuests:
    ReservationStayCardStayCardRoomRateService.getRoomRateGuests(axios),
  saveStayCardRoomRates:
    ReservationStayCardStayCardRoomRateService.saveStayCardRoomRate(axios),
});
