import { AxiosInstance } from "axios";
import { BoardService } from "services";

export interface BoardServiceModel {
  getAll: ReturnType<typeof BoardService.getAll>;
  create: ReturnType<typeof BoardService.create>;
  update: ReturnType<typeof BoardService.update>;
  remove: ReturnType<typeof BoardService.remove>;
  toggleStatus: ReturnType<typeof BoardService.toggleStatus>;
}

export const initBoardService = (axios: AxiosInstance): BoardServiceModel => ({
  getAll: BoardService.getAll(axios),
  create: BoardService.create(axios),
  update: BoardService.update(axios),
  remove: BoardService.remove(axios),
  toggleStatus: BoardService.toggleStatus(axios),
});
