import { PromosEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import {
  PromoCreateModel,
  PromoModel,
} from "models/RevenueManagement/Promo/PromoModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";
import { sprintf } from "sprintf-js";

export const getAll =
  (axios: AxiosInstance) =>
  async (
    params?: FetchingRequestParamsModels | undefined
  ): Promise<PromoModel[]> => {
    return await axios
      .get(PromosEndpoint.PROMOTION_CHARGE_GET_ALL, { params })
      .then(({ data }) => data.items);
  };

export const getOne =
  (axios: AxiosInstance) =>
  async (id: number): Promise<PromoModel> => {
    return await axios
      .get(sprintf(PromosEndpoint.PROMOTION_CHARGE_GET_ONE, id))
      .then(({ data }) => data);
  };

export const create =
  (axios: AxiosInstance) =>
  async (promo: PromoCreateModel): Promise<PromoModel> => {
    return await axios
      .post(PromosEndpoint.PROMOTION_CHARGE_CREATE, promo)
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async ({
    id,
    promo,
  }: {
    id: number;
    promo: PromoModel;
  }): Promise<PromoModel> => {
    return await axios
      .patch(sprintf(PromosEndpoint.PROMOTION_CHARGE_UPDATE, id), promo)
      .then(({ data }) => data.data);
  };

export const remove =
  (axios: AxiosInstance) =>
  async (id: number): Promise<PromoModel> => {
    return await axios
      .delete(sprintf(PromosEndpoint.PROMOTION_CHARGE_REMOVE, id))
      .then(({ data }) => data.data);
  };
