import { AxiosInstance } from "axios";
import {
  BillingGroupServiceModel,
  initBillingGroupService,
} from "./BillingGroup/BillingGroupService";
import {
  ChargeCodeServiceModel,
  initChargeCodeService,
} from "./ChargeCode/ChargeCodeService";
import {
  ChargeGroupServiceModel,
  initChargeGroupService,
} from "./ChargeGroup/ChargeGroupService";
import {
  SettingsCurrencyServiceModel,
  initCurrencyService,
} from "./Currency/CurrencyService";
import { ItemServiceModel, initItemService } from "./Item/ItemService";
import {
  DiscountTypeServiceModel,
  initBillingTypeService,
} from "./billing/BillingService";

export interface SettingRevenuManagementServiceModel {
  chargeCode: ChargeCodeServiceModel;
  chargeGroup: ChargeGroupServiceModel;
  billingGroup: BillingGroupServiceModel;
  item: ItemServiceModel;
  discountType: DiscountTypeServiceModel;
  currency: SettingsCurrencyServiceModel;
}

export const initSettingRevenuManagementService = (
  axios: AxiosInstance
): SettingRevenuManagementServiceModel => ({
  chargeCode: initChargeCodeService(axios),
  chargeGroup: initChargeGroupService(axios),
  item: initItemService(axios),
  currency: initCurrencyService(axios),
  billingGroup: initBillingGroupService(axios),
  discountType: initBillingTypeService(axios),
});
