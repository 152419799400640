import AeroportConfig from "./aeroport";
import common from "./common.json";
import landingPage from "./landing_page.json";
import OriginOfBooking from "./origin_of_booking";
import ReservationstateConfig from "./reservation_state";
import RoomLikeAndFeatureConfig from "./room_likes_feature";
import SegmentMarketCodeConfig from "./segment_market_code";
import MarketCodeConfig from "./segment_market_code/market_code";
import SourceConfig from "./source";
import VipCategoryConfig from "./vip_category";

const ReservationConfig = {
    common,
    SegmentMarketCodeConfig,
    landingPage,
    MarketCodeConfig,
    AeroportConfig,
    OriginOfBooking,
    VipCategoryConfig,
    RoomLikeAndFeatureConfig,
    SourceConfig,
    ReservationstateConfig
};

export default ReservationConfig;