import { CardsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance, AxiosResponse } from "axios";
import {
  CreateNoteModel,
  NoteModel,
} from "models/RevenueManagement/Card/NoteModel";
import { sprintf } from "sprintf-js";

export const getByCard =
  (axios: AxiosInstance) =>
  async (cardId: number | string): Promise<Array<NoteModel>> => {
    return await axios
      .get(sprintf(CardsEndpoint.CARD_NOTE_GET_ALL, cardId))
      .then(({ data }) => data.items);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: CreateNoteModel): Promise<AxiosResponse<NoteModel>> => {
    return await axios.post(
      sprintf(CardsEndpoint.CARD_NOTE_CREATE, data.card_id),
      data
    );
  };

export const remove =
  (axios: AxiosInstance) =>
  async (cardId: number, noteId: number): Promise<AxiosResponse<NoteModel>> => {
    return await axios.delete(
      sprintf(CardsEndpoint.CARD_NOTE_DELETE, cardId, noteId)
    );
  };
