import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { SETTINGS_USER_CHANGE_PASSWORD } from "@constants/ApiEndpoints/SettingsEndpoint";
import { AxiosInstance } from "axios";
import { ChangePasswordModel, UserModel } from "models/Settings/Acl/userModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";
import { sprintf } from "sprintf-js";

export const remove =
  (axios: AxiosInstance) =>
    async (id: number): Promise<UserModel> => {
      return await axios.delete(
        `${SettingsEndpoint.SETTINGS_USER}/${id}`
      );
    };

export const getAll =
  (axios: AxiosInstance) =>
    async (params?: FetchingRequestParamsModels): Promise<UserModel[]> => {
      return await axios
        .get(SettingsEndpoint.SETTINGS_USER, {
          params,
        })
        .then(({ data }) => data.items);
    };

export const getById =
  (axios: AxiosInstance) =>
    async (id?: number): Promise<UserModel> => {
      return await axios
        .get(`/${SettingsEndpoint.SETTINGS_USER}/${id}`)
        .then(({ data }) => data);
    };

export const create =
  (axios: AxiosInstance) =>
    async (data: UserModel): Promise<UserModel> => {
      return await axios
        .post(SettingsEndpoint.SETTINGS_USER, data)
        .then(({ data }) => data);
    };

export const update =
  (axios: AxiosInstance) =>
    async (data: UserModel): Promise<UserModel> => {
      return await axios
        .put(`/${SettingsEndpoint.SETTINGS_USER}/${data.id}`, data)
        .then(({ data }) => data);
    };

export const updatePassword =
  (axios: AxiosInstance) =>
    async (data: ChangePasswordModel & { id: number }): Promise<UserModel> => {

      return await axios
        .patch(sprintf(SETTINGS_USER_CHANGE_PASSWORD, data.id), data)
        .then(({ data }) => data);
    };
