import {
  CASH_REGISTER_VERIFY_CODE_PIN,
  CASHIER_CREATE_EXCHANHE,
  CASHIER_CREATE_OTHER_OPERATION,
  CASHIER_GET_ALL,
} from "@constants/ApiEndpoints/CashierRootEndpoint";
import { CASHIER_ROOT_ENDPOINT } from "@constants/ApiEndpoints/RootEndpoint";
import { AxiosInstance } from "axios";
import _ from "lodash";
import { OtherOperationModel } from "models/Cashier/OtherOperation";
import { TransactionDataModel } from "models/Cashier/TransactionsModels";
import { CashRegisterModel } from "models/Settings/Financial/CashOpertationModel";
import { CashierAuthFormModel } from "pages/CashierPage/components/Form/CashierAuthForm";
import { ExchangeOperationFormModel } from "pages/CashierPage/models";
import { sprintf } from "sprintf-js";

export type Params = {
  search?: string;
  limit?: number;
  offset?: number;
};

export const getCashRegisterForCurrentUser =
  (axios: AxiosInstance) =>
  async (): Promise<{
    items: CashRegisterModel[];
    total: number;
  }> =>
    await axios.get(`${CASHIER_ROOT_ENDPOINT}/qsdqsd`).then(({ data }) => data);

export const getAllCashRegisters =
  (axios: AxiosInstance) => async (): Promise<any> =>
    await axios.get(`${CASHIER_ROOT_ENDPOINT}`).then(({ data }) => data);

export const getAll =
  (axios: AxiosInstance) =>
  async (params?: {
    limit?: number;
    offset?: number;
    cash_register: number;
  }): Promise<TransactionDataModel> =>
    await axios
      .get(`${CASHIER_GET_ALL}/${params?.cash_register}`, {
        params: _.omit(params, "cash_register"),
      })
      .then(({ data }) => data);

export const update =
  (axios: AxiosInstance) =>
  async (data: Partial<OtherOperationModel>): Promise<OtherOperationModel> => {
    return await axios
      .post(CASHIER_GET_ALL, data)
      .then(({ data }) => data?.items);
  };

export const createOther =
  (axios: AxiosInstance) =>
  async (data: Partial<OtherOperationModel>): Promise<OtherOperationModel> => {
    return await axios
      .post(sprintf(CASHIER_CREATE_OTHER_OPERATION, data.cash_register_id), {
        ..._.omit(data, "cash_register_id"),
      })
      .then(({ data }) => data?.items);
  };

export const createExchange =
  (axios: AxiosInstance) =>
  async (
    data: Partial<ExchangeOperationFormModel>
  ): Promise<ExchangeOperationFormModel> => {
    return await axios
      .post(sprintf(CASHIER_CREATE_EXCHANHE, data?.cash_register_id), {
        ..._.omit(data, "cash_register_id"),
      })
      .then(({ data }) => data?.items);
  };

export const verifyCodePin =
  (axios: AxiosInstance) =>
  async (data: CashierAuthFormModel): Promise<any> => {
    return await axios
      .post(CASH_REGISTER_VERIFY_CODE_PIN, {
        ...data,
      })
      .then(({ data }) => data?.items);
  };
