import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const SettingsIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.803 3a13.36 13.36 0 0 0-3.032.358 1 1 0 0 0-.767.866l-.212 1.935A1.998 1.998 0 0 1 8.999 7.77h-.002L7.22 6.988a1 1 0 0 0-1.133.232 13.309 13.309 0 0 0-3.044 5.247 1 1 0 0 0 .366 1.097l1.577 1.156a2 2 0 0 1 0 3.227l-1.577 1.155a1 1 0 0 0-.366 1.096 13.305 13.305 0 0 0 3.044 5.249 1 1 0 0 0 1.133.23l1.777-.782a2 2 0 0 1 2.795 1.613l.212 1.935a1 1 0 0 0 .766.864c.968.226 1.982.36 3.033.36a13.36 13.36 0 0 0 3.033-.358 1 1 0 0 0 .767-.866l.212-1.935a1.998 1.998 0 0 1 2.793-1.613l1.779.782a1 1 0 0 0 1.133-.23 13.314 13.314 0 0 0 3.044-5.25 1 1 0 0 0-.366-1.095l-1.577-1.155a1.998 1.998 0 0 1 0-3.227l1.577-1.155a1 1 0 0 0 .366-1.096A13.314 13.314 0 0 0 25.52 7.22a1 1 0 0 0-1.133-.23l-1.779.782a1.999 1.999 0 0 1-2.793-1.613l-.212-1.935a1 1 0 0 0-.766-.865A13.328 13.328 0 0 0 15.803 3zm0 2c.65 0 1.274.117 1.899.227l.125 1.15a4.002 4.002 0 0 0 5.587 3.227l1.057-.466a11.24 11.24 0 0 1 1.904 3.283l-.937.687a4.002 4.002 0 0 0 0 6.45l.937.688a11.24 11.24 0 0 1-1.904 3.283l-1.057-.465a4.002 4.002 0 0 0-5.587 3.226l-.125 1.15c-.625.11-1.25.227-1.899.227-.65 0-1.273-.117-1.898-.227l-.125-1.15a4.002 4.002 0 0 0-5.587-3.227l-1.058.466a11.232 11.232 0 0 1-1.903-3.283l.937-.687a4.002 4.002 0 0 0 0-6.452l-.937-.688a11.235 11.235 0 0 1 1.905-3.282l1.056.465a4.002 4.002 0 0 0 5.587-3.226l.125-1.15c.625-.11 1.25-.226 1.898-.226zm0 6c-2.933 0-5.333 2.4-5.333 5.333 0 2.934 2.4 5.334 5.333 5.334 2.934 0 5.334-2.4 5.334-5.334 0-2.933-2.4-5.333-5.334-5.333zm0 2a3.318 3.318 0 0 1 3.334 3.333 3.318 3.318 0 0 1-3.334 3.334 3.318 3.318 0 0 1-3.333-3.334A3.318 3.318 0 0 1 15.803 13z"
      fillRule="nonzero"
    />
  </SvgIcon>
);
