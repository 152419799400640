import { Components, Theme } from "@mui/material";

export const MuiRadio: Components<Omit<Theme, "components">>["MuiRadio"] = {
  styleOverrides: {
    root: {
      "& .MuiSvgIcon-root": {
        fontSize: "1.5rem",
      },
    },
  },
};
