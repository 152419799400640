import { AxiosInstance } from "axios";
import { CurrencyChainService } from "services";

export interface CurrencyChainServiceModel {
  getAll: ReturnType<typeof CurrencyChainService.getAll>;
  toggleStatus: ReturnType<typeof CurrencyChainService.toggleStatus>;
}

export const initCurrencyChainService = (
  axios: AxiosInstance
): CurrencyChainServiceModel => ({
  getAll: CurrencyChainService.getAll(axios),
  toggleStatus: CurrencyChainService.toggleStatus(axios),
});
