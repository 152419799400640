import { Components, Theme } from "@mui/material";

export const MuiDialogTitle: Components<
  Omit<Theme, "components">
>["MuiDialogTitle"] = {
  styleOverrides: {
    root: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      padding: "0 0 24px 0",
    },
  },
};
