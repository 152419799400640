import { AxiosInstance } from "axios";
import { RulesAndPromotionService } from "services";

export interface RulesAndPromotionServiceModel {
  getAll: ReturnType<typeof RulesAndPromotionService.getAll>;
  getById: ReturnType<typeof RulesAndPromotionService.getById>;
  create: ReturnType<typeof RulesAndPromotionService.create>;
  update: ReturnType<typeof RulesAndPromotionService.update>;
  remove: ReturnType<typeof RulesAndPromotionService.remove>;
}

export const initRulesAndPromotionService = (
  axios: AxiosInstance
): RulesAndPromotionServiceModel => ({
  getAll: RulesAndPromotionService.getAll(axios),
  getById: RulesAndPromotionService.getById(axios),
  create: RulesAndPromotionService.create(axios),
  update: RulesAndPromotionService.update(axios),
  remove: RulesAndPromotionService.remove(axios),
});
