import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { NosShowPenalityModel } from "models/Settings/RatesAndPromo/Rules/NosShowPenalityModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";

export const remove =
  (axios: AxiosInstance) =>
  async (id: number): Promise<NosShowPenalityModel> => {
    return await axios.delete(
      `${SettingsEndpoint.SETTINGS_RATES_RULE_NOS_SHOW_PENALITY}/${id}`
    );
  };

export const getAll =
  (axios: AxiosInstance) =>
  async (
    params?: FetchingRequestParamsModels | undefined
  ): Promise<NosShowPenalityModel[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_RATES_RULE_NOS_SHOW_PENALITY, { params })
      .then(({ data }) => data.items);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: NosShowPenalityModel): Promise<NosShowPenalityModel> => {
    return await axios
      .post(SettingsEndpoint.SETTINGS_RATES_RULE_NOS_SHOW_PENALITY, data)
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (data: NosShowPenalityModel): Promise<NosShowPenalityModel> => {
    return await axios
      .put(
        `/${SettingsEndpoint.SETTINGS_RATES_RULE_NOS_SHOW_PENALITY}/${data.id}`,
        data
      )
      .then(({ data }) => data);
  };

export const toggleStatus =
  (axios: AxiosInstance) =>
  async (id: number): Promise<NosShowPenalityModel> => {
    return await axios
      .patch(`/${SettingsEndpoint.SETTINGS_RATES_RULE_NOS_SHOW_PENALITY}/${id}`)
      .then(({ data }) => data);
  };
