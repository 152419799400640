import { AxiosInstance } from "axios";
import { VipCategoryService } from "services";

export interface VipCategoryServiceModel {
  getAll: ReturnType<typeof VipCategoryService.getAll>;
  create: ReturnType<typeof VipCategoryService.create>;
  update: ReturnType<typeof VipCategoryService.update>;
  remove: ReturnType<typeof VipCategoryService.remove>;
  toggleStatus: ReturnType<typeof VipCategoryService.toggleStatus>;
}

export const initVipCategoryService = (
  axios: AxiosInstance
): VipCategoryServiceModel => ({
  getAll: VipCategoryService.getAll(axios),
  create: VipCategoryService.create(axios),
  update: VipCategoryService.update(axios),
  remove: VipCategoryService.remove(axios),
  toggleStatus: VipCategoryService.toggleStatus(axios),
});
