import { ReservationsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import _ from "lodash";
import {
  GuestCreateModel,
  GuestGeneralModel,
  GuestModel,
} from "models/Reservations/Guest/GuestModel";

export const getAll =
  (axios: AxiosInstance) =>
  async (params?: any): Promise<GuestGeneralModel[]> => {
    return await axios
      .get(ReservationsEndpoint.RESERVATION_GUEST_CARD, {
        params,
      })
      .then(({ data }) => data.items);
  };

export const getAllPaginated =
  (axios: AxiosInstance) =>
  async (
    params?: any
  ): Promise<{ items: GuestGeneralModel[]; total: number }> => {
    return await axios
      .get(ReservationsEndpoint.RESERVATION_GUEST_CARD, {
        params,
      })
      .then(({ data }) => data);
  };

export const getOneById =
  (axios: AxiosInstance) =>
  async (id: number): Promise<GuestModel> => {
    return await axios
      .get(`${ReservationsEndpoint.RESERVATION_GUEST_CARD}/${id}`)
      .then(({ data }) => data);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: GuestCreateModel): Promise<GuestModel> => {
    return await axios
      .post(ReservationsEndpoint.RESERVATION_GUEST_CARD, data)
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (
    data: Pick<GuestModel, "general" | "preferences">
  ): Promise<GuestModel> => {
    const general = _.omit(data.general, "photo");
    return await axios
      .put(
        `${ReservationsEndpoint.RESERVATION_GUEST_CARD}/${data.general.id}`,
        {
          ...data,
          general: general,
        }
      )
      .then(({ data }) => data);
  };
