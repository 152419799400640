import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const ChevronRightIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.293 5.293a1 1 0 0 1 1.414 0l6 6a1 1 0 0 1 0 1.414l-6 6a1 1 0 1 1-1.414-1.414L13.585 12 8.293 6.707a1 1 0 0 1-.083-1.32l.083-.094z"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};
