import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { ChargeGroupModel } from "models/Settings/RevenuManagement/ChargeGroupModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";

export const remove =
  (axios: AxiosInstance) =>
  async (id: number): Promise<ChargeGroupModel> => {
    return await axios.delete(
      `${SettingsEndpoint.SETTINGS_REVENU_MANAGEMENT_CHARGE_GROUP}/${id}`
    );
  };

export const getAll =
  (axios: AxiosInstance) =>
  async (params?: FetchingRequestParamsModels): Promise<ChargeGroupModel[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_REVENU_MANAGEMENT_CHARGE_GROUP, {
        params,
      })
      .then(({ data }) => data.items);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: ChargeGroupModel): Promise<ChargeGroupModel> => {
    return await axios
      .post(SettingsEndpoint.SETTINGS_REVENU_MANAGEMENT_CHARGE_GROUP, data)
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (data: ChargeGroupModel): Promise<ChargeGroupModel> => {
    return await axios
      .put(
        `/${SettingsEndpoint.SETTINGS_REVENU_MANAGEMENT_CHARGE_GROUP}/${data.id}`,
        data
      )
      .then(({ data }) => data);
  };

export const toggleStatus =
  (axios: AxiosInstance) =>
  async (id: number): Promise<ChargeGroupModel> => {
    return await axios
      .patch(
        `/${SettingsEndpoint.SETTINGS_REVENU_MANAGEMENT_CHARGE_GROUP}/${id}`
      )
      .then(({ data }) => data);
  };
