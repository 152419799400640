import CancellationPenalityConfig from "./cancellation_penality";
import DepositRequestConfig from "./deposit_request";
import NosShowPenalityConfig from "./nos_show_penality";

const RulesConfig = {
    CancellationPenalityConfig,
    NosShowPenalityConfig,
    DepositRequestConfig,
};

export default RulesConfig;