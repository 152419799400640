import { FinancialAccountNumberListModel } from "models/Settings/Financial/FinancialAccountNumberModel";

const AccountNumberFakeData: FinancialAccountNumberListModel[] = [
  {
    id: 1,
    account_number: "9001",
    description: "PM",
    account_group_id: null,
    account_group_name: "PM",
    is_active: true,
  },
  {
    id: 2,
    account_number: "9002",
    description: "City ledger",
    account_group_id: null,
    account_group_name: "STF",
    is_active: false,
  },
  {
    id: 3,
    account_number: "9003",
    description: "POS",
    account_group_id: null,
    account_group_name: "POS",
    is_active: true,
  },
];

export default AccountNumberFakeData;
