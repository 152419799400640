import { ReservationsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import _ from "lodash";
import { StayCardGeneralInfosModel } from "models/Reservations/StayCard/ReservationStayCardModel";
import { StayCalenderModeEnum } from "pages/ReservationModule/pages/StayCardPage/components/PageContent/components/StayPlanForm/components/StayCalendar/models";
import { BoardModel } from "pages/ReservationModule/pages/StayCardPage/components/PageContent/components/StayPlanForm/components/StayCalendar/models/BoardModel";
import { DiscountModel } from "pages/ReservationModule/pages/StayCardPage/components/PageContent/components/StayPlanForm/components/StayCalendar/models/DiscountModel";
import { OccupancyModel } from "pages/ReservationModule/pages/StayCardPage/components/PageContent/components/StayPlanForm/components/StayCalendar/models/OccupancyModel";
import { PerPaxStayCalenderModel } from "pages/ReservationModule/pages/StayCardPage/components/PageContent/components/StayPlanForm/components/StayCalendar/models/PerPaxStayCalenderModel";
import { RateModel } from "pages/ReservationModule/pages/StayCardPage/components/PageContent/components/StayPlanForm/components/StayCalendar/models/RateModel";
import { SearchAvailabilitiesModel } from "pages/ReservationModule/pages/StayCardPage/components/PageContent/components/StayPlanForm/components/StayCalendar/models/SearchAvailabiltyModel";
import { sprintf } from "sprintf-js";

type GetParamsType = {
  details: SearchAvailabilitiesModel;
  general: {
    arrival: string;
    departure: string;
  };
  mode: StayCalenderModeEnum;
};

export const getRoomRateRoom =
  (axios: AxiosInstance) =>
  async ({
    ...params
  }: GetParamsType): Promise<{
    occupancy: Array<OccupancyModel>;
    board: Array<BoardModel>;
    rate: Array<RateModel>;
    discount: Array<DiscountModel>;
  }> => {
    return await axios
      .post(
        sprintf(ReservationsEndpoint.RESERVATION_STAY_CARD_GET_ROOMRATES_ROOM),
        {
          ..._.omit(params, "index"),
        }
      )
      .then(({ data }) => data);
  };

export const getRoomRateGuests =
  (axios: AxiosInstance) =>
  async ({ ...params }: GetParamsType): Promise<PerPaxStayCalenderModel> => {
    return await axios
      .post(
        sprintf(
          ReservationsEndpoint.RESERVATION_STAY_CARD_GET_ROOMRATES_GUESTS
        ),
        {
          ..._.omit(params, "index"),
        }
      )
      .then(({ data }) => data);
  };

export const saveStayCardRoomRate =
  (axios: AxiosInstance) =>
  async ({
    guest,
    room_reservation,
    data,
    ...params
  }: any): Promise<StayCardGeneralInfosModel> => {
    return await axios
      .post(
        sprintf(
          ReservationsEndpoint.RESERVATION_STAY_CARD_GET_ROOMRATES_ROOM,
          guest,
          String(room_reservation)
        ),
        { ...data }
      )
      .then(({ data }) => data);
  };
