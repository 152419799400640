export interface RoomRateCustomModel<T extends CustomReservationModel> {
  room_rate_custom_id: number;
  name: string;
  type: T;
  price: number;
  availability: number;
  note: string;
  adult: number;
  child: number;
  inf: number;
  data: CustomReservationGlobalModel<T>;
}

export interface RoomRateCustomPerPaxDataModel {
  total: number;
  occupancy: {
    adult: number;
    child: number;
    inf: number;
  };
  guests: RoomRatePax[];
}

export enum CustomReservationModel {
  ROOM = "room",
  PAX = "pax",
}

export type CustomReservationGlobalModel<T extends CustomReservationModel> =
  T extends CustomReservationModel.ROOM
    ? RoomRateCustomPerRoomDataModel
    : T extends CustomReservationModel.PAX
    ? RoomRateCustomPerPaxDataModel
    : undefined;

export interface RoomRateCustomPerRoomDataModel {
  total: number;
  arrival: string;
  departure: string;
  stay: number | string;
  details: ColModel;
}

export interface RoomRateCustomNewPerPaxDataModel {
  total: number;
  occupancy: OccupancyDetails;
  guests: {
    guest_id: number;
    first_name: string;
    last_name: string;
    birthday: string | null;
    guest_type: "adult" | "child" | "inf";
    nationality: string | null;
    total: number;
    details: ColModel;
  }[];
}

export interface BoardDetails {
  id: number;
  name: string;
  date: string;
}
export interface DiscountDetails {
  type: string;
  date: string;
  value: string;
}
export interface OccupancyDetails {
  adult: number;
  child: number;
  inf: number;
  date: string;
  ages: Array<number> | null;
}

export interface ColModel {
  rate: RateModel[];
  isForced?: boolean;
  board: BoardDetails[];
  discount: DiscountDetails[];
  occupancy: OccupancyDetails[];
}

export interface RateModel {
  date: string;
  id: number;
  name: string;
  amount: number | null;
}

export interface RoomRatePax {
  guest_id: number | null;
  first_name: string;
  last_name: string;
  arrival?: string;
  derparture?: string;
  birthday: string | null;
  guest_type: "adult" | "child" | "inf";
  nationality: string;
  total: number;
  details: ColModel;
}

export enum FormTypes {
  LIGNE_BOARD = "ligneBoard",
  LIGNE_RATE = "ligneRate",
  LIGNE_VALUE = "ligneValue",
  LIGNE_DISCOUNT = "ligneDiscount",
  LIGNE_OCCUPANCY = "ligneOccupancy",
  BOARD = "board",
  VALUE = "value",
  DISCOUNT = "discount",
  RATE = "rate",
  OCCUPANCY = "occupancy",
}
