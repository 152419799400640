import { Components, Theme } from "@mui/material";
export const MuiFormControl: Components<
  Omit<Theme, "components">
>["MuiFormControl"] = {
  styleOverrides: {
    root: {
      marginTop: "0px",
      margin: "0px",

      // "&.MuiTextField-root": {
      //   border: "solid 1px red",
      //   marginTop: 12,
      // },
    },
  },
};
