import { AxiosInstance } from "axios";
import { HouseKeepingRootService } from "services";

export interface HouseKeepingRootServiceModel {
  getAll: ReturnType<typeof HouseKeepingRootService.getAll>;
  update: ReturnType<typeof HouseKeepingRootService.update>;
}

export const initHouseKeepingRootService = (
  axios: AxiosInstance
): HouseKeepingRootServiceModel => ({
  getAll: HouseKeepingRootService.getAll(axios),
  update: HouseKeepingRootService.update(axios),
});
