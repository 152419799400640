import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { RateFrequencyModel } from "models/Settings/RatesAndPromo/FrequencyModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";

export const remove =
  (axios: AxiosInstance) =>
  async (id: number): Promise<RateFrequencyModel> => {
    return await axios.delete(
      `${SettingsEndpoint.SETTINGS_RATES_FREQUENCY}/${id}`
    );
  };

export const getAll =
  (axios: AxiosInstance) =>
  async (
    params?: FetchingRequestParamsModels
  ): Promise<RateFrequencyModel[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_RATES_FREQUENCY, {
        params,
      })
      .then(({ data }) => data.items);
  };

export const getById =
  (axios: AxiosInstance) =>
  async (id?: number): Promise<RateFrequencyModel> => {
    return await axios
      .get(`/${SettingsEndpoint.SETTINGS_RATES_FREQUENCY}/${id}`)
      .then(({ data }) => data);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: any): Promise<RateFrequencyModel> => {
    return await axios
      .post(SettingsEndpoint.SETTINGS_RATES_FREQUENCY, data)
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (data: RateFrequencyModel): Promise<RateFrequencyModel> => {
    return await axios
      .put(`/${SettingsEndpoint.SETTINGS_RATES_FREQUENCY}/${data.id}`, data)
      .then(({ data }) => data);
  };

export const toggleStatus =
  (axios: AxiosInstance) =>
  async (id: number): Promise<RateFrequencyModel> => {
    return await axios
      .patch(`/${SettingsEndpoint.SETTINGS_RATES_FREQUENCY}/${id}`)
      .then(({ data }) => data);
  };
