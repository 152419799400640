import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Select,
  Stack,
  TextField,
  TextFieldProps,
} from "@mui/material";
import DateRangePicker from "components/DateRangePicker";
import { MenuItem } from "components/styled/SelectMenuItem";
import { CalendarIcon } from "components/SvgIcons/CalendarIcon";
import { DeleteFilledIcon } from "components/SvgIcons/DeleteFilledIcon";
import { PlusIcon } from "components/SvgIcons/PlusIcon";
import { SearchIcon } from "components/SvgIcons/SearchIcon";
import { FC, useId } from "react";
import { HeaderContainer } from "./Container";

const DateTextField: FC<TextFieldProps> = ({ ...props }) => (
  <TextField
    size="small"
    variant="filled"
    sx={{ width: "10rem" }}
    InputProps={{
      endAdornment: <CalendarIcon sx={{ mr: "0.5rem" }} />,
    }}
    {...props}
  />
);

type SearchBarProps = {
  search?: string;
  onChangeSearch?: (value: string) => void;
  onClickClearSearch?: () => void;
  onClickAddNew?: () => void;
};

const SearchBar: FC<SearchBarProps> = ({
  search,
  onChangeSearch,
  onClickClearSearch,
  onClickAddNew,
}) => {
  const typeLabelId = useId();

  return (
    <HeaderContainer>
      <Grid container columnSpacing="1rem">
        <Grid item xs>
          <TextField
            fullWidth
            variant="standard"
            placeholder="Search by name..."
            value={search ?? ""}
            onChange={(e) => onChangeSearch?.(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={onClickClearSearch}>
                    <DeleteFilledIcon
                      fontSize="small"
                      sx={{ color: "colors.gray.o50" }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs="auto">
          <DateRangePicker
            value={[new Date(), new Date()]}
            onChange={() => {}}
            renderInput={({ startProps, endProps }) => (
              <Stack direction="row" spacing="1rem">
                <DateTextField label="FROM" {...startProps} />
                <DateTextField label="TO" {...endProps} />
              </Stack>
            )}
          />
        </Grid>
        <Grid item xs="auto">
          <FormControl size="small" variant="filled" sx={{ width: "10rem" }}>
            <InputLabel id={typeLabelId}>Type</InputLabel>
            <Select labelId={typeLabelId} sx={{ width: "10rem" }}>
              <MenuItem firstItem value="">
                <em>Select one</em>
              </MenuItem>
              <MenuItem value="current">Current</MenuItem>
              <MenuItem value="today">Today</MenuItem>
              <MenuItem value="future">Future</MenuItem>
              <MenuItem value="hisotry">Hisotry</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs="auto">
          <Button
            size="large"
            variant="contained"
            sx={{ minWidth: "8rem" }}
            onClick={onClickAddNew}
            startIcon={<PlusIcon />}
          >
            ADD NEW
          </Button>
        </Grid>
      </Grid>
    </HeaderContainer>
  );
};

export default SearchBar;
