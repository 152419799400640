import { RatesEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance, AxiosResponse } from "axios";
import {
  RateRangeRoomFormModel,
  RateRangeRoomModel,
} from "models/RevenueManagement/Rate/RateRangeRoomModel";
import { sprintf } from "sprintf-js";

export const getAll =
  (axios: AxiosInstance) =>
  async (
    rateId: number,
    rangeId: number,
    levelId: number
  ): Promise<Array<RateRangeRoomModel>> => {
    return await axios
      .get(
        sprintf(
          RatesEndpoint.RATE_RANGE_LEVEL_ROOM_GET_ALL,
          rateId,
          rangeId,
          levelId
        )
      )
      .then(({ data }) => data);
  };

export const getOneById =
  (axios: AxiosInstance) =>
  async (
    rateId: number,
    rangeId: number,
    levelId: number,
    id: number
  ): Promise<RateRangeRoomModel> => {
    return await axios
      .get(
        sprintf(
          RatesEndpoint.RATE_RANGE_LEVEL_ROOM_GET_ONE,
          rateId,
          rangeId,
          levelId,
          id
        )
      )
      .then(({ data }) => data);
  };

export const create =
  (axios: AxiosInstance) =>
  async (
    rateId: number,
    rangeId: number,
    // levelId: number,
    payload: RateRangeRoomFormModel
  ): Promise<RateRangeRoomModel> => {
    return await axios
      .post(
        sprintf(RatesEndpoint.RATE_RANGE_LEVEL_ROOM_CREATE, rateId, rangeId),
        {
          ...payload,
          board_services: payload.board_services.map((item) => item.id),
          room_types: payload.room_types.map((item) => ({
            id: item.room_type.id,
            allocation: item.allocation,
            guarantee: item.guarantee,
          })),
        }
      )
      .then((response) => response.data);
  };

export const remove =
  (axios: AxiosInstance) =>
  async (
    rateId: number,
    rangeId: number,

    id: number
  ): Promise<AxiosResponse<RateRangeRoomModel>> => {
    return await axios.delete(
      sprintf(RatesEndpoint.RATE_RANGE_LEVEL_ROOM_DELETE, rateId, rangeId, id)
    );
  };
