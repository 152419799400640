import { AxiosInstance } from "axios";
import { GroupServiceRevenuManagementModel, initGroupRevenuManagementService } from "./GroupRevenuManagmentService";
import { GroupServiceModel, initGroupService } from "./GroupService";
import { GuestCardFieldsServiceModel, initGuestCardFieldsService } from "./GuestCardFieldsService";

export interface SettingCardServiceModel {
  group: GroupServiceModel;
  guestCardFields: GuestCardFieldsServiceModel;
  groupRevenuManagement: GroupServiceRevenuManagementModel;
}

export const initSettingCardService = (
  axios: AxiosInstance
): SettingCardServiceModel => ({
  group: initGroupService(axios),
  groupRevenuManagement: initGroupRevenuManagementService(axios),
  guestCardFields: initGuestCardFieldsService(axios),
});
