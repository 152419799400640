import { CardsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance, AxiosResponse } from "axios";
import { BillingInfoModel } from "models/RevenueManagement/Card/BillingInfoModel";
import { sprintf } from "sprintf-js";

export const getByCard =
  (axios: AxiosInstance) =>
  async (cardId: number | string): Promise<Array<BillingInfoModel>> => {
    return await axios
      .get(sprintf(CardsEndpoint.CARD_BILLING_INFO_CREATE, cardId))
      .then(({ data }) => data.items);
  };

export const getRouteById =
  (axios: AxiosInstance) =>
  async (data: any): Promise<BillingInfoModel> => {
    return await axios
      .get(
        sprintf(CardsEndpoint.CARD_BILLING_INFO_GET_ONE, data.cardId, data.id)
      )
      .then(({ data }) => data);
  };

export const getOne =
  (axios: AxiosInstance) =>
  async (
    cardId: number | string,
    billingId: number
  ): Promise<Array<BillingInfoModel>> => {
    return await axios
      .get(
        sprintf(CardsEndpoint.CARD_BILLING_INFO_BY_CARDID, cardId, billingId)
      )
      .then(({ data }) => data.items);
  };

export const create =
  (axios: AxiosInstance) =>
  async (
    data: BillingInfoModel & { card_id: number }
  ): Promise<AxiosResponse<BillingInfoModel>> => {
    return await axios.post(
      sprintf(CardsEndpoint.CARD_BILLING_INFO_CREATE, data.card_id),
      data
    );
  };

export const update =
  (axios: AxiosInstance) =>
  async (
    data: BillingInfoModel & { card_id: number }
  ): Promise<AxiosResponse<BillingInfoModel>> => {
    return await axios.put(
      sprintf(CardsEndpoint.CARD_BILLING_INFO_BY_CARDID, data.card_id, data.id),
      data
    );
  };

export const remove =
  (axios: AxiosInstance) =>
  async (data: any): Promise<AxiosResponse<BillingInfoModel>> => {
    return await axios.delete(
      sprintf(CardsEndpoint.CARD_BILLING_INFO_BY_CARDID, data.cardId, data.id)
    );
  };
