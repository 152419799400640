import { AxiosInstance } from "axios";
import { DashboardService } from "services";

export interface DashboardServiceModel {
  getAll: ReturnType<typeof DashboardService.getAll>;
}

export const initDashboardService = (
  axios: AxiosInstance
): DashboardServiceModel => ({
  getAll: DashboardService.getAll(axios),
});
