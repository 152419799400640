import { ReservationsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import ReservationRoomRatePreference from "models/Reservations/ReservationRoomRatePreference";
import { ReservationRoomRateModel } from "pages/ReservationModule/pages/CreateReservationPageNew/models";
import { sprintf } from "sprintf-js";

export const getByRoomRate =
  (axios: AxiosInstance) =>
  async (
    reservationId: number,
    roomRateId: number
  ): Promise<ReservationRoomRatePreference> => {
    const path = sprintf(
      ReservationsEndpoint.ROOM_RATE_PREFERENCE_GET,
      reservationId,
      roomRateId
    );
    return await axios.get(path).then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (item: {
    reservationId: number;
    roomRateId: number;
    data: ReservationRoomRatePreference;
  }): Promise<ReservationRoomRateModel[]> => {
    const path = sprintf(
      ReservationsEndpoint.ROOM_RATE_PREFERENCE_UPDATE,
      item.reservationId,
      item.roomRateId
    );
    return await axios.put(path, item.data).then(({ data }) => data);
  };
