import { AxiosInstance } from "axios";
import { CashierRootServices } from "services";

export interface CashierExchangeServiceModel {
  createExchange: ReturnType<typeof CashierRootServices.createExchange>;
}

export const initCashierExchangeService = (
  axios: AxiosInstance
): CashierExchangeServiceModel => ({
  createExchange: CashierRootServices.createExchange(axios),
});
