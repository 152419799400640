import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import EnConfig from "./en";
import DeConfig from "./de";
import EsConfig from "./es";
import FrConfig from "./fr";

export const defaultNS = "common";

export const resources = {
  en: EnConfig,
  fr: FrConfig,
  de: DeConfig,
  es: EsConfig,
};

i18next
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "en",
    fallbackLng: "en",
    debug: true,
    resources,
    defaultNS,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
      bindI18n: "languageChanged loaded",
    },
  });

export default i18next;
