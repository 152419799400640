import {
  CardModel,
  CardListModel,
  CardCreateModel,
} from "models/RevenueManagement/Card/CardModel";
import { AxiosInstance } from "axios";
import { sprintf } from "sprintf-js";
import { CardsEndpoint } from "@constants/ApiEndpoints";

export type Params = {
  search?: string;
  limit?: number;
  offset?: number;
};

export const create =
  (axios: AxiosInstance) =>
  async (data: CardCreateModel): Promise<CardModel> => {
    return await axios
      .post(CardsEndpoint.CARD_CREATE, data)
      .then(({ data }) => data);
  };

export const getAll =
  (axios: AxiosInstance) =>
  async (params?: any): Promise<{ items: CardListModel[]; total: number }> =>
    await axios
      .get(CardsEndpoint.CARD_GET_ALL, { params })
      .then(({ data }) => data);

export const getOneById =
  (axios: AxiosInstance) =>
  async (id: number | string): Promise<CardModel> => {
    return await axios
      .get(sprintf(CardsEndpoint.CARD_GET_ONE, id))
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (
    data: Pick<CardModel, "general" | "financial">
  ): Promise<CardModel> => {
    return await axios
      .put(sprintf(CardsEndpoint.CARD_UPDATE, data.general.id), data)
      .then(({ data }) => data);
  };

export const updateAsDraft =
  (axios: AxiosInstance) =>
  async (data: CardModel): Promise<CardModel> => {
    return await axios
      .put(sprintf(CardsEndpoint.CARD_UPDATE_DRAFT, data.general.id), data)
      .then(({ data }) => data);
  };

export const discard =
  (axios: AxiosInstance) =>
  async (id: number): Promise<any> => {
    return await axios.delete(sprintf(CardsEndpoint.CARD_DISCARD, id));
  };
