import { AxiosInstance } from "axios";
import { AclService } from "services";

export interface PermissionServiceModel {
  getAll: ReturnType<typeof AclService.PermissionService.getAll>;
  getAllUserPermissions: ReturnType<typeof AclService.PermissionService.getAllUserPermissions>;
  getAllRolePermissions: ReturnType<typeof AclService.PermissionService.getAllRolePermissions>;
}

export const initPermissionService = (
  axios: AxiosInstance
): PermissionServiceModel => ({
  getAll: AclService.PermissionService.getAll(axios),
  getAllUserPermissions: AclService.PermissionService.getAllUserPermissions(axios),
  getAllRolePermissions: AclService.PermissionService.getAllRolePermissions(axios),
});
