import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import {
  PaymentMethodItemReservation,
  PaymentMethodModel,
} from "models/Settings/Financial/PaymentMethodModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";

export interface Options {
  id: number;
  label: string;
}

export interface TypeModel {
  id: string | undefined;
  value: string | number;
  label: string;
}

export const remove =
  (axios: AxiosInstance) =>
  async (id: number): Promise<PaymentMethodModel> => {
    return await axios.delete(
      `${SettingsEndpoint.SETTINGS_FINANCIAL_PAYMENT_METHOD}/${id}`
    );
  };

export const getAll =
  (axios: AxiosInstance) =>
  async (
    params?: FetchingRequestParamsModels
  ): Promise<PaymentMethodModel[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_FINANCIAL_PAYMENT_METHOD, {
        params,
      })
      .then(({ data }) => data?.items);
  };

export const getAllPaymentMethodForReservation =
  (axios: AxiosInstance) =>
  async (
    params?: FetchingRequestParamsModels
  ): Promise<PaymentMethodItemReservation[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_FINANCIAL_PAYMENT_METHOD, {
        params,
      })
      .then(({ data }) => data);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: PaymentMethodModel): Promise<PaymentMethodModel> => {
    return await axios
      .post(SettingsEndpoint.SETTINGS_FINANCIAL_PAYMENT_METHOD, data)
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (data: PaymentMethodModel): Promise<PaymentMethodModel> => {
    return await axios
      .put(
        `/${SettingsEndpoint.SETTINGS_FINANCIAL_PAYMENT_METHOD}/${data.id}`,
        data
      )
      .then(({ data }) => data);
  };

export const toggleStatus =
  (axios: AxiosInstance) =>
  async (id: number): Promise<PaymentMethodModel> => {
    return await axios
      .patch(`/${SettingsEndpoint.SETTINGS_FINANCIAL_PAYMENT_METHOD}/${id}`)
      .then(({ data }) => data);
  };
