import { Alert, AlertTitle, Box, Link, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useRouteError } from "react-router-dom";

export const RootErrorBoundary: FC = (): JSX.Element => {
  const { t } = useTranslation("common");
  let error = useRouteError() as Error;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100%",
      }}
    >
      <Alert severity="error">
        <AlertTitle>{t("errors.router.notFound.title")}</AlertTitle>

        <Typography color="textSecondary" fontWeight={"fontWeightBold"}>
          {t("errors.router.notFound.description")}
        </Typography>
        <Typography
          color="textSecondary"
          sx={{ lineHeight: 1.5, fontWeight: "normal", fontSize: 14 }}
        >
          <pre>{error.message || JSON.stringify(error)}</pre>
        </Typography>
        <Link
          href="/"
          sx={{
            textDecoration: "none",
            color: (theme) => theme.palette.info.main,
          }}
        >
          {t("errors.router.notFound.link")}
        </Link>
      </Alert>
    </Box>
  );
};
