import { ReservationsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import {
  StayCardModel,
  StayFormModel,
  StayModel,
} from "models/Reservations/StayCard/ReservationStayCardModel";
import { sprintf } from "sprintf-js";

type GetParamsType = {
  voucher: string | number;
  confirmation_number: string | number;
  guest: string | number;
  night_number: number;
};

type UpdateParamsType = {
  guest: string | number;
  room_reservation: string | number;
  data: StayFormModel;
};

export const getStayData =
  (axios: AxiosInstance) =>
  async (params: GetParamsType): Promise<StayModel> => {
    return await axios
      .get(
        sprintf(ReservationsEndpoint.RESERVATION_STAY_CARD_GET, params.guest),
        { params }
      )
      .then(({ data }) => data);
  };

export const updateStay =
  (axios: AxiosInstance) =>
  async (params: UpdateParamsType): Promise<StayCardModel> => {
    return await axios
      .put(
        sprintf(
          ReservationsEndpoint.RESERVATION_STAY_CARD_UPDATE,
          params.guest,
          params.room_reservation
        ),
        params.data
      )
      .then(({ data }) => data);
  };
