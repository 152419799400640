import { AxiosInstance } from "axios";
import { BlocServices } from "services/Settings";

export interface BlocServiceModel {
  getAll: ReturnType<typeof BlocServices.getAll>;
  getOne: ReturnType<typeof BlocServices.getOne>;
  create: ReturnType<typeof BlocServices.create>;
  update: ReturnType<typeof BlocServices.update>;
  patch: ReturnType<typeof BlocServices.patch>;
  remove: ReturnType<typeof BlocServices.remove>;
}

export const initBlocServiceModel = (
  axios: AxiosInstance
): BlocServiceModel => ({
  getAll: BlocServices.getAll(axios),
  getOne: BlocServices.getOne(axios),
  create: BlocServices.create(axios),
  update: BlocServices.update(axios),
  patch: BlocServices.patch(axios),
  remove: BlocServices.remove(axios),
});
