import { AxiosInstance } from "axios";
import { PaymentTypeService } from "services";

export interface PaymentTypeServiceModel {
  create: ReturnType<typeof PaymentTypeService.create>;
  update: ReturnType<typeof PaymentTypeService.update>;
  remove: ReturnType<typeof PaymentTypeService.remove>;
  toggleStatus: ReturnType<typeof PaymentTypeService.toggleStatus>;
}

export const initPaymentTypeService = (
  axios: AxiosInstance
): PaymentTypeServiceModel => ({
  create: PaymentTypeService.create(axios),
  update: PaymentTypeService.update(axios),
  remove: PaymentTypeService.remove(axios),
  toggleStatus: PaymentTypeService.toggleStatus(axios),
});
