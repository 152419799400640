import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { CashCategoryModel } from "models/Settings/Financial/CashCategoryModel";

import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";

export interface Options {
  id: number;
  label: string;
}

export interface TypeModel {
  id: string | undefined;
  value: string | number;
  label: string;
}

export const remove =
  (axios: AxiosInstance) =>
  async (id: number): Promise<CashCategoryModel> => {
    return await axios.delete(
      `${SettingsEndpoint.SETTINGS_FINANCIAL_CASH_CATGEORY_PATH}/${id}`
    );
  };

export const getAll =
  (axios: AxiosInstance) =>
  async (
    params?: FetchingRequestParamsModels
  ): Promise<{ items: CashCategoryModel[]; total: number }> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_FINANCIAL_CASH_CATGEORY_PATH, {
        params,
      })
      .then(({ data }) => {
        return data;
      });
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: CashCategoryModel): Promise<CashCategoryModel> => {
    return await axios
      .post(SettingsEndpoint.SETTINGS_FINANCIAL_CASH_CATGEORY_PATH, data)
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (data: CashCategoryModel): Promise<CashCategoryModel> => {
    return await axios
      .put(
        `/${SettingsEndpoint.SETTINGS_FINANCIAL_CASH_CATGEORY_PATH}/${data.id}`,
        data
      )
      .then(({ data }) => data);
  };

export const toggleStatus =
  (axios: AxiosInstance) =>
  async (id: number): Promise<CashCategoryModel> => {
    return await axios
      .patch(`/${SettingsEndpoint.SETTINGS_FINANCIAL_CASH_CATGEORY_PATH}/${id}`)
      .then(({ data }) => data);
  };
