import { RoutePaths } from "@constants";
import { Paths } from "@constants/Navigation/AppPaths";
import { RootContextProps } from "components/RootContainer";
import { GuestModel } from "models/Reservations/Guest/GuestModel";
import React from "react";
import { Breadcrumb } from "routing/components/Breadcrumb";
import { RoutePathDefinition } from "routing/routes";

const CardsListPage = React.lazy(
  () => import("pages/ReservationModule/pages/GuestPage/pages/List")
);

const CardsEditPage = React.lazy(
  () => import("pages/ReservationModule/pages/GuestPage/pages/Edit")
);

const GuestRoutes = (Api: RootContextProps): Array<RoutePathDefinition> => [
  {
    path: Paths.GUEST_PATH,
    handle: {
      crumb: () => <Breadcrumb label="Guest" path={RoutePaths.GUEST_LIST} />,
    },
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<>...</>}>
            <CardsListPage />
          </React.Suspense>
        ),
        handle: {
          crumb: () => <Breadcrumb label="Find Guest" path={null} />,
        },
      },
      {
        path: ":id/edit",
        element: (
          <React.Suspense fallback={<>...</>}>
            <CardsEditPage />
          </React.Suspense>
        ),
        loader: async ({ params }): Promise<GuestModel | null> => {
          const {
            ChainApi: {
              reservations: {
                guest: {
                  guest: { getOneById },
                },
              },
            },
          } = Api;
          if (params?.id) {
            return await getOneById(Number(params.id));
          }
          throw new Response("Not Found", { status: 404 });
        },
        handle: {
          crumb: (data: GuestModel) => (
            <Breadcrumb label={data?.general.first_name} path={null} />
          ),
        },
      },
    ],
  },
];

export default GuestRoutes;
