import { AxiosInstance } from "axios";
import { RateService } from "services/RevenueManagement/Rate";

export interface RateRateServiceModel {
  create: ReturnType<typeof RateService.create>;
  getAll: ReturnType<typeof RateService.getAll>;
  getByRoomType: ReturnType<typeof RateService.getByRoomType>;
  getByMarketAndCard: ReturnType<typeof RateService.getByMarketAndCard>;
  getOneById: ReturnType<typeof RateService.getOneById>;
  update: ReturnType<typeof RateService.update>;
  updateAsDraft: ReturnType<typeof RateService.updateAsDraft>;
  remove: ReturnType<typeof RateService.remove>;
  discard: ReturnType<typeof RateService.discard>;
  toggleStatus: ReturnType<typeof RateService.toggleStatus>;
  searchForAssignment: ReturnType<typeof RateService.searchForAssignment>;
}

export const initRateRateService = (
  axios: AxiosInstance
): RateRateServiceModel => ({
  create: RateService.create(axios),
  getAll: RateService.getAll(axios),
  getByRoomType: RateService.getByRoomType(axios),
  getByMarketAndCard: RateService.getByMarketAndCard(axios),
  getOneById: RateService.getOneById(axios),
  update: RateService.update(axios),
  updateAsDraft: RateService.updateAsDraft(axios),
  remove: RateService.remove(axios),
  discard: RateService.discard(axios),
  toggleStatus: RateService.toggleStatus(axios),
  searchForAssignment: RateService.searchForAssignment(axios),
});
