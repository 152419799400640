import { ReservationsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance, AxiosResponse } from "axios";
import { CreateNoteModel, NoteModel } from "models/Reservations/Guest/NoteModel";
import { sprintf } from "sprintf-js";

export const getByGuest =
  (axios: AxiosInstance) =>
    async (cardId: number | string): Promise<Array<NoteModel>> => {
      return await axios
        .get(sprintf(ReservationsEndpoint.GUEST_NOTE_GET_ALL, cardId))
        .then(({ data }) => data.items);
    };

export const create =
  (axios: AxiosInstance) =>
    async (data: CreateNoteModel): Promise<AxiosResponse<NoteModel>> => {
      return await axios.post(
        sprintf(ReservationsEndpoint.GUEST_NOTE_CREATE, data.card_id),
        data
      );
    };
    

export const remove =
  (axios: AxiosInstance) =>
    async (cardId: number, noteId: number): Promise<AxiosResponse<NoteModel>> => {
      return await axios.delete(
        sprintf(ReservationsEndpoint.GUEST_NOTE_DELETE, cardId, noteId)
      );
    };
