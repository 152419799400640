import { AxiosInstance } from "axios";
import { RakManagerService } from "services/RevenueManagement/RakManager";

export interface RakManagerServiceModel {
  getAll: ReturnType<typeof RakManagerService.getAll>;
  getOne: ReturnType<typeof RakManagerService.getOne>;
  updateGlobalRakRate: ReturnType<typeof RakManagerService.updateGlobalRakRate>;
  updateRakRate: ReturnType<typeof RakManagerService.updateRakRate>;
  rollbackChanges: ReturnType<typeof RakManagerService.rollbackChanges>;
}

export const initRakManagerService = (
  axios: AxiosInstance
): RakManagerServiceModel => ({
  getAll: RakManagerService.getAll(axios),
  getOne: RakManagerService.getOne(axios),
  updateGlobalRakRate: RakManagerService.updateGlobalRakRate(axios),
  updateRakRate: RakManagerService.updateRakRate(axios),
  rollbackChanges: RakManagerService.rollbackChanges(axios),
});
