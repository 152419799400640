import { CardMedia } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { SyntheticEvent } from "react";
import { logout } from "utils/logout";

export const PageHeader = () => {
  return (
    <AppBar position="static" elevation={0}>
      <Box
        sx={{
          pl: "2.5rem",
          pr: "1rem",
          backgroundColor: "common.white",
          boxShadow: "0 1px 0 0 #e3e7eb",
        }}
      >
        <Toolbar disableGutters>
          {/* <LightrsaLogo
            viewBox="0 0 144 32"
            sx={{ width: "9rem", height: "2rem", mr: 1 }}
          /> */}
          <CardMedia
            onClick={(e) => e.stopPropagation()}
            component="img"
            sx={{ width: "4.188rem", mr: 1 }}
            image="/assets/hotix.png"
            onError={(e: SyntheticEvent<HTMLImageElement, Event>) => {
              e.currentTarget.src = "/assets/hotix.png";
            }}
          />
          <Typography
            variant="inter18"
            fontWeight={"fontWeightRegular"}
            sx={{ color: "common.black" }}
          >
            Hospitality
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}></Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} />

          <Box sx={{ flexGrow: 0 }}>
            <Button
              onClick={logout}
              sx={{
                fontSize: (theme) => theme.typography.inter16.fontSize,
                fontWeight: (theme) => theme.typography.fontWeightSemibold,
                ":hover": {
                  backgroundColor: "transparent",
                },
                ":focus": {
                  backgroundColor: "transparent",
                },
              }}
            >
              Log out
            </Button>
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
};
