import { styled } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
export const SnackbarProviderStyled = styled(SnackbarProvider)({
  marginRight: "5rem",
  "&.SnackbarItem-contentRoot": {
    padding: 0,
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  "&.SnackbarItem-message": {
    padding: 0,
  },
});
