import { Components, Theme } from "@mui/material";
// import InterRegular from "../../assets/fonts/Inter-Regular.ttf";
// import InterBold from "../../assets/fonts/Inter-Bold.ttf";

export const MuiCssBaseline: Components<
  Omit<Theme, "components">
>["MuiCssBaseline"] = {
  styleOverrides: {
    // "@font-face": [
    //   {
    //     fontFamily: "Inter",
    //     fontStyle: "normal",
    //     fontDisplay: "swap",
    //     fontWeight: 400,
    //     src: `local('Inter'), local('Inter-Regular'), url(${InterRegular}) format('ttf')`,
    //   },
    //   {
    //     fontFamily: "Inter",
    //     fontStyle: "blod",
    //     fontDisplay: "swap",
    //     fontWeight: 700,
    //     src: `local('Inter'), local('Inter-Bold.ttf'), url(${InterBold}) format('ttf')`,
    //   },
    //   {
    //     fontFamily: "Inter",
    //     fontStyle: "blod",
    //     fontDisplay: "swap",
    //     fontWeight: 700,
    //     src: `local('Inter'), local('Inter-Bold.ttf'), url(${InterBold}) format('ttf')`,
    //   },
    // ],

    "input:-webkit-autofill": {
      transition: "background-color 9999s ease-in-out 0s",
    },
    "input:-webkit-autofill:hover": {
      transition: "background-color 9999s ease-in-out 0s",
    },
    "input:-webkit-autofill:focus": {
      transition: "background-color 9999s ease-in-out 0s",
      // "-webkit-text-fill-color": "#6a798f",
    },
    "input:-webkit-autofill:active": {
      transition: "background-color 9999s ease-in-out 0s",
    },

    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "0px solid slategrey",
    },
    body: {
      overflowX: "hidden",
      overflowY: "hidden!important",
    },
  },
};
