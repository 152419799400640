import { AxiosInstance } from "axios";
import {
  StayCardBillService,
  initStayCardBillService,
} from "./StayCardBillService";
import {
  StayCardGeneralInfosService,
  initStayCardGeneralInfosService,
} from "./StayCardGeneralInfosService";
import {
  StayCardHeaderService,
  initStayCardHeaderService,
} from "./StayCardHeaderService";
import {
  StayCardRoomRatesService,
  initStayCardRoomRatesService,
} from "./StayCardRoomRate";
import { StayCardService, initStayCardService } from "./StayCardService";
import {
  StayCardStayService,
  initStayCardStayService,
} from "./StayCardStayService";

export interface ReservationStayCardServiceModel {
  stayCard: StayCardService;
  stayCardBill: StayCardBillService;
  stayCardHeader: StayCardHeaderService;
  stayCardStay: StayCardStayService;
  stayCardGeneralInfos: StayCardGeneralInfosService;
  stayCardRoomRate: StayCardRoomRatesService;
}

export const initReservationStayCardServiceModel = (
  axios: AxiosInstance
): ReservationStayCardServiceModel => ({
  stayCard: initStayCardService(axios),
  stayCardBill: initStayCardBillService(axios),
  stayCardHeader: initStayCardHeaderService(axios),
  stayCardStay: initStayCardStayService(axios),
  stayCardGeneralInfos: initStayCardGeneralInfosService(axios),
  stayCardRoomRate: initStayCardRoomRatesService(axios),
});
