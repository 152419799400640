import { AxiosInstance } from "axios";
import { UserService } from "services/Settings/ACL";

export interface UserServiceModel {
  getAll: ReturnType<typeof UserService.getAll>;
  getById: ReturnType<typeof UserService.getById>;
  create: ReturnType<typeof UserService.create>;
  update: ReturnType<typeof UserService.update>;
  remove: ReturnType<typeof UserService.remove>;
  updatePassword: ReturnType<typeof UserService.updatePassword>;
}

export const initUserService = (
  axios: AxiosInstance
): UserServiceModel => ({
  getAll: UserService.getAll(axios),
  create: UserService.create(axios),
  update: UserService.update(axios),
  remove: UserService.remove(axios),
  getById: UserService.getById(axios),
  updatePassword: UserService.updatePassword(axios),
});
