import { AxiosInstance } from "axios";
import { UploadedFileModel } from "models/Settings/Helper/UploadedFile";
import { HelperServices } from "services";

export interface HelperServicesModel {
  uploadFile: (formdata: FormData) => Promise<UploadedFileModel | null>;
}

export const initHelperServices = (
  axios: AxiosInstance
): HelperServicesModel => ({
  uploadFile: HelperServices.uploadFile(axios),
});
