import * as React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const ChevronLeftIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="m8 12 8-6v12z" fillRule="evenodd" />
    </SvgIcon>
  );
};
