import { lazy, Suspense } from "react";
import { Breadcrumb } from "routing/components/Breadcrumb";
import { RoutePathDefinition } from "routing/routes";

const QuickReservationPage = lazy(
  () => import("../pages/QuickReservationPage")
);
const QuickReservationSearchResultPage = lazy(
  () => import("../pages/QuickReservationResultPage/SearchResultPage")
);
const QuickReservationResultPage = lazy(
  () => import("../pages/QuickReservationResultPage")
);
const QuickReservationPreferences = lazy(
  () => import("../pages/QuickReservationResultPage/Preferences")
);
const QuickReservationAddons = lazy(
  () => import("../pages/QuickReservationResultPage/Preferences/Addons")
);
const QuickReservationLikesAndFearutes = lazy(
  () =>
    import("../pages/QuickReservationResultPage/Preferences/LikesAndFearutes")
);
const QuickReservationSummaryPage = lazy(
  () => import("../pages/QuickReservationResultPage/Summary")
);

const QuickReservationRoutes: Array<RoutePathDefinition> = [
  {
    index: true,
    element: (
      <Suspense fallback={<>...</>}>
        <QuickReservationPage />
      </Suspense>
    ),
    handle: {
      crumb: () => <Breadcrumb label="Quick Reservation" path={null} />,
    },
  },
  {
    path: "result",
    element: (
      <Suspense fallback={<>...</>}>
        <QuickReservationResultPage />
      </Suspense>
    ),
    handle: {
      crumb: () => <Breadcrumb label="Quick Reservation" path={null} />,
    },
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<>...</>}>
            <QuickReservationSearchResultPage />
          </Suspense>
        ),
        handle: {
          crumb: () => <Breadcrumb label="Quick Reservation" path={null} />,
        },
      },
      {
        path: "preferences",
        element: (
          <Suspense fallback={<>...</>}>
            <QuickReservationPreferences />
          </Suspense>
        ),
        handle: {
          crumb: () => <Breadcrumb label="Quick Reservation" path={null} />,
        },
      },
      {
        path: "addons",
        element: (
          <Suspense fallback={<>...</>}>
            <QuickReservationAddons />
          </Suspense>
        ),
        handle: {
          crumb: () => <Breadcrumb label="Quick Reservation" path={null} />,
        },
      },
      {
        path: "likesandfeatures",
        element: (
          <Suspense fallback={<>...</>}>
            <QuickReservationLikesAndFearutes />
          </Suspense>
        ),
        handle: {
          crumb: () => <Breadcrumb label="Quick Reservation" path={null} />,
        },
      },
      {
        path: "summary",
        element: (
          <Suspense fallback={<>...</>}>
            <QuickReservationSummaryPage />
          </Suspense>
        ),
        handle: {
          crumb: () => <Breadcrumb label="Quick Reservation" path={null} />,
        },
      },
    ],
  },
];

export default QuickReservationRoutes;
