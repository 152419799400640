import { GuestCardFieldsModel } from "models/Settings/Cards/GuestCardFieldsModel";

export enum GuestCardFieldsEnumModel {
  BIRTH_DAY = "birth_day",
  NATIONALITY = "nationality",
  LANGUAGE = "language",
  NATIONAL_ID = "national_id",
  NATIONAL_ID_DATE_DELIVERY = "national_id_date_delivery",
  PASSPORT_ID = "passport_id",
  PASSPORT_DATE_DELIVRY = "passport_date_delivery",
  NATIONAL_ID_DATE_EXPIRY = "national_id_date_expiry",
  ENTRY_ID = "entry_id",
  ENTRY_DATE = "entry_date",
  JOB_TITLE = "job_title",
  ADDRESS_1 = "address_1",
  ADDRESS_2 = "address_2",
  COUNTRY = "country",
  CITY = "city",
  ZIP_CODE = "zip_code",
  PHONE = "phone",
  MOBILE = "mobile",
  EMAIL_ADDRESS = "email_address",
}

export interface TypeOfGuestFields {
  type: "date" | "string" | "number" | "email" | "phone" | "autocomplete";
}

export const GuestCardFieldsType: Record<
  GuestCardFieldsEnumModel,
  TypeOfGuestFields
> = {
  birth_day: { type: "date" },
  nationality: { type: "autocomplete" },
  language: { type: "string" },
  national_id: { type: "string" },
  national_id_date_delivery: { type: "date" },
  passport_id: { type: "string" },
  passport_date_delivery: { type: "date" },
  national_id_date_expiry: { type: "date" },
  entry_id: { type: "string" },
  entry_date: { type: "date" },
  job_title: { type: "string" },
  address_1: { type: "string" },
  address_2: { type: "string" },
  country: { type: "string" },
  city: { type: "string" },
  zip_code: { type: "string" },
  phone: { type: "phone" },
  mobile: { type: "phone" },
  email_address: { type: "email" },
};

export const GuestCardFieldsData: GuestCardFieldsModel[] = [
  {
    field: GuestCardFieldsEnumModel.BIRTH_DAY,
    is_mandatory: true,
    is_visible: false,
  },
  {
    field: GuestCardFieldsEnumModel.NATIONALITY,
    is_mandatory: true,
    is_visible: false,
  },
  {
    field: GuestCardFieldsEnumModel.LANGUAGE,
    is_mandatory: true,
    is_visible: false,
  },
  {
    field: GuestCardFieldsEnumModel.NATIONAL_ID,
    is_mandatory: true,
    is_visible: false,
  },
  {
    field: GuestCardFieldsEnumModel.NATIONAL_ID_DATE_DELIVERY,
    is_mandatory: true,
    is_visible: false,
  },
  {
    field: GuestCardFieldsEnumModel.PASSPORT_ID,
    is_mandatory: true,
    is_visible: false,
  },
  {
    field: GuestCardFieldsEnumModel.PASSPORT_DATE_DELIVRY,
    is_mandatory: true,
    is_visible: false,
  },
  {
    field: GuestCardFieldsEnumModel.NATIONAL_ID_DATE_EXPIRY,
    is_mandatory: true,
    is_visible: false,
  },
  {
    field: GuestCardFieldsEnumModel.ENTRY_ID,
    is_mandatory: true,
    is_visible: false,
  },
  {
    field: GuestCardFieldsEnumModel.ENTRY_DATE,
    is_mandatory: true,
    is_visible: false,
  },
  {
    field: GuestCardFieldsEnumModel.JOB_TITLE,
    is_mandatory: true,
    is_visible: false,
  },
  {
    field: GuestCardFieldsEnumModel.ADDRESS_1,
    is_mandatory: true,
    is_visible: false,
  },
  {
    field: GuestCardFieldsEnumModel.ADDRESS_2,
    is_mandatory: true,
    is_visible: false,
  },
  {
    field: GuestCardFieldsEnumModel.COUNTRY,
    is_mandatory: true,
    is_visible: false,
  },
  {
    field: GuestCardFieldsEnumModel.CITY,
    is_mandatory: true,
    is_visible: false,
  },
  {
    field: GuestCardFieldsEnumModel.ZIP_CODE,
    is_mandatory: true,
    is_visible: false,
  },
  {
    field: GuestCardFieldsEnumModel.PHONE,
    is_mandatory: true,
    is_visible: false,
  },
  {
    field: GuestCardFieldsEnumModel.MOBILE,
    is_mandatory: true,
    is_visible: false,
  },
  {
    field: GuestCardFieldsEnumModel.EMAIL_ADDRESS,
    is_mandatory: true,
    is_visible: false,
  },
];
