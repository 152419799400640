import {
  Box,
  Dialog,
  DialogProps,
  DialogTitle,
  DialogTitleProps,
  IconButton,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { CloseIcon } from "components/SvgIcons/CloseIcon";
import { FC, forwardRef } from "react";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const RootDialog = forwardRef<HTMLDivElement, DialogProps>(
  (props, ref) => {
    return (
      <Dialog
        ref={ref}
        TransitionComponent={Transition}
        maxWidth={"lg"}
        sx={{
          "& .MuiDialog-paper": {
            border: "1px solid",
            borderColor: "colors.gray.o20",
          },
        }}
        {...props}
      ></Dialog>
    );
  }
);

export const RootDialogTitle: FC<
  DialogTitleProps & {
    onClose?: () => void;
    titles?: {
      subTitle?: string;
      title?: string;
    };
  }
> = (props): JSX.Element => {
  const { children, titles, onClose, ...other } = props;

  return (
    <DialogTitle {...other} sx={{ padding: "0 0 32px 0", mr: 4 }}>
      {titles ? (
        <Stack>
          {titles?.subTitle && (
            <Typography
              variant="inter14"
              fontWeight={"fontWeightMedium"}
              color="colors.gray.o50"
            >
              {titles.subTitle}
            </Typography>
          )}
          {titles?.title && (
            <Typography
              variant="inter20"
              fontWeight={"fontWeightSemibold"}
              color="common.black"
              lineHeight="normal"
            >
              {titles.title}
            </Typography>
          )}
        </Stack>
      ) : (
        <Stack>{children}</Stack>
      )}
      <Box sx={{ flexGrow: 1 }} />
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 12,
            top: 13,
            color: (theme) => theme.palette.common.black,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
