import { RootContextProps } from "components/RootContainer";
import { RoleModel } from "models/Settings/Acl/RoleModel";
import { UserModel } from "models/Settings/Acl/userModel";
import React, { FC } from "react";
import { Outlet, RouteObject, useRouteError } from "react-router-dom";
import { Breadcrumb } from "routing/components/Breadcrumb";

const LandingPage = React.lazy(() => import("../Pages/LandingPage"));
const CreateRolePage = React.lazy(
  () => import("../Pages/RolePage/EditPage/CreateRole")
);
const EditRolePage = React.lazy(
  () => import("../Pages/RolePage/EditPage/EditRole")
);
const ListingRoles = React.lazy(() => import("../Pages/RolePage/ListingPage"));
const CreateUserPage = React.lazy(
  () => import("../Pages/UserPage/EditPage/CreateUserPage")
);
const EditUserPage = React.lazy(
  () => import("../Pages/UserPage/EditPage/EditUserPage")
);
const ListingUsers = React.lazy(() => import("../Pages/UserPage/ListingPage"));
const AclPage = React.lazy(() => import("../Pages/AclPage"));

const SettingsCardsBoundary: FC = (): JSX.Element => {
  let error = useRouteError() as Error;
  return (
    <>
      <h2>Error 💥</h2>
      <p>{error.message}</p>
    </>
  );
};

export const routePathsAcl = (api: RootContextProps): RouteObject[] => [
  {
    index: true,
    element: <LandingPage />,
    errorElement: <SettingsCardsBoundary />,
  },
  {
    path: "acl",
    element: <AclPage />,
    handle: {
      crumb: () => (
        <Breadcrumb label="Acl Tree" path={"settings/acl/acl-tree"} />
      ),
    },
  },
  {
    path: "users",
    element: <Outlet />,
    handle: {
      crumb: () => <Breadcrumb label="Users" path={"settings/acl/users"} />,
    },
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <ListingUsers />
          </React.Suspense>
        ),
      },
      {
        path: "create-user",
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <CreateUserPage />
          </React.Suspense>
        ),
      },
      {
        path: ":id/edit",
        element: (
          <React.Suspense fallback={<>...LoadingPage</>}>
            <EditUserPage />
          </React.Suspense>
        ),
        loader: async ({ params }): Promise<UserModel | null> => {
          const {
            ChainApi: {
              settings: {
                acl: {
                  user: { getById },
                },
              },
            },
          } = api;
          if (params?.id) {
            return await getById(Number(params.id));
          }
          throw new Response("Not Found", { status: 404 });
        },
        handle: {
          crumb: (data: UserModel) => (
            <Breadcrumb label={data?.first_name} path={null} />
          ),
        },
      },
    ],
  },
  {
    path: "roles",
    element: <Outlet />,
    handle: {
      crumb: () => <Breadcrumb label="Role" path={"settings/acl/roles"} />,
    },
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <ListingRoles />
          </React.Suspense>
        ),
      },
      {
        path: "create-role",
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <CreateRolePage />
          </React.Suspense>
        ),
      },
      {
        path: ":id/edit",
        element: (
          <React.Suspense fallback={<>...</>}>
            <EditRolePage />
          </React.Suspense>
        ),
        loader: async ({ params }): Promise<RoleModel | null> => {
          const {
            ChainApi: {
              settings: {
                acl: {
                  role: { getById },
                },
              },
            },
          } = api;
          if (params?.id) {
            return await getById(Number(params.id));
          }
          throw new Response("Not Found", { status: 404 });
        },
        handle: {
          crumb: (data: RoleModel) => (
            <Breadcrumb label={data?.name} path={null} />
          ),
        },
      },
    ],
  },
];
