import { AxiosInstance } from "axios";
import { ReservationStateService } from "services";

export interface ReservationStateServiceModel {
  getAll: ReturnType<typeof ReservationStateService.getAll>;
  create: ReturnType<typeof ReservationStateService.create>;
  update: ReturnType<typeof ReservationStateService.update>;
  remove: ReturnType<typeof ReservationStateService.remove>;
  toggleStatus: ReturnType<typeof ReservationStateService.toggleStatus>;
}

export const initReservationStateService = (
  axios: AxiosInstance
): ReservationStateServiceModel => ({
  getAll: ReservationStateService.getAll(axios),
  create: ReservationStateService.create(axios),
  update: ReservationStateService.update(axios),
  remove: ReservationStateService.remove(axios),
  toggleStatus: ReservationStateService.toggleStatus(axios),
});
