import { Container, Stack } from "@mui/material";
import { FC } from "react";
import { WhoamiModel } from "services/UserService";
import { PageHeader } from "./PageHeader";
import SectionUserInfo from "./SectionUserInfo";
import { PageContent } from "./PageContent";

const PageContainer: FC<{ user: WhoamiModel }> = ({ user }) => {
  return (
    <Container
      maxWidth="lg"
      sx={{
        height: "100vh",
      }}
    >
      <Stack
        sx={{
          bgcolor: "common.white",
          height: "100vh",
          overflowY: "auto",
        }}
      >
        {user && (
          <>
            <PageHeader />
            <Stack>
              <SectionUserInfo user={user} />
              <Stack direction="row" pl="22.5rem">
                <PageContent user={user} />
              </Stack>
            </Stack>
          </>
        )}
      </Stack>
    </Container>
  );
};

export default PageContainer;
