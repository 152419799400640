import { AxiosInstance } from "axios";
import { CashRegisterService } from "services";

export interface CashRegisterServiceModel {
  getAll: ReturnType<typeof CashRegisterService.getAll>;
  toggleStatus: ReturnType<typeof CashRegisterService.toggleStatus>;
  create: ReturnType<typeof CashRegisterService.create>;
  update: ReturnType<typeof CashRegisterService.update>;
  remove: ReturnType<typeof CashRegisterService.remove>;
  getOne: ReturnType<typeof CashRegisterService.getOne>;
  getAllOperationTypes: ReturnType<
    typeof CashRegisterService.getAllOperationTypes
  >;
}

export const initCashRegisterService = (
  axios: AxiosInstance
): CashRegisterServiceModel => ({
  getAll: CashRegisterService.getAll(axios),
  toggleStatus: CashRegisterService.toggleStatus(axios),
  create: CashRegisterService.create(axios),
  update: CashRegisterService.update(axios),
  remove: CashRegisterService.remove(axios),
  getOne: CashRegisterService.getOne(axios),
  getAllOperationTypes: CashRegisterService.getAllOperationTypes(axios),
});
