import { SETTINGS } from "./CommonEndpoint";

export const ROOMS = `${SETTINGS}/rooms`;

export const ROOMS_FLOOR = `${ROOMS}/floor`;
export const ROOMS_FLOOR_ID = `${ROOMS_FLOOR}/%s`;

export const ROOMS_LIST = `${ROOMS}/room`;
export const ROOMS_LIST_ID = `${ROOMS_LIST}/%s`;

export const ROOMS_CATEGORY = `${ROOMS}/room-category`;
export const ROOMS_CATEGORY_ID = `${ROOMS_CATEGORY}/%s`;
