import { AxiosInstance } from "axios";
import { RateAddOnsService } from "services/RevenueManagement/Rate";

export interface RateAddOnsServiceModel {
  create: ReturnType<typeof RateAddOnsService.create>;
  remove: ReturnType<typeof RateAddOnsService.remove>;
  getAll: ReturnType<typeof RateAddOnsService.getAll>;
  getList: ReturnType<typeof RateAddOnsService.getList>;
}

export const initRateAddOnsService = (
  axios: AxiosInstance
): RateAddOnsServiceModel => ({
  create: RateAddOnsService.create(axios),
  remove: RateAddOnsService.remove(axios),
  getAll: RateAddOnsService.getAll(axios),
  getList: RateAddOnsService.getList(axios),
});
