import { FC } from "react";
import {
  Box,
  BoxProps,
  SvgIconProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { Bookmark2Icon } from "components/SvgIcons/Bookmark2Icon";

const Container = styled(Box)({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  flexFlow: "column",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 0,
});

const IconContainer = styled(Box)(({ theme }) => ({
  width: "5rem",
  height: "5rem",
  borderRadius: "2.5rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.colors.gray.o30,
  padding: "1rem",
  "& .MuiSvgIcon-root": {
    color: theme.palette.colors.gray.o50,
    fontSize: "3rem",
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  textShadow: "2px 2px 0 #f5f6f8",
  fontFamily: "'Inter'",
  fontSize: "3.5rem",
  fontWeight: "bold",
  textAlign: "center",
  color: theme.palette.colors.gray.o30,
  lineHeight: "normal",
}));

const SubTitle = styled(Title)({
  fontSize: "1.5rem",
  fontWeight: 500,
});

interface EmptyContentProps {
  title?: string;
  subTitle?: string;
  icon?: FC<SvgIconProps>;
  TitleProps?: TypographyProps;
  SubTitleProps?: TypographyProps;
  IconContainerProps?: BoxProps;
  IconProps?: SvgIconProps;
}

const EmptyContent: FC<BoxProps & EmptyContentProps> = ({
  title,
  subTitle,
  icon: Icon,
  TitleProps,
  SubTitleProps,
  IconContainerProps,
  IconProps,
  ...props
}) => {
  const theme = useTheme();

  return (
    <Container {...props}>
      <IconContainer {...IconContainerProps}>
        {Icon ? (
          <Icon />
        ) : (
          <Bookmark2Icon
            sx={{ color: theme.palette.colors.gray.o50, fontSize: "3rem" }}
            {...IconProps}
          />
        )}
      </IconContainer>
      <Title {...TitleProps}>{title}</Title>
      <SubTitle {...SubTitleProps}>{subTitle}</SubTitle>
    </Container>
  );
};

export default EmptyContent;
