import { Components, Theme } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import SquareIcon from "@mui/icons-material/Square";
import HorizontalRuleOutlinedIcon from "@mui/icons-material/HorizontalRuleOutlined";
export const MuiCheckbox: Components<Omit<Theme, "components">>["MuiCheckbox"] =
  {
    defaultProps: {
      checkedIcon: <CheckIcon />,
      icon: <SquareIcon />,
      indeterminateIcon: <HorizontalRuleOutlinedIcon />,
    },
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        borderRadius: 4,
        padding: 0,
        width: "1.5rem",
        height: "1.5rem",
        border: `solid 2px ${theme.palette.colors.gray.o50}`,
        //backgroundColor: theme.palette.common.white,
        backgroundColor: "transparent",

        "& .MuiSvgIcon-root": {
          display: "none",
          width: "1rem",
          height: "1rem",
          fontSize: 20,
          margin: "0.25rem",
          color: theme.palette.common.white,
        },

        "&.Mui-checked": {
          border: `solid 2px ${theme.palette.colors.purple.o70}`,
        },

        // padding: " 0.25rem",
        // borderRadius: "4px",
        // margin: 0,
        // padding: 0,
        // position: "relative",
        // border:
        //   ownerState.checked || ownerState.indeterminate
        //     ? `solid 1.5px ${theme.palette.colors.purple.o70}`
        //     : `solid 1.5px ${theme.palette.colors.gray.o50}`,
        // "& .MuiSvgIcon-root": {
        //   fill:
        //     ownerState.checked || ownerState.indeterminate
        //       ? theme.palette.colors.purple.o70
        //       : "#fff",
        // },
      }),
      colorPrimary: ({ theme }) => ({
        // backgroundColor: theme.palette.common.white,
        backgroundColor: "transparent",
        "&:hover": {
          backgroundColor: theme.palette.common.white,
          border: `solid 2px ${theme.palette.colors.purple.o70}`,
        },
        "&.Mui-disabled": {
          border: `solid 2px ${theme.palette.colors.gray.o40}`,
          // backgroundColor: theme.palette.colors.gray.o40,
        },
        "&.Mui-checked": {
          border: `solid 2px ${theme.palette.colors.purple.o70}`,
          backgroundColor: theme.palette.colors.purple.o70,
          "& .MuiSvgIcon-root": {
            display: "block",
            width: "1rem",
            height: "1rem",
            fontSize: 20,
            margin: "0.25rem",
            color: theme.palette.common.white,
          },
          "&.Mui-disabled": {
            border: `solid 2px ${theme.palette.colors.gray.o40}`,
            backgroundColor: theme.palette.colors.gray.o40,
          },
        },
        "&.MuiCheckbox-indeterminate": {
          border: `solid 2px ${theme.palette.colors.purple.o70}`,
          backgroundColor: theme.palette.colors.purple.o70,
          "& .MuiSvgIcon-root": {
            display: "block",
            width: "1rem",
            height: "1rem",
            fontSize: 20,
            margin: "0.25rem",
            color: theme.palette.common.white,
          },
        },
      }),
    },
  };
