import { ReservationsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import ReservationSummaryModel from "models/Reservations/ReservationSummaryModel";
import { ReservationModel } from "pages/ReservationModule/pages/CreateReservationPageNew/models";
import { ReservationPaymentMethodModel } from "pages/ReservationModule/pages/CreateReservationPageNew/models/ReservationPaymentModel";
import { ReservationTargetEnum } from "pages/ReservationModule/pages/CreateReservationPageNew/models/ReservationTargetEnum";
import { sprintf } from "sprintf-js";

export const getSummary =
  (axios: AxiosInstance) =>
    async (id: number): Promise<ReservationSummaryModel> => {
      return await axios
        .get(sprintf(ReservationsEndpoint.RESERVATION_GET_SUMMARY, id))
        .then(({ data }) => data);
    };

export const saveSummary =
  (axios: AxiosInstance) =>
    async (
      data?:
        | (ReservationModel & { target: ReservationTargetEnum })
        | (ReservationPaymentMethodModel & { target: ReservationTargetEnum })
    ): Promise<ReservationSummaryModel> => {
      return await axios
        .post(ReservationsEndpoint.RESERVATION_SAVE_RESERVATION, { ...data })
        .then(({ data }) => {

          return data;
        });
    };
