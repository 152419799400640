import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import {
  PaymentTypeModel,
  PaymentTypeModelListItem,
} from "models/Settings/Financial/PaymentTypeModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";

export interface Options {
  id: number;
  label: string;
}

export interface TypeModel {
  id: string | undefined;
  value: string | number;
  label: string;
}

export const remove =
  (axios: AxiosInstance) =>
    async (id: number): Promise<PaymentTypeModel> => {
      return await axios.delete(
        `${SettingsEndpoint.SETTINGS_FINANCIAL_PAYMENT_TYPE}/${id}`
      );
    };

export const getAll =
  (axios: AxiosInstance) =>
    async (
      params?: FetchingRequestParamsModels
    ): Promise<PaymentTypeModelListItem[]> => {
      return await axios
        .get(SettingsEndpoint.SETTINGS_FINANCIAL_PAYMENT_TYPE, {
          params,
        })
        .then(({ data }) => data?.items);
    };

export const create =
  (axios: AxiosInstance) =>
    async (data: PaymentTypeModel): Promise<PaymentTypeModel> => {
      return await axios
        .post(SettingsEndpoint.SETTINGS_FINANCIAL_PAYMENT_TYPE, data)
        .then(({ data }) => data);
    };

export const update =
  (axios: AxiosInstance) =>
    async (data: PaymentTypeModel): Promise<PaymentTypeModel> => {
      return await axios
        .put(
          `/${SettingsEndpoint.SETTINGS_FINANCIAL_PAYMENT_TYPE}/${data.id}`,
          data
        )
        .then(({ data }) => data);
    };

export const toggleStatus =
  (axios: AxiosInstance) =>
    async (id: number): Promise<PaymentTypeModel> => {
      return await axios
        .patch(`/${SettingsEndpoint.SETTINGS_FINANCIAL_PAYMENT_TYPE}/${id}`)
        .then(({ data }) => data);
    };

/** Charge Code Services */

export const updateChargeCode =
  (axios: AxiosInstance) =>
    async (data: {
      payment_type_id: number;
      charge_code_id: number;
    }): Promise<PaymentTypeModel> => {
      return await axios
        .post(`/${SettingsEndpoint.SETTINGS_FINANCIAL_PAYMENT_TYPE}`, data)
        .then(({ data }) => data);
    };

export const removeChargeCode =
  (axios: AxiosInstance) =>
    async (id: number): Promise<PaymentTypeModel> => {

      return await axios.delete(
        `${SettingsEndpoint.SETTINGS_FINANCIAL_PAYMENT_TYPE}/${id}`
      );
    };

export const getChargeCodeByPaymentId =
  (axios: AxiosInstance) =>
    async (
      id?: number
    ): Promise<{
      payment_type_id: number;
      charge_code_id: number;
    }> => {
      return await axios
        .get(
          `/${SettingsEndpoint.SETTINGS_FINANCIAL_PAYMENT_TYPE_CHARGE_CODE}/charge_code/${id}`
        )
        .then(({ data }) => data);
    };
