import { REVENUE_MANAGEMENT_ROOT_ENDPOINT } from "./RootEndpoint";

export const REVENUE_MANAGEMENT_RATE = `${REVENUE_MANAGEMENT_ROOT_ENDPOINT}/rate`;

export const RATE_ASSIGN_MARKET_GROUP_AGENCY = `${REVENUE_MANAGEMENT_RATE}/card-group-market/assign`;

export const RATE_CREATE = REVENUE_MANAGEMENT_RATE;
export const RATE_GET_ALL = REVENUE_MANAGEMENT_RATE;
export const RATE_GET_BY_ROOM_TYPE = `${REVENUE_MANAGEMENT_RATE}/by-reservation-room-type/%1$s/%2$s`;
export const RATE_GET_BY_MARKET_AND_CARD = `${REVENUE_MANAGEMENT_RATE}/reservation/by-card-group-market/search`;
export const RATE_GET_ONE = `${REVENUE_MANAGEMENT_RATE}/%s`;
export const RATE_UPDATE = `${REVENUE_MANAGEMENT_RATE}/%s`;
export const RATE_UPDATE_AS_DRAFT = `${REVENUE_MANAGEMENT_RATE}/draft/%s`;
export const RATE_DELETE = `${REVENUE_MANAGEMENT_RATE}/%s`;
export const RATE_DISCARD = `${REVENUE_MANAGEMENT_RATE}/discard/%s`;
export const RATE_TOGGLE_STATUS = `${REVENUE_MANAGEMENT_RATE}/%s`;

export const RATE_RANGE_CREATE = `${REVENUE_MANAGEMENT_RATE}/%s/range`;
export const RATE_RANGE_COPY_FROM = `${REVENUE_MANAGEMENT_RATE}/%s/rate/copy`;
export const RATE_RANGE_BROKE = `${REVENUE_MANAGEMENT_RATE}/%s/rate/broke`;
export const RATE_RANGE_BASE_ON = `${REVENUE_MANAGEMENT_RATE}/%s/rate/base`;
export const RATE_RANGE_GET_ALL = `${REVENUE_MANAGEMENT_RATE}/%s/range`;
export const RATE_RANGE_GET_ONE = `${REVENUE_MANAGEMENT_RATE}/%1$s/range/%2$s`;
export const RATE_RANGE_DELETE = `${REVENUE_MANAGEMENT_RATE}/%1$s/range/%2$s`;
export const RATE_RANGE_DUPLICATE = `${REVENUE_MANAGEMENT_RATE}/%1$s/range/%2$s/duplicate`;

export const RATE_CARD_CREATE = `${REVENUE_MANAGEMENT_RATE}/%s/agency-card`;
export const RATE_CARD_CREATE_ASSIGN = `${REVENUE_MANAGEMENT_RATE}/%s/card-group-market/assign`;
export const RATE_CARD_GET_ALL = `${REVENUE_MANAGEMENT_RATE}/%s/agency-card`;
export const RATE_CARD_GET_ALL_ASSIGNED = `${REVENUE_MANAGEMENT_RATE}/%s/card-group-market/assigned`;

export const RATE_ADD_ONS_CREATE = `${REVENUE_MANAGEMENT_RATE}/%s/addon`;
export const RATE_ADD_ONS_REMOVE = `${REVENUE_MANAGEMENT_RATE}/%s/addon/%s`;
export const RATE_ADD_ONS_GET_ALL = `${REVENUE_MANAGEMENT_RATE}/%s/addon`;
export const RATE_ADD_ONS_GET_LIST = `${REVENUE_MANAGEMENT_RATE}/%s/addon/add`;
export const RATE_ADD_ONS_GET_FOR_RESERVATION = `${REVENUE_MANAGEMENT_RATE}/%s/addon/reservation`;

export const RATE_RANGE_LEVEL_CREATE = `${REVENUE_MANAGEMENT_RATE}/%1$s/range/%2$s/level`;
export const RATE_RANGE_LEVEL_GET_ALL = `${REVENUE_MANAGEMENT_RATE}/%1$s/range/%2$s/level`;
export const RATE_RANGE_LEVEL_GET_ONE = `${REVENUE_MANAGEMENT_RATE}/%1$s/range/%2$s/level/%3$s`;
export const RATE_RANGE_LEVEL_DELETE = `${REVENUE_MANAGEMENT_RATE}/%1$s/range/%2$s/level/%3$s`;

export const RATE_RANGE_LEVEL_ROOM_CREATE = `${REVENUE_MANAGEMENT_RATE}/%1$s/range/%2$s/room`;
export const RATE_RANGE_LEVEL_ROOM_GET_ALL = `${REVENUE_MANAGEMENT_RATE}/%1$s/range/%2$s/room`;
export const RATE_RANGE_LEVEL_ROOM_GET_ONE = `${REVENUE_MANAGEMENT_RATE}/%1$s/range/%2$s/room/%3$s`;
export const RATE_RANGE_LEVEL_ROOM_DELETE = `${REVENUE_MANAGEMENT_RATE}/%1$s/range/%2$s/room/%3$s`;

export const RATE_CUSTOM_RESERVATION_SEARCH = `${REVENUE_MANAGEMENT_RATE}/search`;
