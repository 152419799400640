import { AxiosInstance } from "axios";
import { RangeLevelService } from "services/RevenueManagement/Rate";

export interface RateRangeLevelServiceModel {
  create: ReturnType<typeof RangeLevelService.create>;
  getAll: ReturnType<typeof RangeLevelService.getAll>;
  getOneById: ReturnType<typeof RangeLevelService.getOneById>;
  remove: ReturnType<typeof RangeLevelService.remove>;
}

export const initRateRangeLevelService = (
  axios: AxiosInstance
): RateRangeLevelServiceModel => ({
  create: RangeLevelService.create(axios),
  getAll: RangeLevelService.getAll(axios),
  getOneById: RangeLevelService.getOneById(axios),
  remove: RangeLevelService.remove(axios),
});
