export enum AccessibilityTypes {
    CRS_CONNEXTION = "CRS Connection",
    CLOSED_CHANNEL_MANAGER_CONNECTION = "ClosedChannel Manager Connection",
    USE_PRECHECKIN_PLATFORM = "Use Pre-Check-in platform",
    CHECKIN_CHECKOUT_STATION = "Check-in /check-out Station",
    REQUIRE_SIGNATURE_AT_CHECKIN = "Require Signature at Check-in",
    REQUIRE_SIGNATURE_AT_CHECKOUT = "Require Signature at Check-out",
    USE_HOUSE_KEEPING_MOBILE_PLATFORM = "Use House keeping Mobile platform",
    USE_MAINTENANCE_MOBILE_PLATFORM = "Use Maintenance Mobile platform",
    USE_ID_SCAN = "Use Id SCAN",
    GUEST_PICTURE = "Guest picture",
    USE_CREDIT_CARD_INFORMATIONS = "Use Credit Card informations ",
    SEND_INVOICE_BY_EMAIL = "Send Invoice by e-mail"
}