import { AxiosInstance } from "axios";
import { DraftService } from "services";

export interface DraftServiceModel {
  getAll: ReturnType<typeof DraftService.getAll>;
}

export const initDraftService = (axios: AxiosInstance): DraftServiceModel => ({
  getAll: DraftService.getAll(axios),
});
