import axios, { AxiosInstance } from "axios";
// import { getStorageItem } from "./localStorage";
export const ACCESS_TOKEN = "accessToken";

export const createHttpClient = (baseURL: string): AxiosInstance => {
  const instance = axios.create({
    baseURL: baseURL,
    withCredentials: true,
    headers: { "Content-Type": "application/json" },
  });

  instance.interceptors.request.use(
    (request) => {
      request.params = {
        ...request.params,
        fake_email: process.env.REACT_APP_API_USER_NAME,
      };

      // const access_token = getStorageItem(ACCESS_TOKEN) || "";
      // request.headers = {
      //   ...request.headers,
      //   ...(access_token ? { Authorization: `Bearer ${access_token}` } : {}),
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // };

      return request;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
  instance.interceptors.response.use(
    (response) => response.data,
    (response) => response.data
  );
  return instance;
};

const httpClient = createHttpClient(process.env.REACT_APP_API_URL as string);

export default httpClient;
