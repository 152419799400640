import { AxiosInstance } from "axios";
import { RateTypeService } from "services";

export interface RateTypeServiceModel {
  getAll: ReturnType<typeof RateTypeService.getAll>;
  create: ReturnType<typeof RateTypeService.create>;
  update: ReturnType<typeof RateTypeService.update>;
  remove: ReturnType<typeof RateTypeService.remove>;
  toggleStatus: ReturnType<typeof RateTypeService.toggleStatus>;
}

export const initRateTypeService = (
  axios: AxiosInstance
): RateTypeServiceModel => ({
  getAll: RateTypeService.getAll(axios),
  create: RateTypeService.create(axios),
  update: RateTypeService.update(axios),
  remove: RateTypeService.remove(axios),
  toggleStatus: RateTypeService.toggleStatus(axios),
});
