import { ReservationsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance, AxiosResponse } from "axios";
import { PaymentGuestModel } from "models/Reservations/Guest/GuestModel";
import { sprintf } from "sprintf-js";

export const create =
  (axios: AxiosInstance) =>
    async ({
      guestId,
      data,
    }: {
      guestId: number;
      data: PaymentGuestModel;
    }): Promise<PaymentGuestModel> => {
      return await axios
        .post(sprintf(ReservationsEndpoint.GUEST_PAYMENT_METHOD_CREATE, guestId), data)
        .then(({ data }) => data);
    };

export const update =
  (axios: AxiosInstance) =>
    async ({
      guestId,
      data,
    }: {
      guestId: number;
      data: PaymentGuestModel;
    }): Promise<PaymentGuestModel> => {
      return await axios
        .put(sprintf(ReservationsEndpoint.GUEST_PAYMENT_METHOD_ID, guestId, data.id), data)
        .then(({ data }) => data);
    };

export const getByGuest =
  (axios: AxiosInstance) =>
    async (cardId: number): Promise<PaymentGuestModel[]> => {
      return await axios
        .get(sprintf(ReservationsEndpoint.GUEST_PAYMENT_METHOD_GET_ALL, cardId), { params: { limit: 1000 } })
        .then(({ data }) => data.items);
    };


export const remove =
  (axios: AxiosInstance) =>
    async (cardId: number, pmId: number): Promise<AxiosResponse<PaymentGuestModel>> => {
      return await axios.delete(
        sprintf(ReservationsEndpoint.GUEST_PAYMENT_METHOD_ID, cardId, pmId)
      );
    };
