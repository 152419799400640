import { Components, Theme } from "@mui/material";
export const MuiTableHead: Components<
  Omit<Theme, "components">
>["MuiTableHead"] = {
  styleOverrides: {
    root: {
      height: 24,
    },
  },
};
