import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const Bookmark2Icon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17 2H7a3 3 0 0 0-3 3v16l.006.114a1 1 0 0 0 1.575.7L12 17.228l6.419 4.586A1 1 0 0 0 20 21V5a3 3 0 0 0-3-3zm0 2 .117.007A1 1 0 0 1 18 5v14.057l-5.419-3.87-.107-.068a1 1 0 0 0-1.055.067L6 19.057V5a1 1 0 0 1 1-1h10z"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};
