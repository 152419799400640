import { RoutePaths } from "@constants";
import PageSuspenseFallback from "components/PageSuspenseFallback";
import { RootContextProps } from "components/RootContainer";
import { CardModel } from "models/RevenueManagement/Card/CardModel";
import { PromoModel } from "models/RevenueManagement/Promo/PromoModel";
import React from "react";
import { Breadcrumb } from "routing/components/Breadcrumb";
import { RoutePathDefinition } from "routing/routes";
import RatesRoutes from "../pages/RateModule/routing";
import RakManagerRoutes from "../pages/RateManagerModule/routing/routes";

const CardsListPage = React.lazy(
  () => import("pages/RevenuManagementModule/pages/CardsPage/List")
);
const CardsEditPage = React.lazy(
  () => import("pages/RevenuManagementModule/pages/CardsPage/Edit")
);

const PromosListPage = React.lazy(
  () => import("pages/RevenuManagementModule/pages/PromosPage/List")
);
const PromoEditPage = React.lazy(
  () => import("pages/RevenuManagementModule/pages/PromosPage/Edit")
);

const EndOfDateCheckPage = React.lazy(
  () => import("pages/RevenuManagementModule/pages/EndOfDateCheckPage")
);

const RevenueManagementRoutes = (
  Api: RootContextProps
): Array<RoutePathDefinition> => [
  {
    path: "cards",
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<>...</>}>
            <CardsListPage />
          </React.Suspense>
        ),
        handle: {
          crumb: () => <Breadcrumb label="Find cards" path={null} />,
        },
      },
      {
        path: ":id/edit",
        element: (
          <React.Suspense fallback={<>...</>}>
            <CardsEditPage />
          </React.Suspense>
        ),
        loader: async ({ params: { id } }) => {
          return await Api.HotelApi.revenueManagement.card.card.getOneById(
            +id!
          );
        },
        handle: {
          crumb: (data: CardModel) => (
            <>
              <Breadcrumb label={`Cards . `} path={RoutePaths.CARD_LIST} />
              <Breadcrumb label={data?.general?.name} path={null} />
            </>
          ),
        },
      },
    ],
  },
  {
    path: "rate-manager",
    handle: {
      crumb: () => (
        <Breadcrumb
          label={"Rate Manager"}
          path="/revenue-management/rate-manager"
        />
      ),
    },
    children: RakManagerRoutes(Api),
  },
  {
    path: "rates",
    children: RatesRoutes(Api),
  },

  {
    path: "promos",
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<PageSuspenseFallback />}>
            <PromosListPage />
          </React.Suspense>
        ),
        handle: {
          crumb: () => (
            <Breadcrumb label="Extend Pricing Conditions" path={null} />
          ),
        },
      },
      {
        path: ":id/edit",
        element: (
          <React.Suspense fallback={<PageSuspenseFallback />}>
            <PromoEditPage />
          </React.Suspense>
        ),
        loader: async ({ params }): Promise<PromoModel | null> => {
          const {
            HotelApi: {
              revenueManagement: {
                promo: { getOne },
              },
            },
          } = Api;
          if (params?.id) {
            return await getOne(Number(params.id));
          }
          throw new Response("Not Found", { status: 404 });
        },
        handle: {
          crumb: (data: PromoModel) => (
            <Breadcrumb label={data?.description} path={null} />
          ),
        },
      },
    ],
  },
  {
    path: "end-of-day-check",
    element: (
      <React.Suspense fallback={<>...</>}>
        <EndOfDateCheckPage />
      </React.Suspense>
    ),
    handle: {
      crumb: () => <Breadcrumb label="Before End Of Day" path={null} />,
    },
  },
];

export default RevenueManagementRoutes;
