import { AxiosInstance } from "axios";
import { CashierService } from "services";

export interface CashierServiceModel {
  getAll: ReturnType<typeof CashierService.getAll>;
  toggleStatus: ReturnType<typeof CashierService.toggleStatus>;
  create: ReturnType<typeof CashierService.create>;
  update: ReturnType<typeof CashierService.update>;
  remove: ReturnType<typeof CashierService.remove>;
}

export const initCashierService = (
  axios: AxiosInstance
): CashierServiceModel => ({
  getAll: CashierService.getAll(axios),
  toggleStatus: CashierService.toggleStatus(axios),
  create: CashierService.create(axios),
  update: CashierService.update(axios),
  remove: CashierService.remove(axios),
});

//
