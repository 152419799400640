import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { SecurityModel } from "models/Settings/ApplicationSettings/SecurityModel";

export const getAll =
  (axios: AxiosInstance) => async (): Promise<SecurityModel> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_APPLICATION_SECURITY)
      .then(({ data }) => data);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: SecurityModel): Promise<SecurityModel[]> => {
    return await axios
      .post(SettingsEndpoint.SETTINGS_APPLICATION_SECURITY, data)
      .then(({ data }) => data);
  };
