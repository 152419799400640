import * as React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const ChevronLeftTwoIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g fillRule="evenodd">
        <path d="m12 12 6-6v12zM6 12l6-6v12z" />
      </g>
    </SvgIcon>
  );
};
