import { AxiosInstance } from "axios";
import { SourceService } from "services";

export interface SourceServiceModel {
  getAll: ReturnType<typeof SourceService.getAll>;
  create: ReturnType<typeof SourceService.create>;
  update: ReturnType<typeof SourceService.update>;
  remove: ReturnType<typeof SourceService.remove>;
  toggleStatus: ReturnType<typeof SourceService.toggleStatus>;
}

export const initSourceService = (
  axios: AxiosInstance
): SourceServiceModel => ({
  getAll: SourceService.getAll(axios),
  create: SourceService.create(axios),
  update: SourceService.update(axios),
  remove: SourceService.remove(axios),
  toggleStatus: SourceService.toggleStatus(axios),
});
