import { AxiosInstance } from "axios";
import { RateRangeService } from "services/RevenueManagement/Rate";

export interface RateRateRangeServiceModel {
  create: ReturnType<typeof RateRangeService.create>;
  getAll: ReturnType<typeof RateRangeService.getAll>;
  getOneById: ReturnType<typeof RateRangeService.getOneById>;
  update: ReturnType<typeof RateRangeService.update>;
  remove: ReturnType<typeof RateRangeService.remove>;
  broke: ReturnType<typeof RateRangeService.broke>;
  duplicate: ReturnType<typeof RateRangeService.duplicate>;
  copyFrom: ReturnType<typeof RateRangeService.copyFrom>;
  baseOn: ReturnType<typeof RateRangeService.baseOn>;
}

export const initRateRateRangeService = (
  axios: AxiosInstance
): RateRateRangeServiceModel => ({
  create: RateRangeService.create(axios),
  getAll: RateRangeService.getAll(axios),
  getOneById: RateRangeService.getOneById(axios),
  update: RateRangeService.update(axios),
  remove: RateRangeService.remove(axios),
  broke: RateRangeService.broke(axios),
  duplicate: RateRangeService.duplicate(axios),
  copyFrom: RateRangeService.copyFrom(axios),
  baseOn: RateRangeService.baseOn(axios),
});
