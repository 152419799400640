import { AxiosInstance } from "axios";
import { HouseKeepingMaintenanceTypeServices } from "services/Settings";

export interface HouseKeepingMaintenanceTypeServiceModel {
  getAll: ReturnType<typeof HouseKeepingMaintenanceTypeServices.getAll>;
  getOne: ReturnType<typeof HouseKeepingMaintenanceTypeServices.getOne>;
  create: ReturnType<typeof HouseKeepingMaintenanceTypeServices.create>;
  update: ReturnType<typeof HouseKeepingMaintenanceTypeServices.update>;
  patch: ReturnType<typeof HouseKeepingMaintenanceTypeServices.patch>;
  remove: ReturnType<typeof HouseKeepingMaintenanceTypeServices.remove>;
}

export const initHouseKeepingMaintenanceTypeServiceModel = (
  axios: AxiosInstance
): HouseKeepingMaintenanceTypeServiceModel => ({
  getAll: HouseKeepingMaintenanceTypeServices.getAll(axios),
  getOne: HouseKeepingMaintenanceTypeServices.getOne(axios),
  create: HouseKeepingMaintenanceTypeServices.create(axios),
  update: HouseKeepingMaintenanceTypeServices.update(axios),
  patch: HouseKeepingMaintenanceTypeServices.patch(axios),
  remove: HouseKeepingMaintenanceTypeServices.remove(axios),
});
