import { FC } from "react";
import { Backdrop, Box, Button, Paper, Stack, Typography } from "@mui/material";

export const SessionExpired: FC = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Backdrop
        sx={{
          color: "colors.gray.o70",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          position: "absolute",
          backgroundColor: "transparent",
          backdropFilter: "blur(1px)",
        }}
        open={true}
      >
        <Paper
          sx={{
            width: 400,
            height: 300,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 8,
            p: 3,
          }}
        >
          <Stack direction={"column"}>
            <Typography
              align="center"
              sx={{
                fontSize: 18,
                fontWeight: 600,
                color: "colors.gray.o50",
                mb: 2,
              }}
            >
              Your session has expired
            </Typography>

            <Typography
              align="center"
              sx={{
                fontSize: 14,
                fontWeight: 500,
                color: "colors.gray.o50",
                mb: 4,
              }}
            >
              Please refresh the page. Don't worry, we kept all of your filters
              and breakdowns in place
            </Typography>

            <Button
              variant="contained"
              onClick={() => window.location.reload()}
            >
              REFRESH
            </Button>
          </Stack>
        </Paper>
      </Backdrop>
    </Box>
  );
};
