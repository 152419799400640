import { AxiosInstance } from "axios";
import { RateCustomReservationService } from "services/RevenueManagement/Rate";

export interface RateCustomReservationServiceModel {
  search: ReturnType<typeof RateCustomReservationService.search>;
}

export const initRateCustomReservationService = (
  axios: AxiosInstance
): RateCustomReservationServiceModel => ({
  search: RateCustomReservationService.search(axios),
});
