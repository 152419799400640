import { ThemeOptions } from "@mui/material";
import {
  MuiButton,
  MuiButtonBase,
  MuiToggleButtonGroup,
  MuiToggleButton,
  MuiCheckbox,
  MuiCssBaseline,
  MuiFilledInput,
  MuiFormControl,
  MuiFormHelperText,
  MuiInputBase,
  MuiInput,
  MuiInputLabel,
  MuiLink,
  MuiOutlinedInput,
  MuiSvgIcon,
  MuiTable,
  MuiTableCell,
  MuiTableHead,
  MuiTableRow,
  MuiTableSortLabel,
  MuiAutocomplete,
  MuiInputAdornment,
  MuiSelect,
  MuiAlert,
  MuiTooltip,
  MuiSwitch,
  MuiAccordion,
  MuiAccordionSummary,
  MuiPaper,
  MuiStepper,
  MuiStep,
  MuiStepLabel,
  MuiStepConnector,
  MuiMenu,
  MuiPaginationItem,
  MuiDialog,
  MuiDialogTitle,
  MuiDialogContent,
  MuiDialogActions,
  MuiRadio,
} from "./Overrides";

export const components: ThemeOptions["components"] = {
  MuiCssBaseline,

  /**** TEXT FIELD ****/
  MuiInputBase,
  MuiFormHelperText,
  MuiFilledInput,
  MuiOutlinedInput,
  MuiFormControl,
  MuiInputLabel,
  MuiInput,
  MuiInputAdornment,

  /****  BUTTON ****/
  MuiButton,
  MuiButtonBase,
  MuiToggleButtonGroup,
  MuiToggleButton,

  /****  SWITCH ****/
  MuiSwitch,
  MuiRadio,

  /****  ICON ****/
  MuiSvgIcon,

  /****  LINK ****/
  MuiLink,

  /****  TABLE ****/
  MuiTable,
  MuiTableHead,
  MuiTableRow,
  MuiTableSortLabel,
  MuiTableCell,

  /****  AUTOCOMPLETE ****/
  MuiAutocomplete,

  /****  SELECT ****/
  MuiSelect,

  /****  CHECKBOX ****/
  MuiCheckbox,

  /****  ALERT ****/
  MuiAlert,

  /****  TOOLTIP ****/
  MuiTooltip,

  /****  ACCORDION ****/
  MuiAccordion,
  MuiAccordionSummary,

  /****  PAPER ****/
  MuiPaper,

  /****  STEPPER ****/
  MuiStepper,
  MuiStep,
  MuiStepLabel,
  MuiStepConnector,

  /****  MENU ****/
  MuiMenu,

  /****  PAGINATION ****/
  MuiPaginationItem,

  /****  DIALOG ****/
  MuiDialog,
  MuiDialogTitle,
  MuiDialogContent,
  MuiDialogActions,
};
