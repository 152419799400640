import { AxiosInstance } from "axios";
import { ItemService } from "services";

export interface ItemServiceModel {
  getAll: ReturnType<typeof ItemService.getAll>;
  create: ReturnType<typeof ItemService.create>;
  update: ReturnType<typeof ItemService.update>;
  remove: ReturnType<typeof ItemService.remove>;
  toggleStatus: ReturnType<typeof ItemService.toggleStatus>;
}

export const initItemService = (axios: AxiosInstance): ItemServiceModel => ({
  getAll: ItemService.getAll(axios),
  create: ItemService.create(axios),
  update: ItemService.update(axios),
  remove: ItemService.remove(axios),
  toggleStatus: ItemService.toggleStatus(axios),
});
