import { AxiosInstance } from "axios";
import { ReservationDependenciesService } from "services";

export interface ReservationDependenciesServiceModel {
  getAll: ReturnType<typeof ReservationDependenciesService.getAll>;
}

export const initReservationDependenciesService = (
  axios: AxiosInstance
): ReservationDependenciesServiceModel => ({
  getAll: ReservationDependenciesService.getAll(axios),
});
