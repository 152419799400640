import { Components, Theme } from "@mui/material";
import { LinkBehavior } from "../../components/LinkBehavior";
export const MuiButtonBase: Components<
  Omit<Theme, "components">
>["MuiButtonBase"] = {
  defaultProps: {
    LinkComponent: LinkBehavior,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      "&.MuiPickersDay-root": {
        borderRadius: 0,
        "&:hover": {
          boxShadow: `inset 0 -2px 0 0 ${theme.palette.colors.purple.o70}`,
        },
      },
    }),
  },
};
