import { DraftEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { DraftApiModel } from "models/Drafts/DraftApiModel";

export const getAll =
  (axios: AxiosInstance) => async (): Promise<DraftApiModel> => {
    return await axios
      .get(DraftEndpoint.DRAFT_GET_ALL)
      .then(({ data }) => data);
  };
