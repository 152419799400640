import { AxiosInstance } from "axios";
import { DiscountTypeService } from "services";

export interface DiscountTypeServiceModel {
  getAll: ReturnType<typeof DiscountTypeService.getAll>;
  create: ReturnType<typeof DiscountTypeService.create>;
  update: ReturnType<typeof DiscountTypeService.update>;
  remove: ReturnType<typeof DiscountTypeService.remove>;
  toggleStatus: ReturnType<typeof DiscountTypeService.toggleStatus>;
  search: ReturnType<typeof DiscountTypeService.search>;
  getOne: ReturnType<typeof DiscountTypeService.getOne>;
}

export const initBillingTypeService = (
  axios: AxiosInstance
): DiscountTypeServiceModel => ({
  getAll: DiscountTypeService.getAll(axios),
  create: DiscountTypeService.create(axios),
  update: DiscountTypeService.update(axios),
  remove: DiscountTypeService.remove(axios),
  toggleStatus: DiscountTypeService.toggleStatus(axios),
  search: DiscountTypeService.search(axios),
  getOne: DiscountTypeService.getOne(axios),
});
