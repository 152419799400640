import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { CurrencyChainModel } from "models/Settings/Financial/CurrencyModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";

export const getAll =
  (axios: AxiosInstance) =>
  async (
    params?: FetchingRequestParamsModels
  ): Promise<CurrencyChainModel[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_CURRENCY, {
        params,
      })
      .then(({ data }) => data.items);
  };

export const toggleStatus =
  (axios: AxiosInstance) =>
  async (id: number): Promise<CurrencyChainModel> => {
    return await axios
      .patch(`SettingsEndpoint.SETTINGS_CURRENCY/${id}`)
      .then(({ data }) => data.items);
  };
