import { AxiosInstance } from "axios";
import { ReservationStayCardStayService } from "services/Reservations";

export interface StayCardStayService {
  getStayData: ReturnType<typeof ReservationStayCardStayService.getStayData>;
  updateStay: ReturnType<typeof ReservationStayCardStayService.updateStay>;
}

export const initStayCardStayService = (
  axios: AxiosInstance
): StayCardStayService => ({
  getStayData: ReservationStayCardStayService.getStayData(axios),
  updateStay: ReservationStayCardStayService.updateStay(axios),
});
