import { AxiosInstance } from "axios";
import { CardsEndpoint } from "@constants/ApiEndpoints";
import { GroupModel } from "models/Settings/Cards/GroupModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";

export const getAll =
  (axios: AxiosInstance) =>
  async (
    params?: FetchingRequestParamsModels | undefined
  ): Promise<GroupModel[]> => {
    return await axios
      .get(CardsEndpoint.CARD_GROUP_GET_ALL, { params })
      .then(({ data }) => data.items);
  };
