import { lazy, Suspense } from "react";
import { Breadcrumb } from "routing/components/Breadcrumb";
import { RoutePathDefinition } from "routing/routes";

const SchedulerPage = lazy(() => import("../pages/SchedulerPage"));

// const RoomPlanDetailsPage = lazy(() => import("../pages/DetailsPage"));

const RoomPlanRoutes: Array<RoutePathDefinition> = [
  {
    index: true,
    element: (
      <Suspense fallback={<>...</>}>
        <SchedulerPage />
      </Suspense>
    ),
    handle: {
      crumb: () => <Breadcrumb label="Details View" path={null} />,
    },
  },
];

export default RoomPlanRoutes;
