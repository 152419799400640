import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { Button, ButtonProps, Typography } from "@mui/material";
import { FC } from "react";

export const DialogButtonSave: FC<LoadingButtonProps> = ({
  children,
  ...props
}) => (
  <LoadingButton size="large" variant="contained" color="primary" {...props}>
    <Typography variant="inter16" fontWeight={600} textTransform="uppercase">
      {children}
    </Typography>
  </LoadingButton>
);

export const DialogButtonJustif: FC<LoadingButtonProps> = ({
  children,
  ...props
}) => (
  <LoadingButton size="large" variant="contained" color="warning" {...props}>
    <Typography variant="inter16" fontWeight={600} textTransform="uppercase">
      {children}
    </Typography>
  </LoadingButton>
);

export const DialogButtonCancel: FC<ButtonProps> = ({ children, ...props }) => (
  <Button size="large" variant="text" color="primary" {...props}>
    <Typography variant="inter16" fontWeight={600} textTransform="uppercase">
      {children}
    </Typography>
  </Button>
);

export const DialogButtonRemove: FC<ButtonProps> = ({ children, ...props }) => (
  <Button size="large" variant="contained" color="error" {...props}>
    <Typography variant="inter16" fontWeight={600} textTransform="uppercase">
      {children}
    </Typography>
  </Button>
);
