import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const CheckIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M12.862 4.195a.667.667 0 0 1 .943.943L6.47 12.471a.667.667 0 0 1-.942 0L2.195 9.138a.667.667 0 1 1 .943-.943L6 11.057l6.862-6.862z"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};
