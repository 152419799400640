import common from "./common.json";
import list from "./list.json";
import generalInformations from "./generalInformations.json";
import roomsAndRates from "./roomsAndRates.json";
import billingInformations from "./billingInformations.json";
import summary from "./summary.json";
import stayCard from "./stayCard.json";

const ReservationLangConfig = {
  common,
  list,
  generalInformations,
  billingInformations,
  roomsAndRates,
  summary,
  stayCard,
};

export default ReservationLangConfig;
