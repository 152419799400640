import { AxiosInstance } from "axios";
import { ReservationActionsService } from "services/Reservations";

export interface ReservationActionsServiceModel {
  create: ReturnType<typeof ReservationActionsService.create>;
  getAll: ReturnType<typeof ReservationActionsService.getAll>;
  validate: ReturnType<typeof ReservationActionsService.validate>;
  update: ReturnType<typeof ReservationActionsService.update>;
  remove: ReturnType<typeof ReservationActionsService.remove>;
}

export const initReservationActionsService = (
  axios: AxiosInstance
): ReservationActionsServiceModel => ({
  create: ReservationActionsService.create(axios),
  update: ReservationActionsService.update(axios),
  getAll: ReservationActionsService.getAll(axios),
  validate: ReservationActionsService.validate(axios),
  remove: ReservationActionsService.remove(axios),
});
