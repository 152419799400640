import { Components, Theme } from "@mui/material";

export const MuiStepLabel: Components<
  Omit<Theme, "components">
>["MuiStepLabel"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      alignItems: "flex-start",
      paddingTop: "10px",
      "&.MuiStepLabel-alternativeLabel": {
        flexDirection: "row",
        alignItems: "flex-start",
      },
    }),

    label: ({ theme }) => ({
      fontSize: theme.typography.inter16.fontSize,
      color: theme.palette.common.black,
      fontWeight: theme.typography.fontWeightMedium,
      "&.MuiStepLabel-alternativeLabel": {
        marginTop: 1,
      },
      //   "&.Mui-disabled": {
      //     color: "red",
      //   },
    }),
    iconContainer: ({ theme }) => ({
      "&.MuiStepLabel-alternativeLabel": {
        marginRight: 8,
      },

      color: theme.palette.colors.gray.o50,

      "&.Mui-completed": {
        color: theme.palette.colors.purple.o70,
      },
      "&.Mui-active": {
        color: theme.palette.colors.purple.o70,
      },
    }),
    horizontal: {},
    alternativeLabel: {},
    labelContainer: {
      "&.MuiStepLabel-alternativeLabel": {
        textAlign: "left",
      },
    },
  },
};
