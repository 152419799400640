import { SettingsHouseKeepingEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import {
  WorkTaskCreateModel,
  WorkTaskModel,
  WorkTaskResponseModel,
} from "models/Settings/HouseKeeping/WorkTaskModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";
import { sprintf } from "sprintf-js";

export const getAll =
  (axios: AxiosInstance) =>
  async (params?: FetchingRequestParamsModels): Promise<WorkTaskModel[]> => {
    return await axios
      .get(SettingsHouseKeepingEndpoint.HOUSE_KEEPING_WORK_TASK_GET_ALL, {
        params,
      })
      .then(({ data }) => data.items);
  };

export const getOne =
  (axios: AxiosInstance) =>
  async (id: number | string): Promise<WorkTaskResponseModel> => {
    return await axios
      .get(
        sprintf(
          SettingsHouseKeepingEndpoint.HOUSE_KEEPING_WORK_TASK_GET_ONE,
          id
        )
      )
      .then(({ data }) => data);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: WorkTaskCreateModel): Promise<WorkTaskModel> => {
    return await axios
      .post(SettingsHouseKeepingEndpoint.HOUSE_KEEPING_WORK_TASK_CREATE, data)
      .then(({ data }) => data);
  };

type UpdateModel = { id: number | string; data: Partial<WorkTaskCreateModel> };

export const update =
  (axios: AxiosInstance) =>
  async ({ id, data }: UpdateModel): Promise<WorkTaskModel> => {
    return await axios
      .put(
        sprintf(
          SettingsHouseKeepingEndpoint.HOUSE_KEEPING_WORK_TASK_UPDATE,
          id
        ),
        data
      )
      .then(({ data }) => data);
  };

export const patch =
  (axios: AxiosInstance) =>
  async (id: number): Promise<WorkTaskModel> => {
    return await axios
      .patch(
        sprintf(SettingsHouseKeepingEndpoint.HOUSE_KEEPING_WORK_TASK_PATCH, id)
      )
      .then(({ data }) => data);
  };

export const remove =
  (axios: AxiosInstance) =>
  async (id: number | string): Promise<WorkTaskModel> => {
    return await axios
      .delete(
        sprintf(SettingsHouseKeepingEndpoint.HOUSE_KEEPING_WORK_TASK_DELETE, id)
      )
      .then(({ data }) => data);
  };
