import { AxiosInstance } from "axios";
import { ReservationRoomsAndRatesPreferencesService } from "services/Reservations/RoomsAndRates";

export interface RoomsAndRatesPreferencesServiceModel {
  update: ReturnType<typeof ReservationRoomsAndRatesPreferencesService.update>;
  getByRoomRate: ReturnType<
    typeof ReservationRoomsAndRatesPreferencesService.getByRoomRate
  >;
}

export const initRoomsAndRatesPreferencesService = (
  axios: AxiosInstance
): RoomsAndRatesPreferencesServiceModel => ({
  update: ReservationRoomsAndRatesPreferencesService.update(axios),
  getByRoomRate:
    ReservationRoomsAndRatesPreferencesService.getByRoomRate(axios),
});
