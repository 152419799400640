import alerts from "./alerts.json";
import form from "./form.json";
import header from "./header.json";
import list from "./list.json";

const AgeRangeConfig = {
    alerts,
    form,
    header,
    list
};

export default AgeRangeConfig;