import { Components, Theme } from "@mui/material";
export const MuiSvgIcon: Components<Omit<Theme, "components">>["MuiSvgIcon"] = {
  styleOverrides: {
    root: {
      "&.MuiSelect-icon": {
        right: 16,
      },
    },
    fontSizeMedium: {
      fontSize: "1.5rem",
    },
    fontSizeLarge: {
      fontSize: "2rem",
    },
    fontSizeSmall: {
      fontSize: "0.875rem",
    },
  },
};
