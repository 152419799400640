import { AxiosInstance } from "axios";
import { AddonService } from "services";

export interface AddonServiceModel {
  getAll: ReturnType<typeof AddonService.getAll>;
  getAllItemsAndPackages: ReturnType<
    typeof AddonService.getAllItemsAndPackages
  >;
  getById: ReturnType<typeof AddonService.getById>;
  create: ReturnType<typeof AddonService.create>;
  update: ReturnType<typeof AddonService.update>;
  remove: ReturnType<typeof AddonService.remove>;
  toggleStatus: ReturnType<typeof AddonService.toggleStatus>;
}

export const initAddonService = (axios: AxiosInstance): AddonServiceModel => ({
  getAll: AddonService.getAll(axios),
  getAllItemsAndPackages: AddonService.getAllItemsAndPackages(axios),
  getById: AddonService.getById(axios),
  create: AddonService.create(axios),
  update: AddonService.update(axios),
  remove: AddonService.remove(axios),
  toggleStatus: AddonService.toggleStatus(axios),
});
