import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import _ from "lodash";
import {
  MarketCodeModel,
  MarketCodeRateModel,
} from "models/Settings/Reservation/SegmentsAndMarketCodes/MarketCodeModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";
import { sprintf } from "sprintf-js";

type Params = {
  segmentid?: number;
  search?:
    | [
        {
          value: string;
          operator: string;
          column: string;
        }
      ]
    | undefined;
  limit?: number;
  offset?: number;
  sort?: string;
};

export const remove =
  (axios: AxiosInstance) =>
  async (id: number): Promise<MarketCodeModel> => {
    return await axios.delete(
      `${SettingsEndpoint.SETTINGS_RESERVATION_SEGMENT_MARKET_CODE}/${id}`
    );
  };

export const getAll =
  (axios: AxiosInstance) =>
  async (
    params?: FetchingRequestParamsModels | undefined
  ): Promise<MarketCodeModel[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_RESERVATION_SEGMENT_MARKET_CODE, {
        params,
      })
      .then(({ data }) => data.items);
  };

export const getByParent =
  (axios: AxiosInstance) =>
  async (params?: Params): Promise<MarketCodeModel[]> => {
    return await axios
      .get(
        sprintf(
          SettingsEndpoint.SETTINGS_RESERVATION_SEGMENT_MARKET_CODE_GET_BY_PARENT,
          params?.segmentid
        ),
        {
          params: _.omit(params, "segmentid"),
        }
      )
      .then(({ data }) => data);
  };

export const create =
  (axios: AxiosInstance) =>
  async (
    data: Omit<MarketCodeModel, "id" | "segmentMarketCode">
  ): Promise<MarketCodeModel> => {
    return await axios
      .post(SettingsEndpoint.SETTINGS_RESERVATION_SEGMENT_MARKET_CODE, data)
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (data: MarketCodeModel): Promise<MarketCodeModel> => {
    return await axios
      .put(
        `/${SettingsEndpoint.SETTINGS_RESERVATION_SEGMENT_MARKET_CODE}/${data.id}`,
        data
      )
      .then(({ data }) => data);
  };

export const toggleStatus =
  (axios: AxiosInstance) =>
  async (id: number): Promise<MarketCodeModel> => {
    return await axios
      .patch(
        `/${SettingsEndpoint.SETTINGS_RESERVATION_SEGMENT_MARKET_CODE}/${id}`
      )
      .then(({ data }) => data);
  };

export const getAllAssignedRates =
  (axios: AxiosInstance) =>
  async (
    params?: (FetchingRequestParamsModels & { marketId: number }) | undefined
  ): Promise<MarketCodeRateModel[]> => {
    return await axios
      .get(
        sprintf(
          SettingsEndpoint.SETTINGS_RESERVATION_MARKET_RATE,
          params?.marketId
        ),
        {
          params,
        }
      )
      .then(({ data }) => data.items);
  };

export const defaultRate =
  (axios: AxiosInstance) =>
  async (
    params?:
      | (FetchingRequestParamsModels & { marketId: number; rateId: number })
      | undefined
  ): Promise<MarketCodeRateModel[]> => {
    return await axios
      .patch(
        sprintf(
          SettingsEndpoint.SETTINGS_RESERVATION_MARKET_RATE_PATCH,
          params?.marketId,
          params?.rateId
        )
      )
      .then(({ data }) => data);
  };
