import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import {
  DiscountTypeApplyOnEnum,
  DiscountTypeModel,
  DiscountTypeSelectedItemModel,
} from "models/Settings/RevenuManagement/DiscountTypeModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";

export const remove =
  (axios: AxiosInstance) =>
    async (id: number): Promise<DiscountTypeModel> => {
      return await axios.delete(
        `${SettingsEndpoint.SETTINGS_REVENU_MANAGEMENT_DISCOUNT_TYPE}/${id}`
      );
    };

export const getAll =
  (axios: AxiosInstance) =>
    async (
      params?: FetchingRequestParamsModels
    ): Promise<DiscountTypeModel[]> => {
      return await axios
        .get(SettingsEndpoint.SETTINGS_REVENU_MANAGEMENT_DISCOUNT_TYPE, {
          params,
        })
        .then(({ data }) => data.items);
    };

export const search =
  (axios: AxiosInstance) =>
    async (params?: {
      type?: DiscountTypeApplyOnEnum;
      search?: string;
    }): Promise<DiscountTypeSelectedItemModel[]> => {


      return await axios
        .get(SettingsEndpoint.SETTINGS_REVENU_MANAGEMENT_DISCOUNT_TYPE_SEARCH, {
          params,
        })
        .then(({ data }) => data.items);
    };

export const create =
  (axios: AxiosInstance) =>
    async (
      data: Omit<DiscountTypeModel, "apply_on">
    ): Promise<DiscountTypeModel> => {
      return await axios
        .post(SettingsEndpoint.SETTINGS_REVENU_MANAGEMENT_DISCOUNT_TYPE, data)
        .then(({ data }) => data);
    };

export const update =
  (axios: AxiosInstance) =>
    async (
      data: Omit<DiscountTypeModel, "apply_on">
    ): Promise<DiscountTypeModel> => {
      return await axios
        .put(
          `/${SettingsEndpoint.SETTINGS_REVENU_MANAGEMENT_DISCOUNT_TYPE}/${data.id}`,
          data
        )
        .then(({ data }) => data);
    };

export const toggleStatus =
  (axios: AxiosInstance) =>
    async (id: number): Promise<DiscountTypeModel> => {
      return await axios
        .patch(
          `/${SettingsEndpoint.SETTINGS_REVENU_MANAGEMENT_DISCOUNT_TYPE}/${id}`
        )
        .then(({ data }) => data);
    };

export const getOne =
  (axios: AxiosInstance) =>
    async (id: number): Promise<DiscountTypeModel> => {
      return await axios
        .get(
          `/${SettingsEndpoint.SETTINGS_REVENU_MANAGEMENT_DISCOUNT_TYPE}/${id}`
        )
        .then(({ data }) => data);
    };
