import { ReservationsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import ReservationRoomRateActionModel, {
  ReservationRoomRateActionFormModel,
  ReservationRoomRateActionListModel,
} from "models/Reservations/ReservationRoomRateAction";
import { sprintf } from "sprintf-js";

export const getByRoomRate =
  (axios: AxiosInstance) =>
  async (
    reservationId: number,
    roomRateId: number
  ): Promise<ReservationRoomRateActionListModel[]> => {
    return await axios
      .get(
        sprintf(
          ReservationsEndpoint.ROOM_RATE_ACTION_GET,
          reservationId,
          roomRateId
        )
      )
      .then(({ data }) => data.items);
  };

export const create =
  (axios: AxiosInstance) =>
  async (item: {
    reservationId: number;
    roomRateId: number;
    data: ReservationRoomRateActionFormModel;
  }): Promise<ReservationRoomRateActionModel> => {
    return await axios
      .post(
        sprintf(
          ReservationsEndpoint.ROOM_RATE_ACTION_CREATE,
          item.reservationId,
          item.roomRateId
        ),
        item.data
      )
      .then(({ data }) => data);
  };

export const remove =
  (axios: AxiosInstance) =>
  async (data: {
    reservationId: number;
    roomRateId: number;
    actionId: number;
  }): Promise<any> => {
    return await axios
      .delete(
        sprintf(
          ReservationsEndpoint.ROOM_RATE_ACTION_REMOVE,
          data.reservationId,
          data.roomRateId,
          data.actionId
        )
      )
      .then(({ data }) => data);
  };
