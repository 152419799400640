// import { ReservationsEndpoint } from "@constants/ApiEndpoints";
// import { AxiosInstance } from "axios";
// import ReservationRoomRateNoteModel, {
//   ReservationRoomRateNoteFormModel,
// } from "models/Reservations/ReservationRoomRateNote";
// import { sprintf } from "sprintf-js";

// export const getAll =
//   (axios: AxiosInstance) =>
//   async (id: number): Promise<ReservationRoomRateNoteModel[]> => {
//     return await axios
//       .get(sprintf(ReservationsEndpoint.RESERVATION_NOTES_GET_ALL, id))
//       .then(({ data }) => data.items);
//   };

// export const create =
//   (axios: AxiosInstance) =>
//   async (item: {
//     id: number;
//     data: ReservationRoomRateNoteFormModel;
//   }): Promise<ReservationRoomRateNoteModel[]> => {
//     return await axios
//       .post(
//         sprintf(ReservationsEndpoint.RESERVATION_NOTES_CREATE, item.id),
//         item.data
//       )
//       .then(({ data }) => data);
//   };

import { ReservationsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { ArrivalListModel, NoteModel } from "models/FrontDesk/ArrivalModel";
import { sprintf } from "sprintf-js";

export const getAll =
  (axios: AxiosInstance) =>
  async (params: any): Promise<Array<NoteModel>> => {
    /* To update notes model */
    return await axios
      .get(
        sprintf(
          ReservationsEndpoint.RESERVATION_ARRIVAL_NOTES,
          params?.data?.id!
        ),
        { params: params?.filters }
      )
      .then(({ data }) => data.items);
  };

export const create =
  (axios: AxiosInstance) =>
  async (item: { id: number; data: NoteModel }): Promise<NoteModel> => {
    return await axios
      .post(
        sprintf(ReservationsEndpoint.RESERVATION_NOTES_GET_ALL, item?.id),
        item?.data
      )
      .then(({ data }) => data);
  };
export const update =
  (axios: AxiosInstance) =>
  async (item: { id: number; data: NoteModel }): Promise<NoteModel> => {
    return await axios
      .put(
        sprintf(
          ReservationsEndpoint.RESERVATION_NOTES_REMOVE_UPDATE,
          item?.id,
          item?.data?.id
        ),
        item?.data
      )
      .then(({ data }) => data);
  };

export const remove =
  (axios: AxiosInstance) =>
  async (data: {
    reservationId: number;
    noteId: number;
  }): Promise<ArrivalListModel> => {
    return await axios
      .delete(
        sprintf(
          ReservationsEndpoint.RESERVATION_NOTES_REMOVE_UPDATE,
          data?.reservationId,
          data?.noteId
        )
      )
      .then(({ data }) => data);
  };
