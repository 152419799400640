import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { RangeTemplateDependencyModel } from "models/Settings/RatesAndPromo/RangeTemplateDependencyModel";
import { RangeTemplateFormModel } from "models/Settings/RatesAndPromo/RangeTemplateModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";

export const remove =
  (axios: AxiosInstance) =>
  async (id: number): Promise<RangeTemplateFormModel> => {
    return await axios.delete(
      `${SettingsEndpoint.SETTINGS_RATES_RANGE_TEMPLATE}/${id}`
    );
  };

export const getAll =
  (axios: AxiosInstance) =>
  async (
    params?: FetchingRequestParamsModels
  ): Promise<RangeTemplateFormModel[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_RATES_RANGE_TEMPLATE, {
        params,
      })
      .then(({ data }) => data.items);
  };

export const getDependencies =
  (axios: AxiosInstance) =>
  async (
    params?: FetchingRequestParamsModels
  ): Promise<RangeTemplateDependencyModel> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_RATES_RANGE_TEMPLATE_DEPENDENCY, {
        params,
      })
      .then(({ data }) => data);
  };

export const getById =
  (axios: AxiosInstance) =>
  async (id?: number): Promise<RangeTemplateFormModel> => {
    return await axios
      .get(`/${SettingsEndpoint.SETTINGS_RATES_RANGE_TEMPLATE}/${id}`)
      .then(({ data }) => data);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: RangeTemplateFormModel): Promise<RangeTemplateFormModel> => {
    return await axios
      .post(SettingsEndpoint.SETTINGS_RATES_RANGE_TEMPLATE, data)
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (data: RangeTemplateFormModel): Promise<RangeTemplateFormModel> => {
    return await axios
      .put(
        `/${SettingsEndpoint.SETTINGS_RATES_RANGE_TEMPLATE}/${data.id}`,
        data
      )
      .then(({ data }) => data);
  };

export const updateIsActive =
  (axios: AxiosInstance) =>
  async (data: RangeTemplateFormModel): Promise<RangeTemplateFormModel> => {
    return await axios
      .patch(
        `/${SettingsEndpoint.SETTINGS_RATES_RANGE_TEMPLATE}/${data.id}`,
        data
      )
      .then(({ data }) => data);
  };
