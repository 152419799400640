import * as React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const SearchIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M11 2a9 9 0 0 1 7.032 14.617l3.675 3.676a1 1 0 0 1-1.414 1.414l-3.676-3.675A9 9 0 1 1 11 2zm0 2a7 7 0 1 0 4.865 12.033.732.732 0 0 1 .078-.09l.09-.078A7 7 0 0 0 11 4z"
        fill="#939EAF"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};
