import { Components, Theme } from "@mui/material";

export const MuiFilledInput: Components<
  Omit<Theme, "components">
>["MuiFilledInput"] = {
  defaultProps: {
    disableUnderline: true,
  },
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      padding: 0,
      backgroundColor: theme.palette.common.white,
      boxShadow: `inset 0 -1.5px 0 0 ${theme.palette.colors.gray.o40}`,

      "&:hover": {
        backgroundColor: theme.palette.common.white,
      },
      ".background > &": {
        backgroundColor: theme.palette.colors.gray.o10,
      },

      "&.MuiInputBase-colorPrimary": {
        ...(!ownerState.error &&
          !ownerState.disabled && {
            "&:hover": {
              backgroundColor: theme.palette.common.white,

              boxShadow: `inset 0 -2px 0 0 ${theme.palette.colors.purple.o70}`,
              ".background > &": {
                backgroundColor: theme.palette.colors.gray.o10,
              },
            },

            "&.Mui-focused": {
              boxShadow: `inset 0px 0px 0px 2px ${theme.palette.colors.purple.o70}`,
              backgroundColor: theme.palette.common.white,
              ".background > &": {
                backgroundColor: theme.palette.colors.gray.o10,
              },
            },
          }),
      },

      "&.Mui-error": {
        boxShadow: `inset 0 -1.5px 0 0 ${theme.palette.error.main}`,
        backgroundColor: theme.palette.common.white,
        ".background > &": {
          backgroundColor: theme.palette.colors.gray.o10,
        },
      },

      ...{ "&.MuiInputLabel-root": { color: "red" } },
    }),
  },
};
