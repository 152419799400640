import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const CloseIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M6.707 5.293 12 10.585l5.293-5.292a1 1 0 0 1 1.414 1.414L13.415 12l5.292 5.293a1 1 0 0 1-1.414 1.414L12 13.415l-5.293 5.292a1 1 0 1 1-1.414-1.414L10.585 12 5.293 6.707a1 1 0 0 1 1.414-1.414z"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};
