import {
  CardServiceModel,
  initCardService,
} from "api/Services/RevenueManagement/Card";
import { AxiosInstance } from "axios";
import { PromoServiceModel, initPromoService } from "./Promo/PromoService";
import { RateServiceModel, initRateService } from "./Rate";
import { RakManagerServiceModel, initRakManagerService } from "./RakManager";

export interface HotelRevenueManagementServiceModel {
  rate: RateServiceModel;
  promo: PromoServiceModel;
  card: CardServiceModel;
  rak: RakManagerServiceModel;
}

export const initHotelRevenueManagementService = (
  axios: AxiosInstance
): HotelRevenueManagementServiceModel => ({
  rate: initRateService(axios),
  rak: initRakManagerService(axios),
  promo: initPromoService(axios),
  card: initCardService(axios),
});
