import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { ReservationStateModel } from "models/Settings/Reservation/ReservationState/ReservationStateModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";

export const remove =
  (axios: AxiosInstance) =>
  async (id: number): Promise<ReservationStateModel> => {
    return await axios.delete(
      `${SettingsEndpoint.SETTINGS_RESERVATION_ROOT_ENDPOINT_STATE}/${id}`
    );
  };

export const getAll =
  (axios: AxiosInstance) =>
  async (
    params?: FetchingRequestParamsModels
  ): Promise<ReservationStateModel[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_RESERVATION_ROOT_ENDPOINT_STATE, {
        params,
      })
      .then(({ data }) => data.items);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: ReservationStateModel): Promise<ReservationStateModel> => {
    return await axios
      .post(SettingsEndpoint.SETTINGS_RESERVATION_ROOT_ENDPOINT_STATE, data)
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (data: ReservationStateModel): Promise<ReservationStateModel> => {
    return await axios
      .put(
        `${SettingsEndpoint.SETTINGS_RESERVATION_ROOT_ENDPOINT_STATE}/${data.id}`,
        data
      )
      .then(({ data }) => data);
  };

export const toggleStatus =
  (axios: AxiosInstance) =>
  async (id: number): Promise<ReservationStateModel> => {
    return await axios
      .patch(`${SettingsEndpoint.SETTINGS_RESERVATION_ROOT_ENDPOINT_STATE}/${id}`)
      .then(({ data }) => data);
  };
