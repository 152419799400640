import { AxiosInstance } from "axios";

export interface WhoamiHotelModel {
  name: string;
  url: string;
  image: string | undefined;
  id: number;
}

export interface WhoamiHotelModel {
  first_name: string;
  last_name: string;
  email: string;
  avatar: string | undefined;
  id: number;
}

export interface WhoamiModel {
  chain: string;
  hotels: WhoamiHotelModel[];
  user: WhoamiHotelModel;
  token: string | null;
}

export const whoami =
  (axios: AxiosInstance) => async (): Promise<WhoamiModel | null> => {
    return await axios.get("/whoami");
  };
