import { AxiosInstance } from "axios";
import { RateCardService } from "services/RevenueManagement/Rate";

export interface RateCardServiceModel {
  create: ReturnType<typeof RateCardService.create>;
  getAll: ReturnType<typeof RateCardService.getAll>;
  getAllByRateId: ReturnType<typeof RateCardService.getAllByRateId>;
}

export const initRateCardService = (
  axios: AxiosInstance
): RateCardServiceModel => ({
  create: RateCardService.create(axios),
  getAll: RateCardService.getAll(axios),
  getAllByRateId: RateCardService.getAllByRateId(axios),
});
