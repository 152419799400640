import { AxiosInstance } from "axios";
import { LogbookServiceModel, initLogbookService } from "./logbook/CurrencyService";

export interface WidgetsServiceModel {
  logbook: LogbookServiceModel;
}

export const initWidgetsService = (
  axios: AxiosInstance
): WidgetsServiceModel => ({
  logbook: initLogbookService(axios)
});
