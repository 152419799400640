import { AxiosInstance } from "axios";
import { RoleServiceModel, initRoleService } from "./RoleService";
import { UserServiceModel, initUserService } from "./UserService";
import {
  PermissionServiceModel,
  initPermissionService,
} from "./permissionService";

export interface AclServiceModel {
  permission: PermissionServiceModel;
  user: UserServiceModel;
  role: RoleServiceModel;
}

export const initAclService = (axios: AxiosInstance): AclServiceModel => ({
  permission: initPermissionService(axios),
  user: initUserService(axios),
  role: initRoleService(axios),
});
