import { AxiosInstance } from "axios";

import { ReservationsGuestService, initReservationsGuestService } from "./GuestService";

export interface GuestServices {
  guest: ReservationsGuestService;
}

export const initGuestServices = (
  axios: AxiosInstance
): GuestServices => ({
  guest: initReservationsGuestService(axios),
});
