import { AxiosInstance } from "axios";
import { OriginOfBookingService } from "services";

export interface OriginOfBookingServiceModel {
  getAll: ReturnType<typeof OriginOfBookingService.getAll>;
  create: ReturnType<typeof OriginOfBookingService.create>;
  update: ReturnType<typeof OriginOfBookingService.update>;
  remove: ReturnType<typeof OriginOfBookingService.remove>;
  toggleStatus: ReturnType<typeof OriginOfBookingService.toggleStatus>;
}

export const initOriginOfBookingService = (
  axios: AxiosInstance
): OriginOfBookingServiceModel => ({
  getAll: OriginOfBookingService.getAll(axios),
  create: OriginOfBookingService.create(axios),
  update: OriginOfBookingService.update(axios),
  remove: OriginOfBookingService.remove(axios),
  toggleStatus: OriginOfBookingService.toggleStatus(axios),
});
