import { AxiosInstance } from "axios";
import { RestrictionService } from "services";

export interface RestrictionServiceModel {
  getAll: ReturnType<typeof RestrictionService.getAll>;
  create: ReturnType<typeof RestrictionService.create>;
  remove: ReturnType<typeof RestrictionService.remove>;
}

export const initRestrictionService = (
  axios: AxiosInstance
): RestrictionServiceModel => ({
  getAll: RestrictionService.getAll(axios),
  create: RestrictionService.create(axios),
  remove: RestrictionService.remove(axios),
});
