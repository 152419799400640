import { AxiosInstance } from "axios";
import { DepositRequestService } from "services";

export interface DepositRequestServiceModel {
  getAll: ReturnType<typeof DepositRequestService.getAll>;
  create: ReturnType<typeof DepositRequestService.create>;
  update: ReturnType<typeof DepositRequestService.update>;
  remove: ReturnType<typeof DepositRequestService.remove>;
  toggleStatus: ReturnType<typeof DepositRequestService.toggleStatus>;
}

export const initDepositRequestService = (
  axios: AxiosInstance
): DepositRequestServiceModel => ({
  getAll: DepositRequestService.getAll(axios),
  create: DepositRequestService.create(axios),
  update: DepositRequestService.update(axios),
  remove: DepositRequestService.remove(axios),
  toggleStatus: DepositRequestService.toggleStatus(axios),
});
