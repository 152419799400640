import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import HouseKeepingStateModel from "models/Settings/HouseKeeping/HouseKeepingState";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";
import { sprintf } from "sprintf-js";

export const getAll =
  (axios: AxiosInstance) =>
  async (
    params?: FetchingRequestParamsModels
  ): Promise<HouseKeepingStateModel[]> => {
    return axios
      .get(SettingsEndpoint.SETTINGS_APPLICATION_HOUSE_KEEPING_CONFIG, {
        params,
      })
      .then(({ data }) => data?.items);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: HouseKeepingStateModel): Promise<HouseKeepingStateModel> => {
    return await axios
      .post(SettingsEndpoint.SETTINGS_APPLICATION_HOUSE_KEEPING_CONFIG, {
        ...data,
        order: Number(data.order),
      })
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (data: HouseKeepingStateModel): Promise<HouseKeepingStateModel> => {
    return await axios
      .put(
        sprintf(
          SettingsEndpoint.SETTINGS_APPLICATION_HOUSE_KEEPING_CONFIG_ID,
          data.id
        ),
        { ...data, order: Number(data.order) }
      )
      .then(({ data }) => data);
  };

export const patch =
  (axios: AxiosInstance) =>
  async (
    data: Partial<HouseKeepingStateModel>
  ): Promise<HouseKeepingStateModel> => {
    return await axios
      .patch(
        sprintf(
          SettingsEndpoint.SETTINGS_APPLICATION_HOUSE_KEEPING_CONFIG_ID,
          data?.id!
        ),
        data
      )
      .then(({ data }) => data);
  };

export const remove =
  (axios: AxiosInstance) =>
  async (id: number | string): Promise<HouseKeepingStateModel> => {
    return await axios
      .delete(
        sprintf(
          SettingsEndpoint.SETTINGS_APPLICATION_HOUSE_KEEPING_CONFIG_ID,
          id
        )
      )
      .then(({ data }) => data);
  };
