import { AxiosInstance } from "axios";
import { BoardSettingsService } from "services";

export interface BoardSettingsModel {
  getAll: ReturnType<typeof BoardSettingsService.getAll>;
  getById: ReturnType<typeof BoardSettingsService.getById>;
  create: ReturnType<typeof BoardSettingsService.create>;
  update: ReturnType<typeof BoardSettingsService.update>;
  remove: ReturnType<typeof BoardSettingsService.remove>;
  toggleStatus: ReturnType<typeof BoardSettingsService.toggleStatus>;
}

export const initBoardSettings = (
  axios: AxiosInstance
): BoardSettingsModel => ({
  getAll: BoardSettingsService.getAll(axios),
  create: BoardSettingsService.create(axios),
  update: BoardSettingsService.update(axios),
  remove: BoardSettingsService.remove(axios),
  toggleStatus: BoardSettingsService.toggleStatus(axios),
  getById: BoardSettingsService.getById(axios),
});
