import { AxiosInstance } from "axios";
import { CardPaymentMethodService } from "services/RevenueManagement/Card";

export interface CardPaymentMethodServiceModel {
  create: ReturnType<typeof CardPaymentMethodService.create>;
  getByCard: ReturnType<typeof CardPaymentMethodService.getByCard>;
  remove: ReturnType<typeof CardPaymentMethodService.remove>;
}

export const initCardPaymentMethodService = (
  axios: AxiosInstance
): CardPaymentMethodServiceModel => ({
  create: CardPaymentMethodService.create(axios),
  getByCard: CardPaymentMethodService.getByCard(axios),
  remove: CardPaymentMethodService.remove(axios),
});
