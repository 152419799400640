import { DashboardEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import DashboardModel from "models/Dashboard";

export const getAll =
  (axios: AxiosInstance) => async (): Promise<DashboardModel> => {
    return await axios
      .get(DashboardEndpoint.DASHBOARD_GET_ALL)
      .then(({ data }) => data);
  };
