import { AxiosInstance } from "axios";
import { CardBilling } from "services/RevenueManagement/Card";

export interface CardBillingModel {
  getByCard: ReturnType<typeof CardBilling.getByCard>;
  getOne: ReturnType<typeof CardBilling.getOne>;
  create: ReturnType<typeof CardBilling.create>;
  update: ReturnType<typeof CardBilling.update>;
  remove: ReturnType<typeof CardBilling.remove>;
  getRouteBtId: ReturnType<typeof CardBilling.getRouteById>;
}

export const initCardBilling = (axios: AxiosInstance): CardBillingModel => ({
  getByCard: CardBilling.getByCard(axios),
  getOne: CardBilling.getOne(axios),
  create: CardBilling.create(axios),
  update: CardBilling.update(axios),
  remove: CardBilling.remove(axios),
  getRouteBtId: CardBilling.getRouteById(axios),
});
