import { CardsEndpoint } from "@constants/ApiEndpoints";
import Api from "utils/httpClient";

export type CardGroupType = {
  id: string;
  name: string;
  is_active: boolean;
};

export const getCardGroupsService = async (): Promise<Array<CardGroupType>> => {
  return await Api.get(CardsEndpoint.CARD_GROUP_GET_ALL).then(
    ({ data }) => data.items
  );
};
