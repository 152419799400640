import { AxiosInstance } from "axios";
import { CashierRootServices } from "services";

export interface CashierOtherServiceModel {
  update: ReturnType<typeof CashierRootServices.update>;
  create: ReturnType<typeof CashierRootServices.createOther>;
}

export const initCashierOtherService = (
  axios: AxiosInstance
): CashierOtherServiceModel => ({
  update: CashierRootServices.update(axios),
  create: CashierRootServices.createOther(axios),
});
