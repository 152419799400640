import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { RestrictionModel } from "models/Settings/RatesAndPromo/RestrictionsModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";

export const remove =
  (axios: AxiosInstance) =>
  async (id: number): Promise<RestrictionModel> => {
    return await axios.delete(
      `${SettingsEndpoint.SETTINGS_RATES_RESTRICTION}/${id}`
    );
  };

export const getAll =
  (axios: AxiosInstance) =>
  async (params?: FetchingRequestParamsModels): Promise<RestrictionModel> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_RATES_RESTRICTION, {
        params,
      })
      .then(({ data }) => data);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: RestrictionModel): Promise<RestrictionModel> => {
    return await axios
      .post(SettingsEndpoint.SETTINGS_RATES_RESTRICTION, data)
      .then(({ data }) => data);
  };
