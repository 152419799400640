import { AxiosInstance } from "axios";
import { CardContactService } from "services/RevenueManagement/Card";

export interface CardContactServiceModel {
  create: ReturnType<typeof CardContactService.create>;
  getAll: ReturnType<typeof CardContactService.getAll>;
  getByCard: ReturnType<typeof CardContactService.getByCard>;
  update: ReturnType<typeof CardContactService.update>;
  remove: ReturnType<typeof CardContactService.remove>;
}

export const initCardContactService = (
  axios: AxiosInstance
): CardContactServiceModel => ({
  create: CardContactService.create(axios),
  getAll: CardContactService.getAll(axios),
  getByCard: CardContactService.getByCard(axios),
  update: CardContactService.update(axios),
  remove: CardContactService.remove(axios),
});
