import { AxiosInstance } from "axios";
import { GroupService } from "services";

export interface GroupServiceModel {
  getAll: ReturnType<typeof GroupService.getAll>;
  create: ReturnType<typeof GroupService.create>;
  update: ReturnType<typeof GroupService.update>;
  remove: ReturnType<typeof GroupService.remove>;
  toggleStatus: ReturnType<typeof GroupService.toggleStatus>;
  getAllAssignedRates: ReturnType<typeof GroupService.getAllAssignedRates>;
  defaultRate: ReturnType<typeof GroupService.defaultRate>;
}

export const initGroupService = (axios: AxiosInstance): GroupServiceModel => ({
  getAll: GroupService.getAll(axios),
  create: GroupService.create(axios),
  update: GroupService.update(axios),
  remove: GroupService.remove(axios),
  toggleStatus: GroupService.toggleStatus(axios),
  getAllAssignedRates: GroupService.getAllAssignedRates(axios),
  defaultRate: GroupService.defaultRate(axios),
});
