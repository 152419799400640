import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const TrashIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 1a3 3 0 0 1 3 3l-.001.999L21 5a1 1 0 0 1 0 2l-1.001-.001L20 20a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3L3.999 6.999 3 7a1 1 0 1 1 0-2l3.999-.001L7 4a3 3 0 0 1 3-3h4zM7.982 6.999H5.999L6 20a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1l-.001-13.001h-1.982L16 7l-.018-.001H8.017L8 7l-.018-.001zM10 10a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0v-6a1 1 0 0 1 1-1zm4 0a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0v-6a1 1 0 0 1 1-1zm0-7h-4a1 1 0 0 0-1 1l-.001.999h6L15 4a1 1 0 0 0-1-1z"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};
