import { useTranslation } from "react-i18next";
import * as yup from "yup";

export default function useSchema() {
  const { t } = useTranslation("validation");

  const schema = yup.object().shape({
    currency_id: yup.number().nullable().required(t("required")),
    from_date: yup.date().nullable().required(t("required")),
    rate: yup.number().nullable().required(t("required")),
    is_active: yup.boolean(),
  });

  return schema;
}
