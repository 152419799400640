import { AxiosInstance } from "axios";
import {
  RoomsAndRatesPreferencesServiceModel,
  initRoomsAndRatesPreferencesService,
} from "./PreferencesService";
import {
  RoomsAndRatesServiceModel,
  initRoomsAndRatesService,
} from "./RoomsAndRatesService";
import {
  RoomsAndRatesNotesServiceModel,
  initRoomsAndRatesNotesService,
} from "./NotesService";
import {
  RoomsAndRatesActionsServiceModel,
  initRoomsAndRatesActionsService,
} from "./ActionsService";
import {
  RoomsAndRatesAddonsServiceModel,
  initRoomsAndRatesAddonsService,
} from "./AddonsService";

export interface ReservationRoomsAndRatesService {
  roomsAndRates: RoomsAndRatesServiceModel;
  preferences: RoomsAndRatesPreferencesServiceModel;
  notes: RoomsAndRatesNotesServiceModel;
  actions: RoomsAndRatesActionsServiceModel;
  addons: RoomsAndRatesAddonsServiceModel;
}

export const initReservationRoomsAndRatesService = (
  axios: AxiosInstance
): ReservationRoomsAndRatesService => ({
  roomsAndRates: initRoomsAndRatesService(axios),
  preferences: initRoomsAndRatesPreferencesService(axios),
  notes: initRoomsAndRatesNotesService(axios),
  actions: initRoomsAndRatesActionsService(axios),
  addons: initRoomsAndRatesAddonsService(axios),
});
