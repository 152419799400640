import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { CurrencyModel } from "models/Settings/Financial/CurrencyModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";

export const remove =
  (axios: AxiosInstance) =>
  async (id: number): Promise<CurrencyModel> => {
    return await axios.delete(
      `${SettingsEndpoint.SETTINGS_FINANCIAL_CURRENCY}/${id}`
    );
  };

export const getAll =
  (axios: AxiosInstance) =>
  async (params?: FetchingRequestParamsModels): Promise<CurrencyModel[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_FINANCIAL_CURRENCY, {
        params,
      })
      .then(({ data }) => data.items);
  };
export const create =
  (axios: AxiosInstance) =>
  async (data: CurrencyModel): Promise<CurrencyModel> => {
    return await axios
      .post(SettingsEndpoint.SETTINGS_FINANCIAL_CURRENCY, data)
      .then(({ data }) => data);
  };
export const update =
  (axios: AxiosInstance) =>
  async (data: CurrencyModel): Promise<CurrencyModel> => {
    return await axios
      .put(`/${SettingsEndpoint.SETTINGS_FINANCIAL_CURRENCY}/${data.id}`, data)
      .then(({ data }) => data);
  };

export const toggleStatus =
  (axios: AxiosInstance) =>
  async (id: number): Promise<CurrencyModel> => {
    return await axios
      .patch(`/${SettingsEndpoint.SETTINGS_FINANCIAL_CURRENCY}/${id}`)
      .then(({ data }) => data);
  };
