import { Components, Theme } from "@mui/material";

export const MuiDialogActions: Components<
  Omit<Theme, "components">
>["MuiDialogActions"] = {
  styleOverrides: {
    root: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 0,
    },
  },
};
