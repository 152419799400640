import { AxiosInstance } from "axios";
import { ChargeCodeService } from "services";

export interface ChargeCodeServiceModel {
  getAll: ReturnType<typeof ChargeCodeService.getAll>;
  getById: ReturnType<typeof ChargeCodeService.getById>;
  create: ReturnType<typeof ChargeCodeService.create>;
  update: ReturnType<typeof ChargeCodeService.update>;
  remove: ReturnType<typeof ChargeCodeService.remove>;
  toggleStatus: ReturnType<typeof ChargeCodeService.toggleStatus>;
}

export const initChargeCodeService = (
  axios: AxiosInstance
): ChargeCodeServiceModel => ({
  getAll: ChargeCodeService.getAll(axios),
  getById: ChargeCodeService.getById(axios),
  create: ChargeCodeService.create(axios),
  update: ChargeCodeService.update(axios),
  remove: ChargeCodeService.remove(axios),
  toggleStatus: ChargeCodeService.toggleStatus(axios),
});
