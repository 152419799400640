import { AxiosInstance } from "axios";
import { SecurityService } from "services";

export interface SecurityServiceModel {
  getAll: ReturnType<typeof SecurityService.getAll>;
  create: ReturnType<typeof SecurityService.create>;
}

export const initSecurityService = (
  axios: AxiosInstance
): SecurityServiceModel => ({
  getAll: SecurityService.getAll(axios),
  create: SecurityService.create(axios),
});
