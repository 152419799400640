import { AxiosInstance } from "axios";
import { ReservationProof } from "services/Reservations";

export interface ReservationProofServiceModel {
    getAll: ReturnType<typeof ReservationProof.getAll>;
    create: ReturnType<typeof ReservationProof.create>;
    remove: ReturnType<typeof ReservationProof.remove>;
    download: ReturnType<typeof ReservationProof.download>;
    upload: ReturnType<typeof ReservationProof.upload>;
}

export const initReservationProofService = (
    axios: AxiosInstance
): ReservationProofServiceModel => ({
    getAll: ReservationProof.getAll(axios),
    create: ReservationProof.create(axios),
    remove: ReservationProof.remove(axios),
    download: ReservationProof.download(axios),
    upload: ReservationProof.upload(axios),
});
