import { SETTINGS_PATH } from "./RootPaths";

export const SETTINGS_HOTEL_PATH = `${SETTINGS_PATH}/hotel`;
export const SETTINGS_PROPERTY_STAF_PATH = `${SETTINGS_PATH}/property-staf`;
export const SETTINGS_RESERVATION_PATH = `${SETTINGS_PATH}/reservation`;
export const SETTINGS_ROOMS_PATH = `${SETTINGS_PATH}/rooms`;
export const SETTINGS_FINANCIAL_PATH = `${SETTINGS_PATH}/financial`;
export const SETTINGS_REVENU_MANAGEMENT_PATH = `${SETTINGS_PATH}/revenu-management`;
export const SETTINGS_HOUSE_KEEPING_PATH = `${SETTINGS_PATH}/house-keeping`;
export const SETTINGS_MAINTENANCE_PATH = `${SETTINGS_PATH}/maintenance`;
export const SETTINGS_TRANSFER_EXCURSION_PATH = `${SETTINGS_PATH}/transfer-excursion`;
export const SETTINGS_RATE_PROMO_PATH = `${SETTINGS_PATH}/rate-promo`;
export const SETTINGS_CARDS_PATH = `${SETTINGS_PATH}/cards`;
export const SETTINGS_APPLICATION_SETTINGS_PATH = `${SETTINGS_PATH}/application-settings`;
export const SETTINGS_ACL_PATH = `${SETTINGS_PATH}/acl`;

// SETTINGS_FINANCIAL_PATH
// SETTINGS_REVENU_MANAGEMENT_PATH
// SETTINGS_HOUSE_KEEPING_PATH
// SETTINGS_CARDS_PATH
// SETTINGS_APPLICATION_SETTINGS_PATH

// SETTINGS_RESERVATION_PATHS
export const SETTINGS_RESERVATION_SEGMENT_AND_MARKET_CODE_PATH = `${SETTINGS_RESERVATION_PATH}/segment-and-market-codes`;
export const SETTINGS_RESERVATION_SOURCE = `${SETTINGS_RESERVATION_PATH}/sources`;
export const SETTINGS_RESERVATION_RESERVATION_STATES = `${SETTINGS_RESERVATION_PATH}/reservation-states`;
export const SETTINGS_RESERVATION_AEROPORTS = `${SETTINGS_RESERVATION_PATH}/aeroports`;
export const SETTINGS_RESERVATION_VIP_CATEGORY = `${SETTINGS_RESERVATION_PATH}/vip-category`;
export const SETTINGS_RESERVATION_ROOM_LIKE_AND_FEATURE = `${SETTINGS_RESERVATION_PATH}/room_like_and_feature`;
export const SETTINGS_RESERVATION_ORIGIN_OF_BOOKING = `${SETTINGS_RESERVATION_PATH}/origin_of_booking`;
export const SETTINGS_RESERVATION_AGE_RANGES = `${SETTINGS_RESERVATION_PATH}/age-ranges`;

// SETTINGS_RATE_PATHS
export const SETTINGS_RATE_RANGE_TEMPLATE_PATH = `${SETTINGS_RATE_PROMO_PATH}/range-template`;
export const SETTINGS_RATE_PROMOTION_RULES_PATH = `${SETTINGS_RATE_PROMO_PATH}/promotion-rules`;
export const SETTINGS_RATE_RULES_AND_RESTRICTIONS_PATH = `${SETTINGS_RATE_PROMO_PATH}/rules-and-restrictions`;
export const SETTINGS_RATE_RATE_TYPE_PATH = `${SETTINGS_RATE_PROMO_PATH}/rate-type`;
export const SETTINGS_RATE_FREQUENCY_PATH = `${SETTINGS_RATE_PROMO_PATH}/frequency`;
export const SETTINGS_RATE_SEASONS_PATH = `${SETTINGS_RATE_PROMO_PATH}/seasons`;
export const SETTINGS_RATE_BOARDS_PATH = `${SETTINGS_RATE_PROMO_PATH}/boards`;
export const SETTINGS_RATE_COMPILMENTARY_CATEGORY_PATH = `${SETTINGS_RATE_PROMO_PATH}/compilmentary-category`;
export const SETTINGS_RATE_BOARD_SERVICE_SETTINGS_PATH = `${SETTINGS_RATE_PROMO_PATH}/board-service-settings`;

// SETTINGS_ROOMS_PATH
export const SETTINGS_ROOMS_ROOM_TYPES_PATH = `${SETTINGS_ROOMS_PATH}/rooms-types`;
export const SETTINGS_ROOMS_ROOM_CATEGORIES_PATH = `${SETTINGS_ROOMS_PATH}/room-categories`;
export const SETTINGS_ROOMS_ROOM_LISTS_PATH = `${SETTINGS_ROOMS_PATH}/room-lists`;
export const SETTINGS_ROOMS_ROOM_FLOORS_PATH = `${SETTINGS_ROOMS_PATH}/room-floors`;
export const SETTINGS_ROOMS_BLOCS_PATH = `${SETTINGS_ROOMS_PATH}/blocs`;

// SETTINGS_ACL_PATH
export const SETTINGS_ACL_USERS_PATH = `${SETTINGS_ACL_PATH}/users`;
export const SETTINGS_ACL_ROLES_PATH = `${SETTINGS_ACL_PATH}/roles`;

// SETTINGS_APPLICATION_SETTINGS_PATH
export const SETTINGS_APPLICATION_SETTINGS_LANGUAGES_PATH = `${SETTINGS_APPLICATION_SETTINGS_PATH}/languages`;
export const SETTINGS_APPLICATION_SETTINGS_SECURITY_PATH = `${SETTINGS_APPLICATION_SETTINGS_PATH}/security`;
export const SETTINGS_APPLICATION_SETTINGS_INTERFACES_ACCESSIBILITY_PATH = `${SETTINGS_APPLICATION_SETTINGS_PATH}/interfaces-accessibility`;
export const SETTINGS_APPLICATION_SETTINGS_END_OF_DAY = `${SETTINGS_APPLICATION_SETTINGS_PATH}/end-of-day`;
export const SETTINGS_APPLICATION_SETTINGS_END_OF_DAY_CHECK = `${SETTINGS_APPLICATION_SETTINGS_PATH}/end-of-day-check`;

// SETTINGS_CARDS_PATH
export const SETTINGS_CARDS_COMPANY_TA_CARD_GROUP_PATH = `${SETTINGS_CARDS_PATH}/company-ta-cards-group`;
export const SETTINGS_CARDS_GUEST_CARD_FILEDS_PATH = `${SETTINGS_CARDS_PATH}/guest_card_fileds`;

// SETTINGS_FINANCIAL_PATH
export const SETTINGS_FINANCIAL_PAYMENT_METHOD_PATH = `${SETTINGS_FINANCIAL_PATH}/payment-method`;
export const SETTINGS_FINANCIAL_CURRENCY_PATH = `${SETTINGS_FINANCIAL_PATH}/currency`;
export const SETTINGS_FINANCIAL_PAYMENT_TYPE_PATH = `${SETTINGS_FINANCIAL_PATH}/payment-type`;
export const SETTINGS_FINANCIAL_CASH_OPERATION_REGISTER_PATH = `${SETTINGS_FINANCIAL_PATH}/cash-operation-register`;
export const SETTINGS_FINANCIAL_CASH_REGISTER_PATH = `${SETTINGS_FINANCIAL_PATH}/cash-register`;
export const SETTINGS_FINANCIAL_CASH_CATGEORY_PATH = `${SETTINGS_FINANCIAL_PATH}/cash-category`;
export const SETTINGS_FINANCIAL_CASHIER = `${SETTINGS_FINANCIAL_PATH}/cashier`;
export const SETTINGS_FINANCIAL_ACCOUNT_GROUP = `${SETTINGS_FINANCIAL_PATH}/account-group`;
export const SETTINGS_FINANCIAL_ACCOUNT_NUMBER = `${SETTINGS_FINANCIAL_PATH}/account-number`;

// SETTINGS_HOUSE_KEEPING_PATH
export const SETTINGS_HOUSE_KEEPING_SECTIONS_PATH = `${SETTINGS_HOUSE_KEEPING_PATH}/sections`;
export const SETTINGS_HOUSE_KEEPING_STATE_PATH = `${SETTINGS_HOUSE_KEEPING_PATH}/state`;
export const SETTINGS_HOUSE_KEEPING_HOUSEKEEPER_PATH = `${SETTINGS_HOUSE_KEEPING_PATH}/housekeeper`;
export const SETTINGS_HOUSE_KEEPING_MAINTENANCE_TYPE_PATH = `${SETTINGS_HOUSE_KEEPING_PATH}/maintenance-type`;
export const SETTINGS_HOUSE_KEEPING_WORK_TYPE_PATH = `${SETTINGS_HOUSE_KEEPING_PATH}/work-type`;
export const SETTINGS_HOUSE_KEEPING_WORK_TASK_PATH = `${SETTINGS_HOUSE_KEEPING_PATH}/work-task`;

// SETTINGS_REVENU_MANAGEMENT_PATH
export const SETTINGS_REVENU_MANAGEMENT_CHARGES_GROUP = `${SETTINGS_REVENU_MANAGEMENT_PATH}/charges-group`;
export const SETTINGS_REVENU_MANAGEMENT_CHARGE_CODE = `${SETTINGS_REVENU_MANAGEMENT_PATH}/charge-code`;
export const SETTINGS_REVENU_MANAGEMENT_ITEMS = `${SETTINGS_REVENU_MANAGEMENT_PATH}/items`;
export const SETTINGS_REVENU_MANAGEMENT_BILLING_GROUP = `${SETTINGS_REVENU_MANAGEMENT_PATH}/billing-group`;
export const SETTINGS_REVENU_MANAGEMENT_PACKAGES = `${SETTINGS_REVENU_MANAGEMENT_PATH}/packages`;
export const SETTINGS_REVENU_MANAGEMENT_ADD_ONS = `${SETTINGS_REVENU_MANAGEMENT_PATH}/add-ons`;
export const SETTINGS_REVENU_MANAGEMENT_BILLING_TYPE = `${SETTINGS_REVENU_MANAGEMENT_PATH}/billing-type`;
