import CashierRateExchangeModel from "models/Cashier/CashierRateExchange";

const CashierRateExchangeFakeData: CashierRateExchangeModel[] = [
  {
    id: 1,
    from_date: "2024-09-03",
    currency_name: "Euro",
    currency_id: 1,
    currency_unit: 1,
    is_active: true,
    rate: 3.35,
  },
  {
    id: 2,
    from_date: "2024-09-04",
    currency_name: "Dollars",
    currency_id: 2,
    currency_unit: 10,
    is_active: true,
    rate: 3.88,
  },
  {
    id: 3,
    from_date: "2024-09-05",
    currency_name: "Franc Suisse",
    currency_id: 3,
    currency_unit: 1,
    is_active: true,
    rate: 2.55,
  },
];

export default CashierRateExchangeFakeData;
