import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { BoardServiceModel } from "models/Settings/RatesAndPromo/BoardServiceModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";

export const remove =
  (axios: AxiosInstance) =>
  async (id: number): Promise<BoardServiceModel> => {
    return await axios.delete(
      `${SettingsEndpoint.SETTINGS_RATES_BOARD_SERVICE}/${id}`
    );
  };

export const getAll =
  (axios: AxiosInstance) =>
  async (
    params?: FetchingRequestParamsModels
  ): Promise<BoardServiceModel[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_RATES_BOARD_SERVICE, {
        params,
      })
      .then(({ data }) => data.items);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: BoardServiceModel): Promise<BoardServiceModel> => {
    return await axios
      .post(SettingsEndpoint.SETTINGS_RATES_BOARD_SERVICE, data)
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (data: BoardServiceModel): Promise<BoardServiceModel> => {
    return await axios
      .put(`/${SettingsEndpoint.SETTINGS_RATES_BOARD_SERVICE}/${data.id}`, data)
      .then(({ data }) => data);
  };

export const toggleStatus =
  (axios: AxiosInstance) =>
  async (id: number): Promise<BoardServiceModel> => {
    return await axios
      .patch(`/${SettingsEndpoint.SETTINGS_RATES_BOARD_SERVICE}/${id}`)
      .then(({ data }) => data);
  };
