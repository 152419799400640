import React from "react";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { isSunday } from "date-fns";

type Props = {
  isFirstDay?: boolean;
  isLastDay?: boolean;
  isPreSelected?: boolean;
  isInInterval?: boolean;
  handleChangeDateHover?: (value: Date | null) => void;
} & PickersDayProps<Date>;

const DayComponent: React.FC<Props> = ({
  day,
  isFirstDay,
  isLastDay,
  isPreSelected,
  isInInterval,
  handleChangeDateHover,
  ...pickersDayProps
}) => {
  const isSelected = isFirstDay || isLastDay;
  const _isPreSelected = isPreSelected && !isSelected && !isInInterval;
  const isWeekend = isSunday(day);

  return (
    <PickersDay
      day={day}
      sx={({ palette, typography }) => ({
        "&.MuiPickersDay-root": {
          width: "2.5rem",
          height: "2.5rem",
          mx: "0.25rem",
          "&:hover": {
            bgcolor: "colors.purple.o30",
          },
        },
        fontSize: typography.inter14.fontSize,
        fontWeight: "normal",
        color:
          pickersDayProps.outsideCurrentMonth || pickersDayProps.disabled
            ? palette.colors.gray.o30
            : palette.common.black,
        ...((pickersDayProps?.selected || isSelected) && {
          color: palette.common.white,
          fontWeight: 600,
        }),
        ...(isWeekend && { bgcolor: "colors.gray.o10" }),
        ...(isInInterval && { bgcolor: "colors.purple.o20" }),
        ...(isInInterval &&
          pickersDayProps.outsideCurrentMonth && {
            bgcolor: "colors.purple.o10",
          }),
        ...(_isPreSelected && { bgcolor: "colors.purple.o30" }),
      })}
      {...pickersDayProps}
      selected={pickersDayProps?.selected || isSelected}
      onMouseEnter={() => {
        handleChangeDateHover?.(day);
      }}
      onMouseLeave={() => {
        handleChangeDateHover?.(null);
      }}
    />
  );
};

export default DayComponent;
