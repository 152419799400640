import { AxiosInstance } from "axios";
import {
  HouseKeepingStateServicesModel,
  initCheckConfigServices,
} from "./CheckConfig/checkconfig";
import {
  HouseKeepingSectionServiceModel,
  initHouseKeepingSectionServiceModel,
} from "./Section/SectionService";
import {
  HouseKeepingHousekeeperServiceModel,
  initHouseKeepingHousekeeperServiceModel,
} from "./Housekeeper/HousekeeperService";
import {
  HouseKeepingWorkTypeServiceModel,
  initHouseKeepingWorkTypeServiceModel,
} from "./WorkType/WorkTypeService";
import {
  HouseKeepingWorkTaskServiceModel,
  initHouseKeepingWorkTaskServiceModel,
} from "./WorkTask/WorkTaskService";
import {
  HouseKeepingMaintenanceTypeServiceModel,
  initHouseKeepingMaintenanceTypeServiceModel,
} from "./MaintenanceType/MaintenanceTypeService";

export interface SettingHouseKeepingServiceModel {
  houseKeepingState: HouseKeepingStateServicesModel;
  section: HouseKeepingSectionServiceModel;
  housekeeper: HouseKeepingHousekeeperServiceModel;
  workType: HouseKeepingWorkTypeServiceModel;
  workTask: HouseKeepingWorkTaskServiceModel;
  maintenanceType: HouseKeepingMaintenanceTypeServiceModel;
}

export const initSettingHouseKeepingService = (
  axios: AxiosInstance
): SettingHouseKeepingServiceModel => ({
  houseKeepingState: initCheckConfigServices(axios),
  section: initHouseKeepingSectionServiceModel(axios),
  housekeeper: initHouseKeepingHousekeeperServiceModel(axios),
  workType: initHouseKeepingWorkTypeServiceModel(axios),
  workTask: initHouseKeepingWorkTaskServiceModel(axios),
  maintenanceType: initHouseKeepingMaintenanceTypeServiceModel(axios),
});
