import { HotelModel } from "models/Settings/HotelModel";
import { AxiosInstance } from "axios";
import { SettingsEndpoint } from "@constants/ApiEndpoints";

export const getInfos = (axios: AxiosInstance) => async () => {
  return await axios
    .get(SettingsEndpoint.SETTINGS_INFOS)
    .then(({ data }) => data);
};

export const update =
  (axios: AxiosInstance) =>
  async (formdata: HotelModel): Promise<HotelModel> => {
    return await axios
      .post(SettingsEndpoint.SETTINGS_INFOS, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => data);
  };
