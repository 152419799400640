import BillingGroupConfig from "./billing_group";
import ChargeCodeConfig from "./charge_code";
import ChargeGroupConfig from "./charge_group";
import common from "./common.json";
import ItemConfig from "./item";
import landingPage from "./landing_page.json";

const RevenuManagementConfig = {
    common,
    ItemConfig,
    ChargeCodeConfig,
    ChargeGroupConfig,
    BillingGroupConfig,
    landingPage
};

export default RevenuManagementConfig;