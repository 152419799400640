import { DATE_PICKER_FORMAT } from "@constants/date";
import {
  Box,
  ClickAwayListener,
  Divider,
  IconButton,
  Stack,
  TextFieldProps,
} from "@mui/material";
import { CalendarPickerProps as MuiCalendarPickerProps } from "@mui/x-date-pickers/CalendarPicker";
import { format } from "date-fns";
import React, {
  FC,
  RefObject,
  useCallback,
  useId,
  useMemo,
  useRef,
  useState,
} from "react";
import CalendarPicker, {
  CalendarPickerProps,
} from "./components/CalendarPicker";
import {
  CalenderPopper,
  LeftButtonIcon,
  RightButtonIcon,
} from "./components/Shared";
import useCalendarActions from "./hooks/useCalendarActions";
import useDefaultMonthCalendar from "./hooks/useDefaultMonthCalendar";

type ButtoneRef = RefObject<HTMLButtonElement> | null | undefined;
type RenderInputProps = {
  startProps: TextFieldProps;
  endProps: TextFieldProps;
};

type DateRangePickerProps = Omit<
  MuiCalendarPickerProps<Date>,
  "date" | "onChange"
> & {
  value: Array<Date | null>;
  onChange: (value: Array<Date | null>) => void;
  renderInput: (props: RenderInputProps) => React.ReactElement;
  disabledDays?: Array<Date>;
  multipleCalendars?: boolean;
};

const DateRangePicker: FC<DateRangePickerProps> = ({
  value = [null, null],
  onChange,
  renderInput,
  disabledDays,
  multipleCalendars,
  ...rest
}) => {
  const [start, end] = value;

  const [anchorEl, setAnchorEl] = useState<
    HTMLButtonElement | HTMLDivElement | null
  >(null);
  const handleOpen = (
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setFocus(null);
  };

  const [focus, setFocus] = useState<"start" | "end" | null>(null);
  const [preSelectedDate, setPreSelectedDate] = useState<Date | null>(
    new Date()
  );

  const { firstDefaultCalendarMonth, secondDefaultCalendarMonth } =
    useDefaultMonthCalendar(start);

  const handleChangeDateHover = (value: Date | null) =>
    setPreSelectedDate(value);

  const renderDateFormat = (_date: Date | null) =>
    _date ? format(_date, DATE_PICKER_FORMAT) : "";

  const { isFirstDay, isLastDay, isInInterval, isPreSelected } =
    useCalendarActions({
      start,
      end,
      preSelectedDate,
      focus,
    });

  const firstLeftButtonRef = useRef(null) as ButtoneRef;
  const firstRightButtonRef = useRef(null) as ButtoneRef;
  const secondLeftButtonRef = useRef(null) as ButtoneRef;
  const secondRightButtonRef = useRef(null) as ButtoneRef;

  const renderInputProps = (date: Date | null) => {
    const inputRef = React.createRef();
    const value = renderDateFormat(date);
    return { inputRef, value, autoComplete: "new-password" } as TextFieldProps;
  };

  const onStartFocus = () => {
    setFocus("start");
  };

  const onEndFocus = () => {
    setFocus("end");
  };

  const open = Boolean(anchorEl);

  const startProps = {
    ...renderInputProps(start),
    onFocus: onStartFocus,
    focused: open && focus === "start",
  };

  const endProps = {
    ...renderInputProps(end),
    onFocus: onEndFocus,
    focused: open && focus === "end",
  };

  const handleChange = useCallback(
    (value: Date | null) => {
      if (focus !== "end") {
        onChange?.([value, end]);
        (endProps?.inputRef as any)?.current?.focus();
        setFocus("end");
        if (end && end < value!) {
          onChange?.([value, null]);
          return;
        }
      } else {
        onChange?.([start, value]);
        if (start && start > value!) {
          onChange?.([value, null]);
          return;
        }
        if (!start) {
          (startProps?.inputRef as any)?.current?.focus();
          setFocus("start");
          return;
        }
        handleClose();
      }
    },
    [focus, start, end]
  );

  const calendarPickerProps = useMemo(
    (): CalendarPickerProps => ({
      view: "day",
      onChange: handleChange,
      handleChangeDateHover,
      isInInterval,
      isPreSelected,
      isFirstDay,
      isLastDay,
      ...rest,
      date: null,
    }),
    [
      handleChange,
      handleChangeDateHover,
      isInInterval,
      isPreSelected,
      isFirstDay,
      isLastDay,
      rest,
    ]
  );

  const popoverId = useId();

  return (
    <ClickAwayListener onClickAway={handleClose} mouseEvent={"onMouseDown"}>
      <Box>
        <Box
          onClick={handleOpen}
          sx={{
            height: "auto",
          }}
          aria-owns={open ? popoverId : undefined}
          aria-haspopup={true}
        >
          {renderInput({ startProps, endProps })}
        </Box>

        <CalenderPopper id={popoverId} open={open} anchorEl={anchorEl}>
          <Box
            sx={{
              bgcolor: "common.white",
              boxShadow: " 0 8px 16px -4px rgba(0, 0, 0, 0.1)",
            }}
          >
            {!multipleCalendars ? (
              <CalendarPicker
                {...calendarPickerProps}
                defaultCalendarMonth={firstDefaultCalendarMonth}
                componentsProps={{
                  switchViewButton: {
                    sx: { display: "none" },
                  },
                }}
                components={{
                  LeftArrowButton: (_props) => (
                    <IconButton {..._props}>
                      <LeftButtonIcon />
                    </IconButton>
                  ),
                  RightArrowButton: (_props) => (
                    <IconButton {..._props}>
                      <RightButtonIcon />
                    </IconButton>
                  ),
                }}
              />
            ) : (
              <Stack
                direction="row"
                justifyContent="center"
                divider={<Divider orientation="vertical" flexItem />}
              >
                <CalendarPicker
                  {...calendarPickerProps}
                  defaultCalendarMonth={firstDefaultCalendarMonth}
                  componentsProps={{
                    rightArrowButton: {
                      sx: { display: "none" },
                    },
                    switchViewButton: {
                      sx: { display: "none" },
                    },
                  }}
                  components={{
                    LeftArrowButton: (_props) => (
                      <IconButton
                        {..._props}
                        ref={firstLeftButtonRef}
                        onClick={() => {
                          _props?.onClick?.();
                          secondLeftButtonRef?.current?.click?.();
                        }}
                      >
                        <LeftButtonIcon />
                      </IconButton>
                    ),
                    RightArrowButton: (_props) => (
                      <IconButton {..._props} ref={firstRightButtonRef} />
                    ),
                  }}
                />
                <CalendarPicker
                  {...calendarPickerProps}
                  defaultCalendarMonth={secondDefaultCalendarMonth}
                  componentsProps={{
                    leftArrowButton: {
                      sx: { display: "none" },
                    },
                    switchViewButton: {
                      sx: { display: "none" },
                    },
                  }}
                  components={{
                    LeftArrowButton: (_props) => (
                      <IconButton {..._props} ref={secondLeftButtonRef} />
                    ),
                    RightArrowButton: (_props) => (
                      <IconButton
                        {..._props}
                        ref={secondRightButtonRef}
                        onClick={() => {
                          _props?.onClick?.();
                          firstRightButtonRef?.current?.click();
                        }}
                      >
                        <RightButtonIcon />
                      </IconButton>
                    ),
                  }}
                />
              </Stack>
            )}
          </Box>
        </CalenderPopper>
      </Box>
    </ClickAwayListener>
  );
};

export default DateRangePicker;
