import common from "./common.json"
import landingPage from "./landing_page.json"
import RoleConfig from "./roles"
import UserConfig from "./users"

const AclSettingsConfig = {
    common,
    landingPage,
    UserConfig,
    RoleConfig
}

export default AclSettingsConfig