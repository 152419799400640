import { TextField as MuiTextField, TextFieldProps } from "@mui/material";
import { FC, forwardRef } from "react";

const TextFieldNumberRight: FC<TextFieldProps> = forwardRef(
  ({ sx, ...props }, ref) => (
    <MuiTextField
      ref={ref}
      type="number"
      sx={{ ...sx, "& .MuiInputBase-input": { textAlign: "right" } }}
      {...props}
    />
  )
);

export default TextFieldNumberRight;
