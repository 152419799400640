import common from "./common.json";
import landingPage from "./landing_page.json";
import RoomCategoryConfig from "./room_category.json";
import RoomListConfig from "./room_list.json";
import RoomTypeConfig from "./room_types";

const RoomConfig = {
  common,
  landingPage,
  RoomTypeConfig,
  RoomCategoryConfig,
  RoomListConfig,
};

export default RoomConfig;
