import { AxiosInstance } from "axios";
import { ChargeGroupService } from "services";

export interface ChargeGroupServiceModel {
  getAll: ReturnType<typeof ChargeGroupService.getAll>;
  create: ReturnType<typeof ChargeGroupService.create>;
  update: ReturnType<typeof ChargeGroupService.update>;
  remove: ReturnType<typeof ChargeGroupService.remove>;
  toggleStatus: ReturnType<typeof ChargeGroupService.toggleStatus>;
}

export const initChargeGroupService = (
  axios: AxiosInstance
): ChargeGroupServiceModel => ({
  getAll: ChargeGroupService.getAll(axios),
  create: ChargeGroupService.create(axios),
  update: ChargeGroupService.update(axios),
  remove: ChargeGroupService.remove(axios),
  toggleStatus: ChargeGroupService.toggleStatus(axios),
});
