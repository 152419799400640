import { Card, CardActionArea, CardMedia } from "@mui/material";
import { FC } from "react";
import { WhoamiHotelModel } from "services/UserService";
import DefaultImage from "assets/icons/hotel/default.png";

const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
  e.currentTarget.onerror = null;
  e.currentTarget.src = DefaultImage;
};

const HotelCard: FC<{ hotel: WhoamiHotelModel }> = ({ hotel }) => {
  return (
    <Card
      elevation={0}
      sx={{
        p: 2,
        width: "12.5rem",
        height: "7.5rem",
        borderRadius: 16,

        backgroundColor: "colors.gray.o10",
        ":hover": {
          boxShadow: (theme) =>
            `inset 0px 0px 0px 2px ${theme.palette.colors.purple.o70}`,
          backgroundColor: "#fff",
        },
      }}
    >
      <CardActionArea
        onClick={() => {
          window.location.href = `${hotel.url}`;
          return null;
        }}
      >
        <CardMedia
          component="img"
          height={88}
          image={hotel.image ?? DefaultImage}
          alt={hotel.name}
          onError={handleImageError}
        />
      </CardActionArea>
    </Card>
  );
};

export default HotelCard;
