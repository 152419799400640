import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  DialogProps,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Error } from "components/Common/Error";
import {
  DialogButtonCancel,
  DialogButtonSave,
} from "components/DialognActionButtons";
import { RootDialog, RootDialogTitle } from "components/RootDialog";
import { MenuItem } from "components/styled/SelectMenuItem";
import TextFieldNumberRight from "components/TextField/TextFieldNumberRight";
import FinancialAccountNumberModel from "models/Settings/Financial/FinancialAccountNumberModel";
import { FC, useId } from "react";
import { Controller, useForm } from "react-hook-form";
import useSchema from "./useSchema";

type Props = DialogProps & {
  defaultValues: FinancialAccountNumberModel;
  mode?: "create" | "update";
  handleClose?: () => void;
  onSaveSuccess?: () => void;
};

const FinancialAccountNumberCreate: FC<Props> = ({
  defaultValues,
  handleClose,
  ...props
}) => {
  const schema = useSchema();

  const form = useForm<FinancialAccountNumberModel>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = form.handleSubmit((data) => {
    console.log("data", data);
  });

  const typeFieldId = useId();

  return (
    <RootDialog
      {...props}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "27rem",
        },
      }}
    >
      <RootDialogTitle
        titles={{
          subTitle: "Financial Account Number",
          title: "New Account Number",
        }}
        onClose={handleClose}
      />
      <Stack spacing="2rem" my="2rem">
        <Controller
          control={form.control}
          name="account_number"
          render={({ field, fieldState, formState }) => (
            <TextFieldNumberRight
              fullWidth
              label="Number"
              {...field}
              value={field.value ?? ""}
              error={fieldState.error !== undefined}
              helperText={
                fieldState.error !== undefined ? (
                  <ErrorMessage
                    errors={formState.errors}
                    name="account_number"
                    render={({ message }) => <Error>{message}</Error>}
                  />
                ) : null
              }
            />
          )}
        />

        <Controller
          control={form.control}
          name="description"
          render={({ field, fieldState, formState }) => (
            <TextField
              fullWidth
              label="Description"
              placeholder="Insert description"
              sx={{ mb: "1rem" }}
              {...field}
              value={field.value ?? ""}
              error={fieldState.error !== undefined}
              helperText={
                fieldState.error !== undefined ? (
                  <ErrorMessage
                    errors={formState.errors}
                    name="description"
                    render={({ message }) => <Error>{message}</Error>}
                  />
                ) : null
              }
            />
          )}
        />

        <Controller
          control={form.control}
          name="account_group_id"
          render={({ field, fieldState, formState }) => (
            <FormControl fullWidth error={fieldState.error !== undefined}>
              <InputLabel id={typeFieldId}>GROUP</InputLabel>
              <Select
                labelId={typeFieldId}
                {...field}
                value={field.value ?? ""}
              >
                <MenuItem firstItem value="">
                  <em>Select group...</em>
                </MenuItem>
                {([] as any).map((item: any, key: any) => (
                  <MenuItem key={key} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              {fieldState.error !== undefined ? (
                <ErrorMessage
                  errors={formState.errors}
                  name="account_group_id"
                  render={({ message }) => <Error>{message}</Error>}
                />
              ) : null}
            </FormControl>
          )}
        />

        <Controller
          control={form.control}
          name="is_active"
          render={({ field }) => (
            <FormControlLabel
              sx={{ m: 0 }}
              {...field}
              checked={field.value ?? false}
              onChange={(e, checked) => field.onChange(checked)}
              label={
                <Typography
                  variant="inter16"
                  fontWeight={500}
                  sx={{ ml: "1rem" }}
                >
                  Active
                </Typography>
              }
              control={<Switch />}
            />
          )}
        />
      </Stack>

      <Stack direction="row" spacing="1rem" justifyContent="space-between">
        <DialogButtonCancel onClick={handleClose}>CANCEL</DialogButtonCancel>
        <DialogButtonSave onClick={onSubmit}>SAVE</DialogButtonSave>
      </Stack>
    </RootDialog>
  );
};

export default FinancialAccountNumberCreate;
