import { TypographyOptions } from "@mui/material/styles/createTypography";

const FONT_FAMILY = "'Inter'";

export const typography: TypographyOptions = {
  fontFamily: FONT_FAMILY,
  htmlFontSize: 16,
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemibold: 600,
  fontWeightBold: 700,
  fontWeightExtrabold: 800,
  h1: {
    fontSize: "6rem",
    letterSpacing: "-0.01562em",
    fontWeight: 300,
    lineHeight: 1.167,
  },
  h2: {
    fontSize: "3.75rem",
    letterSpacing: "-0.00833em",
    fontWeight: 300,
    lineHeight: 1.2,
  },
  h3: {
    fontSize: "3rem",
    letterSpacing: "0em",
    fontWeight: 400,
    lineHeight: 1.167,
  },
  h4: {
    fontSize: "2.125rem",
    letterSpacing: "0em",
    fontWeight: 400,
    lineHeight: 1.235,
  },
  h5: {
    fontSize: "1.5rem",
    letterSpacing: "0em",
    lineHeight: 1.334,
    fontWeight: 400,
  },
  h6: {
    fontSize: "1.25rem",
    letterSpacing: "0.0075em",
    fontWeight: 500,
    lineHeight: 1.6,
  },
  subtitle1: {
    fontSize: "1rem",
    letterSpacing: "0.00938em",
    fontWeight: 400,
    lineHeight: 1.57,
  },
  subtitle2: {
    fontSize: "0.875rem",
    letterSpacing: "0.00714em",
    fontWeight: 500,
    lineHeight: 1.57,
  },
  body1: {
    fontSize: "1rem",
    letterSpacing: "0.00938em",
    fontWeight: 400,
    lineHeight: 1.5,
  },
  body2: {
    fontSize: "0.875rem",
    letterSpacing: "0.00107em",
    fontWeight: 400,
    lineHeight: 1.43,
  },
  button: {
    fontSize: "0.875rem",
    letterSpacing: "0.02857em",
    fontWeight: 500,
    lineHeight: 1.75,
    textTransform: "none",
  },
  caption: {
    fontSize: "0.75rem",
    letterSpacing: "0.03333em",
    fontWeight: 400,
    lineHeight: 1.66,
  },
  overline: {
    fontSize: "0.75rem",
    letterSpacing: "0.08333em",
    fontWeight: 400,
    lineHeight: 2.66,
    textTransform: "none",
  },
  inter10: {
    fontSize: "0.625rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
  },
  inter12: {
    fontSize: "0.75rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
  },
  inter14: {
    fontSize: "0.875rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
  },
  inter16: {
    fontSize: "1rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
  },
  inter18: {
    fontSize: "1.125rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
  },
  inter20: {
    fontSize: "1.25rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
  },
  inter24: {
    fontSize: "1.5rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
  },
  inter28: {
    fontSize: "1.75rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
  },
  inter32: {
    fontSize: "2rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
  },
  inter36: {
    fontSize: "2.25rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
  },
  inter42: {
    fontSize: "2.625rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
  },
  inter48: {
    fontSize: "3rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
  },
  inter54: {
    fontSize: "3.375rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
  },
  inter60: {
    fontSize: "3.75rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
  },
};
