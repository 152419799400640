import { AxiosInstance } from "axios";
import { PackageService } from "services";

export interface PackageServiceModel {
  getAll: ReturnType<typeof PackageService.getAll>;
  getById: ReturnType<typeof PackageService.getById>;
  create: ReturnType<typeof PackageService.create>;
  update: ReturnType<typeof PackageService.update>;
  remove: ReturnType<typeof PackageService.remove>;
  toggleStatus: ReturnType<typeof PackageService.toggleStatus>;
}

export const initPackageService = (
  axios: AxiosInstance
): PackageServiceModel => ({
  getAll: PackageService.getAll(axios),
  getById: PackageService.getById(axios),
  create: PackageService.create(axios),
  update: PackageService.update(axios),
  remove: PackageService.remove(axios),
  toggleStatus: PackageService.toggleStatus(axios),
});
