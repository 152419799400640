import { CardSetModel } from "models/RevenueManagement/Card/CardSetModel";
import { AxiosInstance } from "axios";
import { sprintf } from "sprintf-js";
import { CardsEndpoint } from "@constants/ApiEndpoints";

export const getAll =
  (axios: AxiosInstance) =>
  async (cardId: number): Promise<CardSetModel[]> => {
    return await axios
      .get(sprintf(CardsEndpoint.CARD_CARD_SET_GET_ALL, cardId))
      .then(({ data }) => data.items);
  };

export const getAllSearch =
  (axios: AxiosInstance) =>
  (cardId: number) =>
  async (
    params?: any
  ): Promise<{ card: CardSetModel[]; card_set: Array<CardSetModel[]> }> => {
    return await axios
      .get(sprintf(CardsEndpoint.CARD_CARD_SET_GET_ALL_SEARCH, cardId), { params })
      .then(({ data }) => data.items);
  };

export const update =
  (axios: AxiosInstance) =>
  async ({
    cardId,
    data,
  }: {
    cardId: number;
    data: { other_card_id: number };
  }) => {
    return await axios
      .put(sprintf(CardsEndpoint.CARD_CARD_SET_UPDATE, cardId), data)
      .then(({ data }) => data);
  };

export const remove = (axios: AxiosInstance) => async (cardId: number) => {
  return await axios
    .delete(sprintf(CardsEndpoint.CARD_CARD_SET_DELETE, cardId))
    .then(({ data }) => data);
};
