import { LIMIT_ITEMS_SELECT } from "@constants/Pagination";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  DialogProps,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Error } from "components/Common/Error";
import {
  DialogButtonCancel,
  DialogButtonSave,
} from "components/DialognActionButtons";
import { useRootContext } from "components/RootContainer";
import { RootDialog, RootDialogTitle } from "components/RootDialog";
import { MenuItem } from "components/styled/SelectMenuItem";
import CashierRateExchangeModel from "models/Cashier/CashierRateExchange";
import { FC, useId } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import useSchema from "./useSchema";
import TextFieldNumberRight from "components/TextField/TextFieldNumberRight";

type Props = DialogProps & {
  defaultValues: CashierRateExchangeModel;
  mode?: "create" | "update";
  handleClose?: () => void;
  onSaveSuccess?: () => void;
};

const CashierExchangeCreate: FC<Props> = ({
  defaultValues,
  handleClose,
  ...props
}) => {
  const CurrencyService = useRootContext().HotelApi.settings.financial.currency;

  const schema = useSchema();

  const form = useForm<CashierRateExchangeModel>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = form.handleSubmit((data) => {
    console.log("data", data);
  });

  const { data: currencies } = useQuery({
    queryKey: "cashier-rate-exchange-get-currencies",
    queryFn: () => CurrencyService.getAll({ limit: LIMIT_ITEMS_SELECT }),
  });

  const currencyFieldId = useId();

  return (
    <RootDialog
      {...props}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "27rem",
        },
      }}
    >
      <RootDialogTitle
        titles={{
          subTitle: "Cashier - Rate Exchange",
          title: "New Rate Exchange",
        }}
        onClose={handleClose}
      />
      <Stack spacing="2rem" my="2rem">
        <Controller
          control={form.control}
          name="currency_id"
          render={({ field, fieldState, formState }) => (
            <FormControl fullWidth error={fieldState.error !== undefined}>
              <InputLabel id={currencyFieldId}>Currency</InputLabel>
              <Select
                labelId={currencyFieldId}
                {...field}
                value={field.value ?? ""}
              >
                <MenuItem firstItem value="">
                  <em>Select type...</em>
                </MenuItem>
                {(currencies || []).map((item, key) => (
                  <MenuItem key={key} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              {fieldState.error !== undefined ? (
                <ErrorMessage
                  errors={formState.errors}
                  name="currency_id"
                  render={({ message }) => <Error>{message}</Error>}
                />
              ) : null}
            </FormControl>
          )}
        />

        <Controller
          control={form.control}
          name="from_date"
          render={({ field, fieldState, formState }) => (
            <DatePicker
              value={field.value ? new Date(field.value) : null}
              onChange={(e, newDate) => field.onChange(newDate)}
              inputFormat="dd/MM/yyyy"
              label="FROM DATE"
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  {...field}
                  value={field.value ?? ""}
                  error={fieldState.error !== undefined}
                  helperText={
                    fieldState.error !== undefined ? (
                      <ErrorMessage
                        errors={formState.errors}
                        name="from_date"
                        render={({ message }) => <Error>{message}</Error>}
                      />
                    ) : null
                  }
                />
              )}
            />
          )}
        />

        <Controller
          control={form.control}
          name="rate"
          render={({ field, fieldState, formState }) => (
            <TextFieldNumberRight
              fullWidth
              label="Rate"
              {...field}
              value={field.value ?? ""}
              error={fieldState.error !== undefined}
              helperText={
                fieldState.error !== undefined ? (
                  <ErrorMessage
                    errors={formState.errors}
                    name="rate"
                    render={({ message }) => <Error>{message}</Error>}
                  />
                ) : null
              }
            />
          )}
        />

        <Controller
          control={form.control}
          name="is_active"
          render={({ field }) => (
            <FormControlLabel
              sx={{ m: 0 }}
              {...field}
              checked={field.value ?? false}
              onChange={(e, checked) => field.onChange(checked)}
              label={
                <Typography
                  variant="inter16"
                  fontWeight={500}
                  sx={{ ml: "1rem" }}
                >
                  Active
                </Typography>
              }
              control={<Switch />}
            />
          )}
        />
      </Stack>

      <Stack direction="row" spacing="1rem" justifyContent="space-between">
        <DialogButtonCancel onClick={handleClose}>CANCEL</DialogButtonCancel>
        <DialogButtonSave onClick={onSubmit}>SAVE</DialogButtonSave>
      </Stack>
    </RootDialog>
  );
};

export default CashierExchangeCreate;
