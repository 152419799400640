import { AxiosInstance } from "axios";
import { LogBookServices } from "services/Widgets";

export interface LogbookServiceModel {
    getAll: ReturnType<typeof LogBookServices.getAll>;
    create: ReturnType<typeof LogBookServices.create>;
}

export const initLogbookService = (
    axios: AxiosInstance
): LogbookServiceModel => ({
    getAll: LogBookServices.getAll(axios),
    create: LogBookServices.create(axios),
});
