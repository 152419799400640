import { SettingsHouseKeepingEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import {
  MaintenanceTypeFormModel,
  MaintenanceTypeModel,
} from "models/Settings/HouseKeeping/MaintenanceTypeModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";
import { sprintf } from "sprintf-js";

export const getAll =
  (axios: AxiosInstance) =>
  async (
    params?: FetchingRequestParamsModels
  ): Promise<MaintenanceTypeModel[]> => {
    return await axios
      .get(
        SettingsHouseKeepingEndpoint.HOUSE_KEEPING_MAINTENANCE_TYPE_GET_ALL,
        {
          params,
        }
      )
      .then(({ data }) => data.items);
  };

export const getOne =
  (axios: AxiosInstance) =>
  async (id: number | string): Promise<MaintenanceTypeModel> => {
    return await axios
      .get(
        sprintf(
          SettingsHouseKeepingEndpoint.HOUSE_KEEPING_MAINTENANCE_TYPE_GET_ONE,
          id
        )
      )
      .then(({ data }) => data);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: MaintenanceTypeFormModel): Promise<MaintenanceTypeModel> => {
    return await axios
      .post(
        SettingsHouseKeepingEndpoint.HOUSE_KEEPING_MAINTENANCE_TYPE_CREATE,
        data
      )
      .then(({ data }) => data);
  };

type UpdateModel = {
  id: number | string;
  data: Partial<MaintenanceTypeFormModel>;
};

export const update =
  (axios: AxiosInstance) =>
  async ({ id, data }: UpdateModel): Promise<MaintenanceTypeModel> => {
    return await axios
      .put(
        sprintf(
          SettingsHouseKeepingEndpoint.HOUSE_KEEPING_MAINTENANCE_TYPE_UPDATE,
          id
        ),
        data
      )
      .then(({ data }) => data);
  };

export const patch =
  (axios: AxiosInstance) =>
  async (id: number): Promise<MaintenanceTypeModel> => {
    return await axios
      .patch(
        sprintf(
          SettingsHouseKeepingEndpoint.HOUSE_KEEPING_MAINTENANCE_TYPE_PATCH,
          id
        )
      )
      .then(({ data }) => data);
  };

export const remove =
  (axios: AxiosInstance) =>
  async (id: number | string): Promise<MaintenanceTypeModel> => {
    return await axios
      .delete(
        sprintf(
          SettingsHouseKeepingEndpoint.HOUSE_KEEPING_MAINTENANCE_TYPE_DELETE,
          id
        )
      )
      .then(({ data }) => data);
  };
