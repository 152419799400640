import { AxiosInstance } from "axios";
import { PromoService } from "services/RevenueManagement/Promo";

export interface PromoServiceModel {
  getAll: ReturnType<typeof PromoService.getAll>;
  getOne: ReturnType<typeof PromoService.getOne>;
  create: ReturnType<typeof PromoService.create>;
  update: ReturnType<typeof PromoService.update>;
  remove: ReturnType<typeof PromoService.remove>;
}

export const initPromoService = (axios: AxiosInstance): PromoServiceModel => ({
  getAll: PromoService.getAll(axios),
  getOne: PromoService.getOne(axios),
  create: PromoService.create(axios),
  update: PromoService.update(axios),
  remove: PromoService.remove(axios),
});
