import { AxiosInstance } from "axios";
import { CancellationPenalityService } from "services";

export interface CancellationPenalityServiceModel {
  getAll: ReturnType<typeof CancellationPenalityService.getAll>;
  create: ReturnType<typeof CancellationPenalityService.create>;
  update: ReturnType<typeof CancellationPenalityService.update>;
  remove: ReturnType<typeof CancellationPenalityService.remove>;
  toggleStatus: ReturnType<typeof CancellationPenalityService.toggleStatus>;
}

export const initCancellationPenalityService = (
  axios: AxiosInstance
): CancellationPenalityServiceModel => ({
  getAll: CancellationPenalityService.getAll(axios),
  create: CancellationPenalityService.create(axios),
  update: CancellationPenalityService.update(axios),
  remove: CancellationPenalityService.remove(axios),
  toggleStatus: CancellationPenalityService.toggleStatus(axios),
});
