import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import {
  DepartmentModel,
  StaffModel,
} from "models/Settings/DepartmentAndStaffModel";
import { EmployeeDepartmentModel } from "models/Shared/EmployeeDepartmentModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";

export const getAll =
  (axios: AxiosInstance) =>
  async (
    params?: { search: string } | undefined
  ): Promise<EmployeeDepartmentModel[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_DEPARTMENT_STAFF_GET_ALL, {
        params,
      })
      .then(({ data }) => data);
  };

export const getDepartments =
  (axios: AxiosInstance) =>
  async (
    params?: FetchingRequestParamsModels | undefined
  ): Promise<DepartmentModel[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_DEPARTMENT_STAFF_DEPARTMENT_GET_ALL, {
        params,
      })
      .then(({ data }) => data.items);
  };

export const getStaffs =
  (axios: AxiosInstance) =>
  async (
    params?: FetchingRequestParamsModels | undefined
  ): Promise<StaffModel[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_DEPARTMENT_STAFF_GET_STAFF_ALL, {
        params,
      })
      .then(({ data }) => data.items);
  };
