import { AxiosInstance } from "axios";
import {
  initRateAddOnsService,
  RateAddOnsServiceModel,
} from "./RateAddOnsService";
import { initRateCardService, RateCardServiceModel } from "./RateCardService";
import {
  initRateCustomReservationService,
  RateCustomReservationServiceModel,
} from "./RateCustomReservationService";
import {
  initRateRangeLevelService,
  RateRangeLevelServiceModel,
} from "./RateRangeLevelService";
import {
  initRateRangeRoomService,
  RateRangeRoomServiceModel,
} from "./RateRangeRoomService";
import {
  initRateRateRangeService,
  RateRateRangeServiceModel,
} from "./RateRangeService";
import { initRateRateService, RateRateServiceModel } from "./RateService";

export interface RateServiceModel {
  rate: RateRateServiceModel;
  range: RateRateRangeServiceModel;
  level: RateRangeLevelServiceModel;
  room: RateRangeRoomServiceModel;
  card: RateCardServiceModel;
  addons: RateAddOnsServiceModel;
  customReservation: RateCustomReservationServiceModel;
}

export const initRateService = (axios: AxiosInstance): RateServiceModel => ({
  rate: initRateRateService(axios),
  range: initRateRateRangeService(axios),
  level: initRateRangeLevelService(axios),
  room: initRateRangeRoomService(axios),
  card: initRateCardService(axios),
  addons: initRateAddOnsService(axios),
  customReservation: initRateCustomReservationService(axios),
});
