import { AxiosInstance } from "axios";
import { CashCategoryService } from "services";

export interface CashCategoryServiceModel {
  getAll: ReturnType<typeof CashCategoryService.getAll>;
  toggleStatus: ReturnType<typeof CashCategoryService.toggleStatus>;
  create: ReturnType<typeof CashCategoryService.create>;
  update: ReturnType<typeof CashCategoryService.update>;
  remove: ReturnType<typeof CashCategoryService.remove>;
}

export const initCashCategoryService = (
  axios: AxiosInstance
): CashCategoryServiceModel => ({
  getAll: CashCategoryService.getAll(axios),
  toggleStatus: CashCategoryService.toggleStatus(axios),
  create: CashCategoryService.create(axios),
  update: CashCategoryService.update(axios),
  remove: CashCategoryService.remove(axios),
});
