import { RakManagerEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { GlobalRakModel } from "models/RevenueManagement/Rak/GlobalRakModel";
import { RateRakModel } from "models/RevenueManagement/Rak/RateRakModel";
import { RevenuManagemenFilterModel } from "models/RevenueManagement/Rak/RevenuManagemenFilterModel";
import { RateEditFormModel } from "pages/RevenuManagementModule/pages/RateManagerModule/pages/GlobalRakPage/components/Forms/RateEditForm/models/RateEditFormModel";
import { RollbackChangesRequestModel } from "pages/RevenuManagementModule/pages/RateManagerModule/pages/GlobalRakPage/components/Forms/RateEditForm/models/RollbackChangesRequestModel";
import { LevelEditFormModel } from "pages/RevenuManagementModule/pages/RateManagerModule/pages/RateRakPage/components/Forms/LevelEditForm/models/LevelEditFormModel";
import { PricingEditFormModel } from "pages/RevenuManagementModule/pages/RateManagerModule/pages/RateRakPage/components/Forms/PricingEditForm/models/PricingEditFormModel";
import { sprintf } from "sprintf-js";

export const getAll =
  (axios: AxiosInstance) =>
  async (params: RevenuManagemenFilterModel): Promise<GlobalRakModel[]> => {
    return await axios
      .get(RakManagerEndpoint.REVENUE_MANAGEMENT_RAK, { params })
      .then(({ data }) => data);
  };

export const getOne =
  (axios: AxiosInstance) =>
  async (
    id: number,
    params: RevenuManagemenFilterModel
  ): Promise<RateRakModel> => {
    return await axios
      .get(sprintf(RakManagerEndpoint.REVENUE_MANAGEMENT_RAK_RATE, id), {
        params,
      })
      .then(({ data }) => data);
  };

export const updateRakRate =
  (axios: AxiosInstance) =>
  async (
    params: RateEditFormModel | LevelEditFormModel | PricingEditFormModel
  ): Promise<RateRakModel> => {
    return await axios
      .post(
        RakManagerEndpoint.REVENUE_MANAGEMENT_RAK,

        {
          ...params,
        }
      )
      .then(({ data }) => data);
  };

export const updateGlobalRakRate =
  (axios: AxiosInstance) =>
  async (params: RevenuManagemenFilterModel): Promise<any> => {
    return await axios
      .patch(
        RakManagerEndpoint.REVENUE_MANAGEMENT_RAK,

        {
          ...params,
        }
      )
      .then(({ data }) => data);
  };

export const rollbackChanges =
  (axios: AxiosInstance) =>
  async (params: RollbackChangesRequestModel): Promise<any> => {
    return await axios
      .delete(RakManagerEndpoint.REVENUE_MANAGEMENT_RAK, {
        params,
      })
      .then(({ data }) => data);
  };
