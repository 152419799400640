import { AxiosInstance } from "axios";
import { CardServiceModel, initCardService } from "./Card";

export interface RevenueManagementServiceModel {
  card: CardServiceModel;
}

export const initRevenueManagementService = (
  axios: AxiosInstance
): RevenueManagementServiceModel => ({
  card: initCardService(axios),
});
