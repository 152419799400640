import { Components, Theme } from "@mui/material";
export const MuiToggleButton: Components<
  Omit<Theme, "components">
>["MuiToggleButton"] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: 8,
    }),
  },
};
