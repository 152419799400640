import { styled } from "@mui/material/styles";
import {
  CalendarPicker as MuiCalendarPicker,
  CalendarPickerProps as MuiCalendarPickerProps,
} from "@mui/x-date-pickers/CalendarPicker";
import DayComponent from "./DayComponent";
import { PickersDayProps } from "@mui/x-date-pickers";

export type CalendarPickerProps = MuiCalendarPickerProps<Date> & {
  isFirstDay?: (date: Date) => boolean;
  isLastDay?: (date: Date) => boolean;
  isPreSelected?: (date: Date) => boolean;
  isInInterval?: (date: Date) => boolean;
  handleChangeDateHover?: (value: Date | null) => void;
};

const CalendarPicker = styled(
  ({
    isFirstDay,
    isLastDay,
    isPreSelected,
    isInInterval,
    handleChangeDateHover,
    ...props
  }: CalendarPickerProps) => (
    <MuiCalendarPicker
      {...props}
      showDaysOutsideCurrentMonth
      dayOfWeekFormatter={(day) => day.toUpperCase()}
      reduceAnimations
      renderDay={(
        day: Date,
        _selectedDays: Date[],
        pickersDayProps: PickersDayProps<Date>
      ) => (
        <DayComponent
          {...pickersDayProps}
          isFirstDay={isFirstDay?.(day)}
          isLastDay={isLastDay?.(day)}
          isInInterval={isInInterval?.(day)}
          isPreSelected={isPreSelected?.(day)}
          handleChangeDateHover={handleChangeDateHover}
        />
      )}
    />
  ),
)(({ theme }) => ({
  "&.MuiCalendarPicker-root": {
    margin: "unset",
    width: "22.5rem",
    maxHeight: "25.5rem",
  },
  "& .MuiDayPicker-header": {
    marginBottom: "0.5rem",
    "& .MuiTypography-root": {
      marginLeft: "0.25rem",
      marginRight: "0.25rem",
      width: "2.5rem",
      height: "2.5rem",
      color: theme.palette.colors.gray.o80,
      fontSize: theme.typography.inter12.fontSize,
      fontWeight: 500,
    },
  },
  "& .PrivatePickersSlideTransition-root": {
    minHeight: "18.5rem",
  },
  "& .MuiDayPicker-weekContainer": {
    marginBottom: "0.5rem",
  },
  "& .MuiPickersCalendarHeader-root": {
    position: "relative",
    paddingLeft: "12px",
    paddingRight: "12px",
  },
  "& .MuiPickersCalendarHeader-labelContainer": {
    flexGrow: 1,
    position: "absolute",
    width: "calc(100% - 24px)",
    "& .MuiPickersCalendarHeader-label": {
      width: "100%",
      textAlign: "center",
      color: theme.palette.common.black,
      fontSize: theme.typography.inter16.fontSize,
      fontWeight: 600,
    },
  },
  "& .MuiPickersArrowSwitcher-root": {
    flexGrow: 1,
    justifyContent: "space-between",
    "& .MuiButtonBase-root": {
      borderRadius: 0,
      width: "2.5rem",
      height: "2.5rem",
      color: theme.palette.common.black,
      fontSize: theme.typography.inter16.fontSize,
      fontWeight: 600,
    },
  },
}));

export default CalendarPicker;
