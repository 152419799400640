import { AxiosInstance } from "axios";
import { CommonEndpoint } from "@constants/ApiEndpoints";
import { GlobalSettingsModel } from "models/Shared/GlobalSettingsModel";

export interface GlobalSettingsServiceModel {
  get: ReturnType<typeof get>;
}

export const initGlobalSettingsService = (
  axios: AxiosInstance
): GlobalSettingsServiceModel => ({
  get: get(axios),
});

export const get =
  (axios: AxiosInstance) => async (): Promise<GlobalSettingsModel> => {
    return await axios.get(CommonEndpoint.CLOBAL_SETTINGS);
  };
