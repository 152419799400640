import { AxiosInstance } from "axios";
import { HouseKeepingHousekeeperServices } from "services/Settings";

export interface HouseKeepingHousekeeperServiceModel {
  getAll: ReturnType<typeof HouseKeepingHousekeeperServices.getAll>;
  getOne: ReturnType<typeof HouseKeepingHousekeeperServices.getOne>;
  create: ReturnType<typeof HouseKeepingHousekeeperServices.create>;
  update: ReturnType<typeof HouseKeepingHousekeeperServices.update>;
  patch: ReturnType<typeof HouseKeepingHousekeeperServices.patch>;
  remove: ReturnType<typeof HouseKeepingHousekeeperServices.remove>;
}

export const initHouseKeepingHousekeeperServiceModel = (
  axios: AxiosInstance
): HouseKeepingHousekeeperServiceModel => ({
  getAll: HouseKeepingHousekeeperServices.getAll(axios),
  getOne: HouseKeepingHousekeeperServices.getOne(axios),
  create: HouseKeepingHousekeeperServices.create(axios),
  update: HouseKeepingHousekeeperServices.update(axios),
  patch: HouseKeepingHousekeeperServices.patch(axios),
  remove: HouseKeepingHousekeeperServices.remove(axios),
});
