export const CARD_LIST = "/revenue-management/cards";
export const CARD_EDIT = "/revenue-management/cards/%s/edit";

export const GUEST_LIST = "/guest";
export const GUEST_EDIT = "/guest/%s/edit";

export const RATE_LIST = "/revenue-management/rates";
export const RATE_EDIT = "/revenue-management/rates/%s/edit";

export const PROMOS_LIST = "/revenue-management/promos";
