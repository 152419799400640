import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { CashRegisterOperationModel } from "models/Settings/Financial/CashRegisterOpertationModel";

import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";

export interface Options {
  id: number;
  label: string;
}

export interface TypeModel {
  id: string | undefined;
  value: string | number;
  label: string;
}

export const remove =
  (axios: AxiosInstance) =>
  async (id: number): Promise<CashRegisterOperationModel> => {
    return await axios.delete(
      `${SettingsEndpoint.SETTINGS_FINANCIAL_CASH_REGISTER_OPERTATION_PATH}/${id}`
    );
  };

export const getAll =
  (axios: AxiosInstance) =>
  async (
    params?: FetchingRequestParamsModels
  ): Promise<{ items: CashRegisterOperationModel[]; total: number }> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_FINANCIAL_CASH_REGISTER_OPERTATION_PATH, {
        params,
      })
      .then(({ data }) => {
        return data;
      });
  };

export const create =
  (axios: AxiosInstance) =>
  async (
    data: CashRegisterOperationModel
  ): Promise<CashRegisterOperationModel> => {
    return await axios
      .post(
        SettingsEndpoint.SETTINGS_FINANCIAL_CASH_REGISTER_OPERTATION_PATH,
        data
      )
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (
    data: CashRegisterOperationModel
  ): Promise<CashRegisterOperationModel> => {
    return await axios
      .put(
        `/${SettingsEndpoint.SETTINGS_FINANCIAL_CASH_REGISTER_OPERTATION_PATH}/${data.id}`,
        data
      )
      .then(({ data }) => data);
  };

export const toggleStatus =
  (axios: AxiosInstance) =>
  async (id: number): Promise<CashRegisterOperationModel> => {
    return await axios
      .patch(
        `/${SettingsEndpoint.SETTINGS_FINANCIAL_CASH_REGISTER_OPERTATION_PATH}/${id}`
      )
      .then(({ data }) => data);
  };
