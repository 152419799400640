import {
  AppBar,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { ArrowLeftIcon } from "components/SvgIcons/ArrowLeftIcon";
import { DeleteFilledIcon } from "components/SvgIcons/DeleteFilledIcon";
import { PlusIcon } from "components/SvgIcons/PlusIcon";
import { SearchIcon } from "components/SvgIcons/SearchIcon";
import { FC } from "react";

type Props = {
  search?: string | null;
  searchPlaceholder?: string | null;
  handleSearchChange?: (search: string) => void;
  handleClickBack?: () => void;
  handleClickAddNew?: () => void;
  addNewButtonName?: string | null;
};

const SettingListHeader: FC<Props> = ({
  search,
  searchPlaceholder,
  addNewButtonName,
  handleSearchChange,
  handleClickBack,
  handleClickAddNew,
}) => {
  return (
    <AppBar
      position="sticky"
      elevation={0}
      sx={{
        bgcolor: "colors.gray.o30",
        p: "1.5rem",
        display: "flex",
        flexFlow: "row",
        flex: "0 1 auto",
        zIndex: 1,
      }}
    >
      <Button
        variant="text"
        sx={{ height: "3rem", width: "3rem", mr: "0.5rem" }}
        onClick={handleClickBack}
      >
        <ArrowLeftIcon />
      </Button>
      <TextField
        value={search}
        onChange={(e) => handleSearchChange?.(e.target.value)}
        fullWidth
        variant="standard"
        {...(searchPlaceholder && { placeholder: searchPlaceholder })}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton size="small" onClick={() => handleSearchChange?.("")}>
                <DeleteFilledIcon
                  fontSize="small"
                  sx={{ color: "colors.gray.o50" }}
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Button
        variant="contained"
        color="primary"
        sx={{ minWidth: "8.5rem", ml: "1rem" }}
        onClick={handleClickAddNew}
        startIcon={<PlusIcon />}
      >
        <Typography variant="inter16" fontWeight={600}>
          {addNewButtonName ?? "ADD NEW"}
        </Typography>
      </Button>
    </AppBar>
  );
};

export default SettingListHeader;
