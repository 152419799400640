import { AxiosInstance } from "axios";
import { SeasonService } from "services";

export interface SeasonServiceModel {
  getAll: ReturnType<typeof SeasonService.getAll>;
  create: ReturnType<typeof SeasonService.create>;
  update: ReturnType<typeof SeasonService.update>;
  remove: ReturnType<typeof SeasonService.remove>;
  toggleStatus: ReturnType<typeof SeasonService.toggleStatus>;
}

export const initSeasonService = (
  axios: AxiosInstance
): SeasonServiceModel => ({
  getAll: SeasonService.getAll(axios),
  create: SeasonService.create(axios),
  update: SeasonService.update(axios),
  remove: SeasonService.remove(axios),
  toggleStatus: SeasonService.toggleStatus(axios),
});
