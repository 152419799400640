import { AxiosInstance } from "axios";
import {
  EndOfDayTaskServiceModel,
  initEndOfDayTaskService,
} from "./TaskService";

export interface EndOfDayServiceModel {
  task: EndOfDayTaskServiceModel;
}

export const initEndOfDayService = (
  axios: AxiosInstance
): EndOfDayServiceModel => ({
  task: initEndOfDayTaskService(axios),
});
