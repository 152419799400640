import { Components, Theme } from "@mui/material";
export const MuiInput: Components<Omit<Theme, "components">>["MuiInput"] = {
  defaultProps: {
    disableUnderline: true,
  },
  styleOverrides: {
    root: ({ ownerState, theme }) => {
      return {
        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
          {
            display: "none",
          },
        "& input[type=number]": {
          MozAppearance: "textfield",
        },
        boxShadow: `inset 0 -1.5px 0 0 ${theme.palette.colors.gray.o40}`,
        backgroundColor: theme.palette.common.white,

        "&:hover": {
          backgroundColor: theme.palette.common.white,
        },

        ".background > &": {
          backgroundColor: theme.palette.colors.gray.o10,
        },

        "&.MuiInputBase-colorPrimary": {
          ...(!ownerState.error &&
            !ownerState.disabled && {
              "&:hover": {
                boxShadow: `inset 0 -2px 0 0 ${theme.palette.colors.purple.o70}`,
              },
              "&.Mui-focused": {
                boxShadow: `inset 0px 0px 0px 2px ${theme.palette.colors.purple.o70}`,
              },
            }),
        },

        ...(ownerState.disabled && {
          "&.Mui-disabled": {
            backgroundColor: theme.palette.colors.gray.o30,
            borderColor: "transparent",
            ".background > &": {
              backgroundColor: theme.palette.colors.gray.o30,
            },
          },
          boxShadow: "none",
        }),

        "&.Mui-error": {
          boxShadow: `inset 0 -1.5px 0 0 ${theme.palette.error.main}`,
        },
        "&.MuiSelect-root": {
          border: "solid 1px green",
        },
      };
    },
    input: {
      "&.MuiSelect-select": {
        padding: "10px 8px 0px 14px",
        height: "2em",
      },
    },
  },
};
