import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { LanguageModel } from "models/Settings/ApplicationSettings/LanguageModel";

export const getCurrentLanguage =
  (axios: AxiosInstance) => async (): Promise<LanguageModel> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_APPLICATION_LANGUAGE)
      .then(({ data }) => data.items);
  };

export const create =
  (axios: AxiosInstance) =>
  async (lngCode: string): Promise<LanguageModel> => {
    return await axios
      .post(SettingsEndpoint.SETTINGS_APPLICATION_LANGUAGE, {
        default_language: lngCode,
      })
      .then(({ data }) => data);
  };
