import { Crumb } from "components/TranslatableCrub";
import { lazy, Suspense } from "react";
import { RoutePathDefinition } from "routing/routes";

const ArrivalPage = lazy(() => import("../pages/ArrivalPage"));
const DeparturePage = lazy(() => import("../pages/DeparturePage"));
const InHousePage = lazy(() => import("../pages/InHousePage"));
const SchedulerPage = lazy(
  () => import("../../RoomPlanModule/pages/SchedulerPage")
);

const FrontDeskRoutes: Array<RoutePathDefinition> = [
  {
    path: "arrival",
    element: (
      <Suspense fallback={<>...</>}>
        <ArrivalPage />
      </Suspense>
    ),
    handle: {
      crumb: () => <Crumb label="frontDesk:arrival" />,
    },
  },
  {
    path: "departure",
    element: (
      <Suspense fallback={<>...</>}>
        <DeparturePage />
      </Suspense>
    ),
    handle: {
      crumb: () => <Crumb label="frontDesk:departure" />,
    },
  },
  {
    path: "inhouse",
    element: (
      <Suspense fallback={<>...</>}>
        <InHousePage />
      </Suspense>
    ),
    handle: {
      crumb: () => <Crumb label="frontDesk:inHouse" />,
    },
  },
  {
    path: "room-plan",
    element: (
      <Suspense fallback={<>...</>}>
        <SchedulerPage />
      </Suspense>
    ),
    handle: {
      crumb: () => <Crumb label="rootMenu.room_plan" path={null} />,
    },
  },
];

export default FrontDeskRoutes;
