import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { SourceModel } from "models/Settings/Reservation/source/SourceModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";

export const remove =
  (axios: AxiosInstance) =>
  async (id: number): Promise<SourceModel> => {
    return await axios.delete(
      `${SettingsEndpoint.SETTINGS_RESERVATION_SOURCE}/${id}`
    );
  };

export const getAll =
  (axios: AxiosInstance) =>
  async (params?: FetchingRequestParamsModels): Promise<SourceModel[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_RESERVATION_SOURCE, {
        params,
      })
      .then(({ data }) => data.items);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: SourceModel): Promise<SourceModel> => {
    return await axios
      .post(SettingsEndpoint.SETTINGS_RESERVATION_SOURCE, data)
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (data: SourceModel): Promise<SourceModel> => {
    return await axios
      .put(`${SettingsEndpoint.SETTINGS_RESERVATION_SOURCE}/${data.id}`, data)
      .then(({ data }) => data);
  };

export const toggleStatus =
  (axios: AxiosInstance) =>
  async (id: number): Promise<SourceModel> => {
    return await axios
      .patch(`${SettingsEndpoint.SETTINGS_RESERVATION_SOURCE}/${id}`)
      .then(({ data }) => data);
  };
