import { AxiosInstance } from "axios";
import { ReservationArrivalService } from "services/Reservations";

export interface ArrivalService {
  getAll: ReturnType<typeof ReservationArrivalService.getAll>;
  patch: ReturnType<typeof ReservationArrivalService.patch>;
  getAllRoomType: ReturnType<typeof ReservationArrivalService.getAllRoomType>;
  checkin: ReturnType<typeof ReservationArrivalService.checkin>;
  cancel: ReturnType<typeof ReservationArrivalService.cancel>;
  confirm: ReturnType<typeof ReservationArrivalService.confirm>;
  autoAssign: ReturnType<typeof ReservationArrivalService.autoAssign>;
  autoAssignOne: ReturnType<typeof ReservationArrivalService.autoAssignOne>;
  autoAssignMany: ReturnType<typeof ReservationArrivalService.autoAssignMany>;
  updateArrival: ReturnType<typeof ReservationArrivalService.updateArrival>;
  updateArrivalAndAffectRoom: ReturnType<
    typeof ReservationArrivalService.updateArrivalAndAffectRoom
  >;
  updateDeparture: ReturnType<typeof ReservationArrivalService.updateDeparture>;
  revert: ReturnType<typeof ReservationArrivalService.revert>;
}

export const initArrivalService = (axios: AxiosInstance): ArrivalService => ({
  getAll: ReservationArrivalService.getAll(axios),
  patch: ReservationArrivalService.patch(axios),
  getAllRoomType: ReservationArrivalService.getAllRoomType(axios),
  checkin: ReservationArrivalService.checkin(axios),
  cancel: ReservationArrivalService.cancel(axios),
  confirm: ReservationArrivalService.confirm(axios),
  autoAssign: ReservationArrivalService.autoAssign(axios),
  autoAssignOne: ReservationArrivalService.autoAssignOne(axios),
  autoAssignMany: ReservationArrivalService.autoAssignMany(axios),
  updateArrival: ReservationArrivalService.updateArrival(axios),
  updateArrivalAndAffectRoom:
    ReservationArrivalService.updateArrivalAndAffectRoom(axios),
  updateDeparture: ReservationArrivalService.updateDeparture(axios),
  revert: ReservationArrivalService.revert(axios),
});
