import { AxiosInstance } from "axios";
import { BlocServiceModel, initBlocServiceModel } from "./Bloc/BlocService";
import { RoomsTypesModel, initRoomsTypes } from "./RoomType/RoomTypeService";
import {
  RoomsCategoryServiceModel,
  initRoomsCategoryServiceModel,
} from "./RoomsCategoryService";
import {
  RoomsFloorServiceModel,
  initRoomsFloorServiceModel,
} from "./RoomsFloorService";
import {
  RoomsListServiceModel,
  initRoomsListServiceModel,
} from "./RoomsListService";

export interface SettingRoomsServiceModel {
  roomType: RoomsTypesModel;
  roomFloor: RoomsFloorServiceModel;
  roomCategory: RoomsCategoryServiceModel;
  roomList: RoomsListServiceModel;
  bloc: BlocServiceModel;
}

export const initSettingRoomsService = (
  axios: AxiosInstance
): SettingRoomsServiceModel => ({
  roomType: initRoomsTypes(axios),
  roomFloor: initRoomsFloorServiceModel(axios),
  roomCategory: initRoomsCategoryServiceModel(axios),
  roomList: initRoomsListServiceModel(axios),
  bloc: initBlocServiceModel(axios),
});
