import { AxiosInstance } from "axios";
import { GuestCardFieldsService } from "services";

export interface GuestCardFieldsServiceModel {
  getAll: ReturnType<typeof GuestCardFieldsService.getAll>;
  create: ReturnType<typeof GuestCardFieldsService.create>;
  update: ReturnType<typeof GuestCardFieldsService.update>;
  remove: ReturnType<typeof GuestCardFieldsService.remove>;
  toggleStatus: ReturnType<typeof GuestCardFieldsService.toggleStatus>;
}

export const initGuestCardFieldsService = (
  axios: AxiosInstance
): GuestCardFieldsServiceModel => ({
  getAll: GuestCardFieldsService.getAll(axios),
  create: GuestCardFieldsService.create(axios),
  update: GuestCardFieldsService.update(axios),
  remove: GuestCardFieldsService.remove(axios),
  toggleStatus: GuestCardFieldsService.toggleStatus(axios),
});
