import { AxiosInstance } from "axios";
import { ReservationGeneralInformationsService } from "services/Reservations";

export interface ReservationGeneralInformationsServiceModel {
  create: ReturnType<typeof ReservationGeneralInformationsService.create>;
  update: ReturnType<typeof ReservationGeneralInformationsService.update>;
  getOne: ReturnType<typeof ReservationGeneralInformationsService.getOne>;
}

export const initReservationGeneralInformationsService = (
  axios: AxiosInstance
): ReservationGeneralInformationsServiceModel => ({
  create: ReservationGeneralInformationsService.create(axios),
  update: ReservationGeneralInformationsService.update(axios),
  getOne: ReservationGeneralInformationsService.getOne(axios),
});
