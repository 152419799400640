import { DraftQueriesKeys } from "@constants/Queries";
import { createApiInstance } from "api/ApiInstance";
import { ChainServiceModel, initChainService } from "api/ChainApi";
import { HotelServiceModel, getHotelService } from "api/HotelApi";
import { useHostname } from "hooks/useHostname";
import { GlobalSettingsModel } from "models/Shared/GlobalSettingsModel";
import React, { FC, ReactNode, createContext, useContext } from "react";
import { useQuery } from "react-query";
import { WhoamiModel } from "services/UserService";
import { changeLanguage } from "utils/changeLanguageI18n";
import RootContainerLoading from "./Loading";
import { SessionExpired } from "./SessionExpired";

export interface RootContextProps {
  HotelApi: HotelServiceModel;
  ChainApi: ChainServiceModel;
  user: WhoamiModel;
  globalSettings?: GlobalSettingsModel | null;
  globalSettingsQueryKey?: (
    | string
    | {
        HotelApi: HotelServiceModel;
        ChainApi: ChainServiceModel;
      }
    | null
  )[];
}

export const RootContext = createContext<RootContextProps>({
  HotelApi: {} as HotelServiceModel,
  ChainApi: {} as ChainServiceModel,
  user: {} as WhoamiModel,
});

export const useRootContext = () => useContext(RootContext);

export const RootContainer: FC<{ children: ReactNode }> = ({ children }) => {
  const _hostname = useHostname();

  const rootContextValue = React.useMemo(() => {
    if (!_hostname) return null;
    const hotelApiInstance = createApiInstance(_hostname.HOTEL_API_URL);
    const chainApiInstance = createApiInstance(_hostname.CHAIN_API_URL);
    const HotelApi = getHotelService(hotelApiInstance);
    const ChainApi = initChainService(chainApiInstance);
    return {
      HotelApi,
      ChainApi,
    };
  }, [_hostname]);

  const {
    isLoading,
    isError,
    data: user,
  } = useQuery({
    queryKey: ["whoami", rootContextValue],
    queryFn: () => rootContextValue && rootContextValue.ChainApi.user.whoami(),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const globalSettingsQueryKey = ["globalSettings", rootContextValue];

  const { data: globalSettings } = useQuery({
    queryKey: globalSettingsQueryKey,
    queryFn: () =>
      rootContextValue && rootContextValue.HotelApi.globalSettings.get(),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: (data) => {
      if (data?.default_language) {
        changeLanguage(data?.default_language);
      }
    },
  });

  useQuery({
    queryKey: DraftQueriesKeys.DRAFT_GET_ALL,
    queryFn: rootContextValue?.HotelApi.draft.getAll,
    enabled: Boolean(rootContextValue),
  });

  if (isLoading) {
    return <RootContainerLoading />;
  }

  if (!isLoading && !user) {
    return <SessionExpired />;
  }

  if (!rootContextValue || isError || !user) {
    return <RootContainerLoading error />;
  }

  return (
    <RootContext.Provider
      value={{
        ...rootContextValue,
        user,
        globalSettings,
        globalSettingsQueryKey,
      }}
    >
      {children}
    </RootContext.Provider>
  );
};

export default RootContainer;
