import axios from "axios";

const Api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL as string,
  headers: {
    common: {
      "Content-Type": "application/json",
    },
  },
});

Api.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    if (error?.response) Promise.reject(error.response);
    return Promise.reject(error);
  }
);

Api.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export default Api;
