import { AppRunningModeModel } from "models/Shared/AppRunningModeModel";
import { useEffect, useState } from "react";

interface ApiProps {
  CHAIN_API_URL: string;
  HOTEL_API_URL: string;
}

export function useHostname(): ApiProps | null {
  const [host, setHost] = useState<ApiProps | null>(null);
  const { hostname /* port */ } = window.location;

  useEffect(() => {
    if (
      [
        AppRunningModeModel.DEVELOPMENT,
        AppRunningModeModel.PREPROD,
        AppRunningModeModel.TEST,
      ].includes(process.env.REACT_APP_MODE as AppRunningModeModel)
    ) {
      setHost({
        CHAIN_API_URL: String(process.env.REACT_APP_API_URL), //`https://api.magic-life.dev.preprod.pms.lightresa.com`,
        HOTEL_API_URL: String(process.env.REACT_APP_API_URL_2), // `https://api.africana.magic-life.dev.preprod.pms.lightresa.com`,
      });
    } else {
      const [hotelName = undefined, chainName = undefined] =
        hostname.split(".");
      if (hotelName && chainName) {
        setHost({
          CHAIN_API_URL: `https://${hotelName}.${chainName}.${process.env.REACT_APP_API_DOMAIN_SUFFIX}/chain`,
          HOTEL_API_URL: `https://${hotelName}.${chainName}.${process.env.REACT_APP_API_DOMAIN_SUFFIX}`,
        });
      } else {
        setHost(null);
      }
    }
  }, [hostname]);

  return host;
}
