import { HOUSE_KEEPING_GET_ALL } from "@constants/ApiEndpoints/HouseKeepingRootEndpoint";
import { AxiosInstance } from "axios";
import {
  RoomStatusFormModel,
  RoomStatusListModel,
} from "pages/HouseKeepingModule/pages/RoomStatusPage/models/RoomStatusModel";

export type Params = {
  search?: string;
  limit?: number;
  offset?: number;
};

export const getAll =
  (axios: AxiosInstance) =>
  async (params?: {
    room_number?: string | undefined;
    limit?: number;
    offset?: number;
  }): Promise<RoomStatusListModel> =>
    await axios.get(HOUSE_KEEPING_GET_ALL, { params }).then(({ data }) => data);

export const update =
  (axios: AxiosInstance) =>
  async (data: Partial<RoomStatusFormModel>): Promise<RoomStatusFormModel> => {
    return await axios
      .post(HOUSE_KEEPING_GET_ALL, data)
      .then(({ data }) => data?.items);
  };
