import { SettingsHouseKeepingEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import BlocModel, { BlocCreateModel } from "models/Settings/HouseKeeping/Bloc";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";
import { sprintf } from "sprintf-js";

export const getAll =
  (axios: AxiosInstance) =>
  async (params?: FetchingRequestParamsModels): Promise<BlocModel[]> => {
    return await axios
      .get(SettingsHouseKeepingEndpoint.HOUSE_KEEPING_BLOC, { params })
      .then(({ data }) => data.items);
  };

export const getOne =
  (axios: AxiosInstance) =>
  async (id: number | string): Promise<BlocModel> => {
    return await axios
      .get(sprintf(SettingsHouseKeepingEndpoint.HOUSE_KEEPING_BLOC_ID, id))
      .then(({ data }) => data);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: BlocCreateModel): Promise<BlocModel> => {
    return await axios
      .post(SettingsHouseKeepingEndpoint.HOUSE_KEEPING_BLOC, data)
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (data: BlocModel): Promise<BlocModel> => {
    return await axios
      .put(
        sprintf(SettingsHouseKeepingEndpoint.HOUSE_KEEPING_BLOC_ID, data.id),
        data
      )
      .then(({ data }) => data);
  };

export const patch =
  (axios: AxiosInstance) =>
  async (data: Partial<BlocModel>): Promise<BlocModel> => {
    return await axios
      .patch(
        sprintf(SettingsHouseKeepingEndpoint.HOUSE_KEEPING_BLOC_ID, data?.id!),
        data
      )
      .then(({ data }) => data);
  };

export const remove =
  (axios: AxiosInstance) =>
  async (id: number | string): Promise<BlocModel> => {
    return await axios
      .delete(sprintf(SettingsHouseKeepingEndpoint.HOUSE_KEEPING_BLOC_ID, id))
      .then(({ data }) => data);
  };
