import { Components, Theme } from "@mui/material";
export const MuiTooltip: Components<Omit<Theme, "components">>["MuiTooltip"] = {
  styleOverrides: {
    tooltip: ({ theme }) => ({
      padding: theme.spacing(2),
      fontSize: theme.typography.inter14.fontSize,
      fontWeight: "normal",
      backgroundColor: theme.palette.colors.blueR.o90,
    }),
    arrow: ({ theme }) => ({
      color: theme.palette.colors.blueR.o90,
    }),
  },
};
