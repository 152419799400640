import { AxiosInstance } from "axios";
import { ReservationBillingInformations } from "services/Reservations";

export interface ReservationBillingInformationsServiceModel {
  create: ReturnType<typeof ReservationBillingInformations.create>;
  update: ReturnType<typeof ReservationBillingInformations.update>;
  remove: ReturnType<typeof ReservationBillingInformations.remove>;
  getOne: ReturnType<typeof ReservationBillingInformations.getOne>;
  getAllByReservation: ReturnType<
    typeof ReservationBillingInformations.getAllByReservation
  >;
  getCharges: ReturnType<typeof ReservationBillingInformations.getCharges>;
}

export const initReservationBillingInformationsService = (
  axios: AxiosInstance
): ReservationBillingInformationsServiceModel => ({
  create: ReservationBillingInformations.create(axios),
  update: ReservationBillingInformations.update(axios),
  remove: ReservationBillingInformations.remove(axios),
  getOne: ReservationBillingInformations.getOne(axios),
  getAllByReservation:
    ReservationBillingInformations.getAllByReservation(axios),
  getCharges: ReservationBillingInformations.getCharges(axios),
});
