import { AxiosInstance } from "axios";
import { ReservationReservationService, initReservationReservationService } from "../ReservationService";
import { GuestFileServiceModel, initGuestFileService } from "./CardFileService";
import { GuestNoteServiceModel, initGuestNoteService } from "./CardNoteService";
import { GuestPaymentMethodServiceModel, initGuestPaymentMethodService } from "./CardPaymentMethodService";
import { GuestServiceModel, initGuestService } from "./GuestService";


export interface ReservationsGuestService {
    guest: GuestServiceModel;
    file: GuestFileServiceModel;
    paymentMethod: GuestPaymentMethodServiceModel;
    note: GuestNoteServiceModel;
    reservation: ReservationReservationService;
}

export const initReservationsGuestService = (
    axios: AxiosInstance
): ReservationsGuestService => ({
    guest: initGuestService(axios),
    file: initGuestFileService(axios),
    paymentMethod: initGuestPaymentMethodService(axios),
    note: initGuestNoteService(axios),
    reservation: initReservationReservationService(axios),
});
