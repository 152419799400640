import { ReservationsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { ReservationRoomRateModel } from "pages/ReservationModule/pages/CreateReservationPageNew/models";
import { sprintf } from "sprintf-js";

export const getAll =
  (axios: AxiosInstance) =>
  async (id: number): Promise<ReservationRoomRateModel[]> => {
    return await axios
      .get(sprintf(ReservationsEndpoint.RESERVATION_GET_ROOM_RATES, id))
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (item: {
    id: number;
    data: { is_draft: boolean; rooms: ReservationRoomRateModel[] };
  }): Promise<ReservationRoomRateModel[]> => {
    return await axios
      .put(
        sprintf(ReservationsEndpoint.RESERVATION_UPDATE_ROOM_RATE, item.id),
        item.data
      )
      .then(({ data }) => data);
  };

export const updateOne =
  (axios: AxiosInstance) =>
  async (item: {
    reservationId: number;
    room: ReservationRoomRateModel;
  }): Promise<ReservationRoomRateModel> => {
    return await axios
      .put(
        sprintf(
          ReservationsEndpoint.RESERVATION_UPDATE_ONE_ROOM_RATE,
          item.reservationId,
          item.room.id!
        ),
        item.room
      )
      .then(({ data }) => data);
  };

export const createOne =
  (axios: AxiosInstance) =>
  async (item: {
    reservationId: number;
    room: ReservationRoomRateModel;
  }): Promise<ReservationRoomRateModel> => {
    return await axios
      .post(
        sprintf(
          ReservationsEndpoint.RESERVATION_CREATE_ROOM_RATE,
          item.reservationId
        ),
        item.room
      )
      .then(({ data }) => data);
  };

export const duplicate =
  (axios: AxiosInstance) =>
  async (params: {
    reservationId: number;
    id: number;
  }): Promise<ReservationRoomRateModel> => {
    return await axios
      .get(
        sprintf(
          ReservationsEndpoint.RESERVATION_DUPLICATE_ROOM_RATE,
          params.reservationId,
          params.id
        )
      )
      .then(({ data }) => data);
  };
