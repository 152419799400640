import { RESERVATION_ROOT_ENDPOINT } from "./RootEndpoint";
export { RESERVATION_ROOT_ENDPOINT } from "./RootEndpoint";
export const ROOM_RATE = `${RESERVATION_ROOT_ENDPOINT}/%s/room-rate`;

export const RESERVATION_CONFIRM = `${RESERVATION_ROOT_ENDPOINT}/%s/confirm`;

export const RESERVATION_PAYEMENT_TYPE = `${RESERVATION_ROOT_ENDPOINT}/payment-type`;

export const RESERVATION_CARD_GROUP_SEARCH = `${RESERVATION_ROOT_ENDPOINT}/card-group/search`;
export const RESERVATION_CARD_RESERVATION_SEARCH = `${RESERVATION_ROOT_ENDPOINT}/card-reservation/search`;

export const RESERVATION_ROOMS = `${RESERVATION_ROOT_ENDPOINT}/rooms`;
export const RESERVATION_CREATE_GENERAL_INFORMATION = `${RESERVATION_ROOT_ENDPOINT}/create/general-information`;
export const RESERVATION_UPDATE_GENERAL_INFORMATION = `${RESERVATION_ROOT_ENDPOINT}/%s/update/general-information`;
export const RESERVATION_GET_GENERAL_INFORMATION = `${RESERVATION_ROOT_ENDPOINT}/%s/show/general-information`;
export const RESERVATION_DISCARD = `${RESERVATION_ROOT_ENDPOINT}/%s/discard`;
export const RESERVATION_UPDATE_GUEST = `${RESERVATION_ROOT_ENDPOINT}/%s/guest/update`;
export const RESERVATION_GET_GUEST = `${RESERVATION_ROOT_ENDPOINT}/%s/guest/show`;

export const RESERVATION_ARRIVAL_GET_ALL = `${RESERVATION_ROOT_ENDPOINT}/rooms/arrival`;
export const RESERVATION_ARRIVAL_UPDATE_ROOM = `${RESERVATION_ROOT_ENDPOINT}/rooms/%s/arrival`;
export const RESERVATION_ARRIVAL_ACTIONS = `${RESERVATION_ROOT_ENDPOINT}/rooms/%s/action`;
export const RESERVATION_ARRIVAL_NOTES = `${RESERVATION_ROOT_ENDPOINT}/rooms/%s/note`;
export const RESERVATION_ARRIVAL_CHECKIN = `${RESERVATION_ROOT_ENDPOINT}/rooms/checked-in`;
export const RESERVATION_ARRIVAL_CHECKOUT = `${RESERVATION_ROOT_ENDPOINT}/rooms/checked-out`;
export const RESERVATION_ARRIVAL_REVERT = `${RESERVATION_ROOT_ENDPOINT}/rooms/revert`;
export const RESERVATION_ARRIVAL_CANCEL = `${RESERVATION_ROOT_ENDPOINT}/rooms/cancelled`;
export const RESERVATION_ARRIVAL_CONFIRM = `${RESERVATION_ROOT_ENDPOINT}/rooms/confirm`;
export const RESERVATION_ARRIVAL_AUTO_ASSIGN = `${RESERVATION_ROOT_ENDPOINT}/rooms/auto-assign`;
export const RESERVATION_ARRIVAL_AUTO_ASSIGN_ONE = `${RESERVATION_ROOT_ENDPOINT}/rooms/auto-assign-one`;
export const RESERVATION_ARRIVAL_AUTO_ASSIGN_MANY = `${RESERVATION_ROOT_ENDPOINT}/rooms/auto-assign`;
export const RESERVATION_ARRIVAL_CHECK_IN_TIME = `${RESERVATION_ROOT_ENDPOINT}/rooms/check-in-time`;
export const RESERVATION_ARRIVAL_CHECK_OUT_TIME = `${RESERVATION_ROOT_ENDPOINT}/rooms/check-out-time`;
export const RESERVATION_ARRIVAL_CHECK_IN_TIME_AND_AFFECT_ROOM = `${RESERVATION_ROOT_ENDPOINT}/rooms/%s/arrival`;
export const RESERVATION_DEPARTURE_GET_ALL = `${RESERVATION_ROOT_ENDPOINT}/rooms/departure`;
export const RESERVATION_INHOUSE_GET_ALL = `${RESERVATION_ROOT_ENDPOINT}/rooms/in-house`;
export const RESERVATION_INHOUSE_GET_ALL_GUESTS_PER_ROOM_NUMBER = `${RESERVATION_ROOT_ENDPOINT}/rooms/in-house/guests`;

export const RESERVATION_ACTIONS_GET_ALL = `${RESERVATION_ROOT_ENDPOINT}/rooms/%s/action`;
export const RESERVATION_ACTIONS_CREATE = `${RESERVATION_ROOT_ENDPOINT}/rooms/%s/action`;
export const RESERVATION_ACTIONS_ITEM = `${RESERVATION_ROOT_ENDPOINT}/rooms/%s/action/%s`;

export const RESERVATION_NOTES_GET_ALL = `${RESERVATION_ROOT_ENDPOINT}/rooms/%s/note`;
export const RESERVATION_NOTES_CREATE = `${RESERVATION_ROOT_ENDPOINT}/rooms/%s/note`;
export const RESERVATION_NOTES_REMOVE_UPDATE = `${RESERVATION_ROOT_ENDPOINT}/rooms/%s/note/%s`;

export const RESERVATION_STAY_CARD_GET = `${RESERVATION_ROOT_ENDPOINT}/stay-card/%s`;
export const RESERVATION_STAY_CARD_CHARGES_SEARCH = `${RESERVATION_ROOT_ENDPOINT}/rooms/%s/bill`;
// path => /reservation/stay-card/{guest}/charges
export const RESERVATION_STAY_CARD_CHARGES_GET = `${RESERVATION_ROOT_ENDPOINT}/stay-card/%s/charges`;
export const RESERVATION_STAY_CARD_GENERAL_INFOS_GET = `${RESERVATION_ROOT_ENDPOINT}/stay-card/%s/general-information`;
export const RESERVATION_STAY_CARD_GENERAL_INFOS_POST = `${RESERVATION_ROOT_ENDPOINT}/stay-card/%s/general-information/%s`;
export const RESERVATION_STAY_CARD_UPDATE = `${RESERVATION_ROOT_ENDPOINT}/stay-card/%s/room-reservation/%s`;
export const RESERVATION_STAY_CARD_HEADER_GET = `${RESERVATION_ROOT_ENDPOINT}/stay-card/%s/header`;
export const RESERVATION_STAY_CARD_UPDATE_MARKET = `${RESERVATION_ROOT_ENDPOINT}/rooms/market`;

export const RESERVATION_STAY_CHECKING_GUEST = `${RESERVATION_ROOT_ENDPOINT}/rooms/%s/guests/checked_in`;
export const RESERVATION_STAY_ORDER_GUEST = `${RESERVATION_ROOT_ENDPOINT}/rooms/%s/guests/%s/order`;

export const RESERVATION_STAY_CUSTOM_RESERVATION_PER_PAX = `${RESERVATION_ROOT_ENDPOINT}/custom/%s/guests/%s/guest/%s/pax`;
export const RESERVATION_STAY_CUSTOM_RESERVATION_PAX_TYPE = `${RESERVATION_ROOT_ENDPOINT}/custom/%s/%s`;
export const RESERVATION_STAY_CUSTOM_RESERVATION_STAY = `${RESERVATION_ROOT_ENDPOINT}/stay/%s/%s`;
export const RESERVATION_STAY_REVERT_STATE = `${RESERVATION_ROOT_ENDPOINT}/rooms/state/change`;

export const RESERVATION_STAY_FORM_ROOM = `${RESERVATION_ROOT_ENDPOINT}/stay-card/%s/room`;
export const RESERVATION_STAY_FORM_ROOM_PREFERENCE = `${RESERVATION_ROOT_ENDPOINT}/stay-card/%s/room_preference`;
export const RESERVATION_STAY_FORM_ADDON = `${RESERVATION_ROOT_ENDPOINT}/stay-card/%s/addon`;
export const RESERVATION_STAY_FORM_RATE_AND_DISCOUNT = `${RESERVATION_ROOT_ENDPOINT}/stay-card/%s/rate_and_discount`;

// Staycard Calender Room
export const RESERVATION_STAY_CARD_GET_ROOMRATES_ROOM = `${RESERVATION_ROOT_ENDPOINT}/search/room`;
// Staycard Calender Pax
export const RESERVATION_STAY_CARD_GET_ROOMRATES_GUESTS = `${RESERVATION_ROOT_ENDPOINT}/search/pax`;

export const RESERVATION_FORCAST_AVAILABILITY_GET_ALL = `${RESERVATION_ROOT_ENDPOINT}/forecast/availability`;
export const RESERVATION_FORCAST_ROOM_GET_ALL = `${RESERVATION_ROOT_ENDPOINT}/forecast/room`;
export const RESERVATION_FORCAST_PAX_GET_ALL = `${RESERVATION_ROOT_ENDPOINT}/forecast/pax`;

export const RESERVATION_CREATE_ROOM_RATE = `${ROOM_RATE}/create`;
export const RESERVATION_UPDATE_ROOM_RATE = `${ROOM_RATE}/update`;
export const RESERVATION_UPDATE_ONE_ROOM_RATE = `${ROOM_RATE}/%s/update`;
export const RESERVATION_GET_ROOM_RATES = `${ROOM_RATE}`;
export const RESERVATION_DUPLICATE_ROOM_RATE = `${ROOM_RATE}/%s/duplicate`;

export const ROOM_RATE_PREFERENCE_UPDATE = `${ROOM_RATE}/%s/preference`;
export const ROOM_RATE_PREFERENCE_GET = `${ROOM_RATE}/%s/preference`;

export const ROOM_RATE_NOTE_GET = `${ROOM_RATE}/%s/note`;
export const ROOM_RATE_NOTE_CREATE = `${ROOM_RATE}/%s/note`;
export const ROOM_RATE_NOTE_REMOVE = `${ROOM_RATE}/%s/note`;

export const ROOM_RATE_ACTION_GET = `${ROOM_RATE}/%s/action`;
export const ROOM_RATE_ACTION_CREATE = `${ROOM_RATE}/%s/action`;
export const ROOM_RATE_ACTION_REMOVE = `${ROOM_RATE}/%s/action/%s`;

export const ROOM_RATE_ADDONS_GET = `${ROOM_RATE}/%s/addon`;
export const ROOM_RATE_ADDONS_CREATE = `${ROOM_RATE}/%s/addon/update`;
export const ROOM_RATE_ADDONS_REMOVE = `${ROOM_RATE}/%s/addon/%s`;

export const RESERVATION_GUEST_REVENU_MANAGEMENT =
  "revenue-management/guest-card";

// Guest Endpoint
export const RESERVATION_GUEST_CARD = "/revenue-management/guest-card/card";

// Guest General Endpoint
export const GUEST_DISCARD = `${RESERVATION_GUEST_REVENU_MANAGEMENT}/%s/discard`;
export const GUEST_UPDATE_DRAFT = `${RESERVATION_GUEST_REVENU_MANAGEMENT}/%s/draft`;
export const GUEST_CARD_FIELD_GUETS = `${RESERVATION_GUEST_REVENU_MANAGEMENT}/%s/guest-card-fields`;

// Guest File Endpoint
export const GUEST_FILE_DOWNLOAD = `${RESERVATION_GUEST_REVENU_MANAGEMENT}/%1$s/file/%2$s/download`;
export const GUEST_FILE_DELETE = `${RESERVATION_GUEST_REVENU_MANAGEMENT}/%1$s/file/%2$s`;
export const GUEST_FILE_GET_ALL = `${RESERVATION_GUEST_REVENU_MANAGEMENT}/%1$s/file`;
export const GUEST_FILE_CREATE = `${RESERVATION_GUEST_REVENU_MANAGEMENT}/%1$s/file`;

// Guest Note Endpoint
export const GUEST_NOTE_GET_ONE = `${RESERVATION_GUEST_REVENU_MANAGEMENT}/%1$s/note/%2$s`;
export const GUEST_NOTE_DELETE = `${RESERVATION_GUEST_REVENU_MANAGEMENT}/%1$s/note/%2$s`;
export const GUEST_NOTE_GET_ALL = `${RESERVATION_GUEST_REVENU_MANAGEMENT}/%1$s/note`;
export const GUEST_NOTE_CREATE = `${RESERVATION_GUEST_REVENU_MANAGEMENT}/%1$s/note`;

// Guest Payment Endpoint
export const GUEST_PAYMENT_METHOD_ID = `${RESERVATION_GUEST_REVENU_MANAGEMENT}/%1$s/payment-method/%2$s`;
export const GUEST_PAYMENT_METHOD_GET_ALL = `${RESERVATION_GUEST_REVENU_MANAGEMENT}/%1$s/payment-method`;
export const GUEST_PAYMENT_METHOD_CREATE = `${RESERVATION_GUEST_REVENU_MANAGEMENT}/%1$s/payment-method`;

export const RESERVATION_GET_ADD_ONS = `${RESERVATION_ROOT_ENDPOINT}/%s/addon`;
export const RESERVATION_UPDATE_ADD_ONS = `${RESERVATION_ROOT_ENDPOINT}/%s/addon/update`;

export const RESERVATION_GET_SUMMARY = `${RESERVATION_ROOT_ENDPOINT}/%s/summary`;
export const RESERVATION_SAVE_RESERVATION = `${RESERVATION_ROOT_ENDPOINT}/summary`;

export const RESERVATION_GUEST = "revenue-management/guest-card/card";
export const RESERVATION_GUEST_GET_ALL = "revenue-management/guest-card/card";

export const CUSTOM_RESERVARION = "reservation/%s/room-rate/%s/custom";
export const CUSTOM_RESERVARION_TYPE = `${CUSTOM_RESERVARION}/%s/%s`;
export const RESERVATION_BOARD_DETAILS = `revenue-management/rate/by-room-type/%s`;
export const CUSTOM_RESERVARION_RECALCULATE_PRICE = `${CUSTOM_RESERVARION}/%s/recalculate-price`;

// Reservation Proof
export const PROOF = `reservation/%s/room-rate/%s/room-rate-proof`;
export const PROOF_TWO_OPTION =
  "reservation/%s/room-rate/%s/room-rate-proof/%s";

export const BILLING_INFORMATIONS_CREATE = `${RESERVATION_ROOT_ENDPOINT}/%s/billing-route`;
export const BILLING_INFORMATIONS_UPDATE = `${RESERVATION_ROOT_ENDPOINT}/%s/billing-route/%s`;
export const BILLING_INFORMATIONS_GET_ONE = `${RESERVATION_ROOT_ENDPOINT}/%s/billing-route/%s`;
export const BILLING_INFORMATIONS_SEARCH = `${RESERVATION_ROOT_ENDPOINT}/%s/billing-route`;
export const BILLING_INFORMATIONS_REMOVE = `${RESERVATION_ROOT_ENDPOINT}/%s/billing-route/%s`;
export const BILLING_INFORMATIONS_GET_ALL_BY_RESERVATION = `${RESERVATION_ROOT_ENDPOINT}/%s/billing-route`;
export const BILLING_INFORMATIONS_GET_CHARGES = `${RESERVATION_ROOT_ENDPOINT}/%s/billing-route-charges`;

// Room Plan
export const ROOM_PLAN = `${RESERVATION_ROOT_ENDPOINT}/room-plan`;
export const ROOM_PLAN_DATA = `${ROOM_PLAN}/reservations`;
export const ROOM_PLAN_UPDATE = `${ROOM_PLAN}/reservations/%s`;
export const ROOM_PLAN_TO_ASSIGN = `${ROOM_PLAN}/arrival`;

export const RESERVATION_ITEMS_AND_PACKAGES = `${RESERVATION_ROOT_ENDPOINT}/items-packages`;
export const RESERVATION_ROOMS_ADDONS = `${RESERVATION_ROOT_ENDPOINT}/rooms/%s/addon`;
export const RESERVATION_ROOMS_ADDONS_ID = `${RESERVATION_ROOT_ENDPOINT}/rooms/%s/addon/%s`;
export const RESERVATION_STAY_FORM_DEMOGRAPHIC_INFO = `${RESERVATION_ROOT_ENDPOINT}/rooms/%s/demographic-informations`;
export const RESERVATION_STAY_FORM_TRANSFERT = `${RESERVATION_ROOT_ENDPOINT}/rooms/%s/flights`;

export const RESERVATION_STAY_CARD_BILL = `${RESERVATION_ROOT_ENDPOINT}/%s/bills`;
export const RESERVATION_STAY_CARD_BILL_GET_ALL = `reservation/rooms/%s/bill`;
export const RESERVATION_STAY_CARD_BILL_CREATE = `reservation/room/%s/bills`;
export const RESERVATION_STAY_CARD_BILL_POST_TRANSACTION = `${RESERVATION_ROOT_ENDPOINT}/%s/bills/post/%s/transaction`;
export const RESERVATION_STAY_CARD_BILL_PAYMENTS = `${RESERVATION_STAY_CARD_BILL}/%s/payments/%s`;
export const RESERVATION_STAY_CARD_BILL_UPDATE = `${RESERVATION_STAY_CARD_BILL}/%s/charges/%s/edit`;
export const RESERVATION_STAY_CARD_BILL_PAYMENTS_EDIT = `${RESERVATION_STAY_CARD_BILL_PAYMENTS}/edit`;
export const RESERVATION_STAY_CARD_BILL_PAYMENTS_TRANSFERT = `${RESERVATION_STAY_CARD_BILL_PAYMENTS}/transfer`;
export const RESERVATION_STAY_CARD_BILL_CHARGE_TRANSFERT = `${RESERVATION_STAY_CARD_BILL}/%s/charges/%s/transfer`;
