import { ReservationsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance, AxiosProgressEvent, AxiosResponse } from "axios";
import { ProofModel } from "models/Reservations/ProofModel";
import { UploadedFileModel } from "models/Settings/Helper/UploadedFile";
import { sprintf } from "sprintf-js";

// export const getAll =
//   (axios: AxiosInstance) =>
//     async (room_rate: number | string): Promise<Array<ProofModel>> => {
//       return await axios
//         .get(sprintf(ReservationsEndpoint.PROOF, room_rate))
//         .then(({ data }) => data.items);
//     };

export const create =
  (axios: AxiosInstance) =>
    async (formdata: FormData): Promise<UploadedFileModel | null> => {
      try {
        return await axios
          .post(sprintf(ReservationsEndpoint.PROOF, 27), formdata, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(({ data }) => data);
      } catch (e) {
        return Promise.resolve(null);
      }
    };

// export const remove =
//   (axios: AxiosInstance) =>
//     async (room_rate: number, proofId: number): Promise<AxiosResponse<ProofModel>> => {
//       return await axios.delete(
//         sprintf(ReservationsEndpoint.PROOF_DELETE, room_rate, proofId),
//       );
//     };

export const getAll =
  (axios: AxiosInstance) =>
    async (
      reservationId: number,
      roomRateId: number
    ): Promise<Array<ProofModel>> => {
      return await axios
        .get(sprintf(ReservationsEndpoint.PROOF, reservationId, roomRateId))
        .then(({ data }) => data.items);
    };

export const download =
  (axios: AxiosInstance) =>
    async (created_at: number, link: string): Promise<any> => {

      return await axios.get(link, {
        responseType: "blob",
      });
    };

export interface FileModel {
  id?: number;
  name: string;
  note: string;
  room_rate: number;
  created_at: Date;
}

export const upload =
  (axios: AxiosInstance) =>
    async ({
      data,
      onUploadProgress,
    }: {
      data: any;
      onUploadProgress: (progress: number) => void;
    }): Promise<FileModel> => {
      const formdata = new FormData();
      formdata.append("file", data.file);
      formdata.append("room_rate", data.room_rate);
      formdata.append("note", data.note);
      return await axios
        .post(
          sprintf(
            ReservationsEndpoint.PROOF,
            data.reservationId,
            data.roomRateId
          ),
          formdata,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent: AxiosProgressEvent) => {
              const progress = progressEvent.total
                ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
                : 0;
              onUploadProgress(progress);
            },
          }
        )
        .then(({ data }) => data);
    };

export const remove =
  (axios: AxiosInstance) =>
    async (
      reservationId: number,
      roomRateId: number,
      fileId: number
    ): Promise<AxiosResponse<FileModel>> => {

      return await axios.delete(
        sprintf(
          ReservationsEndpoint.PROOF_TWO_OPTION,
          reservationId,
          roomRateId,
          fileId
        )
      );
    };
