import { AxiosInstance } from "axios";
import { CashRegisterOperationService } from "services";

export interface CashRegisterOperationServiceModel {
  getAll: ReturnType<typeof CashRegisterOperationService.getAll>;
  toggleStatus: ReturnType<typeof CashRegisterOperationService.toggleStatus>;
  create: ReturnType<typeof CashRegisterOperationService.create>;
  update: ReturnType<typeof CashRegisterOperationService.update>;
  remove: ReturnType<typeof CashRegisterOperationService.remove>;
}

export const initCashRegisterOperationService = (
  axios: AxiosInstance
): CashRegisterOperationServiceModel => ({
  getAll: CashRegisterOperationService.getAll(axios),
  toggleStatus: CashRegisterOperationService.toggleStatus(axios),
  create: CashRegisterOperationService.create(axios),
  update: CashRegisterOperationService.update(axios),
  remove: CashRegisterOperationService.remove(axios),
});
