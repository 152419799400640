import { AxiosInstance } from "axios";
import { PaymentMethodService } from "services";

export interface PaymentMethodServiceModel {
  getAll: ReturnType<typeof PaymentMethodService.getAll>;
  create: ReturnType<typeof PaymentMethodService.create>;
  update: ReturnType<typeof PaymentMethodService.update>;
  remove: ReturnType<typeof PaymentMethodService.remove>;
  toggleStatus: ReturnType<typeof PaymentMethodService.toggleStatus>;
}

export const initPaymentMethodService = (
  axios: AxiosInstance
): PaymentMethodServiceModel => ({
  getAll: PaymentMethodService.getAll(axios),
  create: PaymentMethodService.create(axios),
  update: PaymentMethodService.update(axios),
  remove: PaymentMethodService.remove(axios),
  toggleStatus: PaymentMethodService.toggleStatus(axios),
});
