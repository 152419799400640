import { AxiosInstance } from "axios";
import { HouseKeepingSectionServices } from "services/Settings";

export interface HouseKeepingSectionServiceModel {
  getAll: ReturnType<typeof HouseKeepingSectionServices.getAll>;
  getOne: ReturnType<typeof HouseKeepingSectionServices.getOne>;
  create: ReturnType<typeof HouseKeepingSectionServices.create>;
  update: ReturnType<typeof HouseKeepingSectionServices.update>;
  patch: ReturnType<typeof HouseKeepingSectionServices.patch>;
  remove: ReturnType<typeof HouseKeepingSectionServices.remove>;
}

export const initHouseKeepingSectionServiceModel = (
  axios: AxiosInstance
): HouseKeepingSectionServiceModel => ({
  getAll: HouseKeepingSectionServices.getAll(axios),
  getOne: HouseKeepingSectionServices.getOne(axios),
  create: HouseKeepingSectionServices.create(axios),
  update: HouseKeepingSectionServices.update(axios),
  patch: HouseKeepingSectionServices.patch(axios),
  remove: HouseKeepingSectionServices.remove(axios),
});
