import { AxiosInstance } from "axios";
import { CardCardService } from "services/RevenueManagement/Card";

export interface CardCardServiceModel {
  create: ReturnType<typeof CardCardService.create>;
  getAll: ReturnType<typeof CardCardService.getAll>;
  getOneById: ReturnType<typeof CardCardService.getOneById>;
  update: ReturnType<typeof CardCardService.update>;
  updateAsDraft: ReturnType<typeof CardCardService.updateAsDraft>;
  discard: ReturnType<typeof CardCardService.discard>;
}

export const initCardCardService = (
  axios: AxiosInstance
): CardCardServiceModel => ({
  create: CardCardService.create(axios),
  getAll: CardCardService.getAll(axios),
  getOneById: CardCardService.getOneById(axios),
  update: CardCardService.update(axios),
  updateAsDraft: CardCardService.updateAsDraft(axios),
  discard: CardCardService.discard(axios),
});
