import { AxiosInstance } from "axios";
import { BillingGroupService } from "services";

export interface BillingGroupServiceModel {
  getAll: ReturnType<typeof BillingGroupService.getAll>;
  create: ReturnType<typeof BillingGroupService.create>;
  update: ReturnType<typeof BillingGroupService.update>;
  remove: ReturnType<typeof BillingGroupService.remove>;
  toggleStatus: ReturnType<typeof BillingGroupService.toggleStatus>;
}

export const initBillingGroupService = (
  axios: AxiosInstance
): BillingGroupServiceModel => ({
  getAll: BillingGroupService.getAll(axios),
  create: BillingGroupService.create(axios),
  update: BillingGroupService.update(axios),
  remove: BillingGroupService.remove(axios),
  toggleStatus: BillingGroupService.toggleStatus(axios),
});
