import common from "./common.json";
import landingPage from "./landing_page.json"
import CurrencyConfig from "./currency";
import PaymentMethodConfig from "./payment_method";

const FinancialConfig = {
    common,
    CurrencyConfig,
    PaymentMethodConfig,
    landingPage
};

export default FinancialConfig;