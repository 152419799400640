import { AxiosInstance } from "axios";
import { RoomLikeAndFeatureService } from "services";

export interface RoomLikeAndFeatureServiceModel {
  getAll: ReturnType<typeof RoomLikeAndFeatureService.getAll>;
  create: ReturnType<typeof RoomLikeAndFeatureService.create>;
  update: ReturnType<typeof RoomLikeAndFeatureService.update>;
  remove: ReturnType<typeof RoomLikeAndFeatureService.remove>;
  toggleStatus: ReturnType<typeof RoomLikeAndFeatureService.toggleStatus>;
}

export const initRoomLikeAndFeatureService = (
  axios: AxiosInstance
): RoomLikeAndFeatureServiceModel => ({
  getAll: RoomLikeAndFeatureService.getAll(axios),
  create: RoomLikeAndFeatureService.create(axios),
  update: RoomLikeAndFeatureService.update(axios),
  remove: RoomLikeAndFeatureService.remove(axios),
  toggleStatus: RoomLikeAndFeatureService.toggleStatus(axios),
});
