import { Box } from "@mui/material";
import { RootContextProps } from "components/RootContainer";
import i18next from "i18next";
import { AddOnModel } from "models/Settings/RevenuManagement/AddOnModel";
import { ChargeCodeModel } from "models/Settings/RevenuManagement/ChargeCodeModel";
import { PackageModel } from "models/Settings/RevenuManagement/PackageModel";
import React, { FC } from "react";
import { Outlet, RouteObject, useRouteError } from "react-router-dom";
import { Breadcrumb } from "routing/components/Breadcrumb";

const CreateAddOnPage = React.lazy(
  () => import("../pages/AddOnsPage/EditPage/CreateAddOn")
);
const EditAddOnPage = React.lazy(
  () => import("../pages/AddOnsPage/EditPage/EditAddOn")
);
const ListingAddOnsPage = React.lazy(
  () => import("../pages/AddOnsPage/ListingPage")
);
const BillingGroupPage = React.lazy(() => import("../pages/BillingGroupPage"));
const CreateChargeCodePage = React.lazy(
  () => import("../pages/ChargeCodePage/EditPage/CreateChargeCode")
);
const EditChargeCodePage = React.lazy(
  () => import("../pages/ChargeCodePage/EditPage/EditChargeCode")
);
const ListingChargeCodePage = React.lazy(
  () => import("../pages/ChargeCodePage/ListingPage")
);
const ChargeGroupPage = React.lazy(() => import("../pages/ChargeGroup"));
const ItemPage = React.lazy(() => import("../pages/ItemsPage"));
const BillingType = React.lazy(() => import("../pages/DiscountType"));
const LandingPage = React.lazy(() => import("../pages/LandingPage"));
const CreatePackagePage = React.lazy(
  () => import("../pages/PackagePage/EditPage/CreatePackage")
);
const EditPackagePage = React.lazy(
  () => import("../pages/PackagePage/EditPage/EditPackage")
);
const ListingPackagePage = React.lazy(
  () => import("../pages/PackagePage/ListingPage")
);

const SettingsCardsBoundary: FC = (): JSX.Element => {
  let error = useRouteError() as Error;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h2>Error 💥</h2>
      <p>{error.message}</p>
    </Box>
  );
};

export const routePathsRevenuManagement = (
  api: RootContextProps
): RouteObject[] => [
  {
    index: true,
    element: <LandingPage />,
    errorElement: <SettingsCardsBoundary />,
  },
  {
    path: "items",
    element: <Outlet />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t("settings:RevenuManagementConfig.landingPage.items")}
          path={"settings/revenu-management/items"}
        />
      ),
    },
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <ItemPage />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    path: "billing-type",
    element: <Outlet />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label="Discount Type"
          path={"settings/revenu-management/discount-type"}
        />
      ),
    },
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <BillingType />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    path: "charges-group",
    element: <Outlet />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t(
            "settings:RevenuManagementConfig.landingPage.charge-group"
          )}
          path={"settings/revenu-management/charges-group"}
        />
      ),
    },
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <ChargeGroupPage />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    path: "billing-group",
    element: <Outlet />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t(
            "settings:RevenuManagementConfig.landingPage.billing-group"
          )}
          path={"settings/revenu-management/billing-group"}
        />
      ),
    },
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <BillingGroupPage />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    path: "charge-code",
    element: <Outlet />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t(
            "settings:RevenuManagementConfig.landingPage.charge-code"
          )}
          path={"settings/revenu-management/charge-code"}
        />
      ),
    },
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <ListingChargeCodePage />
          </React.Suspense>
        ),
      },
      {
        path: "create-charge-code",
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <CreateChargeCodePage />
          </React.Suspense>
        ),
      },
      {
        path: ":id/edit",
        element: (
          <React.Suspense fallback={<>...</>}>
            <EditChargeCodePage />
          </React.Suspense>
        ),
        loader: async ({ params }): Promise<ChargeCodeModel | null> => {
          const {
            HotelApi: {
              settings: {
                revenuManagement: {
                  chargeCode: { getById },
                },
              },
            },
          } = api;
          if (params?.id) {
            return await getById(Number(params.id));
          }
          throw new Response("Not Found", { status: 404 });
        },
        handle: {
          crumb: (data: ChargeCodeModel) => (
            <Breadcrumb label={data?.code} path={null} />
          ),
        },
      },
    ],
  },
  {
    path: "packages",
    element: <Outlet />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t(
            "settings:RevenuManagementConfig.landingPage.packages"
          )}
          path={"settings/rate-promo/packages"}
        />
      ),
    },
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <ListingPackagePage />
          </React.Suspense>
        ),
      },
      {
        path: "create-package",
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <CreatePackagePage />
          </React.Suspense>
        ),
      },
      {
        path: ":id/edit",
        element: (
          <React.Suspense fallback={<>...</>}>
            <EditPackagePage />
          </React.Suspense>
        ),
        loader: async ({ params }): Promise<PackageModel | null> => {
          const {
            HotelApi: {
              settings: {
                rateAndPromo: {
                  package: { getById },
                },
              },
            },
          } = api;
          if (params?.id) {
            return await getById(Number(params.id));
          }
          throw new Response("Not Found", { status: 404 });
        },
        handle: {
          crumb: (data: PackageModel) => (
            <Breadcrumb label={data?.code} path={null} />
          ),
        },
      },
    ],
  },
  {
    path: "add-ons",
    element: <Outlet />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t(
            "settings:RevenuManagementConfig.landingPage.add-on"
          )}
          path={"settings/rate-promo/add-ons"}
        />
      ),
    },
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <ListingAddOnsPage />
          </React.Suspense>
        ),
      },
      {
        path: "create-addon",
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <CreateAddOnPage />
          </React.Suspense>
        ),
      },
      {
        path: ":id/edit",
        element: (
          <React.Suspense fallback={<>...</>}>
            <EditAddOnPage />
          </React.Suspense>
        ),
        loader: async ({ params }): Promise<AddOnModel | null> => {
          const {
            HotelApi: {
              settings: {
                rateAndPromo: {
                  addon: { getById },
                },
              },
            },
          } = api;
          if (params?.id) {
            return await getById(Number(params.id));
          }
          throw new Response("Not Found", { status: 404 });
        },
        handle: {
          crumb: (data: AddOnModel) => (
            <Breadcrumb label={data?.code} path={null} />
          ),
        },
      },
    ],
  },
];
