import { AxiosInstance } from "axios";
import { GuestPaymentService } from "services/Reservations/Guest";

export interface GuestPaymentMethodServiceModel {
  getByGuest: ReturnType<typeof GuestPaymentService.getByGuest>;
  create: ReturnType<typeof GuestPaymentService.create>;
  update: ReturnType<typeof GuestPaymentService.update>;
  remove: ReturnType<typeof GuestPaymentService.remove>;
}

export const initGuestPaymentMethodService = (
  axios: AxiosInstance
): GuestPaymentMethodServiceModel => ({
  getByGuest: GuestPaymentService.getByGuest(axios),
  create: GuestPaymentService.create(axios),
  update: GuestPaymentService.update(axios),
  remove: GuestPaymentService.remove(axios),
});