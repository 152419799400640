import { AxiosInstance } from "axios";
import { RoomsFloorServices } from "services/Settings";

export interface RoomsFloorServiceModel {
  getAll: ReturnType<typeof RoomsFloorServices.getAll>;
  getOne: ReturnType<typeof RoomsFloorServices.getOne>;
  create: ReturnType<typeof RoomsFloorServices.create>;
  update: ReturnType<typeof RoomsFloorServices.update>;
  patch: ReturnType<typeof RoomsFloorServices.patch>;
  remove: ReturnType<typeof RoomsFloorServices.remove>;
}

export const initRoomsFloorServiceModel = (
  axios: AxiosInstance
): RoomsFloorServiceModel => ({
  getAll: RoomsFloorServices.getAll(axios),
  getOne: RoomsFloorServices.getOne(axios),
  create: RoomsFloorServices.create(axios),
  update: RoomsFloorServices.update(axios),
  patch: RoomsFloorServices.patch(axios),
  remove: RoomsFloorServices.remove(axios),
});
