import { Components, Theme } from "@mui/material";
import { palette } from "../palette";
export const MuiButton: Components<Omit<Theme, "components">>["MuiButton"] = {
  defaultProps: {
    disableElevation: true,
  },
  styleOverrides: {
    root: {
      border: "solid 1px transparent",
      lineHeight: "normal",
      letterSpacing: "normal",
      padding: 12,
      "&.Mui-disabled": {
        border: "solid 1px transparent",
        backgroundColor: palette.colors.gray.o20,
        boxShadow: "inset 0 -2px 0 0 rgba(3, 4, 5, 0.05)",
      },
    },
    startIcon: {
      display: "flex",
      alignItems: "center",
      marginRight: "15px",
      marginLeft: "-4px",
      width: "13px",
      height: "13px",
    },
    sizeLarge: ({ theme }) => ({
      padding: "13px 14px 13px 14px",
      fontSize: theme.typography.inter16.fontSize,
      fontWeight: theme.typography.fontWeightSemibold,
      minWidth: 48,
      minHeight: 48,
    }),
    sizeMedium: ({ theme }) => ({
      padding: "11px 12px 10px 12px",
      minWidth: 40,
      fontSize: theme.typography.inter14.fontSize,
      fontWeight: theme.typography.fontWeightMedium,
      minHeight: 40,
    }),
    sizeSmall: ({ theme }) => ({
      padding: "7px 8px 6px 8px",
      minWidth: 32,
      fontSize: theme.typography.inter14.fontSize,
      fontWeight: theme.typography.fontWeightMedium,
      height: 32,
    }),
    containedSizeLarge: {
      boxShadow:
        "1px 1px 2px 0 rgba(0, 0, 0, 0.15), inset 0 -2px 0 0 rgba(3, 4, 5, 0.15)",
    },
    containedSizeMedium: {
      boxShadow:
        "1px 1px 2px 0 rgba(0, 0, 0, 0.15), inset 0 -2px 0 0 rgba(3, 4, 5, 0.15)",
    },
    containedSizeSmall: {
      boxShadow:
        "1px 1px 2px 0 rgba(0, 0, 0, 0.15), inset 0 -2px 0 0 rgba(3, 4, 5, 0.15)",
    },
    outlinedSizeMedium: {
      border: "solid 1px",
    },
    textSizeMedium: {},
    outlinedSizeSmall: {
      border: "solid 1px",
    },
    outlinedSizeLarge: {
      border: "solid 1px",
    },
    outlinedPrimary: {
      "&:hover": {
        backgroundColor: palette.colors.purple.o80,
        color: "#fff",
      },
    },
    textPrimary: {
      "&:hover": {
        backgroundColor: palette.colors.gray.o30,
      },
      "&.Mui-disabled": {
        boxShadow: "none",
      },
    },
  },
};
