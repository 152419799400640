import { AxiosInstance } from "axios";
import {
  QuickReservationSearchService,
  initQuickReservationSearchService,
} from "./QuickReservationSearchService";

export interface ReservationQuickReservationService {
  search: QuickReservationSearchService;
}

export const initReservationQuickReservationService = (
  axios: AxiosInstance
): ReservationQuickReservationService => ({
  search: initQuickReservationSearchService(axios),
});
