import { lazy, Suspense } from "react";
import { Breadcrumb } from "routing/components/Breadcrumb";
import { RoutePathDefinition } from "routing/routes";

const HouseKeepingLandingPage = lazy(() => import("../Pages/LandingPage"));
const HouseKeepingSectionsPage = lazy(() => import("../Pages/SectionsPage"));
const RoomStatePage = lazy(() => import("../Pages/StatePage"));
const HousekeeperPage = lazy(() => import("../Pages/HousekeeperPage"));
const MaintenanceTypePage = lazy(() => import("../Pages/MaintenanceTypePage"));
const WorkTypePage = lazy(() => import("../Pages/WorkTypePage"));
const WorkTaskPage = lazy(() => import("../Pages/WorkTaskPage"));

const HouseKeepingRoutes: Array<RoutePathDefinition> = [
  {
    index: true,
    element: (
      <Suspense fallback={<>...</>}>
        <HouseKeepingLandingPage />
      </Suspense>
    ),
  },
  {
    path: "sections",
    element: (
      <Suspense fallback={<>...</>}>
        <HouseKeepingSectionsPage />
      </Suspense>
    ),
    handle: {
      crumb: () => <Breadcrumb label="Sections" path={null} />,
    },
  },
  {
    path: "state",
    element: (
      <Suspense fallback={<>...</>}>
        <RoomStatePage />
      </Suspense>
    ),
    handle: {
      crumb: () => <Breadcrumb label="State" path={null} />,
    },
  },
  {
    path: "housekeeper",
    element: (
      <Suspense fallback={<>...</>}>
        <HousekeeperPage />
      </Suspense>
    ),
    handle: {
      crumb: () => <Breadcrumb label="HouseKeeper" path={null} />,
    },
  },
  {
    path: "maintenance-type",
    element: (
      <Suspense fallback={<>...</>}>
        <MaintenanceTypePage />
      </Suspense>
    ),
    handle: {
      crumb: () => <Breadcrumb label="Maintenance Type" path={null} />,
    },
  },
  {
    path: "work-type",
    element: (
      <Suspense fallback={<>...</>}>
        <WorkTypePage />
      </Suspense>
    ),
    handle: {
      crumb: () => <Breadcrumb label="Work Type" path={null} />,
    },
  },
  {
    path: "work-task",
    element: (
      <Suspense fallback={<>...</>}>
        <WorkTaskPage />
      </Suspense>
    ),
    handle: {
      crumb: () => <Breadcrumb label="Work Task" path={null} />,
    },
  },
];

export default HouseKeepingRoutes;
