import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { CashRegisterModel } from "models/Settings/Financial/CashOpertationModel";
import { OperationTypesModel } from "models/Settings/Financial/CashRegisterOperationTypeModel";

import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";

export interface Options {
  id: number;
  label: string;
}

export interface TypeModel {
  id: string | undefined;
  value: string | number;
  label: string;
}

export const remove =
  (axios: AxiosInstance) =>
  async (id: number): Promise<CashRegisterModel> => {
    return await axios.delete(
      `${SettingsEndpoint.SETTINGS_FINANCIAL_CASH_REGISTER_PATH}/${id}`
    );
  };

export const getAll =
  (axios: AxiosInstance) =>
  async (
    params?: FetchingRequestParamsModels
  ): Promise<{
    items: CashRegisterModel[];
    total: number;
  }> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_FINANCIAL_CASH_REGISTER_PATH, {
        params,
      })
      .then(({ data }) => {
        return data;
      });
  };

export const getAllOperationTypes =
  (axios: AxiosInstance) =>
  async (
    params?: FetchingRequestParamsModels
  ): Promise<OperationTypesModel> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_FINANCIAL_CASH_OPERATION_TYPE_PATH, {
        params,
      })
      .then(({ data }) => {
        return data;
      });
  };

export const getOne =
  (axios: AxiosInstance) =>
  async (id: number): Promise<CashRegisterModel> => {
    return await axios
      .get(`${SettingsEndpoint.SETTINGS_FINANCIAL_CASH_REGISTER_PATH}/${id}`)
      .then(({ data }) => {
        return data;
      });
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: CashRegisterModel): Promise<CashRegisterModel> => {
    return await axios
      .post(SettingsEndpoint.SETTINGS_FINANCIAL_CASH_REGISTER_PATH, data)
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (data: CashRegisterModel): Promise<CashRegisterModel> => {
    return await axios
      .put(
        `/${SettingsEndpoint.SETTINGS_FINANCIAL_CASH_REGISTER_PATH}/${data.id}`,
        data
      )
      .then(({ data }) => data);
  };

export const toggleStatus =
  (axios: AxiosInstance) =>
  async (id: number): Promise<CashRegisterModel> => {
    return await axios
      .patch(`/${SettingsEndpoint.SETTINGS_FINANCIAL_CASH_REGISTER_PATH}/${id}`)
      .then(({ data }) => data);
  };
