import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { RoomsTypesModel } from "models/Settings/Rooms/RoomTypeModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";

/**
 *
 * @param axios
 * @returns
 */
export const remove =
  (axios: AxiosInstance) =>
  async (id: number): Promise<RoomsTypesModel> => {
    return await axios.delete(
      `${SettingsEndpoint.SETTINGS_ROOMS_ROOM_TYPE}/${id}`
    );
  };

/**
 *
 * @param axios
 * @returns
 */
export const getAll =
  (axios: AxiosInstance) =>
  async (params?: FetchingRequestParamsModels): Promise<RoomsTypesModel[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_ROOMS_ROOM_TYPE, {
        params,
      })
      .then(({ data }) => data.items);
  };

/**
 *
 * @param axios
 * @returns
 */
export const getById =
  (axios: AxiosInstance) =>
  async (id?: number): Promise<RoomsTypesModel> => {
    return await axios
      .get(`/${SettingsEndpoint.SETTINGS_ROOMS_ROOM_TYPE}/${id}`)
      .then(({ data }) => data);
  };

/**
 *
 * @param axios
 * @returns
 */
export const create =
  (axios: AxiosInstance) =>
  async (data: RoomsTypesModel): Promise<RoomsTypesModel> => {
    return await axios
      .post(SettingsEndpoint.SETTINGS_ROOMS_ROOM_TYPE, data)
      .then(({ data }) => data);
  };

/**
 *
 * @param axios
 * @returns
 */
export const update =
  (axios: AxiosInstance) =>
  async (data: RoomsTypesModel): Promise<RoomsTypesModel> => {
    return await axios
      .put(`/${SettingsEndpoint.SETTINGS_ROOMS_ROOM_TYPE}/${data.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => data);
  };

/**
 *
 * @param axios
 * @returns
 */
export const toggleStatus =
  (axios: AxiosInstance) =>
  async (id: number): Promise<RoomsTypesModel> => {
    return await axios
      .patch(`/${SettingsEndpoint.SETTINGS_ROOMS_ROOM_TYPE}/${id}`, null, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => data);
  };
