import { VipCategoryTypeModel } from "../system";

export const chipGuestLevelColors: ChipGuestLevelColorsModel = {
  [VipCategoryTypeModel.HIGH]: {
    bgColor: "colors.yellow.o50",
    color: "string",
  },
  [VipCategoryTypeModel.MEDIUM]: {
    bgColor: "colors.orange.o40",
    color: "string",
  },
  [VipCategoryTypeModel.LOW]: { bgColor: "colors.red.o20", color: "string" },
};

type ChipGuestLevelColorsModel = Record<
  VipCategoryTypeModel,
  { bgColor: string; color: string }
>;
