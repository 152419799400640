import { AxiosInstance } from "axios";
import { ReservationRoomsAndRatesNotesService } from "services/Reservations/RoomsAndRates";

export interface RoomsAndRatesNotesServiceModel {
  create: ReturnType<typeof ReservationRoomsAndRatesNotesService.create>;
  getByRoomRate: ReturnType<
    typeof ReservationRoomsAndRatesNotesService.getByRoomRate
  >;
  remove: ReturnType<typeof ReservationRoomsAndRatesNotesService.remove>;
}

export const initRoomsAndRatesNotesService = (
  axios: AxiosInstance
): RoomsAndRatesNotesServiceModel => ({
  create: ReservationRoomsAndRatesNotesService.create(axios),
  getByRoomRate: ReservationRoomsAndRatesNotesService.getByRoomRate(axios),
  remove: ReservationRoomsAndRatesNotesService.remove(axios),
});
