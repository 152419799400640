import { ReservationsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import ReservationStayCardActionModel, {
  ReservationStayCardActionListModel,
} from "models/Reservations/StayCard/ReservationStayCardActionModel";
import { sprintf } from "sprintf-js";

export const getAll =
  (axios: AxiosInstance) =>
  async (id: number): Promise<ReservationStayCardActionListModel[]> => {
    return await axios
      .get(sprintf(ReservationsEndpoint.RESERVATION_ACTIONS_GET_ALL, id))
      .then(({ data }) => data.items);
  };

export const validate =
  (axios: AxiosInstance) =>
  async (item: {
    id: number;
    data: ReservationStayCardActionListModel;
  }): Promise<ReservationStayCardActionListModel> => {
    return await axios
      .patch(
        sprintf(
          ReservationsEndpoint.RESERVATION_ACTIONS_ITEM,
          item.id,
          item?.data?.id
        )
      )
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (item: {
    id: number;
    data: ReservationStayCardActionModel;
  }): Promise<ReservationStayCardActionModel> => {
    return await axios
      .put(
        sprintf(
          ReservationsEndpoint.RESERVATION_ACTIONS_ITEM,
          item.id,
          item?.data?.id
        ),
        item.data
      )
      .then(({ data }) => data);
  };

export const create =
  (axios: AxiosInstance) =>
  async (item: {
    id: number;
    data: ReservationStayCardActionModel;
  }): Promise<ReservationStayCardActionModel> => {
    return await axios
      .post(
        sprintf(ReservationsEndpoint.RESERVATION_ACTIONS_CREATE, item.id),
        item.data
      )
      .then(({ data }) => data);
  };
export const remove =
  (axios: AxiosInstance) =>
  async (item: {
    id: number;
    data: ReservationStayCardActionListModel;
  }): Promise<any> => {
    return await axios
      .delete(
        sprintf(
          ReservationsEndpoint.RESERVATION_ACTIONS_ITEM,
          item?.id,
          item?.data?.id
        )
      )
      .then(({ data }) => data);
  };
