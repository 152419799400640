import axios from "axios";
import { AppRunningModeModel } from "models/Shared/AppRunningModeModel";

export const createApiInstance = (baseURL: string) => {
  const instance = axios.create({
    baseURL,
    withCredentials: true,
    ...(process.env.REACT_APP_MODE !== AppRunningModeModel.STAGING
      ? {
          params: {
            fake_email: process.env.REACT_APP_API_USER_NAME,
          },
        }
      : {}),
  });

  instance.interceptors.request.use(
    (config) => {
      if (config.headers) {
        const access_token = localStorage.getItem("accessToken") || null;
        config.headers = {
          ...(access_token ? { Authorization: `Bearer ${access_token}` } : {}),
        };
      }
      return config;
    },
    (error) => {
      if (error?.response) Promise.reject(error.response);
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => response.data,
    (error) => Promise.reject(error)
  );

  return instance;
};
