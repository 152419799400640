import { Paths } from "@constants/Navigation/AppPaths";
import React from "react";
import { Breadcrumb } from "routing/components/Breadcrumb";
import { RoutePathDefinition } from "routing/routes";

const RoomStatusPage = React.lazy(() => import("../pages/RoomStatusPage"));
const WorkManagementPage = React.lazy(
  () => import("../pages/WorkManagementPage")
);

const HouseKeepingRoutes: RoutePathDefinition[] = [
  {
    path: Paths.HOUSE_KEEPING_ROOM_STATUS_PATH,
    element: (
      <React.Suspense fallback={<>...</>}>
        <RoomStatusPage />
      </React.Suspense>
    ),
    handle: {
      crumb: () => <Breadcrumb label="Room Status" path={null} />,
    },
  },
  {
    path: Paths.HOUSE_KEEPING_WORK_MANAGEMENT_PATH,
    element: (
      <React.Suspense fallback={<>...</>}>
        <WorkManagementPage />
      </React.Suspense>
    ),
    handle: {
      crumb: () => <Breadcrumb label="Work Management" path={null} />,
    },
  },
];

export default HouseKeepingRoutes;
