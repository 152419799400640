import { Components, Theme } from "@mui/material";

export const MuiPaper: Components<Omit<Theme, "components">>["MuiPaper"] = {
  styleOverrides: {
    root: {
      "&.MuiAccordion-root": {
        "&.Mui-expanded": {
          backgroundColor: "#fafbfc",
        },
        "& .MuiSvgIcon-root": {
          // marginRight: 0,
        },
      },
    },
  },
};
