import { AxiosInstance } from "axios";
import { CardsEndpoint } from "@constants/ApiEndpoints";
import { GroupModel } from "models/Settings/Cards/GroupModel";
import { GroupRevenuManagmentService } from "services";

export const getAll =
  (axios: AxiosInstance) => async (): Promise<GroupModel[]> => {
    return await axios
      .get(CardsEndpoint.CARD_GROUP_GET_ALL)
      .then(({ data }) => data.items);
  };

export interface GroupServiceRevenuManagementModel {
  getAll: ReturnType<typeof GroupRevenuManagmentService.getAll>;
}

export const initGroupRevenuManagementService = (
  axios: AxiosInstance
): GroupServiceRevenuManagementModel => ({
  getAll: GroupRevenuManagmentService.getAll(axios),
});
