import { SettingsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { RateTypeModel } from "models/Settings/RatesAndPromo/RateTypeModel";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";

export const remove =
  (axios: AxiosInstance) =>
  async (id: number): Promise<RateTypeModel> => {
    return await axios.delete(
      `${SettingsEndpoint.SETTINGS_RATES_RATE_TYPE}/${id}`
    );
  };

export const getAll =
  (axios: AxiosInstance) =>
  async (params?: FetchingRequestParamsModels): Promise<RateTypeModel[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_RATES_RATE_TYPE, {
        params,
      })
      .then(({ data }) => data.items);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: RateTypeModel): Promise<RateTypeModel> => {
    return await axios
      .post(SettingsEndpoint.SETTINGS_RATES_RATE_TYPE, data)
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (data: RateTypeModel): Promise<RateTypeModel> => {
    return await axios
      .put(`/${SettingsEndpoint.SETTINGS_RATES_RATE_TYPE}/${data.id}`, data)
      .then(({ data }) => data);
  };

export const toggleStatus =
  (axios: AxiosInstance) =>
  async (id: number): Promise<RateTypeModel> => {
    return await axios
      .patch(`/${SettingsEndpoint.SETTINGS_RATES_RATE_TYPE}/${id}`)
      .then(({ data }) => data);
  };
