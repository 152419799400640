import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  Breadcrumb,
  BreadcrumbPropsModel,
} from "routing/components/Breadcrumb";

export const Crumb: FC<Partial<BreadcrumbPropsModel>> = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <Breadcrumb label={t(props?.label as any)} path={props?.path ?? null} />
  );
};
