import { AxiosInstance } from "axios";
import { FrequencyService } from "services";

export interface FrequencyServiceModel {
  getAll: ReturnType<typeof FrequencyService.getAll>;
  getById: ReturnType<typeof FrequencyService.getById>;
  create: ReturnType<typeof FrequencyService.create>;
  update: ReturnType<typeof FrequencyService.update>;
  remove: ReturnType<typeof FrequencyService.remove>;
  toggleStatus: ReturnType<typeof FrequencyService.toggleStatus>;
}

export const initFrequencyService = (
  axios: AxiosInstance
): FrequencyServiceModel => ({
  getAll: FrequencyService.getAll(axios),
  getById: FrequencyService.getById(axios),
  create: FrequencyService.create(axios),
  update: FrequencyService.update(axios),
  remove: FrequencyService.remove(axios),
  toggleStatus: FrequencyService.toggleStatus(axios),
});
