import common from "./common.json";
import RestrictionConfig from "./restrictions";
import RulesConfig from "./rules";

const RulesAndRestrictionConfig = {
    common,
    RulesConfig,
    RestrictionConfig
};

export default RulesAndRestrictionConfig;