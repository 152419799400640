import { ReservationsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { RoomRateCustomPerRoomDataModel } from "models/Reservations/ReservationCustomModel";
import { ArrivalDepartureModel } from "models/Reservations/ReservationGeneralInformations";
import {
  StayCardItemAndPackageModel,
  StayCardModel,
  StayCardRoomAddonModel,
  StayCardRoomDemographicInformationModel,
} from "models/Reservations/StayCard/ReservationStayCardModel";
import { StayCalenderModeEnum } from "pages/ReservationModule/pages/StayCardPage/components/PageContent/components/StayPlanForm/components/StayCalendar/models";
import { PerPaxStayCalenderModel } from "pages/ReservationModule/pages/StayCardPage/components/PageContent/components/StayPlanForm/components/StayCalendar/models/PerPaxStayCalenderModel";
import { PerRoomStayCalenderModel } from "pages/ReservationModule/pages/StayCardPage/components/PageContent/components/StayPlanForm/components/StayCalendar/models/PerRoomStayCalenderModel";
import { sprintf } from "sprintf-js";
import { FetchingRequestParamsModels } from "models/Shared/FetchingRequestParamsModels";

type ParamsType = {
  voucher: string | number;
  confirmation_number: string | number;
  guest: string | number;
  night_number: number;
};

export const getStayCard =
  (axios: AxiosInstance) =>
  async (params: ParamsType): Promise<StayCardModel> => {
    return await axios
      .get(
        sprintf(ReservationsEndpoint.RESERVATION_STAY_CARD_GET, params.guest),
        { params }
      )
      .then(({ data }) => data);
  };

export const updateMarket =
  (axios: AxiosInstance) =>
  async (data: {
    room_id: number;
    market_id: number;
  }): Promise<StayCardModel> => {
    return await axios
      .patch(ReservationsEndpoint.RESERVATION_STAY_CARD_UPDATE_MARKET, data)
      .then(({ data }) => data);
  };

export const checkingGuest =
  (axios: AxiosInstance) =>
  async (data: {
    room_reservation: number;
    guest_room_id: number;
  }): Promise<StayCardModel> => {
    return await axios
      .patch(
        sprintf(
          ReservationsEndpoint.RESERVATION_STAY_CHECKING_GUEST,
          data?.room_reservation
        ),
        {
          guest_room_id: data?.guest_room_id,
        }
      )
      .then(({ data }) => data);
  };

export const updateGuestOrder =
  (axios: AxiosInstance) =>
  async (data: {
    room_reservation: number;
    new_guest: number;
  }): Promise<StayCardModel> => {
    return await axios
      .put(
        sprintf(
          ReservationsEndpoint.RESERVATION_STAY_ORDER_GUEST,
          data?.room_reservation,
          data?.new_guest
        )
      )
      .then(({ data }) => data);
  };

export const updateDemographicInfo =
  (axios: AxiosInstance) =>
  async (data: {
    id: number;
    data: StayCardRoomDemographicInformationModel;
  }): Promise<StayCardRoomDemographicInformationModel> => {
    return await axios
      .put(
        sprintf(
          ReservationsEndpoint.RESERVATION_STAY_FORM_DEMOGRAPHIC_INFO,
          data?.id
        ),
        { ...data.data }
      )
      .then(({ data }) => data);
  };

export const updateTransfert =
  (axios: AxiosInstance) =>
  async (data: {
    id: number;
    flights: ArrivalDepartureModel[];
  }): Promise<ArrivalDepartureModel> => {
    return await axios
      .put(
        sprintf(ReservationsEndpoint.RESERVATION_STAY_FORM_TRANSFERT, data?.id),
        { flights: data.flights }
      )
      .then(({ data }) => data);
  };

export const updateAddon =
  (axios: AxiosInstance) =>
  async (data: { id: number; data: any }): Promise<StayCardRoomAddonModel> => {
    return await axios
      .put(
        sprintf(ReservationsEndpoint.RESERVATION_STAY_FORM_ADDON, data?.id),
        data.data
      )
      .then(({ data }) => data);
  };

export const updateRateAndDiscount =
  (axios: AxiosInstance) =>
  async (data: { id: number; data: any }): Promise<any> => {
    return await axios
      .put(
        sprintf(
          ReservationsEndpoint.RESERVATION_STAY_FORM_RATE_AND_DISCOUNT,
          data?.id
        ),
        data.data
      )
      .then(({ data }) => data);
  };

export const updateRoomPreferences =
  (axios: AxiosInstance) =>
  async (data: { id: number; data: any }): Promise<any> => {
    return await axios
      .put(
        sprintf(
          ReservationsEndpoint.RESERVATION_STAY_FORM_ROOM_PREFERENCE,
          data?.id
        ),
        data.data
      )
      .then(({ data }) => data);
  };

/**
 *
 * @param axios
 * @returns Addons Services for stay card => to update structure TAG: #to_fix
 */

export const getAddons =
  (axios: AxiosInstance) =>
  async (data: {
    reservationId: number;
  }): Promise<StayCardRoomAddonModel[]> => {
    return await axios
      .get(
        sprintf(
          ReservationsEndpoint.RESERVATION_ROOMS_ADDONS,
          data?.reservationId
        )
      )
      .then(({ data }) => data?.items || []);
  };

export const getItemsAndPackages =
  (axios: AxiosInstance) =>
  async (
    params: FetchingRequestParamsModels
  ): Promise<StayCardItemAndPackageModel[]> => {
    return await axios
      .get(ReservationsEndpoint.RESERVATION_ITEMS_AND_PACKAGES, { params })
      .then(({ data }) => data || []);
  };

export const createAddon =
  (axios: AxiosInstance) =>
  async (data: {
    reservationId: number;
    addons: Omit<StayCardRoomAddonModel, "category">[];
  }): Promise<StayCardRoomAddonModel> => {
    return await axios
      .post(
        sprintf(
          ReservationsEndpoint.RESERVATION_ROOMS_ADDONS,
          data?.reservationId
        ),
        data
      )
      .then(({ data }) => data);
  };

export const deleteAddon =
  (axios: AxiosInstance) =>
  async (data: { reservationId: number; addonId: number }): Promise<any> => {
    return await axios
      .delete(
        sprintf(
          ReservationsEndpoint.RESERVATION_ROOMS_ADDONS_ID,
          data?.reservationId,
          data.addonId
        )
      )
      .then(({ data }) => data);
  };

/**
 * CUSTOM RESERVATION
 */

type CustomReservationParamsType = {
  room_id: number;
  mode: StayCalenderModeEnum;
};

// RESERVATION_STAY_CUSTOM_RESERVATION_PER_PAX
// RESERVATION_STAY_CUSTOM_RESERVATION_PAX_TYPE
export const getCustomStayCardPerGuest =
  (axios: AxiosInstance) =>
  async (params: CustomReservationParamsType): Promise<any> => {
    return await axios
      .get(
        sprintf(
          ReservationsEndpoint.RESERVATION_STAY_CUSTOM_RESERVATION_PER_PAX,
          params.room_id
          // params.pax_id
        ),
        { params }
      )
      .then(({ data }) => data);
  };

export const getCustomStayCardPaxType =
  (axios: AxiosInstance) =>
  async (params: any): Promise<any> => {
    return await axios
      .get(
        sprintf(
          ReservationsEndpoint.RESERVATION_STAY_CUSTOM_RESERVATION_PAX_TYPE,
          params.room_id,
          params?.type
        ),
        { params }
      )
      .then(({ data }) => data as RoomRateCustomPerRoomDataModel);
  };

export const getCustomStayTypeRoom =
  (axios: AxiosInstance) =>
  async (
    params: CustomReservationParamsType
  ): Promise<PerRoomStayCalenderModel | PerPaxStayCalenderModel> => {
    return await axios
      .get(
        sprintf(
          ReservationsEndpoint.RESERVATION_STAY_CUSTOM_RESERVATION_STAY,
          params.room_id,
          params.mode
        )
      )
      .then(({ data }) => data);
  };

export const saveCustomStayTypeRoom =
  (axios: AxiosInstance) =>
  async (
    params: CustomReservationParamsType & { data: any }
  ): Promise<PerRoomStayCalenderModel | PerPaxStayCalenderModel> => {
    return await axios
      .post(
        sprintf(
          ReservationsEndpoint.RESERVATION_STAY_CUSTOM_RESERVATION_STAY,
          params.room_id,
          params.mode
        ),
        { ...params?.data }
      )
      .then(({ data }) => data);
  };

export const updateReservationState =
  (axios: AxiosInstance) =>
  async (params: {
    room_reservation_id: number;
    state_id: number;
  }): Promise<any> => {
    return await axios
      .patch(ReservationsEndpoint.RESERVATION_STAY_REVERT_STATE, {
        room_reservation_id: params?.room_reservation_id,
        state_id: params?.state_id,
      })
      .then(({ data }) => data);
  };

export const recalculate =
  (axios: AxiosInstance) =>
  async (
    params: any & { data: any }
  ): Promise<PerRoomStayCalenderModel | PerPaxStayCalenderModel> => {
    return await axios
      .post(
        sprintf(
          ReservationsEndpoint.RESERVATION_STAY_CUSTOM_RESERVATION_STAY,
          params.room_id,
          "room"
        ),
        { ...params?.data }
      )
      .then(({ data }) => data);
  };

export const getCustomStayCardRoomType =
  (axios: AxiosInstance) =>
  async (
    params: CustomReservationParamsType & { type: "per-pax" | "per-room" }
  ): Promise<any> => {
    return await axios
      .get(
        sprintf(
          ReservationsEndpoint.RESERVATION_STAY_CUSTOM_RESERVATION_PER_PAX,
          params.room_id,
          params?.type
        ),
        { params }
      )
      .then(({ data }) => data);
  };
